import axios from "axios";
import { APPLY_JOB_SUCCESS, APPLY_JOB_FAILED } from "./actionType";
import { toast } from "react-toastify";
import { errorCall } from "../ErrorHandling/action";

export const applyJobSuccess = (data) => ({
  type: APPLY_JOB_SUCCESS,
  payload: data,
});

export const applyJobFailed = (err) => ({
  type: APPLY_JOB_FAILED,
  payload: err,
});

export const postJobs = (finalData, id) => async (dispatch) => {
  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    alternatePhone,
    location,
    gender,
    language,
    qualification,
    institute,
    passingYear,
    percentage,
    workExpYear,
    workExpMonth,
    relExpYear,
    relExpMonth,
    currentlyWorking,
    startDateYear,
    startDateMonth,
    endDateYear,
    endDateMonth,
    company,
    jobTitle,
    fileData,
  } = finalData;

  const data = {
    resume: fileData,
    first_name: firstName,
    last_name: lastName,
    contact: {
      email_primary: email,
      phone_primary: phoneNumber,
      phone_secondary: alternatePhone,
      city: location,
    },
    currently_working: currentlyWorking,
    gender: gender,
    qualifications: [
      {
        degree: qualification,
        institute: institute,
        session_end: passingYear,
        percentage: percentage,
      },
    ],
    job_histories: [
      {
        company: company,
        position: jobTitle,
        joining_year: startDateYear,
        joining_month: startDateMonth,
        termination_year: endDateYear,
        termination_month: endDateMonth,
      },
    ],
    language: language,
    total_experience: {
      month: workExpMonth,
      total_in_months: 0,
      year: workExpYear,
    },
    relevant_experience: {
      month: relExpMonth,
      total_in_months: 0,
      year: relExpYear,
    },
  };

  const token = localStorage.getItem("token");
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/auth/job-candidate/${id}`,
      data,
      header
    );
    await dispatch(applyJobSuccess(res.data));
  
    if (res.data.message === "You have already applied for this Job!") {
      toast.warn(res.data.message);
    }
  } catch (err) {
  

    if (err?.message == "Network Error" || err?.response?.status == 500) {
      dispatch(errorCall(err));
    }
  }
};
