import { Link, useNavigate } from "react-router-dom";
import { statusUpdate } from "../../Redux/CandidateReducer/action";
import { useDispatch } from "react-redux";
import { GetCandidateData, GetStatusData } from "../../Redux/Tempcandidate/action";
import { toast } from "react-toastify";
const ProfileCard = (props) => {
  const { data, value, loading, loader, setStatusChange, tempCandidate, page, setDetailShow, updateprofileMenu ,lastPage} = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleStatusUpdate = (e) => {
    setStatusChange(true);
    setDetailShow(true);
    const value = e.target.value
    const id = e.target.name
    dispatch(statusUpdate(value, id, navigate))
    .then((res)=>{
      const val=data.findIndex((e)=>e.id==id)
      data[val].color_candidate=res?.color
      return val
      // dispatch(candidate(searchData, candidateParams.designation, candidateParams.skill, candidateParams.experience, candidateParams.cities, candidateParams.rating, candidateParams.status, sort, 1))
    })
    .then((response)=>{
      dispatch(GetStatusData(value))})
      .catch((err)=>{
        console.log(err);
        dispatch((err))})
    // setPage(1);
  }
  const sentId = (element, id) => {
    dispatch(GetCandidateData(element))
    updateprofileMenu("Details")
    if (tempCandidate.id != id) {
      setStatusChange(false)
    }
  }
  const copyLink = (e) => {
    const path = `/candidates/profile/${e}`;
    const fullUrl = window.location.origin + path;

    navigator.clipboard.writeText(fullUrl).then(() => {
      toast.success("Link Copied");
    }).catch(err => {
      toast.error('Failed To Copy');
    });
  }
  return (
    <>
      <div className="row g-3 scroll_profile_card" style={{maxHeight:"800px"}} ref={value}>
        {
        // loader == false &&
          (data?.length > 0  ? (data?.map((element,index) => (
            <div className="col-md-6 col-xl-12" key={index}>
              <div className="h-100" onClick={() => sentId(element, element.id)}>
                <div className="all_profile_box h-100" style={{ borderLeft: `5px solid ${element?.color_candidate}` }}>
                  <div className="top_sec d-xl-flex align-items-center ">
                    {
                      element?.profile_image &&
                      <div>
                        <img src={`${element?.profile_image}`} alt="" />
                      </div>
                    }
                    {
                      element?.profile_image == "" && element?.gender == "Female" &&
                      <div>

                        <img src="/images/female.png" alt="" />
                      </div>
                    }
                    {
                      element?.profile_image == "" && element?.gender == "Male" &&
                      <div>
                        <img src="/images/male.jpg" alt="" />
                      </div>
                    }
                    {
                      element?.profile_image == "" && element?.gender == "Unknown" &&
                      <div>
                        <img src="/images/user-alt.png" alt="" />
                      </div>
                    }
                    <div className="rt_txt w-100 ms-xl-3 mt-2 mt-xl-0">
                      <h2 className="w-100">
                        <div className="d-inline-flex align-items-center justify-content-between ">
                          <Link
                            href="#detailed-view-tab"
                            className="me-1"
                            style={{ color: "black" }}
                          >
                            <span>
                              <b style={{ fontSize: "13px", color: "black" }}>
                                {element?.gender == "Female" ? "Ms." : "Mr."}  {element?.first_name} {element?.last_name}
                              </b>
                            </span>
                          </Link>
                          <div className="copy_link_button" onClick={() => copyLink(element?.id)}>
                            <button
                              type="button"
                              data-original-title="null"
                              className=" has_tooltip"
                            >
                              <i className="fa-solid fa-link text-dark"></i>
                              <i
                                className="bi bi-check-circle-fill"
                                style={{ color: "rgb(62, 228, 58)", display: "none" }}
                              ></i>
                            </button>
                          </div>
                        </div>
                        <span style={{ fontSize: "12px", color: "black" }}>
                          {element?.apply_date} Added
                        </span>
                      </h2>
                      <p className="mb-0">

                        <span> {element?.experience_levels?.abbreviation} {element?.designation?.name} , {element?.contact?.city}</span>
                      </p>
                      <div className="filter-tags p-1">


                        <span className="filter-tag"></span>

                      </div>
                    </div>

                  </div>
                  <div className="bottom_sec d-xxl-flex align-items-center justify-content-between mt-4 ">
                    <select className="form-control mb-3 mb-xxl-0" onChange={handleStatusUpdate} name={element?.id} defaultValue={element?.status}>
                      <option value="0">New</option>
                      <option value="1">Reviewed</option>
                      <option value="2">Good Fit</option>
                      <option value="3">Interview Scheduled</option>
                      <option value="4">Offer made</option>
                      <option value="5">Hired</option>
                      <option value="6">Rejected</option>
                      <option value="7">On Hold</option>
                    </select>
                    {element?.update_date && <h3 className="mb-0">{element?.update_date}, Last Updated</h3>}
                  </div>
                  {element?.applied_for && <div className="top_sec mt-2">
                    <small>
                      Applied for -
                      <Link
                        to="/admin/job/65252cbfa977b226705bf122/admin-job-details"
                        className=""
                      >
                        {element?.applied_for}
                      </Link>
                    </small>
                  </div>}
                </div>

              </div>
            </div>
          ))) : (!loading && !loader)&&<div className='d-flex align-items-center justify-content-center' style={{ textAlign: "center", height: '100px' }}>
          <div ></div>
        </div>)}
        {(loading || loader )&&
        [1,2,3,4].map((element, index) => (
        <div key={index} className=" col-xl-12 col-md-6  placeholder-glow">
        <div className="h-100 " >
          <div className="all_profile_box h-100" style={{ borderLeft: `5px solid`,background:"grey" }}>
            <div className="top_sec d-xl-flex align-items-center ">
                <div >
                  <img className="rounded placeholder  " src="/images/user-alt.png" alt="" />
                </div>
              <div className="rt_txt w-100 ms-xl-3 mt-2 mt-xl-0">
                <h2 className="w-100">
                  <div className="d-inline-flex align-items-center justify-content-between placeholder ">
                  <b>xxxxxxxx
                        </b>
                    <div className="copy_link_button">
                    <b>xxxx
                        </b>
                    </div>
                  </div>
                  <span className="placeholder">
                    "xxxxxxxx"
                  </span>
                </h2>
                <p className="mb-0 placeholder">
                  <span> "xxxxxxxx"</span>
                </p>
                <div className="filter-tags p-1">
                  <span className="filter-tag"></span>
                </div>
              </div>
            </div>
            <div className="bottom_sec d-xxl-flex align-items-center justify-content-between mt-4 ">
              <input className=" mb-3 mb-xxl-0 placeholder" />
           <h3 className="mb-0 placeholder">{"element?.update_date"}</h3>
            </div>
         <div className="top_sec mt-2 placeholder">
              <small>
              xxxxxxxx
              </small>
            </div>
          </div>

        </div>
      </div>))
        }
        {(data?.length !== 0 && lastPage<=page)&&
        <div className='d-flex align-items-center justify-content-center' style={{ textAlign: "center", height: '100px' }}>
        <div >No More Data</div>
      </div>}
      </div>
    </>
  );
};

export default ProfileCard;
