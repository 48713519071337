import { element } from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const ProfileDetails = (props) => {
  const [currentProfileMenu, updateprofileMenu] = useState("Qualification");
  const profileMenu = (e) => {
    updateprofileMenu(e);
  };

  const contactData = props?.value?.contact;
  const systemHardware = props?.value?.system_hardware;
  const workDetails = props?.value?.work_details;

  const copyLink = () => {
    const socialLink = props?.value?.socials?.map((element) => element?.link);
    const url = socialLink;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        toast.success("Link Copied");
      })
      .catch((err) => {
        toast.error("Failed To Copy");
      });
  };
  const handleDownload = (resumeUrl) => {
    fetch(resumeUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(
          new Blob([blob], { type: "application/pdf" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "resume");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => console.error("Error downloading file:", error));
  };
  useEffect(() => {
    updateprofileMenu("Qualification");
  }, [props.value.id]);

  return (
    <>
      <div className="profile_items_box_content px-3">
        <div className="Profile_Details_nav w-100">
          <ul className="list-unstyled d-flex flex-wrap align-items-center mb-0">
            <li>
              <button
                className={
                  currentProfileMenu === "Qualification" ? "active" : ""
                }
                onClick={() => profileMenu("Qualification")}
              >
                Qualification
              </button>
            </li>
            <li>
              <button
                className={currentProfileMenu === "Skills" ? "active" : ""}
                onClick={() => profileMenu("Skills")}
              >
                Skills
              </button>
            </li>
            <li>
              <button
                className={currentProfileMenu === "Contacts" ? "active" : ""}
                onClick={() => profileMenu("Contacts")}
              >
                Contacts
              </button>
            </li>
            <li>
              <button
                className={currentProfileMenu === "Hardware" ? "active" : ""}
                onClick={() => profileMenu("Hardware")}
              >
                Hardware
              </button>
            </li>
            <li>
              <button
                className={currentProfileMenu === "Details" ? "active" : ""}
                onClick={() => profileMenu("Details")}
              >
                Work Details
              </button>
            </li>
            <li>
              <button
                className={currentProfileMenu === "Experience" ? "active" : ""}
                onClick={() => profileMenu("Experience")}
              >
                Experience
              </button>
            </li>
            <li>
              <button
                className={currentProfileMenu === "Media" ? "active" : ""}
                onClick={() => profileMenu("Media")}
              >
                Social Media
              </button>
            </li>
          </ul>
        </div>
      </div>

      <div
        className="Profile_Details_content bg-white pt-0 border-top pb-4 px-3"
        style={{ maxHeight: "400px", overflow: "auto" }}
      >
        {/* --------------------------------------Qualification-------------------------------------------- */}
        {currentProfileMenu === "Qualification" ? (
          <div className="bg-white p-3 mb-2">
            {props?.value?.qualifications?.length > 0 ? (
              props?.value?.qualifications?.map((element, index) => (
                <div className="mb-4" key={index}>
                  <div className="txt__sm txt__para">
                    <div className="row mb-2">
                      <div className="col-4">
                        <p className="fw-bold">Degree</p>
                      </div>
                      <div className="col-8">
                        <p className="fw-normal">{element?.degree}</p>
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-4">
                        <p className="fw-bold">Institute</p>
                      </div>
                      <div className="col-8">
                        <p className="fw-normal">{element?.institute}</p>
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-4">
                        <p className="fw-bold">Passout Year</p>
                      </div>
                      <div className="col-8">
                        <p className="fw-normal">{element?.session_end}</p>
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-4">
                        <p className="fw-bold">Percentage</p>
                      </div>
                      <div className="col-8">
                        <p className="fw-normal">
                          {parseFloat(element?.percentage) > 0 &&
                            element?.percentage}
                        </p>
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-4">
                        <p className="fw-bold">Status</p>
                      </div>
                      <div className="col-8">
                        {element?.status?.map((el, index) => (
                          <p className="fw-normal" key={index}>
                            {el}
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <h6 className="text-center">No Data Found</h6>
            )}
          </div>
        ) : null}

        {/* --------------------------------------Skills-------------------------------------------- */}
        {currentProfileMenu === "Skills" ? (
          <>
            {props?.value?.skills_list?.length > 0 ? (
              <div className="p-3">
                <div className="sk-tb martop d-flex align-items-center justify-content-between p-2">
                  <h5 className="fs-5 fw-bold">Skills</h5>
                  <h5 className="fs-5 fw-bold">Experience</h5>
                </div>
                {props?.value?.skills_list?.map((element, index) => {
                  if (
                    props?.value?.skills_list?.filter(
                      (i) => i.name === element?.name
                    ).length < 2
                  ) {
                    return (
                      <div
                        className="sk-tb martop d-flex align-items-center justify-content-between bg-light mt-2 p-2"
                        key={index}
                      >
                        <h5 className="fs-6 fw-normal">{element?.name}</h5>
                        <h5 className="fs-6 fw-normal">
                          {parseInt(element?.exp_year) > 0 &&
                            element?.exp_year + " year"}
                          &nbsp;
                          {parseInt(element?.exp_month) > 0 &&
                            element?.exp_month + " month"}
                        </h5>
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            ) : (
              <h6 className="text-center">No Data Found</h6>
            )}
          </>
        ) : null}

        {/* --------------------------------------Contacts-------------------------------------------- */}
        {currentProfileMenu === "Contacts" ? (
          <>
            {
              <div className="container-fluid">
                <div className="row mt-3">
                  <div className="col">
                    <div className="sk_tb d-flex align-items-center justify-content-between">
                      <h5>Primary Phone Number</h5>
                      <h5 className=" fs-6 fw-normal mb-0">
                        {contactData?.phone_primary}
                      </h5>
                    </div>
                    <div className="sk_tb d-flex align-items-center justify-content-between">
                      <h5>Primary Email</h5>
                      <h5 className="fw-normal">
                        {contactData?.email_primary}
                      </h5>
                    </div>
                    <div className="sk_tb d-flex align-items-center justify-content-between">
                      <h5>Current Location</h5>
                      <h5 className="fw-normal">{contactData?.city}</h5>
                    </div>
                    <div className="sk_tb d-flex align-items-center justify-content-between">
                      <h5>Contact Time</h5>
                      {contactData?.contact_time?.map((element, index) => (
                        <h5 className="fw-normal" key={index}>
                          {element}
                        </h5>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            }
          </>
        ) : null}

        {/* --------------------------------------Hardware-------------------------------------------- */}
        {currentProfileMenu === "Hardware" ? (
          <>
            {systemHardware?.computer != "" ? (
              <div className="p-3">
                <div className="sk-tb d-flex mt-3 align-items-center justify-content-between">
                  <h5 className="fs-6">Computer</h5>
                  <h5 className="fw-normal fs-6">{systemHardware?.computer}</h5>
                </div>
                <div className="sk-tb d-flex mt-3 align-items-center justify-content-between">
                  <h5 className="fs-6">Processor</h5>
                  <h5 className="fw-normal fs-6">
                    {systemHardware?.processor}
                  </h5>
                </div>
                <div className="sk-tb d-flex mt-3 align-items-center justify-content-between">
                  <h5 className="fs-6">Ram(In gb)</h5>
                  <h5 className="fw-normal fs-6">{systemHardware?.ram}</h5>
                </div>
                <div className="sk-tb d-flex mt-3 align-items-center justify-content-between">
                  <h5 className="fs-6">Internet Download Speed(mbps)</h5>
                  <h5 className="fw-normal fs-6">
                    {systemHardware?.internet_download}
                  </h5>
                </div>
                <div className="sk-tb d-flex mt-3 align-items-center justify-content-between">
                  <h5 className="fs-6">Internet Upload Speed(mbps)</h5>
                  <h5 className="fw-normal fs-6">
                    {systemHardware?.internet_upload}
                  </h5>
                </div>
              </div>
            ) : (
              <h6 className="text-center">No Data Found</h6>
            )}
          </>
        ) : null}

        {/* --------------------------------------Work Details-------------------------------------------- */}
        {currentProfileMenu === "Details" ? (
          <>
            {/* {workDetails?.available != false ? ( */}
            <div className="p-3">
              <div className="sk-tb mt-3 d-flex align-items-center justify-content-between">
                <h5 className="fs-6 fw-bolder">Currenty Working</h5>
                <h5 className="fw-normal fs-6">
                  {workDetails?.currently_working}
                </h5>
              </div>
              <div className="sk-tb mt-3 d-flex align-items-center justify-content-between">
                <h5 className="fs-6 fw-bolder">Current Company</h5>
                <h5 className="fw-normal fs-6">
                  {workDetails?.current_company}
                </h5>
              </div>
              <div className="sk-tb mt-3 d-flex align-items-center justify-content-between">
                <h5 className="fs-6 fw-bolder">Current Position</h5>
                <h5 className="fw-normal fs-6">
                  {workDetails?.current_position}
                </h5>
              </div>
              <div className="sk-tb mt-3 d-flex align-items-center justify-content-between">
                <h5 className="fs-6 fw-bolder">Reason of Switching</h5>
                <h5 className="fw-normal fs-6">{workDetails?.why_switching}</h5>
              </div>
              <div className="sk-tb mt-3 d-flex align-items-center justify-content-between">
                <h5 className="fs-6 fw-bolder">Joining</h5>
                <h5 className="fw-normal fs-6">
                  {workDetails?.joining_date_month}
                  {workDetails?.joining_date_year}
                </h5>
              </div>
              {workDetails?.currently_working === "Yes" ? (
                <div className="sk-tb mt-3 d-flex align-items-center justify-content-between">
                  <h5 className="fs-6 fw-bolder">Current CTC(Per Month)</h5>
                  <h5 className="fw-normal fs-6">{workDetails?.current_ctc}</h5>
                </div>
              ) : (
                <div className="sk-tb mt-3 d-flex align-items-center justify-content-between">
                  <h5 className="fs-6 fw-bolder">Last CTC(Per Month)</h5>
                  <h5 className="fw-normal fs-6">{workDetails?.last_ctc}</h5>
                </div>
              )}
              <div className="sk-tb mt-3 d-flex align-items-center justify-content-between">
                <h5 className="fs-6 fw-bolder">Expected CTC(per Month)</h5>
                <h5 className="fw-normal fs-6">{workDetails?.expected_ctc}</h5>
              </div>
              <div className="sk-tb mt-3 d-flex align-items-center justify-content-between">
                <h5 className="fs-6 fw-bolder">Notice Period</h5>
                <h5 className="fw-normal fs-6">{workDetails?.notice_period}</h5>
              </div>
              <div className="sk-tb mt-3 d-flex align-items-center justify-content-between">
                <h5 className="fs-6 fw-bolder">Notice Period Negotiable</h5>
                <h5 className="fw-normal fs-6">
                  {workDetails?.notice_period_negotiable}
                </h5>
              </div>
            </div>
            {/* ) : <h6 className='text-center'>No Data Found</h6>} */}
          </>
        ) : null}

        {/* --------------------------------------Experience-------------------------------------------- */}
        {currentProfileMenu === "Experience" ? (
          <div className="bg-white p-3">
            <p className="text-primary txt__sm fw-bold mb-3">Job Details</p>
            {props?.value?.job_histories?.map((element, index) => (
              <div key={index}>
                {element?.company ? (
                  <div className="mb-4 txt__sm txt__para">
                    <div className="mb-3">
                      <div className="row mb-2">
                        <div className="col-6">
                          <p className="fw-bold">Company Name :</p>
                        </div>
                        <div className="col-6">
                          <p className="fw-normal">{element?.company}</p>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-6">
                          <p className="fw-bold">Position :</p>
                        </div>
                        <div className="col-6">
                          <p className="fw-normal">{element?.position}</p>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-6">
                          <p className="fw-bold">Joining :</p>
                        </div>
                        <div className="col-6">
                          <p className="fw-normal">
                            {element?.joining_month}
                            {element?.joining_year}
                          </p>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-6">
                          <p className="fw-bold">Termination :</p>
                        </div>
                        <div className="col-6">
                          <p className="fw-normal">
                            {element?.termination_month},
                            {element?.termination_year}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <p className="text-center">No Data Found</p>
                )}
              </div>
            ))}
            <p className="text-primary txt__sm fw-bold mb-3">
              Certification Details
            </p>
            {props?.value?.certifications?.map((element, index) => (
              <div key={index}>
                {element?.course_name ? (
                  <div className="mb-4 txt__sm txt__para">
                    <div className="mb-3">
                      <div className="row mb-2">
                        <div className="col-6">
                          <p className="fw-bold">Course Name :</p>
                        </div>
                        <div className="col-6">
                          <p className="fw-normal">{element.course_name}</p>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-6">
                          <p className="fw-bold">Institute :</p>
                        </div>
                        <div className="col-6">
                          <p className="fw-normal">{element.institute_name}</p>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-6">
                          <p className="fw-bold">Completion Year :</p>
                        </div>
                        <div className="col-6">
                          <p className="fw-normal">{element.completion_year}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <p className="text-center">No Data Found</p>
                )}
              </div>
            ))}
            <p className="text-primary txt__sm fw-bold mb-3">Project Details</p>
            {props?.value?.project_details?.map((element, index) => (
              <div key={index}>
                {element?.link ? (
                  <div className="mb-4 txt__sm txt__para">
                    <div className="mb-3">
                      <div className="row mb-2">
                        <div className="col-6">
                          <p className="fw-bold">Project Name :</p>
                        </div>
                        <div className="col-6">
                          <p className="fw-normal">{element.name}</p>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-6">
                          <p className="fw-bold">Project Link :</p>
                        </div>
                        <div className="col-6">
                          <p className="fw-normal text-truncate">
                            <a
                              href={element.link}
                              target="_blank"
                              className="text-truncate"
                            >
                              {element.link}
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <p className="text-center">No Data Found</p>
                )}
              </div>
            ))}
          </div>
        ) : null}

        {/* --------------------------------------Media-------------------------------------------- */}
        {currentProfileMenu === "Media" ? (
          <>
            {props?.value?.socials[0]?.name?.length != 0 ? (
              props?.value?.socials?.map((element, index) => (
                <div className="sk_tb mt-4" key={index}>
                  <h6 className="res-vlu text-truncate">{element.link}</h6>
                  {element?.link && (
                    <h6 className="res_vlu2">
                      <p id="texttocopy"></p>
                      <button onClick={() => copyLink()}>Copy Link</button>
                    </h6>
                  )}
                </div>
              ))
            ) : (
              <h6 className="text-center">No Data Found</h6>
            )}
          </>
        ) : null}
      </div>
      {currentProfileMenu === "Qualification" ? (
        !props?.value?.resume ? (
          <div className="Profile_Details_content bg-white d-100 mt-3">
            <div className="last-section p-4 d-flex  justify-content-between align-items-center w-100">
              <h2 className="mb-4">Resume</h2>
              <b>Not Available</b>
            </div>
          </div>
        ) : (
          <div className="Profile_Details_content bg-white d-100 mt-3">
            <div className="last-section p-4 d-flex  justify-content-between w-100">
              <div>
                <h2 className="mb-4">Resume</h2>
                <Link
                  to={`${props?.value?.resume}`}
                  target="_blank"
                  className="pdf d-flex align-items-center"
                >
                  <span>
                    <img src="/images/pdf-icon.png" alt="" className="me-3" />
                  </span>
                  {props?.value?.first_name}
                  {"  "}
                  {props?.value?.last_name} Resume
                </Link>
              </div>
              <div>
                <button
                  className="border-0 bg-transparent"
                  onClick={(e) => {
                    handleDownload(props?.value?.resume);
                  }}
                >
                  <img src="/images/file-icon.png" alt="" />
                </button>
                <button className="ms-2 border-0 bg-transparent">
                  <Link to={`${props?.value?.resume}`} target="_blank">
                    <img src="/images/printer-icon.png" alt="" />
                  </Link>
                </button>
              </div>
            </div>
          </div>
        )
      ) : null}
    </>
  );
};

export default ProfileDetails;
