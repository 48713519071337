import axios from "axios";
import { ADD_EXPERIENCE_FAILURE, ADD_EXPERIENCE_REQUEST, ADD_EXPERIENCE_SUCCESS } from "./actionType";
import { toast } from "react-toastify";
import { errorCall } from "../../ErrorHandling/action";

export const add_experience_req = () => ({
    type: ADD_EXPERIENCE_REQUEST
});

export const add_experience_succ = (data) => ({
    type: ADD_EXPERIENCE_SUCCESS,
    payload: data
})
export const add_experience_failure = (err) => ({
    type: ADD_EXPERIENCE_FAILURE,
    payload: err
})

export const addExperience = (breviation, name, note, sort_order, status, navigate) => (dispatch) => {
    dispatch(add_experience_req());
    const config = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
    };
    const formData = {
        form: {
            'abbreviation': breviation,
            'name': name,
            'note': note,
            'sort_order': sort_order,
            'status': status
        }
    }
    //   formData.append('framework',framework)
    //   formData.append('name',name)
    //   formData.append('skillId',skillId)
    //   formData.append('status',status)
    return axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/experienceLevel/add`, formData, config).then((res) => {
        dispatch(add_experience_succ(res.data))
        toast.success(res.data.message);
        navigate('/settings/experience');
    }).catch((err) => {
        dispatch(add_experience_failure(err))
        if (err.message === "Network Error" || err.response.status === 500) {
            dispatch(errorCall(err))
            return;
        }
        if (err?.response?.data?.error == "Unauthenticated.") {
            localStorage.removeItem("token");
            navigate("/login");
        }
        toast.error(err?.response?.data?.message);
    })
}


export const updateExperiences = (breviation, name, note, sort_order, status, id, navigate) => (dispatch) => {
    dispatch(add_experience_req());
    const config = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
    };
    const formData = {
        form: {
            'abbreviation': breviation,
            'name': name,
            'note': note,
            'sort_order': sort_order,
            'status': status,
            'id': id
        }
    }

    axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/experienceLevel/update`, formData, config).then((res) => {
        dispatch(add_experience_succ(res?.data))
        toast.success(res?.data?.message);
        navigate("/settings/experience");
        return res
    }).catch((err) => {
        const error = err?.response?.data?.errors?.map(e => (e?.name));
        
        if(error[0] == "The ExperienceLevel Name will only accept (,) and (.)."){
            toast.error("The given data is invalid")
        }
        // const errorData = err?.response?.data?.errors?.map(e => (e?.name));
        if (err?.response?.data?.error == "Unauthenticated.") {
            localStorage.removeItem("token");
            navigate("/login");
        }
        // if (errorData[0] == "The ExperienceLevel Name will only accept (,) and (.)."){
        //     toast.error("The given data is invalid")
        // }
            dispatch(add_experience_failure(err))
        if (err?.message === "Network Error" || err?.response?.status === 500) {
            dispatch(errorCall(err))
        }
        toast.error(err?.response?.data?.message);
    })
}








