import React, { useEffect, useState } from 'react'
import SettingsNavbar from '../Components/SettingsNavbar'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { BsFillArrowLeftCircleFill } from 'react-icons/bs'
import Avatar from '../Components/settings/Avatar'
import { useDispatch, useSelector } from 'react-redux'
import { getPermission } from '../Redux/settings/userPermission/action'
import { findRollf } from '../Redux/settings/findRoll/action'
import { updatePermissionf } from '../Redux/settings/update_permission/action'
import Spiner1 from '../Components/Spiner1'
import Section from '../Components/Permission/Section'
import Item from '../Components/Permission/Item'

const UpdateUserPermission = (props) => {
    const [rollname, setRollName] = useState("");
    const [status, setStatus] = useState("0");
    const [allPermissions, setAllpermissions] = useState([]);
    const [selectData, setSelectData] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const permission = useSelector((state) => state.getPermissionSet);
    const rolllist = useSelector((state) => state.findRollset);
    const updatePermission = useSelector((state) => state.updatePermission);
    const permissionList = permission?.data?.data
    const dispatch = useDispatch();

    const emptyPermission=()=>{
        setAllpermissions([])
        return 
      }

    const handleSelect=(e)=>{
        console.log('select');
        console.log(selectData);
        setSelectData(!selectData)
        const data=[]
        emptyPermission()
         e.target.checked&& permissionList?.map((e) => {e.section&&data.push(e.id)})
         setAllpermissions(data) 
      }

    useEffect(() => {
        setRollName(rolllist?.data?.data?.name);
        setStatus(rolllist?.data?.data?.status);
        const permissions = rolllist?.data?.data?.permissions
        if (Array.isArray(permissions)) {
            setAllpermissions([...permissions]);
        } else {
            // handle the case when permissions is not an array
        }
    }, [rolllist])

    const updatepermission = (e) => {
        dispatch(updatePermissionf(id, rollname, status, allPermissions, navigate))
    }

    useEffect(() => {
        dispatch(getPermission(navigate));
        dispatch(findRollf(id, navigate));
    }, []);

    const groupedPermissions = permissionList?.reduce((pre, curr) => {
        if (!pre[curr.section]) {
            pre[curr.section] = [];
        }
        pre[curr.section].push(curr);
        return pre;
    }, {});

    const formatPermissionName = (name) => {
        return name.replace(/([a-z])([A-Z])/g, '$1 $2')
            .split(" ")
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
    };
    const loader = useSelector((state) => state.getPermissionSet.isLoading);
    const rollLoader = useSelector((state) => state.findRollset.isLoading);

    return (
        <div>
            <SettingsNavbar navdata={{ header: "Update User Type Permission", buttonText: "Permission Button", buttonPath: "/settings/skillsuggetion" }} newSidedata={props.Sidedata} newonSidedataChange={props.onSidedataChange} />
            <div className="user-action-area">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Link to={"/settings/user-permission"}> <BsFillArrowLeftCircleFill /> Permission List</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="create-company-form">
                <div className="container-fluid">
                    <div className="row">
                           {loader || rollLoader?
                           <div className='d-flex align-items-center justify-content-center' style={{ textAlign: "center", height: '100px' }}>
                             <div className="loader"></div>
                           </div>
                           :
                        <div className="col-md-12">
                            <div className="type">
                                <span className='font-weight-bold ms-4' >Type</span>
                            </div>

                            <div className="form">
                                <div className="row g-2">
                                    <div className="col-md-4">
                                        <div className="position-relative list_search_fild">
                                            <input type="text" name="query" placeholder="Skill Name"
                                                aria-label="" value={rollname?rollname:""} className="form-control coustom_P h-100" disabled />
                                            <Avatar name={rollname} size={"34px"} position="absolute" top="3px" left="5px" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <select value={status} className="col-sm-4 mb-2 form-control form-control-custom" disabled>
                                            <option value="1" >Active</option>
                                            <option value="0" >Inactive</option>
                                        </select>
                                    </div>
                                    <div className="col-2 border border-grey rounded px-1 d-flex justify-content-center align-items-center" style={{ height: "4vh",width:"15vh" ,whiteSpace: "nowrap" }}>
                                        <input id='selectall' type="checkbox" checked={selectData} onChange={(e)=>{handleSelect(e)}} />
                                        <label className="form-check-label px-1" htmlFor="selectall">
                                            Select All
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <>
                            <div className="row">
                                {groupedPermissions&&Object.keys(groupedPermissions).map((section,index) =>section!=""&& (
                                    <Section key={index} title={section}>
                                        {groupedPermissions[section].map((permission,i) => (
                                            
                                            <Item  key={i+index}  allPermissions={allPermissions} setAllpermissions={setAllpermissions} id={permission.id} setSelectData={setSelectData} name={<span>{formatPermissionName(permission.name)}</span>} />
                                        ))}
                                    </Section>
                                 ))}
                            </div>
                            <button className="btn btn-success button-outline choosed mt-3" disabled={updatePermission?.isLoading || permission?.isLoading || rolllist?.isLoading} onClick={(e) => updatepermission(e)}>
                                {updatePermission?.isLoading || rolllist?.isLoading || permission?.isLoading ? <Spiner1 /> : "Update"}
                            </button>
                           </>
                        </div>
                          }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpdateUserPermission;
