import React from "react";

const Item = ({ name, id, allPermissions, setAllpermissions ,setSelectData}) => {
  const handleCheckboxChange = (event, id) => {
    setSelectData(false)
    if (event.target.checked) {
      setAllpermissions(prevAccess => [...prevAccess, id]);
    } else {
      setAllpermissions(prevAccess =>
        prevAccess.filter(accessId => accessId !== id)
      );
    }
  };
  return (
    <div className="mb-2 me-3">
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id={id}
          checked={allPermissions?.includes(id) || false}
          onChange={(e) => handleCheckboxChange(e, id)}
        />
        <label className="form-check-label" htmlFor={id}>
          {name}
        </label>
      </div>
    </div>
  );
};

export default Item;
