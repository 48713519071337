
import axios from "axios";
import {
    ADD_APPLICANT_REQUEST,
    ADD_APPLICANT_SUCCESS,
    ADD_APPLICANT_FAILURE,
    GET_APPLICANT_REQUEST,
    GET_APPLICANT_SUCCESS,
    GET_APPLICANT_FAILURE,
    TOKEN_REQUEST,
    TOKEN_SUCCESS,
    TOKEN_FAILURE
} from "./actionType";
import { errorCall } from "../ErrorHandling/action";
import { toast } from "react-toastify";


export const Applicant_Add_Req = (() => ({
    type: ADD_APPLICANT_REQUEST,
}))
export const Applicant_Add_Succ = ((data) => ({
    type: ADD_APPLICANT_SUCCESS,
    payload: data
}))
export const Applicant_Add_Fail = ((error) => ({
    type: ADD_APPLICANT_FAILURE,
    payload: error
}))

export const postApplicant = (navigate, name, email, countryCode, phone, extraDetails, testId, linkedIn, cvFile, testType) => {
    return async function applicant(dispatch, getState) {
        dispatch(Applicant_Add_Req());
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/applicant/addapplicant`, { name, email, countryCode, phone, extraDetails, testId, linkedIn, cvFile }, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
            dispatch(Applicant_Add_Succ(response?.data?.result))
            if (response?.data?.result._id) {
                window.open(`${process.env.REACT_APP_URL}instruction/${testType}/${testId}/${response?.data?.result._id}`)
            }
        } catch (error) {
            if (error.response.data.msg === "Invalid email address.") {
                toast.error("Invalid Email Address");
            }
            if (error?.message === "Network Error" || error?.response?.status === 500) {
                dispatch(errorCall(error))
                return;
            }
            dispatch(Applicant_Add_Fail(error));
        }
    }
};


export const Applicant_Get_Req = (() => ({
    type: GET_APPLICANT_REQUEST,
}))
export const Applicant_Get_Succ = ((data) => ({
    type: GET_APPLICANT_SUCCESS,
    payload: data
}))
export const Applicant_Get_Fail = ((error) => ({
    type: GET_APPLICANT_FAILURE,
    payload: error
}))


export const getSpecificApplicant = (id, navigate) => {
    return async function applicant(dispatch, getState) {
        dispatch(Applicant_Get_Req());
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/applicant/singleapplicant/${id}`);
            if (response?.data?.result) {
                dispatch(Applicant_Get_Succ(response?.data?.result))
            }
        } catch (error) {
            if (error?.message === "Network Error" || error?.response?.status === 500) {
                dispatch(errorCall(error))
                return;
            }
            dispatch(Applicant_Get_Fail(error));
        }
    }
};



export const Token_Req = (() => ({
    type: TOKEN_REQUEST,
}))
export const Token_Succ = ((data) => ({
    type: TOKEN_SUCCESS,
    payload: data
}))
export const Token_Fail = ((error) => ({
    type: TOKEN_FAILURE,
    payload: error
}))


export const validToken = (testToken, navigate) => {
    return async function test(dispatch, getState) {
        dispatch(Token_Req());
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/applicant/valid-token`, {
                testToken: testToken
            })
            if (response.data) {
                dispatch(Token_Succ(response.data))
                return response.data
            }
        } catch (error) {
            if (error?.message === "Network Error" || error?.response?.status === 500) {
                dispatch(errorCall(error))
                return;
            }
            dispatch(Token_Fail(error))
            return error?.response
        }
    }
};