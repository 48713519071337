import { LOGIN_USER_FAILURE, LOGIN_USER_REQUEST, LOGIN_USER_SUCCESS, LOGOUT_USER_REQUEST, LOGOUT_USER_SUCCESS, LOGOUT_USER_FAILURE, TOKEN_USER_REQUEST, TOKEN_USER_SUCCESS, TOKEN_USER_FAILURE } from "./actionType"



const initState = {
    isLoading: false,
    isLoadingLogout: false,
    data: null,
    logoutData: null,
    tokenData: {},

    isError: false,
    isErrorLogout: false,

    errorData: {},
    logoutErrorData: {},
    error: null
}

export const Login_Reducer = (state = initState, action) => {
    switch (action.type) {
        case LOGIN_USER_REQUEST: {
            return {
                isLoading: true,
                data: null,
                isError: false,
            }
        }
        case LOGIN_USER_SUCCESS: {
            return {
                isLoading: false,
                data: action.payload,
                isError: false,
            }
        }
        case LOGIN_USER_FAILURE: {
            return {
                isLoading: false,
                data: null,
                isError: true,
                errorData: action.payload
            }
        }
        default: {
            return state
        }
    }

}

export const Logout_Reducer = (state = initState, action) => {
    switch (action.type) {
        case LOGOUT_USER_REQUEST: {
            return {
                // ...state,
                isLoadingLogout: true,
                // logoutData: null,
                isErrorLogout: false,
            }
        }
        case LOGOUT_USER_SUCCESS: {
            return {
                // ...state,
                isLoadingLogout: false,
                // logoutData: action.payload,
                isErrorLogout: false,
            }
        }
        case LOGOUT_USER_FAILURE: {
            return {
                // ...state,
                isLoadingLogout: false,
                // logoutData: null,
                isErrorLogout: true,
                // error: action.payload.error,
                logoutErrorData: action.payload
            }
        }
        default: {
            return state
        }
    }

}
// export const token_Reducer = (state = initState, action) => {
//     switch (action.type) {
//         case TOKEN_USER_REQUEST: {
//             return {
//                 isLoading: true,
//                 tokenData: null,
//                 isError: false,
//             }
//         }
//         case TOKEN_USER_SUCCESS: {
//             return {
//                 isLoading: false,
//                 tokenData: action.payload,
//                 isError: false,
//             }
//         }
//         case TOKEN_USER_FAILURE: {
//             return {
//                 isLoading: false,
//                 tokenData: null,
//                 isError: true,
//                 errorData: action.payload.error,
//                 passError: action.payload
//             }
//         }
//         default: {
//             return state
//         }
//     }

// }