import {  FIND_CANDIDATE_FAILURE, FIND_CANDIDATE_REQUEST, FIND_CANDIDATE_SUCCESS } from "./actionType"

const initialState = {
    isLoading: false,
    data: {},
    isError: false,
    errorData: "",
    
}

export const findCandidate = (state = initialState,action)=>{
     switch(action.type){
        case FIND_CANDIDATE_REQUEST:{
           return{
            ...state,
            isLoading : true,
            data : [],
            isError : false
           }
        }
        case FIND_CANDIDATE_SUCCESS:{
            return{
                ...state,
                isLoading : false,
                data : action.payload,
                isError : false,
            }
        }
      
        case FIND_CANDIDATE_FAILURE:{
            return{
                ...state,
                isLoading : false,
                data : [],
                isError : true,
                errorData:action.payload
            }
        }
        default : {
            return state
         }
     }
}