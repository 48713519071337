import React from 'react'
import ProfileCard from './ProfileCard';
import ListCard from "./ListCard";
import GridCard from "./GridCard";
import './candidate.css';
import "react-datepicker/dist/react-datepicker.css";
import 'rc-time-picker/assets/index.css';
import { candidate } from '../../Redux/CandidateReducer/action';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
const InfiniteScroll = ({viewList, handleSort, sort, searchData, searchValue ,handleKeyPress, handleSearch ,scrollContainerRef, setShowData ,candidateArray ,candidateLoading ,loading ,setStatusChange ,tempCandidate ,setSearchData, setSort, page ,setDetailShow, setPage,updateprofileMenu,lastPage}) => {
const dispatch=useDispatch();
const navigate=useNavigate();

  const searching=(e)=>{
    dispatch(
      candidate("", viewList.designation, viewList.skill, viewList.experience, viewList.cities, viewList.rating, viewList.status, "", 1, viewList.rangeValues, navigate, viewList.onSkillCheck)
    );
    setSearchData("")
    setSort("")
  }
  return (
    <>
    <div
      className={
        viewList.viewList === "Grid" || viewList.viewList === "List" ? "col-12" : "col-xl-5"
      }
    >
      <div className="user_small_profile row g-3">
        <div className="col-sm-4 col-md-4">
          <select className="form-control profil_select me-2" onChange={handleSort} value={sort}>
            <option value="" defaultValue="selected" disabled="disabled">
              Sort By{" "}
            </option>
            <option value="created_desc">Added Recent</option>
            <option value="created_asc">Added Oldest</option>
            <option value="updated_desc">Updated Recent</option>
            <option value="updated_asc">Updated Oldest</option>
            <option value="first_name">First Name</option>
          </select>
        </div>
        <div className="col-sm-7 col-md-7">
          <span className="d-flex position-relative">
            <input
              type="text"
              name="query"
              placeholder="Search"
              className="form-control pe-4"
              value={searchData ? searchData : ""}
              onChange={(e) => {
                searchValue(e);
              }}
              onKeyDown={handleKeyPress}
            />
            <button type="button" className="fa_search" onClick={handleSearch}>
              <i aria-hidden="true" className="fa fa-search"></i>
            </button>
          </span>
        </div>
        <div className="col-sm-1 col-md-1 d-flex justify-content-center">
          <span className="d-flex position-relative crossResponsiveButton">
            <button type="button" className="form-control bg-danger " onClick={(e)=>{searching()}}>
            <i className="fa-solid fa-xmark text-white"></i>
            </button>
          </span>
        </div>
      </div>
      <div ref={scrollContainerRef} className="userCardArea my-3">
        <div className="row g-3">
        </div>
      </div>
      {viewList.viewList === "Details" ? (<ProfileCard setShowData={setShowData} value={scrollContainerRef} data={candidateArray} loading={loading} loader={candidateLoading} setStatusChange={setStatusChange} tempCandidate={tempCandidate} viewList={viewList} setSearchData={setSearchData} setSort={setSort} searchData={searchData} sort={sort} page={page} setDetailShow={setDetailShow} setPage={setPage} updateprofileMenu={updateprofileMenu}  lastPage={lastPage}/>) : null}
      {viewList.viewList === "List" ? <ListCard setShowData={setShowData} value={scrollContainerRef} data={candidateArray} loading={loading} loader={candidateLoading} page={page}  lastPage={lastPage} /> : null}
      {viewList.viewList === "Grid" ? <GridCard setShowData={setShowData} value={scrollContainerRef} data={candidateArray} loading={loading} loader={candidateLoading} page={page} lastPage={lastPage}/> : null}
    </div>
  </>
  )
}

export default InfiniteScroll