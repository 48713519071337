import React from 'react';
const TestResults = () => {


    return (
        <>
          <div className="profile_items_box_content p-3">
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <p className='txt__xs fw-bold mb-0'>Invitation has not been sent yet</p>
                    </div>
                </div>
            </div>
          </div>
        </>
    )
}
export default TestResults;