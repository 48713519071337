// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table {
  border-collapse: collapse;
}

.table thead th {
  border: none;  /* No borders for header cells */
}

.table tbody tr:first-child td {
  border-top: 1px solid #dee2e6; /* Top border for the first row */
}

.table tbody tr td {
  border: none;  /* No borders for all other cells */
}

.table tbody tr:last-child td {
  border-bottom: 1px solid #dee2e6; /* Bottom border for the last row */
}

.table-dotted {
  border-collapse: collapse;
  background-color: white;
}

.table-dotted th, .table-dotted td {
  border-top: 1px dotted #000;
  border-bottom: 1px dotted #000;
  padding: 8px 12px; /* Adjust padding as needed */
}

.table-dotted th:first-child, .table-dotted td:first-child {
  border-top: none;  /* Remove top border for the first header and cell */
}

.table-dotted th:last-child, .table-dotted td:last-child {
  border-bottom: none;  /* Remove bottom border for the last header and cell */
}
`, "",{"version":3,"sources":["webpack://./src/Pages/user/UserTable.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,YAAY,GAAG,gCAAgC;AACjD;;AAEA;EACE,6BAA6B,EAAE,iCAAiC;AAClE;;AAEA;EACE,YAAY,GAAG,mCAAmC;AACpD;;AAEA;EACE,gCAAgC,EAAE,mCAAmC;AACvE;;AAEA;EACE,yBAAyB;EACzB,uBAAuB;AACzB;;AAEA;EACE,2BAA2B;EAC3B,8BAA8B;EAC9B,iBAAiB,EAAE,6BAA6B;AAClD;;AAEA;EACE,gBAAgB,GAAG,oDAAoD;AACzE;;AAEA;EACE,mBAAmB,GAAG,sDAAsD;AAC9E","sourcesContent":[".table {\n  border-collapse: collapse;\n}\n\n.table thead th {\n  border: none;  /* No borders for header cells */\n}\n\n.table tbody tr:first-child td {\n  border-top: 1px solid #dee2e6; /* Top border for the first row */\n}\n\n.table tbody tr td {\n  border: none;  /* No borders for all other cells */\n}\n\n.table tbody tr:last-child td {\n  border-bottom: 1px solid #dee2e6; /* Bottom border for the last row */\n}\n\n.table-dotted {\n  border-collapse: collapse;\n  background-color: white;\n}\n\n.table-dotted th, .table-dotted td {\n  border-top: 1px dotted #000;\n  border-bottom: 1px dotted #000;\n  padding: 8px 12px; /* Adjust padding as needed */\n}\n\n.table-dotted th:first-child, .table-dotted td:first-child {\n  border-top: none;  /* Remove top border for the first header and cell */\n}\n\n.table-dotted th:last-child, .table-dotted td:last-child {\n  border-bottom: none;  /* Remove bottom border for the last header and cell */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
