import {  GET_USER_LIST_FAILURE, GET_USER_LIST_REQUEST, GET_USER_LIST_SUCCESS } from "./actionType"

const initialState = {
    isLoading: false,
    data: {},
    isError: false,
    errorData: "",
    
}

export const getUserManageList = (state = initialState,action)=>{ 
     switch(action.type){
        case GET_USER_LIST_REQUEST:{
           return{
            ...state,
            isLoading : true,
            // data : [],
            isError : false
           }
        }
        case GET_USER_LIST_SUCCESS:{
            return{
                ...state,
                isLoading : false,
                data : action.payload,
                isError : false,
            }
        }
      
        case GET_USER_LIST_FAILURE:{
            return{
                ...state,
                isLoading : false,
                data : [],
                isError : true,
                errorData:action.payload
            }
        }
        default : {
            return state
         }
     }
}