import axios from "axios";
import { toast } from "react-toastify";
import {
  CATEGORIES_REQUEST,
  CATEGORIES_SUCCESS,
  CATEGORIES_FAILURE,
  CATEGORIES_SINGLE_REQUEST,
  CATEGORIES_SINGLE_SUCCESS,
  CATEGORIES_SINGLE_FAILURE,
  CATEGORY_ADD_REQUEST,
  CATEGORY_ADD_SUCCESS,
  CATEGORY_ADD_FAILURE,
  CATEGORY_EDIT_REQUEST,
  CATEGORY_EDIT_SUCCESS,
  CATEGORY_EDIT_FAILURE,
  CATEGORY_STATUS_REQUEST,
  CATEGORY_STATUS_SUCCESS,
  CATEGORY_STATUS_FAILURE,
  CATEGORY_DELETE_REQUEST,
  CATEGORY_DELETE_SUCCESS,
  CATEGORY_DELETE_FAILURE
} from "./actionType";
import { errorCall } from "../ErrorHandling/action";

export const Cate_Req = ((data) => ({
  type: CATEGORIES_REQUEST,
}))
export const Cate_Succ = ((data) => ({
  type: CATEGORIES_SUCCESS,
  payload: data
}))
export const Cate_Fail = ((error) => ({
  type: CATEGORIES_FAILURE,
  payload: error
}))

export const cateGories = (webToken, id, navigate) => {
  return async function test(dispatch, getState,) {
    dispatch(Cate_Req())
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/category/${id}`, {
        headers: {
          Authorization: `Bearer ${webToken}`,
        }
      });
      dispatch(Cate_Succ(response?.data))
    } catch (error) {
      if (error?.message === "Network Error" || error?.response?.status === 500 || error?.response?.status == 404) {
         dispatch(errorCall(error))
  return;
}
      dispatch(Cate_Fail(error))
      if (error?.response?.data?.message === "Unauthenticated") {
        localStorage.removeItem("token");
         navigate("/login");
      }
    }
  }
}





export const Single_Cate_Req = ((data) => ({
  type: CATEGORIES_SINGLE_REQUEST,
}))
export const Single_Cate_Succ = ((data) => ({
  type: CATEGORIES_SINGLE_SUCCESS,
  payload: data
}))
export const Single_Cate_Fail = ((error) => ({
  type: CATEGORIES_SINGLE_FAILURE,
  payload: error
}))

export const singleCategories = (webToken, id, navigate) => {
  return async function test(dispatch, getState,) {
    try {
      dispatch(Single_Cate_Req())
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/singlecategory/${id}`, {
        headers: {
          Authorization: `Bearer ${webToken}`,
        }
      });
      if (response.data) {
        dispatch(Single_Cate_Succ(response?.data?.result));
      }
    } catch (error) {
      if (error?.message === "Network Error" || error?.response?.status === 500 || error?.response?.status === 404) {
  dispatch(errorCall(error))
  return;
}
      dispatch(Single_Cate_Fail(error))
      if (error?.response?.data?.message === "Unauthenticated") {
        localStorage.removeItem("token");
        navigate("/login");
      }
    }
  }
}



export const Add_Cate_Req = ((data) => ({
  type: CATEGORY_ADD_REQUEST,
}))
export const Add_Cate_Succ = ((data) => ({
  type: CATEGORY_ADD_SUCCESS,
  payload: data
}))
export const Add_Cate_Fail = ((error) => ({
  type: CATEGORY_ADD_FAILURE,
  payload: error
}))


export const cateAdd = (webToken, id, title, navigate) => {
  return async function test(dispatch, getState,) {
    try {
      dispatch(Add_Cate_Req())
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/addcategory/${id}`, {
        categoryName: title
      }, {
        headers: {
          Authorization: `Bearer ${webToken}`,
        }
      });
      if (response.data) {
        dispatch(Add_Cate_Succ("Category Added"))
        toast.success("Category Added Successfully")
        navigate(`/test/create-test-screen/${response?.data?.result._id}`)
      }
    } catch (error) {
      if(error?.message=="Network Error" || error?.response?.status==500){
        dispatch(errorCall(error))
        dispatch(Add_Cate_Fail(error))
      }
      else if (error?.response?.data?.message === "Unauthenticated") {
        localStorage.removeItem("token");
        navigate("/login");
      }else{ 
      dispatch(Add_Cate_Fail(error))}
    }
  }
}




export const Edit_Cate_Req = ((data) => ({
  type: CATEGORY_EDIT_REQUEST,
}))
export const Edit_Cate_Succ = ((data) => ({
  type: CATEGORY_EDIT_SUCCESS,
  payload: data
}))
export const Edit_Cate_Fail = ((error) => ({
  type: CATEGORY_EDIT_FAILURE,
  payload: error
}))
export const editCategory = (webToken, cateid, categoryName, navigate) => {
  return async function test(dispatch, getState) {
    dispatch(Edit_Cate_Req())
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/editcategory/${cateid}`, {
        // testId: testId,
        categoryName: categoryName
      }, {
        headers: {
          Authorization: `Bearer ${webToken}`,
        }
      }
      );
      if (res) {
        dispatch(Edit_Cate_Succ("Success"))

      }
    } catch (error) {
      if (error?.message === "Network Error" || error?.response?.status === 500) {
  dispatch(errorCall(error))
  return;
}
      dispatch(Cate_Fail(error))
      if (error?.response?.data?.message === "Unauthenticated") {
        localStorage.removeItem("token");
        navigate("/login");
      }
    };
  };
};




export const Status_Cate_Req = ((data) => ({
  type: CATEGORY_STATUS_REQUEST,
}))
export const Status_Cate_Succ = ((data) => ({
  type: CATEGORY_STATUS_SUCCESS,
  payload: data
}))
export const Status_Cate_Fail = ((error) => ({
  type: CATEGORY_STATUS_FAILURE,
  payload: error
}))

//not in use
// export const statusChangeCategory = (webToken, cateid, status) => {
//   return async function test(dispatch, getState) {
//     dispatch(Status_Cate_Req())
//     try {
//       const response = await axios.put(
//         `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/statuschange/${cateid}`, {
//         status: status
//       }, {
//         headers: {
//           Authorization: `Bearer ${webToken}`,
//         }
//       }
//       );
//       if (response.data) {
//         dispatch(Status_Cate_Succ(response.data))
//       }
//     } catch (error) {
//       if(error.message=="Network Error"){
//         dispatch(errorCall(error))
//     }
//       if (error?.response?.data?.message === "Unauthenticated") {
//         dispatch(Status_Cate_Fail(error.response?.data));
//       }
//     };
//   };
// };
//not in use



export const Delete_Cate_Req = ((data) => ({
  type: CATEGORY_DELETE_REQUEST,
}))
export const Delete_Cate_Succ = ((data) => ({
  type: CATEGORY_DELETE_SUCCESS,
  payload: data
}))
export const Delete_Cate_Fail = ((error) => ({
  type: CATEGORY_DELETE_FAILURE,
  payload: error
}))
export const deleteCategory = (webToken, cateid, id, navigate) => {
  return async function test(dispatch, getState) {
    dispatch(Delete_Cate_Req())
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/deletecategory/${cateid}`, {
        headers: {
          Authorization: `Bearer ${webToken}`,
        }
      }
      )
      if (response.data.msg) {
        dispatch(Delete_Cate_Succ(response.data))
        dispatch(cateGories(webToken, id))
        toast.success(response.data.msg)
      }
    } catch (error) {
      if (error?.message === "Network Error" || error?.response?.status === 500) {
  dispatch(errorCall(error))
  return;
}
      dispatch(Delete_Cate_Fail(error))
      if (error?.response?.data?.message === "Unauthenticated") {
        localStorage.removeItem("token");
        navigate("/login");
        // window.location.href = 'https://hirekolkata.klizos.com/login'
        //  toast.warn("token is invalid")
      }
    };
  };
};