import React from 'react'

const Spiner1 = () => {
    return (
        <div className="spinner-border spinner-grow-sm text-light" role="status">
            
        </div>
    )
}

export default Spiner1
