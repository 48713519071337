import React from "react";
import "../BlurLoader/BlurLoader.css";
export const BlurLoader = () => {
  return (
    <div className="bluer_box">
      <div className="text-center py-3 loaderBox">
        <div></div>
        <span className="loader"></span>
      </div>
    </div>
  );
};
