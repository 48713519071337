import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showCorrectAnswer } from '../../Redux/QuestionReducer/action';
import { useNavigate, useParams } from 'react-router-dom';
import { ansData, singleResult } from '../../Redux/ResultReducer/action';
import parse from "html-react-parser";
import '../components/css/TestResultShows.css'

const AnswerSheet = (props) => {
    const navigate = useNavigate();
    const sessionTokenValue = localStorage.getItem("token");

    const webToken = sessionTokenValue


    const answerData=useSelector((state)=>state.resultData?.ansData)
    const loadingData=useSelector((state)=>state.resultData)
    const dispatch = useDispatch();
    const { id } = useParams();
    // converting html value to normal text
    const extractStringFromHTML=(htmlString)=> {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlString;
        const extractedString = tempElement.textContent || tempElement.innerText;
    
        return extractedString;
      }

    const menuClick = () => {
      props.onSidedataChange(!props.newSidedata);
    };


    useEffect(()=>{
        dispatch(ansData(navigate, webToken, id))
    },[])
    return (
      <>
        {loadingData?.isLoadingAns ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ textAlign: "center", height: "100px" }}
          >
            <div className="loader"></div>
          </div>
        ) : (
          <section className="answer_sheet">
            <div className="container">
              <button className="fa_bars  d-lg-none" onClick={menuClick}>
                <i className="fa-solid fa-bars fs-4"></i>
              </button>
              <div className="answer_sheet_header border-bottom py-4">
                <h3 className="fw-bold">{answerData?.response?.testName}</h3>
                <h5 className="mb-4">
                  Passed By{" "}
                  <span className="fw-bold text-primary">
                    {answerData?.response?.applicant}
                  </span>
                </h5>
                <p>
                  The green <span className="badge bg-success">background</span>{" "}
                  indicated the correct answers{" "}
                </p>
                <div className="error_check">
                  <div className="form-check right_answer">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                      id="flexCheckDefault"
                      defaultChecked="true"
                      disabled
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      Selected green checkboxes indicate{" "}
                      <span className="fw-bold">
                        the right answers given by the candidate{" "}
                      </span>
                    </label>
                  </div>
                  <div className="form-check wrong_answer">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                      id="flexCheckChecked"
                      defaultChecked="true"
                      disabled
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckChecked"
                    >
                      Selected red checkboxes indicate{" "}
                      <span className="fw-bold">
                        the wrong answers given by the candidate{" "}
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              {answerData?.response?.candidatequestion.map(
                (Question, quesNum) => (
                  <div key={quesNum} className="answer_sheet_body py-4 border-bottom">
                    <h1 className="fs-4">
                      {" "}
                      <span className="fw-bold">
                        Question # {quesNum + 1}
                      </span>{" "}
                      <span className="fs-6 text-secondary">
                        (Attention To Detail)
                      </span>
                    </h1>
                    <div className="bg-light border-radius mt-3 mb-4 p-3 border">
                      <div className="row g-3 justify-content-between">
                        <div className="col-auto">
                          <h6 className="mb-0">
                            Score -{" "}
                            <span className="fw-bold">
                              {(Question?.score).toFixed()}/
                              {(100 /
                                answerData?.response?.candidatequestion?.length).toFixed()}
                            </span>
                          </h6>
                        </div>
                        <div className="col-auto">
                          <h6 className="mb-0">
                            Duration -{" "}
                            <span className="fw-bold">
                              {Question?.duration}
                            </span>
                          </h6>
                        </div>
                        <div className="col-auto">
                          <h6 className="mb-0">
                            Date -{" "}
                            <span className="fw-bold"> {Question?.date}</span>
                          </h6>
                        </div>
                        <div className="col-auto">
                          <h6 className="mb-0">
                            Time -{" "}
                            <span className="fw-bold">{Question?.time}</span>
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="answer_sheet_footer py-4">
                      <h6> {extractStringFromHTML(Question?.question)}</h6>
                      {Question.answer.map((AnsOption, OptionNum) => (
                        <div key={OptionNum} className="wrong_right_area error_check mt-4">
                          {AnsOption?.type == "Right and chosen" && (
                            <div
                              key={OptionNum + 1}
                              className="bg_success_light border-radius border py-2 px-3 mb-3"
                            >
                              <div className="form-check mb-0  right_answer">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="flexCheckDefault"
                                  disabled
                                  defaultChecked="true"
                                />
                                <label
                                  className="form-check-label form_check_label d-flex align-items-center fw-semi"
                                  htmlFor="flexCheckDefault"
                                >
                                  <span className="me-2">
                                    {OptionNum + 1}
                                    {"   "}
                                  </span>{" "}
                                  {extractStringFromHTML(AnsOption?.value)}
                                </label>
                              </div>
                            </div>
                          )}
                          {AnsOption?.type == "Wrong and chosen" && (
                            <div
                              key={OptionNum + 1}
                              className="form-check mb-0 wrong_answer ms-4 py-2 px-3 mb-3"
                            >
                              <input
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                                id="flexCheckChecked"
                                defaultChecked="true"
                                disabled
                              />
                              <label
                                className="form-check-label form_check_label d-flex align-items-center "
                                htmlFor="flexCheckChecked"
                              >
                                <span className="me-2">
                                  {OptionNum + 1}
                                  {"   "}
                                </span>
                                {extractStringFromHTML(AnsOption?.value)}
                              </label>
                            </div>
                          )}
                          {AnsOption?.type == "Right and not chosen" && (
                            <div
                              key={OptionNum + 1}
                              className="bg_success_light border-radius border py-2 px-3 mb-3"
                            >
                              <div className="form-check mb-0 ">
                                <input
                                  className="form-check-input  bg-transparent"
                                  type="checkbox"
                                  defaultValue=""
                                  id="flexCheckDefault"
                                  disabled
                                />
                                <label
                                  className="form-check-label  fw-semi form_check_label d-flex align-items-center"
                                  htmlFor="flexCheckDefault"
                                >
                                  <span className="me-2">
                                    {OptionNum + 1}
                                    {"   "}
                                  </span>{" "}
                                  {extractStringFromHTML(AnsOption?.value)}
                                </label>
                              </div>
                            </div>
                          )}
                          {AnsOption?.type == "Wrong and not chosen" && (
                            <div
                              key={OptionNum + 1}
                              className="form-check mb-0 py-2 ms-4 px-3 mb-3"
                            >
                              <input
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                                id="flexCheckDefault"
                                disabled
                              />
                              <label
                                className="form-check-label form_check_label d-flex align-items-center"
                                htmlFor="flexCheckDefault"
                              >
                                <span className="me-2">
                                  {OptionNum + 1}
                                  {"   "}
                                </span>
                                {extractStringFromHTML(AnsOption?.value)}
                              </label>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )
              )}
            </div>
          </section>
        )}
      </>
    );
}

export default AnswerSheet
