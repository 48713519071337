import React, { useEffect, useRef, useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import "../../Pages/candidates/candidates.css";
import Select from "react-select";
import axios from "axios";
import { toast } from "react-toastify";
import Spiner1 from "../Spiner1";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';
import { Document, Page } from 'react-pdf';

const monthOptions = [
  { value: "", label: "In Months" },
  { value: 1, label: "January" },
  { value: 2, label: "February" },
  { value: 3, label: "March" },
  { value: 4, label: "April" },
  { value: 5, label: "May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October" },
  { value: 11, label: "November" },
  { value: 12, label: "December" },
];
const currentYear = new Date().getFullYear();
const startYear = currentYear - 30;
const endYear = currentYear
const yearOptions = [{ value:"", label:"In Years" }];
for (let year = startYear; year <= endYear; year++) {
  yearOptions.push({ value: year.toString(), label: year.toString() });
}

const numberOption = [];
for (let num = 0; num <= 11; num++) {
  numberOption.push(num);
}
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused ? "2px solid #41b883" : "#000",
    backgroundColor: state.isDisabled ? "#ccc" : "#fff",
    borderRadius: "0.375rem",
    minHeight: "45px",
    boxShadow: state.isFocused && "none",
    color: state.isSelected ? "#41b883" : "#000",
    fontSize: "14px",
    "&:hover": {
      borderColor: state.isFocused ? "#41b883" : "#41b883",
      boxShadow: "none",
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#333",
  }),
  input: (provided) => ({
    ...provided,
    color: "#333",
    fontSize: "14px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused || state.isSelected ? "#41b883" : "#fff",
    color: state.isSelected ? "white" : "#333",
    fontSize: "14px",
    "&:hover": {
      color: state.isFocused ? "#fff" : "#fff",
      boxShadow: "none",
    },
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#eee",
    borderRadius: "0.375rem",
  }),
  menuList: (provided) => ({
    ...provided,
    backgroundColor: "#fff",
    color: "#eee",
    borderRadius: "0.375rem",
  }),
};
const ExperienceData = ({ contact, profileData }) => {

  const navigate = useNavigate();

  const [isCurrentlyWorking, setIsCurrentlyWorking] = useState("");
  const [resume, setResume] = useState("");
  const [crrentlyWorking, setCurrentWorking] = useState("Yes");
  const [joiningfromDate, setjoiningFromDate] = useState("");
  const [joining_month, setJoining_month] = useState("");
  const [endDate, setEnd] = useState("");
  const [workNote, setWorkNote] = useState("");
  const [jobHistory, setJobHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [project, setProject] = useState([]);
  const [cirtificate, setCirtificate] = useState([]);
  const [selectedCVFile, setSelectedCVFile] = useState(null);
  const [showPdf, setShowPdf] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const [histYearError, setHistYearError] = useState("");

  const handleClose = () => setShowPdf(false);
  const handleShow = () => setShowPdf(true);

  const [totalExp, setTotalexp] = useState({
    month: 0,
    total_in_months: 0,
    year: 0,
  });
  const [workDetails, setWorkDetails] = useState({
    current_company: "",
    current_ctc: "",
    current_position: "",
    currently_working: "Yes",
    expected_ctc: "",
    job_preference: [],
    joining_date_month: 0,
    joining_date_year: 0,
    last_company: "",
    last_ctc: "",
    last_position: "",
    notice_period: "",
    notice_period_negotiable: "",
    termination_date_month: 0,
    termination_date_year: 0,
    why_switching: "",
    why_unemployed: "",
    available: "",
  });

  const url = resume;
  // const index = url?.indexOf("resume/") + "resume/"?.length;
  // const resumeName = url?.substring(index);

  let duplicateJobHistory=jobHistory
  let duplicateProject=project
  let duplicateCentificate=cirtificate
  const handleWorkDetails = (e) => {
    const field = e.target.name;
    setWorkDetails((prevWorkDetails) => {
      return {
        ...prevWorkDetails,
        [field]: e.target.value,
        ...(field === "joining_date_year" && prevWorkDetails.termination_date_year <= e.target.value ? { termination_date_year: "" } : {})
      };
    });
  };
  
  
  const handleAvailable = (event) => {
    setWorkDetails({
      ...workDetails,
      [event.target.name]: !workDetails?.available,
    });
  };

  const addJobHistory = (e) => {
    const jobhistory = {
      company: "",
      joining_month: "",
      joining_year: "",
      position: "",
      termination_month: "",
      termination_year: "",
    };
    jobHistory[jobHistory.length-1].company === "" ? toast.error("Please fill Company Name") : 
    setJobHistory([...jobHistory, jobhistory]);
  };

  const removeJobHistory = (e, index) => {
    setJobHistory(jobHistory.filter((_, i) => i !== index));
  };


  const resetHist = (e, index) => {
    setHistYearError("")
    const histElement = [...jobHistory];
        histElement[index] = {
        company: "",
        joining_month:"",
        joining_year: "",
        position: "",
        termination_month:"",
        termination_year: ""
    };
    setJobHistory(histElement);
};

  
  const handleAllInputs = (event, index) => {
    setHistYearError("")
    const name = event.target.name;
    setJobHistory((prevQuqlification) => {
      const updatedQualification = [...prevQuqlification];
      updatedQualification[index][name] = event.target.value;
      return updatedQualification;
    });
  };

  const handleJioningMonth = (event, index) => {
    setHistYearError("")
    setJobHistory((prevQuqlification) => {
      const updatedQualification = [...prevQuqlification];
      updatedQualification[index].joining_month = event.label;
      return updatedQualification;
    });
  };

  const handleJioningYear = (event, index) => {
    setHistYearError("")
    setJobHistory((prevJobHistory) => {
      const updatedJobHistory = [...prevJobHistory];
      if(updatedJobHistory[index].termination_year <= event.value){
        setHistYearError("Please Provide A Valid Job History");
        // updatedJobHistory[index].termination_year = "";
      }
      updatedJobHistory[index].joining_year = event.value;
      return updatedJobHistory;
    });
  };
  

  const handleEndMonth = (event, index) => {
    setHistYearError("")
    setJobHistory((prevQuqlification) => {
      const updatedQualification = [...prevQuqlification];
      updatedQualification[index].termination_month = event.label;
      return updatedQualification;
    });
  };

  const handleEndYear = (event, index) => {
    setHistYearError("")
    setJobHistory((prevQuqlification) => {
      const updatedQualification = [...prevQuqlification];
      updatedQualification[index].termination_year = event.value;
      return updatedQualification;
    });
  };
  const addCirtificate = (event) => {
    const cirtificates = {
      completion_year: "",
      course_name: "",
      institute_name: "",
    };
    cirtificate[cirtificate.length-1].course_name === "" ? toast.error("Please fill Certificate Name") : 
    setCirtificate([...cirtificate, cirtificates]);
  };

  const removeCirtificate = (event, index) => {
    setCirtificate(cirtificate.filter((_, i) => i !== index));
  };
  const resetCertificate = (e, index) => {
    setCirtificate(prevCertificates => {
        return prevCertificates.map((certificate, i) => {
            if (i !== index) {
                // For elements other than the one at the specified index, returning them unchanged
                return certificate;
            }

            // For the element at the specified index, creating a shallow copy and update properties
            return {
                completion_year: "In Years",
                course_name: "",
                institute_name: ""
            };
        });
    });
};

  const handleAllCirtificates = (event, index) => {
    const name = event.target.name;
    setCirtificate((prevCirtificate) => {
      const updatedCirtificate = [...prevCirtificate];
      updatedCirtificate[index][name] = event.target.value;
      return updatedCirtificate;
    });
  };
  const handleCourseCompletedYear = (event, index) => {
    setCirtificate((prevCirtificate) => {
      const updatedCirtificate = [...prevCirtificate];
      updatedCirtificate[index].completion_year = event.value;
      return updatedCirtificate;
    });
  };
  const addProject = () => {
    const projects = {
      name: "",
      link: "",
    };
    project[project.length-1].name === "" ? toast.error("Please fill Project Name") :
    setProject([...project, projects]);
  };

  const removeProject = (e, index) => {
    setProject(project.filter((_, i) => i !== index));
  };
  const resetProject = (value, index) => {
    setProject((prevProject) => {
        return prevProject.map((projectItem, i) => {
            if (i !== index) {
                return projectItem;
            }
            return {
                ...projectItem,
                name: "",
                link: ""
            };
        });
    });
};

  const handleProject = (event, index) => {
    const name = event.target.name;
    setProject((prevProject) => {
      const updatedProject = [...prevProject];
      updatedProject[index][name] = event.target.value;
      return updatedProject;
    });
  };
  const getLocaldata = () => {
    const token = localStorage.getItem("token");
    return token;
  };
  const isValidUrl = (url) => {
    if (url.match(/\S+\./) &&url!="") {
      return true; // Empty string or null is considered valid
    }
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };
  
  const validateLinks = (dataArray) => {
    const invalidLinks = [];
    dataArray.forEach((item, index) => {
      if (!isValidUrl(item.link)&&item.link ) {
        return invalidLinks.push(`Project ${index+1}`);
      }
    });
    return invalidLinks;
  };
  const submitWork = async () => {
    const socialMediaName = project?.map((e) => e.name);
    setIsLoading(true);

if(!profileData?.first_name){
  toast.error('Please Provide First Name.');
  setIsLoading(false)
}else if(!profileData?.last_name){
  toast.error('Please Provide Last Name.');
  setIsLoading(false)
}else if(!profileData?.designation){
  toast.error('Please Provide Designation.');
  setIsLoading(false)
}else if(!profileData?.experience_levels){
  toast.error('Please Provide Experience.');
  setIsLoading(false)
}
else if(workDetails?.currently_working === "Yes" && !totalExp?.month && !totalExp?.year || workDetails?.currently_working === "Yes" &&parseInt(totalExp?.month)+parseInt(totalExp?.year)==0){
  toast.error('Please Provide Total Experience.');
  setIsLoading(false)
}
else if(workDetails?.currently_working === "Yes" && !workDetails?.current_company){
  toast.error('Please Provide Current Company.');
  setIsLoading(false)
}
else if(workDetails?.currently_working === "Yes" && !workDetails?.current_position){
  toast.error('Please Provide Current Position.');
  setIsLoading(false)
}
else if(workDetails?.currently_working === "No" && !totalExp?.month && !totalExp?.year || workDetails?.currently_working ==="No" && totalExp?.year=="0" && totalExp?.month==0){
  toast.error('Please Provide Total Experience.');
  setIsLoading(false)
}
else if(workDetails?.currently_working ==="No" &&parseInt(workDetails?.termination_date_year) < parseInt(workDetails?.joining_date_year)){
  toast.error('The termination date must greater than the joining date.');
  setIsLoading(false)
}
else if(workDetails?.currently_working ==="No" &&parseInt(workDetails?.termination_date_year)== parseInt(workDetails?.joining_date_year)&&parseInt(workDetails.joining_date_month)>=parseInt(workDetails.termination_date_month)){
  toast.error('The termination date must greater than the joining date.');
  setIsLoading(false)
}
else if (validateLinks(project).length > 0) {
  toast.error("Please Provide valid links at: " + validateLinks(project));
  setIsLoading(false);
}
else if(histYearError){
  toast.error(histYearError);
  setIsLoading(false)
}

else{
    const mt = getLocaldata();
    const data = {
      id: contact?.id,
      first_name: profileData?.first_name,
      last_name: profileData?.last_name,
      designation_id: profileData?.designation,
      experience_level_id: profileData?.experience_levels,
      certifications: cirtificate,
      job_histories: jobHistory,
      project_details: project,
      resume: profileData.resume,
      total_experience: totalExp,
      work_details: { ...workDetails },
      work_note: workNote,
    };

    const config = {
      headers: { Authorization: `Bearer ${mt}` },
    };

    try {

    

      const axiosCall=async ()=> {
       const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/candidate_update_work_details`, data, config);
      setIsLoading(false);
      toast.success("Candidate Details Updated Successfully");}

      const reader = new FileReader();
      reader.onload = function (event) {
        data.resume = event.target.result;
        axiosCall(data);
      };
      if (
        profileData?.resume?.type
      ) {
        reader.readAsDataURL(profileData.resume);
      } else {
        axiosCall(data);
      }


      
    } catch (err) {
      setIsLoading(false);
      toast.error("Something went wrong");
      if (err.response?.data?.message === "Unauthenticated.") {
        localStorage.removeItem("token");
        navigate("/login");
      }
    }


    

}

  };
  const handleTotalExp = (e) => {
    setTotalexp((prev) => {
      let updatedexp = { ...prev };
      updatedexp[e.target.name] = e.target.value;
      return updatedexp;
    });
  };
  useEffect(() => {
    setWorkDetails((prev) => {
      return {
        ...prev,
        current_company: contact?.work_details?.current_company,
        current_ctc: contact?.work_details?.current_ctc,
        current_position: contact?.work_details?.current_position,
        currently_working: contact?.work_details?.currently_working,
        expected_ctc: contact?.work_details?.expected_ctc,
        job_preference: contact?.work_details?.job_preference,
        joining_date_month: contact?.work_details?.joining_date_month,
        joining_date_year: contact?.work_details?.joining_date_year,
        last_company: contact?.work_details?.last_company,
        last_ctc: contact?.work_details?.last_ctc,
        last_position: contact?.work_details?.last_position,
        notice_period: contact?.work_details?.notice_period,
        notice_period_negotiable:
          contact?.work_details?.notice_period_negotiable,
        termination_date_month: contact?.work_details?.termination_date_month,
        termination_date_year: contact?.work_details?.termination_date_year,
        why_switching: contact?.work_details?.why_switching,
        why_unemployed: contact?.work_details?.why_unemployed,
        work_note: contact?.work_details?.work_note,
        available: contact?.work_details?.available,
      };
    });
    setTotalexp((prev) => {
      return {
        ...prev,
        month: contact?.total_experience?.month,
        total_in_months: contact?.total_experience?.total_in_months,
        year: contact?.total_experience?.year,
      };
    });
    setIsCurrentlyWorking(contact?.work_details?.currently_working);
    setResume(contact?.resume);
    setJobHistory(contact?.job_histories);
    setjoiningFromDate(contact?.job_histories[0]?.joining_year);
    setJoining_month(contact?.job_histories[0]?.joining_month);
    setEnd(contact?.job_histories[0]?.termination_month);
    setWorkNote(contact?.work_details?.work_note);
    setProject(contact?.project_details);
    setCirtificate(contact?.certifications);
  }, [contact]);

  return (
    <>
      <div className="tab-data mt-4" step={0}>
        <div className="grid_2_md ">
          <div className="grid-box">
            <div className="form-group-gap">
              <div className="form-label-box">
                <label className="txt__para txt__sm fw-bold">
                  Currently Working (Yes/No)
                </label>
              </div>
              <div className="form-control-box">
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    id="customRadioInline10"
                    defaultValue="Yes"
                    name="currently_working"
                    className="form-check-input"
                    checked={workDetails?.currently_working === "Yes"}
                    onChange={(e) => {
                      setIsCurrentlyWorking(e.target.value);
                      setCurrentWorking("Yes");
                      handleWorkDetails(e);
                    }}
                  />
                  <label
                    htmlFor="customRadioInline10"
                    className="form-check-label"
                  >
                    Yes
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    id="customRadioInline20"
                    name="currently_working"
                    defaultValue="No"
                    className="form-check-input"
                    checked={workDetails?.currently_working === "No"}
                    onChange={(e) => {
                      setIsCurrentlyWorking(e.target.value);
                      setCurrentWorking("No");
                      handleWorkDetails(e);
                    }}
                  />
                  <label
                    htmlFor="customRadioInline20"
                    className="form-check-label"
                  >
                    No
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    id="customRadioInline30"
                    name="currently_working"
                    defaultValue="Fresher"
                    className="form-check-input"
                    checked={workDetails?.currently_working === "Fresher"}
                    onChange={(e) => {
                      setIsCurrentlyWorking(e.target.value);
                      setCurrentWorking("Fresher");
                      handleWorkDetails(e);
                    }}
                  />
                  <label
                    htmlFor="customRadioInline30"
                    className="form-check-label"
                  >
                    Fresher
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        {workDetails?.currently_working === "Yes" ? (
          <div>
            <div className="currently_working">
              <div className="row g-3">
                <div className="col-sm-6">
                  <div className="grid-box">
                    <div className="form-group-gap">
                      <div className="form-label-box">
                        <label className="mb-2 txt__para txt__sm fw-bold">
                          Why looking for change?
                        </label>
                      </div>
                      <div className="form-control-box">
                        <textarea
                          rows="1"
                          name="why_switching"
                          style={{minHeight:"21px",maxHeight:"21px"}}
                          value={workDetails.why_switching}
                          placeholder="Type here..."
                          className="form-control-no-border"
                          onChange={(e) => {
                            handleWorkDetails(e);
                          }}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="grid-box">
                    <div className="form-group-gap">
                      <div className="form-label-box d-flex align-items-center justify-content-between mb-2">
                        <label className="txt__para txt__sm fw-bold mb-0">
                          Total experience
                          <span className="text-danger ms-1">*</span>
                        </label>
                      </div>
                      <div className="grid-2">
                        <div className="form-control-box">
                          <select
                            className="form-control-no-border form-control-no-border-select"
                            placeholder="In years"
                            value={totalExp?.year}
                            name="year"
                            onChange={(e) => {
                              handleTotalExp(e);
                            }}
                          >
                            <option value="">
                              In years
                            </option>
                            {numberOption.map((items,index) => (
                              <option key={index} value={items}>{items} years</option>
                            ))}
                          </select>
                        </div>
                        <div className="form-control-box">
                          <select
                            className="form-control-no-border form-control-no-border-select"
                            name="month"
                            value={totalExp?.month}
                            onChange={(e) => {
                              handleTotalExp(e);
                            }}
                          >
                            <option value="" >
                              In months
                            </option>
                            {numberOption.map((item,index) => (
                              <option  key={index} value={item}>{item} month</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="grid-box">
                    <div className="form-group-gap">
                      <div className="form-label-box">
                        <label className="mb-2 txt__para txt__sm fw-bold">
                          Current company name
                        </label>
                        <span className="text-danger ms-1">*</span>
                      </div>
                      <div className="form-control-box">
                        <input
                          type="text"
                          placeholder="Company name"
                          className="form-control-no-border"
                          name="current_company"
                          value={workDetails?.current_company}
                          onChange={(e) => {
                            handleWorkDetails(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="grid-box">
                    <div className="form-group-gap">
                      <div className="form-label-box">
                        <label className=" mb-2 txt__para txt__sm fw-bold">
                          Job title in current company
                        </label>
                        <span className="text-danger ms-1">*</span>
                      </div>
                      <div className="form-control-box">
                        <input
                          type="text"
                          placeholder="Web Developer..."
                          className="form-control-no-border"
                          name="current_position"
                          value={workDetails?.current_position}
                          onChange={(e) => {
                            handleWorkDetails(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="grid-box">
                    <div className="form-group-gap">
                      <div className="form-label-box">
                        <label className=" mb-2 txt__para txt__sm fw-bold">
                          Joining date
                        </label>
                      </div>
                      <div className="grid-2">
                        <div className="form-control-box">
                          <select
                            className="form-control-no-border form-control-no-border-select"
                            name="joining_date_month"
                            onChange={(e) => {
                              handleWorkDetails(e);
                            }}
                            value={workDetails?.joining_date_month}
                          >
                            <option  value="">
                              Month
                            </option>
                            {monthOptions.map((item,index) => (
                              <option  key={index} value={item.value}>{item.label}</option>
                            ))}
                          </select>
                        </div>
                        <div className="form-control-box">
                          <select
                            className="form-control-no-border form-control-no-border-select"
                            name="joining_date_year"
                            value={workDetails?.joining_date_year}
                            onChange={(e) => {
                              handleWorkDetails(e);
                            }}
                          >
                            <option value=""  >
                              Year
                            </option>
                            {yearOptions.map((item,index) => (
                              <option  key={index} value={item.value}>{item.value}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="grid-box">
                    <div className="form-group-gap ms-2">
                      <div className="form-label-box">
                        <label className=" mb-2 txt__para txt__sm fw-bold me-2">
                          Notice period?{" "}
                        </label>
                        <input
                          type="checkbox"
                          value={workDetails?.available}
                          name="available"
                          className="txt__para txt__sm ml-sm-2 ms-2"
                          checked={workDetails?.available}
                          onChange={(e) => {
                            handleAvailable(e);
                          }}
                        />
                        {" "}Available now
                      </div>
                      <div className="form-control-box">
                        <input
                          type="text"
                          name="notice_period"
                          value={workDetails?.notice_period}
                          placeholder="Notice period in days"
                          className="form-control-no-border"
                          checked={workDetails?.notice_period}
                          onChange={(e) => {
                            handleWorkDetails(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="grid-box">
                    <div className="form-group-gap">
                      <div className="form-label-box">
                        <label className=" mb-2 txt__para txt__sm fw-bold">
                          Notice period negotiable (Yes/No)?
                        </label>
                      </div>
                      <div className="form-control-box">
                        <div className="form-check form-check-inline">
                          <input
                            type="radio"
                            id="noticePeriodNegotiable"
                            value="Yes"
                            checked={
                              workDetails?.notice_period_negotiable === "Yes"
                            }
                            name="notice_period_negotiable"
                            className="form-check-input"
                            onChange={(e) => {
                              handleWorkDetails(e);
                            }}
                          />
                          <label
                            htmlFor="noticePeriodNegotiable"
                            className="form-check-label"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            type="radio"
                            id="noticePeriodNotNegotiable"
                            name="notice_period_negotiable"
                            value="No"
                            checked={
                              workDetails?.notice_period_negotiable === "No"
                            }
                            className="form-check-input"
                            onChange={(e) => {
                              handleWorkDetails(e);
                            }}
                          />
                          <label
                             htmlFor="noticePeriodNotNegotiable"
                            className="form-check-label"
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="grid-box">
                      <div className="form-group-gap">
                        <div className="form-label-box">
                          <label className="txt__para txt__sm fw-bold mb-1">
                            Current CTC
                          </label>
                          <p className="txt__xs txt__light">
                            This is the monthly salary in INR: Example 20000
                          </p>
                        </div>
                        <div className="form-control-box">
                          <input
                            type="number"
                            name="current_ctc"
                            placeholder="20000"
                            className="form-control-no-border"
                            value={workDetails?.current_ctc}
                            onChange={(e) => {
                              handleWorkDetails(e);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="grid-box">
                      <div className="form-group-gap">
                        <div className="form-label-box">
                          <label className="txt__para txt__sm fw-bold mb-1">
                            Expected CTC
                          </label>
                          <p className="txt__xs txt__light">
                            This is the monthly salary in INR: Example 20000
                          </p>
                        </div>
                        <div className="form-control-box">
                          <input
                            type="number"
                            placeholder="20000"
                            name="expected_ctc"
                            value={workDetails?.expected_ctc}
                            className="form-control-no-border"
                            onChange={(e) => {
                              handleWorkDetails(e);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {workDetails?.currently_working === "No" ? (
          <div>
            <div className="currently_notworking">
              <div className="row g-3">
                <div className="col-sm-6">
                  <div className="form-group-gap">
                    <div className="form-label-box d-flex align-items-center justify-content-between mb-2">
                      <label className="txt__para txt__sm fw-bold mb-0">
                        Total experience
                        <span className="text-danger ms-1">*</span>
                      </label>
                    </div>
                    <div className="grid-2">
                      <div className="form-control-box">
                        <select
                          className="form-control-no-border form-control-no-border-select"
                          name="year"
                          value={totalExp?.year}
                          onChange={(e) => {
                            handleTotalExp(e);
                          }}
                        >
                          <option   value="">
                            In years
                          </option>
                          {numberOption.map((item, index) => (
                            <option  key={index} value={item}>
                              {item} year{+item > 1 ? "s" : ""}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-control-box">
                        <select
                          className="form-control-no-border form-control-no-border-select"
                          value={totalExp?.month}
                          onChange={(e) => {
                            handleTotalExp(e);
                          }}
                          name="month"
                        >
                          <option value=""  >
                            In months
                          </option>
                          {numberOption.map((item,index) => (
                            <option  key={index} value={item}>{item} month</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group-gap">
                    <div className="form-label-box">
                      <label className="mb-2 txt__para txt__sm fw-bold">
                        Why unemployed?
                      </label>
                    </div>
                    <div className="form-control-box">
                      <textarea
                        rows="1"
                        placeholder="Type here..."
                        className="form-control-no-border"
                        name="why_unemployed"
                        style={{minHeight:"21px",maxHeight:"21px"}}
                        value={workDetails?.why_unemployed}
                        onChange={(e) => {
                          handleWorkDetails(e);
                        }}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group-gap">
                    <div className="form-label-box">
                      <label className="mb-2 txt__para txt__sm fw-bold">
                        Last company name
                      </label>
                    </div>
                    <div className="form-control-box">
                      <input
                        type="text"
                        placeholder="Company name"
                        className="form-control-no-border"
                        name="last_company"
                        value={workDetails?.last_company}
                        onChange={(e) => {
                          handleWorkDetails(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group-gap">
                    <div className="form-label-box">
                      <label className="mb-2 txt__para txt__sm fw-bold">
                        Job title in last company
                      </label>
                    </div>
                    <div className="form-control-box">
                      <input
                        type="text"
                        placeholder="Web Developer..."
                        className="form-control-no-border"
                        name="last_position"
                        value={workDetails?.last_position}
                        onChange={(e) => {
                          handleWorkDetails(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group-gap">
                    <div className="form-label-box">
                      <label className="mb-2 txt__para txt__sm fw-bold">
                        Joining date
                      </label>
                    </div>
                    <div className="grid-2">
                      <div className="form-control-box">
                        <select
                          className="form-control-no-border form-control-no-border-select"
                          name="joining_date_month"
                          value={workDetails?.joining_date_month}
                          onChange={(e) => {
                            handleWorkDetails(e);
                          }}
                        >
                          {/* <option   value="">
                            Month
                          </option> */}
                          {monthOptions.map((item,index) => (
                            <option  key={index} value={item.label}>{item.label}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        className={`form-control-box ${
                          !workDetails?.joining_date_month && "disabled_active"
                        }`}
                      >
                        <select
                          disabled={!workDetails?.joining_date_month}
                          className="form-control-no-border form-control-no-border-select"
                          name="joining_date_year"
                          value={workDetails?.joining_date_year}
                          onChange={(e) => {
                            handleWorkDetails(e);
                          }}
                        >
                          {/* <option value=""  >
                            Year
                          </option> */}
                          {yearOptions.map((item,index) => (
                            <option  key={index} value={item.value}>{item.label}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group-gap">
                    <div className="form-label-box">
                      <label className="mb-2 txt__para txt__sm fw-bold">
                        End date
                      </label>
                    </div>
                    <div className="grid-2">
                      <div
                        className={`form-control-box ${
                          !workDetails?.joining_date_year && "disabled_active"
                        }`}
                      >
                        <select
                          disabled={!workDetails?.joining_date_year}
                          className="form-control-no-border form-control-no-border-select"
                          name="termination_date_month"
                          value={workDetails?.termination_date_month}
                          onChange={(e) => {
                            handleWorkDetails(e);
                          }}
                        >
                          {/* <option value=""  >
                            Month
                          </option> */}
                          {monthOptions.map((item,index) => (
                            <option  key={index} value={item.label}>{item.label}</option>
                          ))}
                        </select>
                      </div>
                      <div
                        className={`form-control-box ${
                          !workDetails?.termination_date_month &&
                          "disabled_active"
                        }`}
                      >
                        <select
                          disabled={!workDetails?.termination_date_month}
                          className="form-control-no-border form-control-no-border-select"
                          name="termination_date_year"
                          value={workDetails?.termination_date_year}
                          onChange={(e) => {
                            handleWorkDetails(e);
                          }}
                        >
                          {/* <option value=""  >
                            Year
                          </option> */}
                          { monthOptions.findIndex((month) => month.label === workDetails?.joining_date_month)
                        >=monthOptions.findIndex((month) => month.label === workDetails?.termination_date_month)?
                        yearOptions.map((item,index) => {
                           if (item.label > workDetails?.joining_date_year) {
                             return <option  key={index} value={item.label}>{item.label}</option>;
                           }
                           return null;
                         }): yearOptions.map((item,index) => {
                           if (item.label >= workDetails?.joining_date_year) {
                             return <option  key={index} value={item.label}>{item.label}</option>;
                           }
                           return null;
                         })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group-gap">
                    <div className="form-label-box">
                      <label className=" mb-2 txt__para txt__sm fw-bold">
                        How soon can you join?
                      </label>
                      <input
                        type="checkbox"
                        className="txt__para txt__sm ml-sm-2 ms-2"
                        name="available"
                        value={workDetails?.available}
                        checked={workDetails?.available}
                        onChange={(e) => {
                          handleAvailable(e);
                        }}
                      />
                      Available now
                    </div>
                    <div className="form-control-box">
                      <input
                        type="text"
                        placeholder="7 days"
                        name="notice_period"
                        value={workDetails?.notice_period}
                        className="form-control-no-border"
                        onChange={(e) => {
                          handleWorkDetails(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row g-3">
                  <div className="col-sm-6">
                    <div className="form-group-gap">
                      <div className="form-label-box">
                        <label className="txt__para txt__sm fw-bold mb-1">
                          Last CTC
                        </label>
                        <p className="txt__xs txt__light">
                          This is the monthly salary in INR: Example 20000
                        </p>
                      </div>
                      <div className="form-control-box">
                        <input
                          type="text"
                          placeholder="20000"
                          className="form-control-no-border"
                          name="last_ctc"
                          value={workDetails?.last_ctc}
                          onChange={(e) => {
                            handleWorkDetails(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group-gap">
                      <div className="form-label-box">
                        <label className="txt__para txt__sm fw-bold mb-1">
                          Expected CTC
                        </label>
                        <p className="txt__xs txt__light">
                          This is the monthly salary in INR: Example 20000
                        </p>
                      </div>
                      <div className="form-control-box">
                        <input
                          type="text"
                          placeholder="20000"
                          className="form-control-no-border"
                          name="expected_ctc"
                          value={workDetails?.expected_ctc}
                          onChange={(e) => {
                            handleWorkDetails(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {workDetails?.currently_working === "Fresher" ? (
          <div>
            <div className="fresher">
              <div className="row g-3">
                <div className="col-sm-6">
                  <div className="form-group-gap">
                    <div className="form-label-box">
                      <label
                        className="mb-2 txt__para txt__sm fw-bold me-3"
                        htmlFor="Available"
                      >
                        How soon can you join?
                      </label>
                      <input
                        id="Available"
                        type="checkbox"
                        value={workDetails?.available}
                        className="txt__para txt__sm me-2"
                        name="available"
                        checked={workDetails?.available}
                        onChange={(e) => handleAvailable(e)}
                      />
                      Available now
                    </div>
                    <div className="form-control-box">
                      <input
                        type="text"
                        placeholder="7 days"
                        className="form-control-no-border"
                        name="notice_period"
                        value={workDetails?.notice_period}
                        onChange={(e) => handleWorkDetails(e)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group-gap">
                    <div className="form-label-box">
                      <label className="mb-2 txt__para txt__sm fw-bold mb-1">
                        Expected CTC
                      </label>
                    </div>
                    <div className="form-control-box">
                      <input
                        type="text"
                        placeholder="20000"
                        className="form-control-no-border"
                        name="expected_ctc"
                        value={workDetails?.expected_ctc}
                        onChange={(e) => handleWorkDetails(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div className="workExTab">
          <Tabs defaultActiveKey="first">
            <Tab eventKey="first" title="Job History">
              {
                <div className="workTabBody">
                  <div className="row g-3 align-items-center justify-content-between mb-4 mt-1">
                    <div className="col-auto">
                      <h6>Job histories</h6>
                    </div>
                    <div className="col-auto">
                      {jobHistory.length<=1 ? <button
                        className="btn-outline"
                        onClick={(e) => addJobHistory(e)}
                      >
                        Add Job History
                      </button>: null}
                    </div>
                  </div>

                  {jobHistory?.map((JobHistory, index) => (
                    <div className="job-history" key={index}>
                      <h6 className="jobHistoryTitle">
                        Job History {index + 1}
                      </h6>
                      <div className="row g-3">
                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Company name"
                            name="company"
                            value={JobHistory?.company}
                            onChange={(e) => handleAllInputs(e, index)}
                          />
                        </div>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="form-control"
                            name="position"
                            placeholder="Job Title"
                            value={JobHistory?.position}
                            onChange={(e) => handleAllInputs(e, index)}
                          />
                        </div>
                        <div className="col-lg-6">
                          <div className="row g-3 align-items-end">
                            <div className="col-sm-6">
                              <label htmlFor="" className="form-label">
                                Joining date
                              </label>
                              <Select
                                id=""
                                className=""
                                options={monthOptions}
                                styles={customStyles}
                                placeholder="In Months"
                                value={monthOptions.find(
                                  (month) =>
                                    month.label == JobHistory?.joining_month
                                ) || { label:"In Month"}}
                                components={{
                                  IndicatorSeparator: () => null,
                                }}
                                onChange={(e) => handleJioningMonth(e, index)}
                              />
                            </div>
                            <div className="col-sm-6">
                              <Select
                                id=""
                                className=""
                                isDisabled={!JobHistory?.joining_month || JobHistory?.joining_month=="In Months"}
                                onChange={(e) => handleJioningYear(e, index)}
                                options={yearOptions}
                                styles={customStyles}
                                placeholder="In Years"
                                value={yearOptions.find(
                                  (year) =>
                                    year.value == JobHistory?.joining_year
                                ) || ""}
                                components={{
                                  IndicatorSeparator: () => null,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="row g-3 align-items-end">
                            <div className="col-sm-6">
                              <label htmlFor="" className="form-label">
                                End date
                              </label>
                              <Select
                                id=""
                                className=""
                                isDisabled={!JobHistory?.joining_year }
                                options={monthOptions}
                                styles={customStyles}
                                placeholder="In Months"
                                value={monthOptions.find(
                                  (month) =>
                                    month?.label ==
                                    JobHistory?.termination_month
                                ) || { label:"In Month"}}
                                components={{
                                  IndicatorSeparator: () => null,
                                }}
                                onChange={(e) => handleEndMonth(e, index)}
                              />
                            </div>
                            <div className="col-sm-6">
                              <Select
                                id=""
                                className=""
                                isDisabled={!JobHistory?.termination_month ||JobHistory?.termination_month=="In Months"}
                                options={ monthOptions.findIndex((month) => month.label === JobHistory.joining_month)>=monthOptions.findIndex((month) => month.label ===JobHistory.termination_month)
                                  ?
                                  yearOptions.filter((item) => {
                                     if (item.label > JobHistory.joining_year) {
                                       return <option value={item.label}>{item.label}</option>;
                                     }
                                     return null;
                                   }): yearOptions.filter((item) => {
                                     if (item.label >= JobHistory?.joining_year) {
                                       return <option value={item.label}>{item.label}</option>;
                                     }
                                     return null;
                                   })}
                                styles={customStyles}
                                placeholder="In Years"
                                value={yearOptions.find(
                                  (year) =>
                                    year.value == JobHistory?.termination_year
                                )}
                                components={{
                                  IndicatorSeparator: () => null,
                                }}
                                onChange={(e) => handleEndYear(e, index)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <button
                          style={{ width: "auto" }}
                          className="btn btn-success button w-10"
                          onClick={(e) => {
                            index < 1
                              ? resetHist(e, index)
                              : removeJobHistory(e, index);
                          }}
                        >
                          Remove
                        </button>
                        { index>0 && index=== duplicateJobHistory.length-1 ? <button
                        className="btn-outline"
                        onClick={(e) => addJobHistory(e)}
                      >
                        Add Job History
                      </button>: null}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              }
            </Tab>
            <Tab eventKey="second" title="Work Note">
              <div className="workTabBody">
                <div className="row g-3 align-items-center justify-content-between mb-4 mt-1">
                  <div className="col-auto">
                    <h6>Add work note</h6>
                  </div>
                </div>
                <div className="row g-3">
                  <div className="col-md-12">
                    <label htmlFor="" className="form-label">
                      Work note
                    </label>
                    <textarea
                      name=""
                      value={workNote}
                      id=""
                      rows="5"
                      className="form-control"
                      onChange={(e) => setWorkNote(e.target.value)}
                    ></textarea>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="third" title="Project Links">
              <div className="workTabBody">
                <div className="row g-3 align-items-center justify-content-between mb-4 mt-1">
                  <div className="col-auto">
                    <h6>Projects</h6>
                  </div>
                  <div className="col-auto">
                    {project.length<=1 ? <button
                      className="btn-outline"
                      onClick={(e) => addProject()}
                    >
                      Add
                    </button> : null}
                  </div>
                </div>

                {project?.map((project, index) => (
                  <div className="job-history" key={index}>
                    <h6 className="jobHistoryTitle">Project {index + 1}</h6>
                    <div className="row g-3">
                      <div className="col-sm-6">
                        <label htmlFor="" className="form-label">
                          Project name
                        </label>
                        <input
                          type="text"
                          value={project.name}
                          className="form-control"
                          name="name"
                          placeholder="Project name"
                          onChange={(e) => handleProject(e, index)}
                        />
                      </div>
                      <div className="col-sm-6">
                        <label htmlFor="" className="form-label">
                          Project link
                        </label>
                        <input
                          type="text"
                          disabled={!project.name}
                          value={project.link}
                          className="form-control"
                          name="link"
                          placeholder="Project link"
                          onChange={(e) => handleProject(e, index)}
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <button
                      className="btn btn-success button mt-4"
                      onClick={(e) =>
                        index == 0
                          ? resetProject(e, index)
                          : removeProject(e, index)
                      }
                    >
                      Remove
                    </button>
                    {index>0 && index===duplicateProject.length-1 ? <button
                      className="btn-outline mt-4"
                      onClick={(e) => addProject()}
                    >
                      Add
                    </button>: null}
                    </div>
                  </div>
                ))}
              </div>
            </Tab>
            <Tab eventKey="fourth" title="Certificate">
              <div className="workTabBody">
                <div className="row g-3 align-items-center justify-content-between mb-4 mt-1">
                  <div className="col-auto">
                    <h6>Certificates</h6>
                  </div>
                  <div className="col-auto">
                    {cirtificate.length<=1 ? <button
                      className="btn-outline"
                      onClick={(e) => addCirtificate(e)}
                    >
                      Add
                    </button> : null}
                  </div>
                </div>
                {cirtificate?.map((cirtificate, index) => (
                  <div className="job-history" key={index}>
                    <h6 className="jobHistoryTitle">Certificate 1</h6>
                    <div className="row g-3">
                      <div className="col-sm-6">
                        <label htmlFor="" className="form-label">
                          Certificate for
                        </label>
                        <input
                          type="text"
                          name="course_name"
                          value={cirtificate?.course_name}
                          className="form-control"
                          placeholder="Certificate name"
                          onChange={(e) => handleAllCirtificates(e, index)}
                        />
                      </div>
                      <div className="col-sm-6">
                        <label htmlFor="" className="form-label">
                          Instituition name
                        </label>
                        <input
                          type="text"
                          name="institute_name"
                          value={cirtificate?.institute_name}
                          className="form-control"
                          placeholder="Instituition name"
                          onChange={(e) => handleAllCirtificates(e, index)}
                        />
                      </div>
                      <div className="col-sm-6">
                        <label htmlFor="" className="form-label">
                          Course completion year
                        </label>
                        <Select
                          name=""
                          id=""
                          className=""
                          options={yearOptions}
                          styles={customStyles}
                          placeholder="In Years"
                          value={yearOptions.find(
                            (year) => year.label == cirtificate?.completion_year
                          )}
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          onChange={(e) => handleCourseCompletedYear(e, index)}
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <button
                      className="btn btn-success button w-auto mt-4"
                      onClick={(e) =>
                        index == 0
                          ? resetCertificate(e, index)
                          : removeCirtificate(e, index)
                      }
                    >
                      Remove
                    </button>
                    {index>0 && index===duplicateCentificate.length-1 ? <button
                      className="btn-outline mt-4"
                      onClick={(e) => addCirtificate(e)}
                    >
                      Add
                    </button>: null}
                    </div>
                  </div>
                ))}
              </div>
            </Tab>
          </Tabs>
        </div>

        <div className="d-flex justify-content-between">
          <button
            type="button"
            disabled={isLoading}
            className="btn nav-link Next_qu"
            onClick={submitWork}
          >
            {isLoading ? <Spiner1 /> : "Submit"}
          </button>
        </div>
      </div>

      <Modal
        className="j_pdf_show_custom"
        show={showPdf}
        onHide={() => setShowPdf(false)}
        backdrop="static"
        keyboard={false}
        size="xl"
        centered
      >
        <Modal.Header closeButton={showPdf}>
          <Modal.Title className="fw-bold fs-5"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pdfModal d-flex align-items-center justify-content-center flex-column pt-4 pb-5">
            <table className=" w-100 d-flex justify-content-center">
              <tbody>
                <tr>
                  <td>
                    <Document
                      file={resume}
                      onLoadSuccess={({ numPages }) => {
                        setMaxPage(numPages);
                      }}
                    >
                      {[...Array(maxPage)].map((_, index) => (
                        <Page
                          key={index + 1}
                          pageNumber={index + 1}
                          renderTextLayer={false}
                          renderAnnotationLayer={false}
                        />
                        // each  page is able to render a page of pdf, thats why we are loading all pages withh different page number using loop
                      ))}
                    </Document>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ExperienceData;
