import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getuser } from '../Redux/settings/profile_setting/action';
import { edituser } from '../Redux/settings/updateProfile/action';
import Spiner1 from '../Components/Spiner1';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const ProfileSettings = ({ Sidedata, onSidedataChange }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userDe = useSelector(state => state.getAuthUser);
    const userupdate = useSelector(state => state.updateProfile);
    const [cPass, setCpass] = useState("");
    const [nPass, setNpass] = useState("");
    const [cfnPass, setCnfpass] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [showPass, setShowPass] = useState({
        currentPass: false,
        newPass: false,
        confirmPass: false,
    });
     const [errors, setErrors] = useState({ cPass: '', nPass: '', cfnPass: '' });

    const passwordRegex = {
        uppercase: /(?=.*[A-Z])/,
        lowercase: /(?=.*[a-z])/,
        digit: /(?=.*\d)/,
        specialChar: /(?=.*[@$!%*?&])/,
        length: /^.{8,}$/,
    };

    const validatePassword = (password) => {
        let tempErrors = '';
        if (!passwordRegex.uppercase.test(password)) {
            tempErrors = 'Password must contain at least one uppercase letter.';
        } else if (!passwordRegex.lowercase.test(password)) {
            tempErrors = 'Password must contain at least one lowercase letter.';
        } else if (!passwordRegex.digit.test(password)) {
            tempErrors = 'Password must contain at least one digit.';
        } else if (!passwordRegex.specialChar.test(password)) {
            tempErrors = 'Password must contain at least one special character.';
        } else if (!passwordRegex.length.test(password)) {
            tempErrors = 'Password must be at least 8 characters long.';
        }
        return tempErrors;
    };


    const submitUserEdit = (e) => {
        e.preventDefault();
        let isValid = true;
        let tempErrors = { cPass: '', nPass: '', cfnPass: '' };
        if (!cPass.trim()) {
            // toast.warn("Enter the current password");
            // return;
            tempErrors.cPass = "Enter the current password";
            isValid = false;
        }

        // Validate new password
        if (!nPass.trim()) {
            // toast.warn("Enter a new password");
            // return;
            tempErrors.nPass = "Enter a new password";
            isValid = false;
        }else {
            const passwordError = validatePassword(nPass);
            if (passwordError) {
                tempErrors.nPass = passwordError;
                isValid = false;
            }
        }
        
        // Validate confirm new password
        if (!cfnPass.trim()) {
            // toast.warn("Confirm the new password");
            // return;
             tempErrors.cfnPass = "Confirm the new password";
            isValid = false;
        }else if (nPass !== cfnPass) {
            tempErrors.cfnPass = "New password and confirm new password do not match";
            isValid = false;
        }
        if(cPass.trim()===nPass.trim()){
            // toast.warn("Please provide new password, different from current password");
            // return;
            tempErrors.nPass = "Please provide a new password different from the current password";
            isValid = false;
        }
        // Check if new password and confirm new password match
        // if (nPass.trim() !== cfnPass.trim()) {
            // toast.warn("New password and confirm new password do not match");
            // return;
        // }
        setErrors(tempErrors);

        if (!isValid) return;

        dispatch(edituser(cPass, userDe?.data?.data?.email, userDe?.data?.data?.id, userDe?.data?.data?.name, nPass, cfnPass, navigate));
    }

    const handleCurrentPass = (e) => {
        setCpass(e.target.value);
    }

    const handleNewPass = (e) => {
        const password = e.target.value;
        setNpass(password);
        const error = validatePassword(password);
        setErrors(prevErrors => ({ ...prevErrors, nPass: error }));
    }
    const handleConfNewPass = (e) => {
        const password = e.target.value;
        setCnfpass(password);
        let error = '';
        if (password !== nPass) {
            error = 'New password and confirm new password do not match.';
        }
        setErrors(prevErrors => ({ ...prevErrors, cfnPass: error }));
    }

    // useEffect(() => {
    //     if (showAlert) {
    //         setTimeout(() => {
    //             setShowAlert(false);
    //         }, 900);
    //     }
    // }, [userupdate, showAlert])

    useEffect(() => {
        dispatch(getuser(navigate))
    }, []);
    const menuClick = () => {
        onSidedataChange(!Sidedata);
    };
    return (
        <div>
            <div className="Profileheader">
                <h2 className=' position-relative mb-3'><button className="fa_bars me-2 d-lg-none" onClick={menuClick}>
                    <i className="fa-solid fa-bars"></i>
                </button>My Profile</h2>
            </div>
            <div className="row">
                <div className="col-md-10">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">
                                    Name
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    placeholder="Name"
                                    defaultValue={userDe?.data?.data?.name}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlInput2" className="form-label">
                                    Email Address
                                </label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="exampleFormControlInput2"
                                    placeholder="Email address"
                                    defaultValue={userDe?.data?.data?.email}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label htmlFor="exampleInputPassword3" className="form-label">
                                    Enter Current Password
                                </label>
                                <div className=' position-relative'>
                                    <input autoComplete='off' type={showPass?.currentPass ? "text" : "password"} className="form-control" id="exampleInputPassword3" placeholder='Enter Current Password' onChange={(e) => handleCurrentPass(e)} />
                                    <button className='profile_setting_eye' onClick={()=>setShowPass((prev)=>({...prev,currentPass:!prev?.currentPass}))}><i className={showPass?.currentPass ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'}></i></button>
                                </div>
                                {errors.cPass && <p className="text-danger">{errors.cPass}</p>}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label htmlFor="exampleInputPassword4" className="form-label">
                                    Enter New Password
                                </label>
                                <div className=' position-relative'>
                                    <input type={showPass?.newPass ? "text" : "password"} className="form-control" id="exampleInputPassword4" placeholder='Enter New Password' onChange={(e) => handleNewPass(e)} />

                                    <button className='profile_setting_eye' onClick={()=>setShowPass((prev)=>({...prev,newPass:!prev?.newPass}))}><i className={showPass?.newPass ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'}></i></button>
                                </div>
                                {errors.nPass && <p className="text-danger">{errors.nPass}</p>}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label htmlFor="exampleInputPassword5" className="form-label">
                                    Confirm New Password
                                </label>
                                <div className=' position-relative'>
                                    <input type={showPass?.confirmPass ? "text" : "password"} className="form-control" id="exampleInputPassword5" placeholder='Confirm New Password' onChange={(e) => handleConfNewPass(e)} />
                                    <button className='profile_setting_eye' onClick={()=>setShowPass((prev)=>({...prev,confirmPass:!prev?.confirmPass}))}><i className={showPass?.confirmPass ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'}></i></button>
                                </div>
                                {errors.cfnPass && <p className="text-danger">{errors.cfnPass}</p>}
                            </div>
                        </div>
                    </div>
                    <button type="submit" disabled={userDe?.isLoading || userupdate?.isLoading ? true : false} className="btn btn-success button-outline button-outline-lg choosed mt-4" onClick={(e) => submitUserEdit(e)}>
                        {userDe?.isLoading || userupdate?.isLoading ? <Spiner1 /> : "Submit"}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ProfileSettings