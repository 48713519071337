import axios from "axios";
import { FIND_SOURCE_FAILURE, FIND_SOURCE_REQUEST, FIND_SOURCE_SUCCESS } from "./actionType";
import { errorCall } from "../../ErrorHandling/action";

export const find_source_req = () => ({
    type: FIND_SOURCE_REQUEST
});

export const find_source_succ = (data) => ({
    type: FIND_SOURCE_SUCCESS,
    payload: data
})
export const find_source_failure = (err) => ({
    type: FIND_SOURCE_FAILURE,
    payload: err
})


const getLocaldata = () => {
    const token = localStorage.getItem("token");
    return token
}
export const findSourcef = (id, navigate) => (dispatch) => {
    dispatch(find_source_req());
    const mt = getLocaldata()
    const config = {
        headers: { Authorization: `Bearer ${mt}` }
    };


    axios.get(`${process.env.REACT_APP_BASE_URL}/api/auth/source/${id}`, config).then((res) => {
        dispatch(find_source_succ(res.data))

        return res
    }).catch((err) => {
        if (err?.response?.data?.error == "Unauthenticated.") {
            localStorage.removeItem("token");
            navigate("/login");
        }
        
        dispatch(find_source_failure(err))
        if (err.message === "Network Error" || err.response.status === 500 || err.response.status === 404) {
            dispatch(errorCall(err))
        }

    })
}


