import React, { useEffect, useRef, useState } from "react";
// import SideNav from "../../Components/Sidebar";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { BsCheck } from "react-icons/bs";
import { MdClose, MdPreview } from "react-icons/md";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { saveTemp, singleTemp, updateTemp } from "../../Redux/EmailReducer/action";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Spiner1 from "../../Components/Spiner1";

const CreateTemplate = (props) => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const singleTempData = useSelector((state) => state.emailData.data);
  const loading = useSelector((state) => state.emailData.isloadingSave);
  const updateLoading = useSelector((state) => state.emailData.isloadingUpdate);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [values, setValues] = useState("");
  const [subject, setSubject] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [emailId, setEmailId] = useState("contact@mail.com");
  const [setDef, setSetDef] = useState(false);
  const [contact, setContact] = useState("contact");
  const [preValue, setPreValue] = useState(" ");
  const [template, setTemplate] = useState(false);
  const editorConfig = {
    toolbar: [
      //   ['bold', 'italic', 'underline', 'strike'],
      ["code-block"],
      ["link"],
      ["image"],
      [{ size: ["small", "normal", "large", "huge"] }],
      [{ align: [] }],
      ["clean"],
    ],
  };
  const options = [
    { value: "chocolate", label: "Chocolate@mail.com" },
    { value: "strawberry", label: "Strawberry@mail.com" },
    { value: "vanilla", label: "Vanilla@mail.com" },
  ];

  const saveTemplate = (e) => {
    setTemplate(true);
  };
  const closeTempName = (e) => {
    if (e.target.name === "cancel") {
      setTemplateName("");
      setTemplate(false);
    } else {
      setTemplate(false);
    }
  };
  const handleContact = (e) => {
    setContact(e.value);
    setEmailId(e.label);
  };
  const handleSubmit = () => {

    if (props.title === "Create_Temp") {
      if (!values || !values.trimStart(" ")) {
        return toast.warn("Please Provide Email Body")
      } else {
        dispatch(
          saveTemp(token, navigate, subject, values, templateName, emailId, setDef)
        );
      }
    } else {
      dispatch(
        updateTemp(token, navigate, id, subject, values, templateName, emailId, setDef)
      );
    }
  };

  const handleValue = (e) => {
    const credentialValue = e.target.getAttribute("value");
 
    setValues(
      values.substring(0, values.length - 4) +
        credentialValue +
        values.substring(values.length - 4)
    );
  };
  const handleEditor=(e)=>{
    setValues(e)
  }
  const quillRef = useRef(null);
  const handleCancel = () => {
    setValues("");
    setSubject("");
    setTemplateName("");
    setEmailId("");
    setSetDef("");
  };
  useEffect(() => {
    setPreValue(
      `<div className="mail_preview_body p-3">
        <p>
          <span className="fw-bold me-1"><b>To:</b> </span>${contact}
          <BiChevronLeft />
          <span>${emailId}</span>
          <BiChevronRight />
        </p>
        <p>
          <span className="fw-bold me-1"><b>Subject:</b> </span>${subject}
        </p>
        ${values ? values.replace(/\[Name\]/g, " Example Name")
        .replace(/\[Test_Link\]/g, " https://example.com/example/test")
        .replace(/\[Position\]/g, " Example Engineer")
        .replace(/\[Exp_Date\]/g, " 12/12/2023")
        .replace(/\[Your_Title\]/g, " Example Engineer")
        .replace(/\[Company_Name\]/g, " Example pvt ltd") : ""
      }
      </div>`
    );
  }, [values, contact, subject, emailId]);
  // added emailId to the dependency array if it's a prop or state

  useEffect(() => {
    if (props.title !== "Create_Temp") {
      dispatch(singleTemp(token, id, navigate));
    }
  }, [id]);

  useEffect(() => {
    if (!token) {
      navigate("/login")
    }
  }, [token])

  useEffect(() => {
    if (props.title !== "Create_Temp") {
      // setValues("");
      setSubject(singleTempData.subject);
      setTemplateName(singleTempData.templateName);
      setEmailId(singleTempData.emailId);
      setSetDef(singleTempData.default);
      setValues(singleTempData.emailBody);
    }
  }, [singleTempData]);
  const menuClick = () => {
    props.onSidedataChange(!props.newSidedata);
  };
  return (
    <div className="content_app">
      <div className="body_content">
        <div className="container-fluid">
          <button className="fa_bars  d-lg-none" onClick={menuClick}>
            <i className="fa-solid fa-bars fs-4"></i>
          </button>
          <div className="row">
            <div className="col-12 hjklop_right">
              <div className="card create_template  my-4">
                <div className="card-body">
                  <div className="card">
                    <div className="card-header bg-primary border-0">
                      {props?.title === "Create_Temp" ? (
                        <h3 className="text-white fs-5 mb-0 py-1">
                          Create Template
                        </h3>
                      ) : (
                        <h3 className="text-white fs-5 mb-0 py-1">
                          Edit Template
                        </h3>
                      )}
                    </div>
                    <div className="card-body">
                      <div className="mail_generate">
                        <div className="input-group mb-3">
                          <span className="input-group-text" id="basic-addon1">
                            Subject
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Subject here"
                            value={subject?subject:""}
                            onChange={(e) => {
                              setSubject(e.target.value);
                            }}
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                          />
                        </div>
                        <div className="mail_area text-editor border-radius position-relative">
                          <div className="dropdown imp_words">
                            <button
                              className="btn btn_drop_color dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Credentials
                            </button>
                            <ul className="dropdown-menu">
                              <li onClick={(e) => { handleValue(e) }} value="[Name]" style={{ cursor: "pointer" }}>
                                <span className="dropdown-item" value="[Name]" >
                                  [Name]
                                </span>
                              </li>
                              <li onClick={(e) => { handleValue(e) }} value="[Test_Link]" style={{ cursor: "pointer" }}>
                                <span className="dropdown-item" value="[Test_Link]">
                                  [Test_Link]
                                </span>
                              </li>
                              <li onClick={(e) => { handleValue(e) }} value="[Position]" style={{ cursor: "pointer" }}>
                                <span className="dropdown-item" value="[Position]">
                                  [Position]
                                </span>
                              </li>
                              <li onClick={(e) => { handleValue(e) }} value="[Exp_Date]" style={{ cursor: "pointer" }}>
                                <span className="dropdown-item" value="[Exp_Date]">
                                  [Exp_Date]
                                </span>
                              </li>
                              <li onClick={(e) => { handleValue(e) }} value="[Your_Title]" style={{ cursor: "pointer" }}>
                                <span className="dropdown-item" value="[Your_Title]">
                                  [Your_Title]
                                </span>
                              </li>
                              <li onClick={(e) => { handleValue(e) }} value="[Company_Name]" style={{ cursor: "pointer" }}>
                                <span className="dropdown-item" value="[Company_Name]">
                                  [Company_Name]
                                </span>
                              </li>
                            </ul>
                          </div>
                          <ReactQuill
                          ref={quillRef}
                            className="quil_text"
                            theme="snow"
                            placeholder="Type here...."
                            modules={editorConfig}
                            value={values?values:""}
                            onChange={(e) => {
                              handleEditor(e)
                            }}
                          />

                          <div className="mial_custom ps-0 ps-sm-3 ps-xl-0 py-2 py-xl-0">
                            <div className="row justify-content-between align-items-center g-3 w-100">
                              <div className="col-auto">
                                {template === false ? (
                                  <p
                                    className="text-primary"
                                    onClick={saveTemplate}
                                  >
                                    Save as new template name{" "}
                                  </p>
                                ) : (
                                  <div className="template_set d-flex align-items-center">
                                    <input
                                      type="text"
                                      className="form-control me-2"
                                      placeholder="Enter template name"
                                      value={templateName?templateName:""}
                                      onChange={(e) => {
                                        setTemplateName(e.target.value);
                                      }}
                                    />
                                    <button
                                      className="btn btn-success"
                                      name="save"
                                      onClick={closeTempName}
                                    >
                                      <BsCheck />
                                    </button>
                                    <button
                                      className="btn btn-danger ms-2"
                                      name="cancel"
                                      onClick={closeTempName}
                                    >
                                      <MdClose />
                                    </button>
                                  </div>
                                )}
                              </div>
                              <div className="col-auto">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={setDef?setDef:false}
                                    value={setDef?setDef:""}
                                    onChange={(e) => {
                                      setSetDef(e.target.checked);
                                    }}
                                    id="flexCheckDefault"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexCheckDefault"
                                  >
                                    Set as default
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mail_generate_btns my-4">
                        <div className="row justify-content-end g-2">
                          <div className="col-auto">
                            <button
                              disabled={loading || updateLoading}
                              onClick={handleSubmit}
                              className="btn btn-success btn-lg px-3"
                            >
                              {loading || updateLoading ? <Spiner1 /> : "Save"}
                            </button>
                          </div>
                          <div className="col-auto">
                            <button
                              onClick={handleCancel}
                              className="btn btn-danger btn-lg px-3"
                            >
                              Cancel{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="mail_preview">
                        <h5 className="fs-5 mb-3">
                          Generate{" "}
                          <span className="fs-4">
                            <MdPreview />{" "}
                          </span>{" "}
                          <span className="fw-bold text-primary"> Preview</span>{" "}
                          for Contact (optional)
                        </h5>
                        <Select
                          options={options}
                          onChange={(e) => {
                            handleContact(e);
                          }}
                          placeholder="Choose a Contact"
                        />
                        <div className="mt-3 mail_preview">
                          <div className="mail_preview_body p-3">
                            <ReactQuill
                              readOnly
                              theme="snow"
                              placeholder="preview..."
                              modules={editorConfig}
                              value={preValue ? preValue : "Loading..."}
                              onChange={setPreValue}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateTemplate;
