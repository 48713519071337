export const DESIGNATION_REQUEST = "DESIGNATION_REQUEST"
export const DESIGNATION_SUCCESS = "DESIGNATION_SUCCESS"
export const DESIGNATION_FAILURE = "DESIGNATION_FAILURE"

export const LOCATION_REQUEST = "LOCATION_REQUEST"
export const LOCATION_SUCCESS = "LOCATION_SUCCESS"
export const LOCATION_FAILURE = "LOCATION_FAILURE"

export const EXPERIENCE_REQUEST = "EXPERIENCE_REQUEST"
export const EXPERIENCE_SUCCESS = "EXPERIENCE_SUCCESS"
export const EXPERIENCE_FAILURE = "EXPERIENCE_FAILURE"

export const SKILLS_REQUEST = "SKILLS_REQUEST"
export const SKILLS_SUCCESS = "SKILLS_SUCCESS"
export const SKILLS_FAILURE = "SKILLS_FAILURE"

export const CITY_REQUEST = "CITY_REQUEST"
export const CITY_SUCCESS = "CITY_SUCCESS"
export const CITY_FAILURE = "CITY_FAILURE"

export const CANDIDATE_REQUEST = "CANDIDATE_REQUEST"
export const CANDIDATE_SUCCESS = "CANDIDATE_SUCCESS"
export const CANDIDATE_FAILURE = "CANDIDATE_FAILURE"
export const CANDIDATE_SUCCESS_ALLRESPONSE = "CANDIDATE_SUCCESS_ALLRESPONSE"

export const ADD_CANDIDATE_REQUEST = "ADD_CANDIDATE_REQUEST"
export const ADD_CANDIDATE_SUCCESS = "ADD_CANDIDATE_SUCCESS"
export const ADD_CANDIDATE_FAILURE = "ADD_CANDIDATE_FAILURE"


export const SAVED_DATA = "SAVED_DATA"

export const RESET_DATA = "RESET_DATA"

// export const SINGLE_CANDIDATE_REQUEST = "SINGLE_CANDIDATE_REQUEST"
// export const SINGLE_CANDIDATE_SUCCESS = "SINGLE_CANDIDATE_SUCCESS"
// export const SINGLE_CANDIDATE_FAILURE = "SINGLE_CANDIDATE_FAILURE"

export const STATUS_UPDATE_REQUEST = "STATUS_UPDATE_REQUEST"
export const STATUS_UPDATE_SUCCESS = "STATUS_UPDATE_SUCCESS"
export const STATUS_UPDATE_FAILURE = "STATUS_UPDATE_FAILURE"

export const EMAIL_REQUEST = "EMAIL_REQUEST"
export const EMAIL_SUCCESS = "EMAIL_SUCCESS"
export const EMAIL_FAILURE = "EMAIL_FAILURE"

// START RESUME EXTRACT
export const RESUMEEXTRACT_REQUEST = "RESUMEEXTRACT_REQUEST"
export const RESUMEEXTRACT_SUCCESS_ADD = "RESUMEEXTRACT_SUCCESS_ADD"
export const RESUMEEXTRACT_SUCCESS_EDIT = "RESUMEEXTRACT_SUCCESS_EDIT"
export const RESUMEEXTRACT_FAILURE = "RESUMEEXTRACT_FAILURE"
// START RESUME EXTRACT