import React, { useEffect, useState } from "react";
// import SideNav from "./SideNav";
import { Pagination } from "react-bootstrap";
import { AiOutlineSearch } from "react-icons/ai";
import { BsPencilSquare } from "react-icons/bs";
import { RiDeleteBin3Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import {
  allTemp,
  deleteTemp,
  updateTemp,
} from "../../Redux/EmailReducer/action";
import parse from "html-react-parser";
import ReactQuill from "react-quill";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const ITEMS_PER_PAGE = 6;

const TemplateList = (props) => {
  const location = useLocation()?.pathname;
  const navigate = useNavigate();
  const sessionTokenValue = localStorage.getItem("token");

  const usersPermission = localStorage?.getItem("userPermission")
  const permissionList = usersPermission?.split(',');

  const webToken = sessionTokenValue;
  const tempData = useSelector((state) => state?.emailData?.data);
  const emailDataLoading = useSelector((state) => state?.emailData?.isloadingAll);
  const dispatch = useDispatch();
  const [activeCardIndex, setActiveCardIndex] = useState(null);
  const [singleTempData, setSingleTempData] = useState("");
  const [allData, setAllData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDefault, setIsDefault] = useState(0);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const editorConfig = {
    toolbar: [],
  };
  const totalPages = Math.ceil(allData?.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;

  const templateClick = (index, data) => {
    setSingleTempData(data);
    setActiveCardIndex(index);
  };
  const handelDelete = (data) => {
    dispatch(deleteTemp(webToken, data._id, navigate)).then(() => {
      dispatch(allTemp(webToken, navigate));
      setSingleTempData(singleTempData[activeCardIndex]);
      setActiveCardIndex(activeCardIndex + 1);
    });
    setSingleTempData("");
  };
  const handleDefault = (e, element) => {
    if (element.target.checked === false) {
      return toast.warn("you can't unchoose another default");
    } else {
      setIsDefault(isDefault + 1);
      dispatch(
        updateTemp(
          webToken,
          navigate,
          e._id,
          e.subject,
          e.emailBody,
          e.templateName,
          e.emailId,
          true
        )
      )
        .then(() => {
          dispatch(allTemp(webToken, navigate));
        })
        .then(() => {
          setAllData(allData);
        });
    }
  };
  const setListArray = () => {
    if (tempData) {
      const defaultData = tempData?.filter((e) => {
        return e.default == true ? e : null;
      });
      const defaultIndex = tempData
        .map((e, index) => {
          return e.default === true ? index : null;
        })
        .filter((index) => index !== null);
      setSingleTempData(defaultData[0]);
      if (activeCardIndex == null) setActiveCardIndex(defaultIndex[0]);
    }
  };
  useEffect(() => {
    dispatch(allTemp(webToken, navigate));
  }, [isDefault]);

  useEffect(() => {
    setSingleTempData(singleTempData);
  }, [singleTempData]);

  useEffect(() => {
    setIsDefault(isDefault);
    dispatch(allTemp(webToken, navigate));
  }, [isDefault]);

  useEffect(() => {
    if (tempData.length > 0) {
      setAllData(tempData);
      setListArray();
    }
  }, [tempData]);

  const menuClick = () => {
    props.onSidedataChange(!props.newSidedata);
  };


  const handleDeleteTemplate = async (data) => {
    const result = await Swal.fire({
      title: 'Are you sure, you want to delete the template?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3ab2e4',
      cancelButtonColor: 'grey',
      cancelButtonText: "No",
      confirmButtonText: 'Yes',

      customClass: {
        confirmButton: 'Swal_confirm',
        cancelButton: 'Swal_cancel',
        icon: 'Swal_icon',
        title: 'Swal_title'
      },
    });

    if (result.isConfirmed) {
      handelDelete(data)
    }
  }


  return (
    <div className="content_app ">
      {allData && !emailDataLoading ? (
        <div className="body_content">
          <div className="container-fluid">
            <button className="fa_bars  d-lg-none" onClick={menuClick}>
              <i className="fa-solid fa-bars fs-4"></i>
            </button>
            <div className="row">
              <div className="col-12 hjklop_right">
                <div className="card template_list my-4">
                  <div className="card-body">
                    <div className="card">
                      <div className="card-header bg-primary border-0">
                        <h3 className="text-white fs-5 mb-0 py-1">
                          Template List
                        </h3>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12">
                            <div className="input-group mb-3">
                              <span
                                className="input-group-text bg-transparent border-end-0"
                                id="basic-addon2"
                              >
                                <AiOutlineSearch />
                              </span>
                              <input
                                type="search"
                                className="form-control border-start-0"
                                placeholder="Search"
                                aria-label="Username"
                                aria-describedby="basic-addon2"
                              />
                            </div>
                          </div>
                          <div className="col-lg-12 col-xl-5 col-xxl-4 order-2 order-xl-1">
                            <div className="template_list_names">
                              <div className="template_list_names_scroll">
                                {/* ------------------------------------------------list data------------------------------------------------------------------------------------------------------------------------------- */}
                                {allData?.length == 0 ? (
                                  // <div
                                  //   className="d-flex align-items-center justify-content-center"
                                  //   style={{
                                  //     textAlign: "center",
                                  //     height: "100px",
                                  //   }}
                                  // >
                                  //   <div className="loader"></div>
                                  // </div>
                                  <div>No Data</div>
                                ) : (
                                  allData
                                    ?.slice(startIndex, endIndex)
                                    ?.map((data, index) => (
                                      <div
                                        className={`template_list_block my-3 p-3 ${activeCardIndex == index
                                          ? "active"
                                          : ""
                                          }`}
                                        onClick={() =>
                                          templateClick(index, data)
                                        }
                                        key={index}
                                      >
                                        <div className="template_list_date">
                                          {" "}
                                          {data?.createDate?.slice(4, 15)}
                                        </div>
                                        <div className="row g-3 mt-1">
                                          <div className="col-12 pb-5">
                                            <div className="template_user_details pt-3 pt-xl-2">
                                              <h6 className="mb-3">
                                                {data?.templateName}
                                              </h6>
                                              <div>
                                                {data?.emailBody?.includes("<p>") && parse(data?.emailBody)}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {data?.default ? (
                                          <div className="set_default">
                                            Default
                                          </div>
                                        ) : null}
                                      </div>
                                    ))
                                )}
                              </div>
                              {allData?.length > 0 ?
                                <div className="row g-2 align-items-center justify-content-between">
                                  <div className="col-auto">
                                    <div className="hhh">
                                      <small className="d-block">
                                        Showing Result
                                      </small>
                                      <span className="fw-bold">
                                        {currentPage} - {totalPages}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="col-auto">
                                    <Pagination className="my-3 justify-content-center">
                                      <Pagination.Prev
                                        disabled={currentPage === 1}
                                        onClick={() =>
                                          handlePageChange(currentPage - 1)
                                        }
                                      />{
                                        [...Array(totalPages)].map(
                                          (_, index) => (
                                            <Pagination.Item
                                              key={index}
                                              active={index + 1 === currentPage}
                                              onClick={() =>
                                                handlePageChange(index + 1)
                                              }
                                            >
                                              {index + 1}
                                            </Pagination.Item>
                                          )
                                        )}
                                      <Pagination.Next
                                        disabled={currentPage === totalPages}
                                        onClick={() =>
                                          handlePageChange(currentPage + 1)
                                        }
                                      />
                                    </Pagination>
                                  </div>
                                </div>
                                : null}
                            </div>
                          </div>
                          <div className="col-lg-12 col-xl-7 col-xxl-8  order-1 order-xl-2">
                            <div className="template_list_area h-100 mt-3 position-relative">
                              {!singleTempData && (
                                // <>
                                //   <div
                                //     className="d-flex align-items-center justify-content-center"
                                //     style={{
                                //       textAlign: "center",
                                //       height: "100px",
                                //     }}
                                //   >
                                //     <div className="loader"></div>
                                //   </div>
                                // </>
                                null
                              )}
                              {/*---------------------- -------------------------------------------choosed template part-------------------------------------------------------------------------------------------------------- */}
                              {singleTempData && (
                                <>
                                  <div className="template_area_header">
                                    <div className="row g-3 p-3 justify-content-center justify-content-sm-between">
                                      <div className="col-sm-6">
                                        <div className="template_list_block border-0 p-3">
                                          <div className="row g-3">
                                            <div className="col-auto col-sm-2"></div>
                                            <div className="col-auto col-sm-10">
                                              <div className="template_user_details pt-2 pt-xl-2">
                                                <h6 className="mb-1">
                                                  {singleTempData.templateName}
                                                </h6>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-auto">
                                        {permissionList?.includes("65bb9ae453e762c89b9af184") &&
                                          <>
                                            <div>
                                              {" "}
                                              <button
                                                onClick={() => {
                                                  navigate(
                                                    `/email/edit-template/${singleTempData._id}`, { state: { from: location } }
                                                  );
                                                }}
                                                className="btn border-0 p-0 bg-transparent edit_template mt-0 mt-sm-4 text-dark"
                                              >
                                                <span className="me-2 ">
                                                  <BsPencilSquare />
                                                </span>
                                                Edit Template{" "}
                                              </button>
                                            </div>

                                            <div>
                                              {" "}
                                              <button
                                                onClick={() => {
                                                  handleDeleteTemplate(singleTempData);
                                                }}
                                                className="btn border-0 p-0 bg-transparent edit_template mt-0 mt-sm-1 text-dark"
                                              >
                                                <span className="me-2 ">
                                                  <RiDeleteBin3Line />
                                                </span>
                                                Delete{" "}
                                              </button>
                                            </div>
                                          </>
                                        }
                                        {!singleTempData.default && (
                                          <div>
                                            {" "}
                                            <input
                                              type="checkbox"
                                              className="me-2 btn border-0 p-0 bg-danger edit_template mt-0  text-dark"
                                              checked={singleTempData.default}
                                              style={{ cursor: "pointer" }}
                                              id="setDefault"
                                              onChange={(element) => {
                                                handleDefault(
                                                  singleTempData,
                                                  element
                                                );
                                              }}
                                            />
                                            <label
                                              htmlFor="setDefault"
                                              className="cursor-pointer ms-1"
                                            >
                                              Set Default{" "}
                                            </label>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="template_area_body p-4 p-lg-5">
                                    <ReactQuill
                                      readOnly
                                      theme="snow"
                                      modules={editorConfig}
                                      value={
                                        singleTempData ? (
                                          singleTempData.emailBody
                                        ) : (
                                          <div
                                            className="d-flex align-items-center justify-content-center"
                                            style={{
                                              textAlign: "center",
                                              height: "100px",
                                            }}
                                          >
                                            <div className="loader"></div>
                                          </div>
                                        )
                                      }
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ textAlign: "center", height: "100px" }}
        >
          <div className="loader"></div>
        </div>
      )}
    </div>
  );
};

export default TemplateList;
