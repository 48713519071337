import { 
    CATEGORIES_REQUEST,
    CATEGORIES_SUCCESS,
    CATEGORIES_FAILURE,
    CATEGORIES_SINGLE_REQUEST,
    CATEGORIES_SINGLE_SUCCESS,
    CATEGORIES_SINGLE_FAILURE,
    CATEGORY_ADD_REQUEST,
    CATEGORY_ADD_SUCCESS,
    CATEGORY_ADD_FAILURE,
    CATEGORY_EDIT_REQUEST,
    CATEGORY_EDIT_SUCCESS,
    CATEGORY_EDIT_FAILURE,
    CATEGORY_STATUS_REQUEST,
    CATEGORY_STATUS_SUCCESS,
    CATEGORY_STATUS_FAILURE,
    CATEGORY_DELETE_REQUEST,
    CATEGORY_DELETE_SUCCESS,
    CATEGORY_DELETE_FAILURE } from "./actionType";

    
    const initialState = {
        count: 0,
        name: "",
        data: [],
        // status: STATUSES.IDLE,
        localStorageDAta: [],
        validationError: "",
        testId: "",
        singleCate: {},
        deletedData: [],

        isLoading:false,
        isLoadingCateSingle:false,
        isLoadingcategAdd:false,
        isLoadingCategEdit:false,
        isLoadingCateStatus:false,
        isLoadingDelete:false,

        isError:false,
        isErrorCateSingle:false,
        isErrorcategAdd:false,
        isErrorCategEdit:false,
        isErrorCateStatus:false,
        isErrorDelete:false,

        errorData:"",
        errorSingleData:"",
        errorAddData:"",
        errorEditData:"",
        errorStatusData:"",
        errorDeleteData:""
      }

      export const Category_Reducer = (state = initialState, action) => {
        switch (action.type) {



          case CATEGORIES_REQUEST: {
            return {
              ...state,
              isLoading: true,
              isError:false,
              data: [],
              errorData: "",
            };
          }
          case CATEGORIES_SUCCESS: {
            return {
              ...state,
              isLoading: false,
              isError:false,
              data:action.payload,
              errorData: "",
            };
          }
          case CATEGORIES_FAILURE: {
            return {
              ...state,
              isLoading: false,
              isError:true,
              data: [],
              errorData: action.payload,
            };
          }


          
          case CATEGORIES_SINGLE_REQUEST: {
            return {
              ...state,
              isLoadingCateSingle: true,
              isErrorCateSingle:false,
              singleCate: {},
              errorSingleData: "",
            };
          }
          case CATEGORIES_SINGLE_SUCCESS: {
            return {
              ...state,
              isLoadingCateSingle: false,
              isErrorCateSingle:false,
              singleCate:action.payload,
              errorSingleData: "",
            };
          }
          case CATEGORIES_SINGLE_FAILURE: {
            return {
              ...state,
              isLoadingCateSingle: false,
              isErrorCateSingle:true,
              singleCate: {},
              errorSingleData: action.payload,
            };
          }


          case CATEGORY_ADD_REQUEST: {
            return {
              ...state,
              isLoadingcategAdd: true,
              isErrorcategAdd:false,
              errorAddData: "",
            };
          }
          case CATEGORY_ADD_SUCCESS: {
            return {
              ...state,
              isLoadingcategAdd: false,
              isErrorcategAdd:false,
              errorAddData: "",
            };
          }
          case CATEGORY_ADD_FAILURE: {
            return {
              ...state,
              isLoadingcategAdd: false,
              isErrorcategAdd:true,
              errorAddData: action.payload,
            };
          }
          
          case CATEGORY_EDIT_REQUEST: {
            return {
              ...state,
              isLoadingCategEdit: true,
              isErrorCategEdit:false,
              errorEditData: "",
            };
          }
          case CATEGORY_EDIT_SUCCESS: {
            return {
              ...state,
              isLoadingCategEdit: false,
              isErrorCategEdit:false,
              errorEditData: "",
            };
          }
          case CATEGORY_EDIT_FAILURE: {
            return {
              ...state,
              isLoadingCategEdit: false,
              isErrorCategEdit:true,
              errorEditData: action.payload,
            };
          }
          
          
          case CATEGORY_STATUS_REQUEST: {
            return {
              ...state,
              isLoadingCateStatus: true,
              isErrorCateStatus:false,
              errorStatusData: "",
            };
          }
          case CATEGORY_STATUS_SUCCESS: {
            return {
              ...state,
              isLoadingCateStatus: false,
              isErrorCateStatus:false,
              errorStatusData: "",
            };
          }
          case CATEGORY_STATUS_FAILURE: {
            return {
              ...state,
              isLoadingCateStatus: false,
              isErrorCateStatus:true,
              errorStatusData: action.payload,
            };
          }
          
          
          case CATEGORY_DELETE_REQUEST: {
            return {
              ...state,
              isLoadingDelete: true,
              isErrorDelete:false,
              errorDeleteData: "",
            };
          }
          case CATEGORY_DELETE_SUCCESS: {
            return {
              ...state,
              isLoadingDelete: false,
              isErrorDelete:false,
              errorDeleteData: "",
            };
          }
          case CATEGORY_DELETE_FAILURE: {
            return {
              ...state,
              isLoadingDelete: false,
              isErrorDelete:true,
              errorDeleteData: action.payload,
            };
          }

          default: {
            return state;
          }
        }
      }