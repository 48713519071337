export const ADD_APPLICANT_REQUEST = "ADD_APPLICANT_REQUEST"
export const ADD_APPLICANT_SUCCESS = "ADD_APPLICANT_SUCCESS"
export const ADD_APPLICANT_FAILURE = "ADD_APPLICANT_FAILURE"

export const GET_APPLICANT_REQUEST = "GET_APPLICANT_REQUEST"
export const GET_APPLICANT_SUCCESS = "GET_APPLICANT_SUCCESS"
export const GET_APPLICANT_FAILURE = "GET_APPLICANT_FAILURE"

export const TOKEN_REQUEST = "TOKEN_REQUEST"
export const TOKEN_SUCCESS = "TOKEN_SUCCESS"
export const TOKEN_FAILURE = "TOKEN_FAILURE"