import axios from "axios";
import {
  SAVE_TEMP_REQ,
  SAVE_TEMP_SUCC,
  SAVE_TEMP_FAIL,
  ALL_TEMP_REQ,
  ALL_TEMP_SUCC,
  ALL_TEMP_FAIL,
  SINGLE_TEMP_REQ,
  SINGLE_TEMP_SUCC,
  SINGLE_TEMP_FAIL,
  UPDATE_TEMP_REQ,
  UPDATE_TEMP_SUCC,
  UPDATE_TEMP_FAIL,
  DELETE_TEMP_REQ,
  DELETE_TEMP_SUCC,
  DELETE_TEMP_FAIL,
} from "./actionType";
import { toast } from "react-toastify";
import { errorCall } from "../ErrorHandling/action";

export const saveTemp_Req = (data) => ({
  type: SAVE_TEMP_REQ,
});
export const saveTemp_Succ = (data) => ({
  type: SAVE_TEMP_SUCC,
  payload: data,
});
export const saveTemp_Fail = (error) => ({
  type: SAVE_TEMP_FAIL,
  payload: error,
});

export const saveTemp = (
  webToken,
  navigate,
  subject,
  emailBody,
  templateName,
  emailId,
  setDef
) => {
  return async function test(dispatch, getState) {
    dispatch(saveTemp_Req())
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/addemail`,
        {
          subject,
          emailBody,
          templateName,
          emailId,
          setDef,
        },
        {
          headers: {
            Authorization: `Bearer ${webToken}`,
          },
        }
      );
      if (response.data) {
        dispatch(saveTemp_Succ("Template Saved"))
        setTimeout(() => {
          toast.success("Template Saved Successfully");
        }, 500);
        // setTimeout(() => {
        navigate("/email/template-list");
        // }, 1500);
      }
      if (response.data.message === "Unauthenticated") {
        dispatch(saveTemp_Fail(response?.data?.message));
      }
    } catch (error) {
      if (error?.message === "Network Error" || error?.response?.status === 500) {
        dispatch(errorCall(error))
        return;
      }
      dispatch(saveTemp_Fail(error));
      if (error?.response?.data?.message === "Unauthenticated") {
        localStorage.removeItem("token");
        navigate("/login");
      }
    }
  };
};



export const allTemp_Req = (data) => ({
  type: ALL_TEMP_REQ,
});
export const allTemp_Succ = (data) => ({
  type: ALL_TEMP_SUCC,
  payload: data,
});
export const allTemp_Fail = (error) => ({
  type: ALL_TEMP_FAIL,
  payload: error,
});


export const allTemp = (webToken, navigate) => {
  return async function test(dispatch, getState) {
    dispatch(allTemp_Req())
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/emails`,
        {
          headers: {
            Authorization: `Bearer ${webToken}`,
          },
        }
      );
      if (response?.data?.result) {
        dispatch(allTemp_Succ(response?.data?.result));
      }
    } catch (error) {
      if (error?.message === "Network Error" || error?.response?.status === 500) {
        dispatch(errorCall(error))
        return;
      }
      dispatch(allTemp_Fail(error));
      if (error?.response?.data?.message === "Unauthenticated") {
        localStorage.removeItem("token");
        navigate("/login");
      }
    }
  };
};



export const singleTemp_Req = (data) => ({
  type: SINGLE_TEMP_REQ,
});
export const singleTemp_Succ = (data) => ({
  type: SINGLE_TEMP_SUCC,
  payload: data,
});
export const singleTemp_Fail = (error) => ({
  type: SINGLE_TEMP_FAIL,
  payload: error,
});

export const singleTemp = (webToken, id, navigate) => {
  return async function test(dispatch, getState) {
    dispatch(singleTemp_Req())
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/email/${id}`,
        {
          headers: {
            Authorization: `Bearer ${webToken}`,
          },
        }
      );
      if (response?.data?.result) {
        dispatch(singleTemp_Succ(response?.data?.result));
      }
    } catch (error) {
      if (error?.message === "Network Error" || error?.response?.status === 500 || error?.response?.status === 404) {
        dispatch(errorCall(error))
        return;
      }
      dispatch(singleTemp_Fail(error));
      if (error?.response?.data?.message === "Unauthenticated") {
        localStorage.removeItem("token");
        navigate("/login");
      }
    }
  };
};




export const updateTemp_Req = (data) => ({
  type: UPDATE_TEMP_REQ,
});
export const updateTemp_Succ = (data) => ({
  type: UPDATE_TEMP_SUCC,
  payload: data,
});
export const updateTemp_Fail = (error) => ({
  type: UPDATE_TEMP_FAIL,
  payload: error,
});
export const updateTemp = (
  webToken,
  navigate,
  id,
  subject,
  emailBody,
  templateName,
  emailId,
  setDef
) => {
  return async function test(dispatch) {
    try {
      dispatch(updateTemp_Req())
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/updateemail/${id}`,
        {
          subject,
          emailBody,
          templateName,
          emailId,
          setDef,
        },
        {
          headers: {
            Authorization: `Bearer ${webToken}`,
          },
        }
      );
      if (response.data) {
        dispatch(updateTemp_Succ(response.data))
        setTimeout(() => {
          toast.success("Template Updated Successfully");
        }, 500);
        setTimeout(() => {
          navigate("/email/template-list");
        }, 1000);
      }
    } catch (error) {
      if (error?.message === "Network Error" || error?.response?.status === 500) {
        dispatch(errorCall(error))
        return;
      }
      dispatch(updateTemp_Fail(error));
      if (error?.response?.data?.message === "Unauthenticated") {
        localStorage.removeItem("token");
        navigate("/login");
      }
    }
  };
};



export const delTemp_Req = (data) => ({
  type: DELETE_TEMP_REQ,
});
export const delTemp_Succ = (data) => ({
  type: DELETE_TEMP_SUCC,
  payload: data,
});
export const delTemp_Fail = (error) => ({
  type: DELETE_TEMP_FAIL,
  payload: error,
});

export const deleteTemp = (webToken, id, navigate) => {
  return async function test(dispatch, getState) {
    dispatch(delTemp_Req())
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/deletemail/${id}`,
        {
          headers: {
            Authorization: `Bearer ${webToken}`,
          },
        }
      );
      if (response.data) {
        dispatch(delTemp_Succ("Template Deleted"))
        setTimeout(() => {
          toast.success("Template Deleted");
        }, 500);
      }
    } catch (error) {
      if (error?.message === "Network Error" || error?.response?.status === 500) {
        dispatch(errorCall(error))
        return;
      }
      dispatch(delTemp_Fail(error));
      if (error?.response?.data?.message === "Unauthenticated") {
        localStorage.removeItem("token");
        navigate("/login");
      }
      toast.warn(error?.response?.data?.msg);
    }
  };
};