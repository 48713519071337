import React from 'react';

const JobCard = ({ title, applicants,image }) => {
  return (
    <div className="box_block p-3 d-sm-flex justify-content-between mb-3">
      <div className="d-sm-flex">
        <div className="img_bx p-2">
          <img src={image} className='img-fluid' alt="" />
        </div>
        <div className="md_txt d-flex flex-column justify-content-between">
          <h2>{title} <span>2 years in Xamarin</span></h2>
          <div className="d-flex flex-wrap">
            <a href="#"><span><i className="fa-brands fa-facebook-f"></i></span>
              Facebook</a>
            <a href="#"><span><i className="fa-brands fa-linkedin-in"></i></span>
              Linkedin</a>
            <a href="#"><span><img src="/images/indeed.png" alt="" /></span>
              Indeed</a>
            <a href="#"><span><img src="/images/monster.png" alt="" /></span>
              Monster</a>
          </div></div></div> 
          <div className="lt_txt d-flex flex-column text-right justify-content-between">
        <h5 className='my-2'>2 days ago</h5>
        <h4 >{applicants}<span>Applicants</span></h4>
      </div>
    </div>
  );
};

export default JobCard;
