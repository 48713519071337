export const SAVE_TEMP_REQ ="SAVE_TEMP_REQ"
export const SAVE_TEMP_SUCC ="SAVE_TEMP_SUCC"
export const SAVE_TEMP_FAIL ="SAVE_TEMP_FAIL"

export const ALL_TEMP_REQ ="ALL_TEMP_REQ"
export const ALL_TEMP_SUCC ="ALL_TEMP_SUCC"
export const ALL_TEMP_FAIL ="ALL_TEMP_FAIL"

export const SINGLE_TEMP_REQ ="SINGLE_TEMP_REQ"
export const SINGLE_TEMP_SUCC ="SINGLE_TEMP_SUCC"
export const SINGLE_TEMP_FAIL ="SINGLE_TEMP_FAIL"


export const UPDATE_TEMP_REQ ="UPDATE_TEMP_REQ"
export const UPDATE_TEMP_SUCC ="UPDATE_TEMP_SUCC"
export const UPDATE_TEMP_FAIL ="UPDATE_TEMP_FAIL"


export const DELETE_TEMP_REQ ="DELETE_TEMP_REQ"
export const DELETE_TEMP_SUCC ="DELETE_TEMP_SUCC"
export const DELETE_TEMP_FAIL ="DELETE_TEMP_FAIL"

