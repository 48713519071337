import { TEMP_CANDIDATE_SUCCESS, STATUS_DROPDOWN_SUCCESS, STATUS_COLOR_SUCCESS, TEMP_CANDIDATE_REQUEST, TEMP_CANDIDATE_FAILURE, STATUS_DROPDOWN_REQUEST, STATUS_DROPDOWN_FAILURE } from "./actionType"

const initialState = {
    isLoading: false,
    data: {},
    status: "",
    color: "",
    isError: false,
    errorData: "",
    isErrorDropdown: false,
    errorDropdownData: ""
}

export const tempCandidate = (state = initialState, action) => {
    switch (action.type) {

        case TEMP_CANDIDATE_REQUEST: {
            return {
                isLoading: true,
                data: null,
                isError: false,
            }
        }
        case TEMP_CANDIDATE_SUCCESS: {
            return {
                isLoading: false,
                data: action.payload,
                isError: false,
            }
        }
        case TEMP_CANDIDATE_FAILURE: {
            return {
                isLoading: false,
                data: null,
                isError: true,
                errorData: action.payload
            }
        }
        default: {
            return state
        }
    }
}

export const tempDropdown = (state = initialState, action) => {
    switch (action.type) {
        case STATUS_DROPDOWN_REQUEST: {
            return {
                isLoading: true,
                data: null,
                isError: false,
            }
        }
        case STATUS_DROPDOWN_SUCCESS: {
            return {
                isLoading: false,
                status: action.payload,
                isError: false,
            }
        }
        case STATUS_DROPDOWN_FAILURE: {
            return {
                isLoading: false,
                data: null,
                isError: true,
                errorData: action.payload
            }
        }
        // case STATUS_COLOR_SUCCESS: {
        //     return {
        //         // ...state,
        //         isLoading: false,
        //         color: action.payload,
        //         isError: false,
        //     }
        // }

        default: {
            return state
        }
    }
}