import axios from "axios";
import { GET_MENU_FAILURE, GET_MENU_REQUEST, GET_MENU_SUCCESS, SORT_MENU_REQUEST, SORT_MENU_SUCCESS, SORT_MENU_FAILURE } from "./actionType";
import { errorCall } from "../../ErrorHandling/action";

export const getMenu_req = () => ({
    type: GET_MENU_REQUEST
});

export const getMenu_succ = (data) => ({
    type: GET_MENU_SUCCESS,
    payload: data
})

export const getMenu_failure = (err) => ({
    type: GET_MENU_FAILURE,
    payload: err
})

const getLocaldata = () => {
    const token = localStorage.getItem("token");
    return token
}

export const getMenuM = (navigate) => (dispatch) => {
    dispatch(getMenu_req());
    const mt = getLocaldata();
    const config = {
        headers: { Authorization: `Bearer ${mt}` }
    };
    axios.get(`${process.env.REACT_APP_BASE_URL}/api/auth/menuList`, config).then((res) => {
        dispatch(getMenu_succ(res.data))
    }).catch((error) => {
        if (error?.response?.data?.error == "Unauthenticated.") {
            localStorage.removeItem("token");
            navigate("/login");
        }

        dispatch(getMenu_failure(error))
        if (error.message === "Network Error" || error.response.status === 500) {
            dispatch(errorCall(error))
        }
    })
}


export const sortMenuList = (navigate, page, perpage, search, status) => (dispatch) => {
    dispatch(getMenu_req());
    const mt = getLocaldata();
    const config = {
        headers: { Authorization: `Bearer ${mt}` }
    };
    const body = {
        "pageNumber": page,
        perpage,
        search: search,
        status: parseInt(status)
    }
    axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/sortMenuList`, body, config).then((res) => {
        dispatch(getMenu_succ(res.data))
    }).catch((error) => {
        if (error?.response?.data?.error == "Unauthenticated.") {
            localStorage.removeItem("token");
            navigate("/login");
        }

        dispatch(getMenu_failure(error))
        if (error.message === "Network Error" || error.response.status === 500) {
            dispatch(errorCall(error))
        }
    })
}