import React from "react";
import "./UserTable.css";
import { Link, useNavigate } from "react-router-dom";

const UserTable = (props) => {
  const navigate = useNavigate()
  const handleClick = (e) => {
    navigate(`/user/user-edit/${e}`)
  }

  const usersPermission = localStorage?.getItem("userPermission")
  const permissionList = usersPermission?.split(',');

  return (
    <div className="table_box p-3">
      <div className="table-responsive ">
        <table className="table table-striped  text-center align-middle">
          <thead>
            <tr>
              <th className="txt__md fw-bold text-start">Name</th>
              <th className="txt__md fw-bold text-center text-md-start">Email</th>
              <th className="txt__md fw-bold text-center text-md-start">Status</th>
              <th className="txt__md fw-bold">Roles</th>
              {permissionList?.includes("65bb96b053e762c89b9af179") &&
                <th className="txt__md fw-bold">Action</th>
              }
            </tr>
          </thead>
          <tbody>
            {props?.userData?.isLoading == true
              ? (
                <tr><td colSpan={5}>
                  <div className='text-center py-3'>
                    <span className="loader"></span>
                  </div>
                </td></tr>
              )
              : (props?.userData?.data?.length == 0 ?
                <tr>
                  <td colSpan={5}>
                    <div className='text-center py-3'>
                      No Data
                    </div>
                  </td>
                </tr> : props?.userData?.data?.user?.map((e, i) => (
                  <tr key={i}>
                    <td className="align-middle">
                      <div className="d-flex align-items-center">
                        <div className="user_initial">{e.name.slice(0, 1)}</div>
                        <p className="mb-0 txt__para txt__sm">{e.name}</p>
                      </div>
                    </td>
                    <td className="align-middle text-center text-md-start">
                      <p className="mb-0 txt__para txt__sm">{e.email}</p>
                    </td>
                    {e.status == 1 ? (
                      <td className="align-start text-success text-center text-md-start" style={{whiteSpace:'nowrap'}}>
                        <span className="statusBadge bg-success"></span>
                        Active
                      </td>
                    ) : (
                      <td className="align-start text-danger text-center text-md-start" style={{whiteSpace:'nowrap'}}>
                        <span className="statusBadge bg-danger"></span>
                        Inactive
                      </td>
                    )}
                    <td className="align-middle">{e.typeName}</td>
                    {permissionList?.includes("65bb96b053e762c89b9af179") &&
                      <td onClick={() => { handleClick(e.id) }} className="align-middle">
                        <Link
                          // href={'/user/user-edit'}
                          className=""
                        ></Link>
                        <Link
                        // to="javascript:void(0);"
                        >
                          <svg
                            width={15}
                            height={15}
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <svg
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M14.502 2.64639C14.6965 2.45126 14.6965 2.13553 14.502 1.94039V1.93939L13.209 0.646393C13.0137 0.451202 12.6972 0.451202 12.502 0.646393L11.459 1.69039L13.459 3.69039L14.502 2.64639ZM10.752 2.39639L12.752 4.39639V4.39739L5.93897 11.2114C5.88384 11.2661 5.81673 11.3072 5.74297 11.3314L3.32897 12.1364C3.23921 12.1662 3.14034 12.1428 3.07347 12.0759C3.00661 12.009 2.98319 11.9101 3.01297 11.8204L3.81797 9.40639C3.8425 9.33253 3.88393 9.26542 3.93897 9.21039L10.752 2.39639Z"
                                fill="#708098"
                              ></path>
                              <path
                                d="M0 13.5C0 14.3284 0.671573 15 1.5 15H12.5C13.3284 15 14 14.3284 14 13.5V7.5C14 7.22386 13.7761 7 13.5 7C13.2239 7 13 7.22386 13 7.5V13.5C13 13.7761 12.7761 14 12.5 14H1.5C1.22386 14 1 13.7761 1 13.5V2.5C1 2.22386 1.22386 2 1.5 2H8C8.27614 2 8.5 1.77614 8.5 1.5C8.5 1.22386 8.27614 1 8 1H1.5C0.671573 1 0 1.67157 0 2.5L0 13.5Z"
                                fill="#708098"
                              ></path>
                            </svg>
                          </svg>
                        </Link>
                      </td>
                    }
                  </tr>
                )))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserTable;
