export const ADD_QUESTION_REQUEST = "ADD_QUESTION_REQUEST"
export const ADD_QUESTION_SUCCESS = "ADD_QUESTION_SUCCESS"
export const ADD_QUESTION_FAILURE = "ADD_QUESTION_FAILURE"

export const COPY_QUESTION_REQUEST = "COPY_QUESTION_REQUEST"
export const COPY_QUESTION_SUCCESS = "COPY_QUESTION_SUCCESS"
export const COPY_QUESTION_FAILURE = "COPY_QUESTION_FAILURE"

export const GET_SPECIFIC_QUES_REQUEST = "GET_SPECIFIC_QUES_REQUEST"
export const GET_SPECIFIC_QUES_SUCCESS = "GET_SPECIFIC_QUES_SUCCESS"
export const GET_SPECIFIC_QUES_FAILURE = "GET_SPECIFIC_QUES_FAILURE"

export const UPDATE_QUESTON_REQUEST = "UPDATE_QUESTON_REQUEST"
export const UPDATE_QUESTON_SUCCESS = "UPDATE_QUESTON_SUCCESS"
export const UPDATE_QUESTON_FAILURE = "UPDATE_QUESTON_FAILURE"

export const GET_QUESTION_REQUEST = "GET_QUESTION_REQUEST"
export const GET_QUESTION_SUCCESS = "GET_QUESTION_SUCCESS"
export const GET_QUESTION_FAILURE = "GET_QUESTION_FAILURE"

export const GET_QUESTION_APPLICANT_SUCCESS = "GET_QUESTION_APPLICANT_REQUEST"
export const GET_QUESTION_APPLICANT_REQUEST = "GET_QUESTION_APPLICANT_SUCCESS"
export const GET_QUESTION_APPLICANT_FAILURE = "GET_QUESTION_APPLICANT_FAILURE"

export const DELETE_QUESTION_REQUEST = "DELETE_QUESTION_REQUEST"
export const DELETE_QUESTION_SUCCESS = "DELETE_QUESTION_SUCCESS"
export const DELETE_QUESTION_FAILURE = "DELETE_QUESTION_FAILURE"

export const CORRECT_ANSWER_REQUEST = "CORRECT_ANSWER_REQUEST"
export const CORRECT_ANSWER_SUCCESS = "CORRECT_ANSWER_SUCCESS"
export const CORRECT_ANSWER_FAILURE = "CORRECT_ANSWER_FAILURE"

// export const CATEGORY_MARKS_REQUEST = "CATEGORY_MARKS_REQUEST"
// export const CATEGORY_MARKS_SUCCESS = "CATEGORY_MARKS_SUCCESS"
// export const CATEGORY_MARKS_FAILURE = "CATEGORY_MARKS_FAILURE"