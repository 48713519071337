import { RECORDING_archive_FAILURE, RECORDING_archive_REQUEST, RECORDING_archive_SUCCESS, RECORDING_GET_FAILURE, RECORDING_GET_REQUEST, RECORDING_GET_SUCCESS } from "./actionType"

const initialState = {
  isLoading: false,
  isError: false,
  data: null,
}

const archiveState = {
  isLoading: false,
  isError: false,
}

export const addToArchive = (state = archiveState, action) => {
  switch (action.type){
    case RECORDING_archive_REQUEST: 
      return{
        ...state,
        isLoading: true,
        isError: false
      }
      case RECORDING_archive_SUCCESS: 
      return{
        ...state,
        isLoading: false,
        isError: false
      }
      case RECORDING_archive_FAILURE: 
      return{
        ...state,
        isLoading: false,
        isError: false
      }
      default:
        return state;
  }

}

export const getCallRecordings = (state = initialState, action) => {
  switch (action.type) {
    case RECORDING_GET_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false
      }

    case RECORDING_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload
      }
    case RECORDING_GET_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true
      }
    default:
      return state;
  }
}