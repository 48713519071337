import {
  VIEW_APPLICANT_REQUEST,
  VIEW_APPLICANT_SUCCESS,
  VIEW_APPLICANT_FAILED,
} from "./actionType";

const initialState = {
  applicants: [],

  isLoading: false,
  isError: false,
};

export const viewApplicants = (state = initialState, action) => {
  switch (action.type) {
    case VIEW_APPLICANT_REQUEST: {
      return {
        ...state,
        applicants: [],
        isLoading: true,
        isError: false,
      };
    }

    case VIEW_APPLICANT_SUCCESS: {
      return {
        ...state,
        applicants: action.payload,
        isLoading: false,
        isError: false,
      };
    }
    case VIEW_APPLICANT_FAILED: {
      return {
        ...state,
        applicants: [],
        isLoading: false,
        isError: true,
      };
    }

    default: {
      return state;
    }
  }
};
