import React, { useEffect, useState } from "react";
import StatisticsCard from "./StatisticsCard";
import { useDispatch, useSelector } from "react-redux";
import { globalSearch } from "../../Redux/SearchReducer/action";
import { useNavigate } from "react-router-dom";

const Statistics = () => {
  const [searchValue, setSearchValue] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const webToken = localStorage.getItem("token");

  const searchData = useSelector((state) => state.searchGlobal.data);

  const handleSearchChange = (e) => {
    e.preventDefault();
    // dispatch(globalSearch(webToken, navigate, searchValue));
  };

  const handleSearchValue = (e) => {
    const newValue = e.target.value;
    setSearchValue(newValue);
  };

  return (
    <div className="statistics">
      <div className="row g-3 row-cols-2">
        <div className="col-12">
          <div className="bg-light lt-box-block p-3">
            <div className="col-sm-7 col-md-8">
              <span className="d-flex position-relative">
                <input
                  type="search"
                  name="query"
                  placeholder="Search"
                  className="form-control pe-4"
                  // value={searchValue ? searchValue : ""}
                  // onChange={(e) => {
                  //   handleSearchValue(e);
                  // }}
                  // onKeyDown={handleKeyPress}
                />

                <button
                  type="button"
                  className="fa_search"
                  onClick={handleSearchChange}
                >
                  <i aria-hidden="true" className="fa fa-search"></i>
                </button>
              </span>
              {/* {searchData?.searchResults?.Applicant?.map((e) => (
                <p>{e?.name}</p>
              ))} */}
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6">
          <StatisticsCard
            title="Ongoing Hiring Jobs"
            value={15}
            image={"/images/group1.png"}
          />
        </div>
        <div className="col-12 col-sm-6">
          <StatisticsCard
            title="Total Applicants"
            value={859}
            image={"/images/group2.png"}
          />
        </div>
        <div className="col-12 col-sm-6">
          <StatisticsCard
            title="Messages"
            value={287}
            image={"/images/group3.png"}
          />
        </div>
        <div className="col-12 col-sm-6">
          <StatisticsCard
            title="Total Hired Candidate"
            value={39}
            image={"/images/group4.png"}
          />
        </div>
      </div>
      <div className="col-sm-6 mt-3">
        <StatisticsCard
          title="Statistics"
          value={39}
          image={"/images/group4.png"}
        />
      </div>
    </div>
  );
};

export default Statistics;
