import React, {useEffect, useState } from 'react'
import SettingsNavbar from '../Components/SettingsNavbar'
import { useDispatch, useSelector } from 'react-redux'
import { experienceshort } from '../Redux/settings/experience/action';
import Avatar from '../Components/settings/Avatar';
import { Link, useNavigate } from 'react-router-dom';
import { globalDebouncing } from '../Debouncing/globalDebouncing';
import Paginate from '../TestPortal/components/Pagination/Pagination';


const Experience = ({ Sidedata, onSidedataChange }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const Experience = useSelector(state => state.getExperienceSet);
    const [search, setSearch] = useState("");
    const [status, setStatus] = useState("");
    const [page,setPage]=useState(1);
    const [perPage,setPerPage]=useState(10);
    const totalPages = useSelector(
        state => state.getExperienceSet?.data?.total_page
    );
    const pageChange=(e)=>{
        setPage(e);
        dispatch(experienceshort(navigate,search, status, e,perPage));

    };


    const usersPermission = localStorage?.getItem("userPermission")
    const permissionList = usersPermission?.split(',');

    const handlePageSizeChange=(e)=>{
        setPage(1);
        setPerPage(parseInt(e.target.value));
    }

    const handleInputChange = (e) => {
        setSearch(e.target.value);
        // debouncedSearch(e.target.value, status,1,perPage);
        globalDebouncing(dispatch, experienceshort, navigate, e.target.value, status, 1, perPage);
        setPage(1);
    };
    const handlestatusInputChange = (e) => {
        setStatus(e.target.value);
        setPage(1);
        // dispatch(skillShort(search, status, type));

    };
    const clearfilter=(e)=>{
        e.preventDefault();
        setPage(1);
        setSearch("");
        setStatus("");
        dispatch(experienceshort(navigate,"","", 1));
    };
    useEffect(() => {
        dispatch(experienceshort(navigate,search, status,page,perPage));
    }, [status,perPage])
    return (
        <div>
            <SettingsNavbar newSidedata={Sidedata} newonSidedataChange={onSidedataChange} navdata={{ header: "Experience Levels", buttonText: "Experience Suggetion", buttonPath: "/settings/skillsuggetion" }} />
            <div className="container-fluid">
                <div className="row g-3">
                    {permissionList?.includes("65bb93fb53e762c89b9af16e") &&
                        <div className="col-md-12">
                            <div className="companyAdmin_action_area mt-4">
                                <div className="add_companyAdmins">
                                    <Link
                                        to={'/settings/add-experience'}
                                        className="add_companyAdmin_link d-flex align-items-center"
                                    >
                                        <span className="icon d-flex align-items-center">
                                            <svg
                                                width="16px"
                                                height="12px"
                                                viewBox="0 0 16 12"
                                                version="1.1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                            >
                                                <g id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                    <g id="add-experience-level" fillRule="nonzero" stroke="#000000">
                                                        <path
                                                            d="M6,7.5 C7.79944855,7.5 9.04904733,7.90396756 9.89919109,8.47073007 C11.1416314,9.29902361 11.5,10.467307 11.5,11 C11.5,11.5 11,11.5 11,11.5 L11,11.5 L1,11.5 C0.5,10.467307 0.858368589,9.29902361 2.10080891,8.47073007 C2.95095267,7.90396756 4.20055145,7.5 6,7.5 L6,7.5 Z M6,0.5 C6.69035594,0.5 7.31535594,0.779822031 7.76776695,1.23223305 C8.22017797,1.68464406 8.5,2.30964406 8.5,3 C8.5,3.69035594 8.22017797,4.31535594 7.76776695,4.76776695 C7.31535594,5.22017797 6.69035594,5.5 6,5.5 C5.30964406,5.5 4.68464406,5.22017797 4.23223305,4.76776695 C3.77982203,4.31535594 3.5,3.69035594 3.5,3 C3.5,2.30964406 3.77982203,1.68464406 4.23223305,1.23223305 C4.68464406,0.779822031 5.30964406,0.5 6,0.5 Z"
                                                            id="Shape"
                                                        />
                                                    </g>
                                                </g>
                                            </svg>


                                        </span>
                                        <span className="txt__sm " style={{ marginLeft: 10 }}>
                                            Add New Experience Level
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    }
                    {/* start form section */}
                    <div className="col-12">
                        <div className="bg-white p-3 rounded">

                            <div className="row g-2">
                                <div className="col-md-4">
                                    <div className="position-relative list_search_fild">
                                        <input type="text" name="query" placeholder="Search"
                                            aria-label="" className="form-control h-100" onChange={(e) => handleInputChange(e)} value={search}/>
                                        <button className="job_list_search_box">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                fill="currentColor" viewBox="0 0 16 16" className="bi bi-search">
                                                <path
                                                    d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z">
                                                </path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <select className="col-sm-4  form-control form-control-custom" onChange={(e) => handlestatusInputChange(e)} value={status}>
                                        <option value="" defaultValue="selected">Select status</option>
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                    </select>
                                </div>
                                <div className="col-9 col-md-3  d-flex align-items-center">
                                {
                                    search==="" &&
                                    status===""?null:
                                        <button className="button_remove" onClick={clearfilter}>Clear Filter</button>
                                }
                                </div>
                                <div className="col-3 col-md-1 d-flex align-items-center justify-content-end">
                                    <select className="col-sm-4 form-control form-control-custom" onChange={(e) => handlePageSizeChange(e)}  style={{width:'61px'}}>
                                        <option value={10}>10</option>
                                        <option value={20}>20</option> 
                                        <option value={50}>50</option> 
                                        <option value={100}>100</option>
                                    </select>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* end form section */}

                    {/* start table section */}
                    <div className="col-12">
                        <div className="table_box p-3">
                            <div className="table-responsive ">
                                <table className="table table-striped setting_ table-striped-border">
                                    <thead>
                                        <tr>
                                            <th className="txt__md fw-bold">Name</th>
                                            <th className="txt__md fw-bold text-center text-md-start">Status</th>
                                            {permissionList?.includes("65bb946053e762c89b9af16f") &&
                                                <th className="txt__md fw-bold text-center">Action</th>
                                            }
                                        </tr>
                                    </thead>
                                    {
                                        Experience?.isLoading && !Experience?.isError ?
                                            <tbody>
                                                <tr><td colSpan={3}>
                                                    <div className='text-center py-3'>
                                                        <span className="loader"></span>
                                                    </div>
                                                </td></tr>
                                            </tbody> : <tbody>

                                                {
                                                    !Experience?.isLoading && !Experience?.isError && Experience?.data?.data?.length !== 0 ? Experience?.data?.data?.map((data, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <Avatar name={data?.name} size={"34px"} />
                                                                    <p className=" mb-0 txt__para txt__sm">{data?.name}</p>
                                                                </div>
                                                            </td>

                                                            {data?.status == 1 ? (
                                                                <td className="align-start text-success text-center text-md-start">
                                                                    <span className="statusBadge bg-success"></span>
                                                                    Active
                                                                </td>
                                                            ) : (
                                                                <td className="align-start text-danger text-center text-md-start">
                                                                    <span className="statusBadge bg-danger"></span>
                                                                    Inactive
                                                                </td>
                                                            )}
                                                            {permissionList?.includes("65bb946053e762c89b9af16f") &&
                                                                <td className="text-center">
                                                                    <Link to={`/settings/update-experience/${data?.id}`}>
                                                                        <svg
                                                                            width={15}
                                                                            height={15}
                                                                            viewBox="0 0 15 15"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                fillRule="evenodd"
                                                                                clipRule="evenodd"
                                                                                d="M14.502 2.64639C14.6965 2.45126 14.6965 2.13553 14.502 1.94039V1.93939L13.209 0.646393C13.0137 0.451202 12.6972 0.451202 12.502 0.646393L11.459 1.69039L13.459 3.69039L14.502 2.64639ZM10.752 2.39639L12.752 4.39639V4.39739L5.93897 11.2114C5.88384 11.2661 5.81673 11.3072 5.74297 11.3314L3.32897 12.1364C3.23921 12.1662 3.14034 12.1428 3.07347 12.0759C3.00661 12.009 2.98319 11.9101 3.01297 11.8204L3.81797 9.40639C3.8425 9.33253 3.88393 9.26542 3.93897 9.21039L10.752 2.39639Z"
                                                                                fill="#708098"
                                                                            />
                                                                            <path
                                                                                d="M0 13.5C0 14.3284 0.671573 15 1.5 15H12.5C13.3284 15 14 14.3284 14 13.5V7.5C14 7.22386 13.7761 7 13.5 7C13.2239 7 13 7.22386 13 7.5V13.5C13 13.7761 12.7761 14 12.5 14H1.5C1.22386 14 1 13.7761 1 13.5V2.5C1 2.22386 1.22386 2 1.5 2H8C8.27614 2 8.5 1.77614 8.5 1.5C8.5 1.22386 8.27614 1 8 1H1.5C0.671573 1 0 1.67157 0 2.5L0 13.5Z"
                                                                                fill="#708098"
                                                                            />
                                                                        </svg>
                                                                    </Link>
                                                                </td>
                                                            }
                                                        </tr>
                                                    )) : <tr>
                                                        <td colSpan={3}><p className='text-center notable_data_txt my-4'>No Data</p></td>
                                                    </tr>
                                                }
                                            </tbody>
                                    }

                                </table>


                                {/* {Experience?.isLoading && !Experience?.isError ? 
                            '' :    ( <p className='text-center notable_data_txt my-4'>Nothing To show</p>) 
                            } */}

                            </div>
                        </div>
                    </div>
                    {totalPages == undefined ? null : (
                                <>
                                  <div className="parentPage">
                                    <Paginate
                                      currentPage={page}
                                      totalPages={totalPages}
                                      onPageChange={(e) => pageChange(e)}
                                    />
                                  </div>
                                </>
                              )}
                        <div className="pagination-container"></div>

                </div>
            </div>





        </div>
    )
}

export default Experience
