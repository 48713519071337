import React from 'react';
import Pagination from 'react-bootstrap/Pagination'
import "../Pagination/Pagination.css";






const Paginate = ({ currentPage, totalPages, onPageChange }) => {
  const activePage = currentPage
  const totalNumberOfPages = totalPages; // example total number of pages
  const pageNeighbours = 2; // number of pages to show around the active page

  const handleSelect = (pageNumber) => {
    onPageChange(pageNumber);
    // Additional logic for page change can be added here
  };

  const paginationItems = [];
  let leftBoundary = activePage - pageNeighbours;
  let rightBoundary = activePage + pageNeighbours;

  if (leftBoundary < 1) leftBoundary = 1;
  if (rightBoundary > totalNumberOfPages) rightBoundary = totalNumberOfPages;

  // Handling ellipses and boundary pages
  const leftEllipsis = leftBoundary > 2;
  const rightEllipsis = rightBoundary < totalNumberOfPages - 1;

  if (leftEllipsis) {
    paginationItems.push(
      <Pagination.Item key={1}onClick={() => handleSelect(1)}>{1}</Pagination.Item>
    );
    paginationItems.push(<Pagination.Ellipsis key="ellipsis-left" />);
  }

  for (let number = leftBoundary; number <= rightBoundary; number++) {
    paginationItems.push(
      <Pagination.Item 
        key={number} 
        className="custom-pagination-item"
        active={number === activePage}
        disabled={number === activePage}
        onClick={() => handleSelect(number)}>
        {number}
      </Pagination.Item>
    );
  }

  if (rightEllipsis) {
    paginationItems.push(<Pagination.Ellipsis  className="custom-pagination-item" key="ellipsis-right" />);
    paginationItems.push(
      <Pagination.Item  className="custom-pagination-item" key={totalNumberOfPages} onClick={() => handleSelect(totalNumberOfPages)}>
        {totalNumberOfPages}
      </Pagination.Item>
    );
  }

  return (
    <Pagination>
      <Pagination.First onClick={() => handleSelect(1)} disabled={activePage === 1}  className="custom-pagination-item" />
      <Pagination.Prev  className="custom-pagination-item" onClick={() => handleSelect(activePage - 1)} disabled={activePage === 1 } />

      {paginationItems}

      <Pagination.Next  className="custom-pagination-item" onClick={() => handleSelect(activePage + 1)} disabled={activePage === totalNumberOfPages} />
      <Pagination.Last  className="custom-pagination-item" onClick={() => handleSelect(totalNumberOfPages)} disabled={activePage === totalNumberOfPages} />
    </Pagination>
  );
}
export default Paginate;
