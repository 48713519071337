import React, { useEffect, useState } from 'react'
import SettingsNavbar from '../Components/SettingsNavbar'
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom'
import { BsFillArrowLeftCircleFill } from 'react-icons/bs'
import Avatar from '../Components/settings/Avatar'
import { useDispatch, useSelector } from 'react-redux'
import { updateExperiences } from '../Redux/settings/addExperience/action'
import { findExperience } from '../Redux/settings/findExperience/action'
import { toast } from 'react-toastify';
import Spiner1 from '../Components/Spiner1'


const UpdateExperience = (props) => {
    const [name, SetName] = useState();
    const [abbreviation, setAbbreviation] = useState("");
    const [SortingOrder, setSortingOrder] = useState("");
    const [status, setStatus] = useState("1");
    const [note, setNote] = useState("");
    const { id } = useParams();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const experienceData = useSelector(state => state.findExperiences);
    const addExperienceSet =  useSelector(state => state.addExperienceSet);
    const handleName = (e) => {
        SetName(e.target.value);
    }
    const handleAbbreviation = (e) => {
        setAbbreviation(e.target.value);
    }
    const handleSortingOrder = (e) => {
        setSortingOrder(e.target.value);
    }
    const handlestatus = (e) => {
        setStatus(e.target.value);
    }
    const handlenote = (e) => {
        setNote(e.target.value);
    }
    const updateExperience = (e) => {

        if (name?.trim() == "") {
            toast.warning("Please Provide Experience Level");
            return
        }else if (abbreviation?.trim() == "") {
            toast.warning("Please Provide Abbreviation");
            return
        }else if (!note.trim()) {
            toast.warn("Please Provide Note");
            return;
        }
        // else if (SortingOrder?.trim() == "") {
        //     toast.warning("Please enter sorting order");
        //     return
        // }

        dispatch(updateExperiences(abbreviation, name, note, SortingOrder, status, id, navigate));
    }

    useEffect(() => {
       if(experienceData?.data?.data)
        { 
        SetName(experienceData?.data?.data?.name);
        setAbbreviation(experienceData?.data?.data?.abbreviation);
        setSortingOrder(experienceData?.data?.data?.sort_order);
        setStatus(experienceData?.data?.data?.status);
        setNote(experienceData?.data?.data?.note);
        }
    }, [experienceData?.data?.data])

    useEffect(() => {
        dispatch(findExperience(id, navigate));
    }, []);
    return (
        <div>
            <SettingsNavbar navdata={{ header: "Update Experience", buttonText: "Experience Button", buttonPath: "/settings/skillsuggetion" }} newSidedata={props.Sidedata} newonSidedataChange={props.onSidedataChange}/>
            <div className="user-action-area">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Link to={"/settings/experience"}> <BsFillArrowLeftCircleFill /> Experience List</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="create-company-form placeholder-glow">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form">
                                <div className="row g-2">
                                    <div className="col-md-4">
                                        <div className="position-relative list_search_fild">
                                            <input type="text" name="query" placeholder="Expereience Level Name"
                                                aria-label="" value={name?name:""} className={`form-control coustom_P h-100 ${addExperienceSet?.isLoading || experienceData?.isLoading ?"placeholder":''}`}onChange={(e) => handleName(e)} />
                                            <Avatar placeholder={addExperienceSet?.isLoading || experienceData?.isLoading ?"placeholder":''}name={name}size={"34px"} position="absolute" top="3px" left="5px" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="position-relative list_search_fild">
                                            <input type="text" name="query" placeholder="Abbreviation"
                                                aria-label="" value={abbreviation?abbreviation:""} className={`form-control coustom_P h-100 ${addExperienceSet?.isLoading || experienceData?.isLoading ?"placeholder":''}`} onChange={(e) => handleAbbreviation(e)} />
                                            <Avatar placeholder={addExperienceSet?.isLoading || experienceData?.isLoading ?"placeholder":''} name={abbreviation} size={"34px"} position="absolute" top="3px" left="5px" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <select className={`col-sm-4 mb-2 form-control form-control-custom ${addExperienceSet?.isLoading || experienceData?.isLoading ?"placeholder":''}`}value={status} onChange={(e) => handlestatus(e)}>
                                            <option value="1" >Active</option>
                                            <option value="0" >Inactive</option>
                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="position-relative list_search_fild">
                                            <input type="text-area" name="query" placeholder="Add Notes..."
                                                aria-label="" value={note?note:""} className={`form-control h-100 ${addExperienceSet?.isLoading || experienceData?.isLoading ?"placeholder":''}`} onChange={(e) => handlenote(e)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button className={`btn btn-success button-outline choosed mt-3 ${addExperienceSet?.isLoading || experienceData?.isLoading ?"placeholder":''}`} disabled={addExperienceSet?.isLoading || experienceData?.isLoading} onClick={(e) => updateExperience(e)}>
                               {addExperienceSet?.isLoading || experienceData?.isLoading ? <Spiner1/> : "Update"} 
                            </button>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default UpdateExperience
