import {
  USER_FAILURE,
  USER_REQUEST,
  USER_SUCCESS,


  ACCESS_REQUEST,
  ACCESS_SUCCESS,
  ACCESS_FAILURE,


  SPEC_USER_REQUEST,
  SPEC_USER_SUCCESS,
  SPEC_USER_FAILURE,


  USER_ADD_SUCCESS,
  USER_ADD_FAILURE,
  USER_ADD_REQUEST,


  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
} from "./actionType";

const initState = {
  isLoading: false,
  isAddLoading: false,
  isUpLoading: false,
  isLoadingAccess:false,
  isLoadingSpecific:false,AddisAddError:true,
  
  isError: false,
  isAddError: false,
  isUpError: false,
  isErrorAccess:false,
  isErrorSpecific:false,
  

  errorData: "",
  errorAddData: "",
  isUpErrorData: "",
  isErrorAccessData:"",
  isErrorSpecificData:"",


  data: [],
  accessData: [],
  passError: [],
  specUser: {},
  updateUser: {},
};

export const User_Reducer = (state = initState, action) => {
  switch (action.type) {
    case USER_REQUEST: {
      return {
        ...state,
        isLoading: true,
        AddisAddError:true,
        
        isError: false,
      };
    }
    case USER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        isError: false,
      };
    }
    case USER_FAILURE: {
      return {
        ...state,
        isLoading: false,
        data: [],
        isError: true,
        errorData: action.payload,
        passError: action.payload,
      };
    }



    case ACCESS_REQUEST: {
      return {
        ...state,
        // accessData: [],
        isLoadingAccess:true,
        isErrorAccess:false,
      };
    }
    case ACCESS_SUCCESS: {
      return {
        ...state,
        isLoadingAccess:false,
        accessData: action.payload,
        isErrorAccess:false,
      };
    }
    case ACCESS_FAILURE: {
      return {
        ...state,
        accessData: [],
        isLoadingAccess:false,
        isErrorAccess:true,
        isErrorAccessData: action.payload,
      };
    }




    case SPEC_USER_REQUEST: {
      return {
        ...state,
        specUser: null,
        isLoadingSpecific:true,
      };
    }
    case SPEC_USER_SUCCESS: {
      return {
        ...state,
        specUser: action.payload,
        isLoadingSpecific:false,
      };
    }
    case SPEC_USER_FAILURE: {
      return {
        ...state,
        specUser: null,
        isLoadingSpecific:false,
        isErrorSpecificData:action.payload,
      };
    }




    case UPDATE_USER_REQUEST: {
      return {
        ...state,
        isUpLoading: true,
        updateUser: null,
      };
    }
    case UPDATE_USER_SUCCESS: {
      return {
        ...state,
        isUpLoading: false,
        updateUser: action.payload,
      };
    }
    case UPDATE_USER_FAILURE: {
      return {
        ...state,
        isUpLoading: false,
        updateUser: null,
        isUpErrorData:action.payload,
      };
    }




    case USER_ADD_SUCCESS: {
      return {
        ...state,
        isAddLoading: false,
        errorAddData: "",
      };
    }
    case USER_ADD_REQUEST: {
      return {
        ...state,
        isAddLoading: true,
        errorAddData:"",
      };
    }
    case USER_ADD_FAILURE: {
      return {
        ...state,
        isAddLoading: false,
        isAddError:true,
        errorAddData:action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
