import { CODE_EXAM_REQ, CODE_EXAM_SUCC, CODE_EXAM_FAIL } from "./actionType"

const initialState = {
    isLoading: false,
    data: {},
    isError: false,
    errorData: ""
}

export const addCodeExamReducer = (state = initialState, action) => {
    switch (action.type) {
        case CODE_EXAM_REQ: {
            return {
                isLoading: true,
                data: {},
                isError: false
            }
        }
        case CODE_EXAM_SUCC: {
            return {
                isLoading: false,
                data: action.payload,
                isError: false,
            }
        }
        case CODE_EXAM_FAIL: {
            return {
                isLoading: false,
                data: {},
                isError: true,
                errorData: action.payload
            }
        }
        default: {
            return state
        }
    }
}