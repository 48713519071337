import React, { useEffect, useState } from "react";
import UserHead from "./UserHead";
import UserTable from "./UserTable";
import "./UserList.css";
import { useDispatch, useSelector } from "react-redux";
import { userCall,accessList } from "../../Redux/User/action";
import { useNavigate } from "react-router-dom";
import  { globalDebouncing }  from "../../Debouncing/globalDebouncing";
import Paginate from "../../TestPortal/components/Pagination/Pagination";

const Userlist = (props) => {
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState("");
  const [type, setType] = useState("");  // filter using type is not in used now
  const [page,setPage]=useState(1);
  const [perPage,setPerPage]=useState(10);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.User_Reducer);
  const totalPages = useSelector(
    (state) => state.User_Reducer?.data?.total_page
   );

  // const handleSearch = (e) => {
  // const target=e.target.value
  // const searchParameters={dispatch,userCall,navigate, token,target}
  // globalDebouncing( searchParameters);
  // setSearch(e.target.value);
  // globalDebouncing( dispatch,userCall,navigate, token, e.target.value);
  // };
  const handleInputChange = (e) => {
    setSearch(e.target.value);
    globalDebouncing(dispatch, userCall, navigate, token, e.target.value, status, type, 1, perPage);
    setPage(1);
  };
  const handlestatusInputChange = (e) => {
    setStatus(parseInt(e.target.value));
    setPage(1);
  };
  const handleTypeInputChange = (e) => {
    setType(e.target.value);
    setPage(1);
  };
  const pageChange = (e) => {
    setPage(e);
    dispatch(userCall(navigate, token, search, status, type, e, perPage));
  };
  const handlePageSizeChange = (e) => {
    setPage(1);
    setPerPage(parseInt(e.target.value));
  }
  const clearfilter = (e) => {
    e.preventDefault();
    setPage(1);
    setType("");
    setSearch("");
    setStatus("");
    // dispatch(userCall(navigate,token,"", "", "", 1, perPage));
  };
  useEffect(() => {
    dispatch(userCall(navigate, token,search,status,type,page,perPage));
  }, [status,type,perPage]);

  useEffect(() => {
    dispatch(accessList(navigate, token));
  }, []);
  const usersPermission = localStorage?.getItem("userPermission")
  const permissionList = usersPermission?.split(',');

  return (
    <div>
      {permissionList?.includes("61ae06f5edb406550227b942") &&
        <UserHead newSidedata={props.Sidedata} newonSidedataChange={props.onSidedataChange} navdata={{ header: "Users", buttonText: "Post Jobs" }} />
      }
      {/* <div className="dashboard-navigation">
        <nav className="dashboard-nav p-3">
          <div
            dir="ltr"
            className="dashboard-carousel-position slick-slider slick-initialized "
          >

          </div>
          <div className=" search-container  ">

            <input
              type="search"
              placeholder="Search..."
              onChange={(e) => { handleSearch(e) }}
              className="search_box rounded-5 text-align-center pl-1 "
            />
          </div>
        </nav>
      </div> */}
      {permissionList?.includes("65bb968153e762c89b9af178") &&
        <div className="user-action-area">
          <div className="add-users  d-flex ">
            <span onClick={() => { navigate('/user/user-create') }} style={{cursor:"pointer"}}>
              <span className="icon bg-white shadow-sm rounded me-2 p-2">
                <svg
                  width="19"
                  height="14"
                  viewBox="0 0 19 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.5 3.5C10.5 5.433 8.933 7 7 7C5.067 7 3.5 5.433 3.5 3.5C3.5 1.567 5.067 0 7 0C8.933 0 10.5 1.567 10.5 3.5ZM0 12.8333C0 14 1.16667 14 1.16667 14H12.8333C12.8333 14 14 14 14 12.8333C14 11.6667 12.8333 8.16667 7 8.16667C1.16667 8.16667 0 11.6667 0 12.8333Z"
                    fill="black"
                  ></path>
                  <path
                    d="M15.75 3.5C16.0722 3.5 16.3334 3.76117 16.3334 4.08333V5.83333H18.0834C18.4055 5.83333 18.6667 6.0945 18.6667 6.41667C18.6667 6.73883 18.4055 7 18.0834 7H16.3334V8.75C16.3334 9.07217 16.0722 9.33333 15.75 9.33333C15.4279 9.33333 15.1667 9.07217 15.1667 8.75V7H13.4167C13.0945 7 12.8334 6.73883 12.8334 6.41667C12.8334 6.0945 13.0945 5.83333 13.4167 5.83333H15.1667V4.08333C15.1667 3.76117 15.4279 3.5 15.75 3.5Z"
                    fill="black"
                  ></path>
                </svg>
              </span>
              <span className="txt__sm txt__para">Add User</span>
            </span>

          </div>
        </div>
      }
          {/* start form section */}
          <div className="col-12 my-4">
                        <div className="bg-white p-3 rounded">

                            <div className="row g-2">
                                <div className="col-md-4">
                                    <div className="position-relative list_search_fild">
                                        <input type="text" onChange={(e) => handleInputChange(e)} name="query" placeholder="Search"
                                            aria-label="" className="form-control h-100" value={search}/>
                                        <button className="job_list_search_box">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                fill="currentColor" viewBox="0 0 16 16" className="bi bi-search">
                                                <path
                                                    d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z">
                                                </path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <select className="col-sm-4  form-control form-control-custom" value={status} onChange={(e) => handlestatusInputChange(e)}>
                                        <option value="" hidden>Select status</option>
                                        <option value={1}>Active</option>
                                        <option value={0}>Inactive</option>
                                    </select>
                                </div>
                                <div className="col-md-2">
                                    <select className="col-sm-4  form-control form-control-custom" value={type} onChange={(e) => handleTypeInputChange(e)}>
                                        <option value="" hidden>Select type</option>
                                        {
                                          userData.isLoadingAccess==true || userData.isErrorAccess==true?null:
                                          userData?.accessData?.map((data,index)=>( 
                                            <option key={index} value={data.id} >{data.name}</option>
                                          ))
                                        }
                                       
                                    </select>
                                </div>
                                    <div className="col-9 col-md-3  d-flex align-items-center">
                                    {
                                    search==="" &&
                                    type==="" &&
                                    status===""?null:
                                        <button className="button_remove" onClick={clearfilter}>Clear Filter</button>
                                    }
                                    </div>
                                
                                <div className="col-3 col-md-1 d-flex align-items-center justify-content-end">
                                    <select className="col-sm-4  form-control form-control-custom" onChange={(e) => handlePageSizeChange(e)} style={{width:'61px'}}>
                                        <option value={10}>10</option>
                                        <option value={20}>20</option> 
                                        <option value={50}>50</option> 
                                        <option value={100}>100</option>
                                    </select>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* end form section */}
      <div className="pb-3">
        <UserTable userData={userData} />
      </div>
      {totalPages == undefined ? null : (
                                <>
                                  <div className="parentPage">
                                    <Paginate
                                      currentPage={page}
                                      totalPages={totalPages}
                                      onPageChange={(e) => pageChange(e)}
                                    />
                                  </div>
                                </>
                              )}
                        <div className="pagination-container"></div>
    </div>
  );
};

export default Userlist;
