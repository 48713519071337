
import { SKILL_UPDATE_REQUEST,SKILL_UPDATE_FAILURE, SKILL_UPDATE_SUCCESS } from "./actionType"

const initialState = {
    isLoading: false,
    data:"",
    isError: false,
    errorData: "",
    targetFrameworkIndex:null,
}

export const updateSkill = (state = initialState,action)=>{
     switch(action.type){
        case SKILL_UPDATE_REQUEST:{
           return{
            ...state,
            isLoading : true,
            data :"",
            isError : false,
            targetFrameworkIndex:action.payload,
           }
        }
        case SKILL_UPDATE_SUCCESS:{
            return{
                ...state,
                isLoading : false,
                data : action?.payload,
                isError : false,
                targetFrameworkIndex:null,
            }
        }
      
        case SKILL_UPDATE_FAILURE:{
            return{
                ...state,
                isLoading : false,
                data :"",
                isError : true,
                errorData:action.payload,
                targetFrameworkIndex:null,
            }
        }
        default : {
            return state
         }
     }
}