import React, { useState } from "react";
import { Col, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { IoMdPerson } from "react-icons/io";
import Button from "react-bootstrap/Button";
import ApplyPopUp from "./ApplyPopUp";
import { postJobs } from "../../Redux/AppyJobs/action";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import UploadCV from "./UploadCV";
import { useParams } from "react-router-dom";

const ApplyJobs = () => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();

  const [fileData, setFileData] = useState(null);
  const [Nerror, setNError] = useState({});
  const [Qerror, setQError] = useState([]);
  const [Jerror, setJError] = useState([]);
  const [Eerror, setEError] = useState([]);
// log
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    alternatePhone: "",
    location: "",
    gender: "",
    language: "",
    workExperience: "",
    relevantExperience: "",
    jobHistory: "",
    qualification: "",
    passingYear: "",
    institute: "",
    percentage: "",
    fileData,
  });

  const getFileData = (newFile) => {
    setFileData(newFile);
  };


  const [workExperience, setWorkExperience] = useState({
    workExpYear: "",
    workExpMonth: "",
  });

  const [relevantExperience, setRelevantExperience] = useState({
    relExpYear: "",
    relExpMonth: "",
  });

  const handleexp = (e, experienceType) => {
    const { name, value } = e.target;
    const yearRegex = /^\d{0,4}$/; 
    if (name === 'workExpYear') {
    if (!yearRegex.test(value)) {
      return;
    }
  }
    if (name === "workExpYear") {
      delete Eerror.workExpYear;
    }
    if (name === "workExpMonth") {
      delete Eerror.workExpMonth;
    }
    if (name === "relExpYear") {
      delete Eerror.relExpYear;
    }
    if (name === "relExpMonth") {
      delete Eerror.relExpMonth;
    }
    if (experienceType === "workExperience") {
      setWorkExperience((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else if (experienceType === "relevantExperience") {
      setRelevantExperience((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };


  const [qualificationData, setQualificationData] = useState([
    {
      qualification: "",
      passingYear: "",
      institute: "",
      percentage: "",
    },
  ]);

 const addList = () => {
  const lastQualification = qualificationData[qualificationData.length - 1];

  if (!lastQualification.qualification) {
    toast.error("Please fill the qualification name");
    return;
  }

  if (!lastQualification.passingYear) {
    toast.error("Please fill the passing year");
    return;
  }

  setQualificationData([
    ...qualificationData,
    { qualification: "", passingYear: "", institute: "", percentage: "", key: Date.now() },
  ]);
};

  const removeField = (index) => {
     setQualificationData((prevData) =>
    prevData.filter((_, i) => i !== index)
  );
};
  const resetFields = (index) => {
    const inputArray=[...qualificationData];
  inputArray[index]=
    {
      qualification: "",
      passingYear: "",
      institute: "",
      percentage: "",
    }
  setQualificationData(inputArray);
};

  const handleList = (e, index) => {
    const { name, value } = e.target;
    const updatedList = [...qualificationData];
    const percentageRegex =/^\d{0,3}(\.\d{1,2})?$/;
    const yearRegex = /^\d{0,4}$/; 
     if (name === 'percentage') {
    if (!percentageRegex.test(value) || parseFloat(value) > 100) {
      return;
    }
  }

  if (name === 'passingYear') {
    if (!yearRegex.test(value)) {
      return;
    }
  }
    updatedList[index][name] = value;

    setQualificationData(updatedList);

    if (name === "qualification") {
      delete Qerror[index]?.qualification;
    }
    if (name === "institute") {
      delete Qerror[index]?.institute;
    }
    if (name === "passingYear") {
      delete Qerror[index]?.passingYear;
    }
    if (name === "percentage") {
      delete Qerror[index]?.percentage;
    }
  };

  const [data, setData] = useState([
    {
      startDate: { startDateYear: "", startDateMonth: "" },
      endDate: { endDateYear: "", endDateMonth: "" },
      company: "",
      jobTitle: "",
      currentlyWorking: false,
    },
  ]);


const handleAddDataPair = () => {
  const lastJobHistory = data[data.length - 1];

  if (!lastJobHistory.company) {
    toast.error("Please fill the company name");
    return;
  }

  if (
    !lastJobHistory.startDate.startDateYear ||
    !lastJobHistory.startDate.startDateMonth
  ) {
    toast.error("Please fill the Joining date");
    return;
  }

  setData([
    ...data,
    {
      id: Date.now(),
      startDate: { startDateYear: "", startDateMonth: "" },
      endDate: { endDateYear: "", endDateMonth: "" },
      company: "",
      jobTitle: "",
      currentlyWorking: false,
    },
  ]);
};

const handleRemoveDataPair = (id) => {
  setData((prevData) => prevData.filter((item) => item.id !== id));
};
const handleResetDataPair = (id) => {
  setData((prevData) =>
    prevData.map((item) =>
      item.id === id
        ? {
            ...item,
            startDate: { startDateYear: "", startDateMonth: "" },
            endDate: { endDateYear: "", endDateMonth: "" },
            company: "",
            jobTitle: "",
            currentlyWorking: false,
          }
        : item
    )
  );
};

  const handleDateChange = (index, field, value, type) => {
    const newData = [...data];
    newData[index][type][field] = value;
    setData(newData);
    if (field === "startDateYear") {
      delete Jerror[index]?.startDateYear;
    }
    if (field === "startDateMonth") {
      delete Jerror[index]?.startDateMonth;
    }
    if (field === "endDateYear") {
      delete Jerror[index]?.endDateYear;
    }
    if (field === "endDateMonth") {
      delete Jerror[index]?.endDateMonth;
    }
  };



  const handleTextChange = (index, field, value) => {
    if (field === "company") {
      delete Jerror[index]?.company;
    }
    if (field === "jobTitle") {
      delete Jerror[index]?.jobTitle;
    }
    const newData = [...data];
    newData[index][field] = value;
    setData(newData);
  };



  const handleCurrentlyWorkingChange = (index) => {
    const newData = [...data];
    newData[index].currentlyWorking = !newData[index].currentlyWorking;

    if (newData[index].currentlyWorking) {
      const currentDate = new Date();
      newData[index].endDate.endDateYear = currentDate.getFullYear().toString();
      newData[index].endDate.endDateMonth = months[currentDate.getMonth()];
    } else {

      newData[index].endDate.endDateYear = "";
      newData[index].endDate.endDateMonth = "";
    }
    setData(newData);
  };



  const handleChange = (e) => {
    const { name, value } = e.target;
    const nameRegex = /^[a-zA-Z\s]+$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; 
    const phoneRegex = /^\d{10}$/;
    const languageRegex = /^[a-zA-Z, ]+$/;
    if (name === "firstName") {
      if (nameRegex.test(value) || value === "") {
      delete Nerror.firstName;
      setFormData({ ...formData, [name]: value });
    } else {
      Nerror.firstName = "First name should only contain letters and spaces";
    }
    }
    if (name === "lastName") {
      if (nameRegex.test(value) || value === "") {
      delete Nerror.lastName;
      setFormData({ ...formData, [name]: value });
    } else {
      Nerror.lastName = "Last name should only contain letters and spaces";
    }
    }
    if (name === "email") {
       if (emailRegex.test(value) || value === "") {
      delete Nerror.email;
      setFormData({ ...formData, [name]: value });
    } else {
      Nerror.email = "Please enter a valid email address";
    }
    }
    if (name === "phoneNumber") {
      if (phoneRegex.test(value) || value === "") {
        delete Nerror.phoneNumber;
      setFormData({ ...formData, [name]: value });
    } else {
      Nerror.phoneNumber = "Phone number should be a 10-digit number";
    }
    }
    if (name === "alternatePhone") {
       if (phoneRegex.test(value) || value === "") {
      delete Nerror.alternatePhone;
      setFormData({ ...formData, [name]: value });
    } else {
      Nerror.alternatePhone = "Alternate number should be a 10-digit number";
    }
    }
    if (name === "gender") {
      delete Nerror.gender;
    }
    if (name === "location") {
      delete Nerror.location;
    }
    if (name === "language") {
      if (languageRegex.test(value) || value === "") {
      delete Nerror.language;
      setFormData({ ...formData, [name]: value });
    } else {
      Nerror.language = "Language should only contain letters";
    }
    }
    setFormData({ ...formData, [name]: value });
  };
 
  const validateQForm = () => {
    const newErrors = [];
    qualificationData.forEach((data, index) => {
      const error = {};
      if (!data.qualification) {
        error.qualification = "Qualification is required";
      }
      if (!data.passingYear) {
        error.passingYear = "Passing year is required";
      }
      else if (isNaN(data.passingYear)) {
      error.passingYear = "Passing year should be a number";
      }
      if (!data.institute) {
        error.institute = "Institute name is required";
      }
      if (!data.percentage) {
        error.percentage = "Percentage is required";
      }
      else if (isNaN(data.percentage) || data.percentage < 0 || data.percentage > 100) {
      error.percentage = "Please enter a valid percentage between 0 and 100";
      }
      if (data.percentage > 100) {
        error.percentage = "Please enter valid percentage";
      }
      newErrors[index] = error;
    });
    setQError(newErrors);
    return newErrors.every((error) => Object.keys(error).length === 0);
  };

  const validateNForm = () => {
    const newErrors = {};

    if (!formData.firstName) {
    newErrors.firstName = "First name is required";
    } else if (!/^[a-zA-Z\s]+$/.test(formData.firstName)) {
      newErrors.firstName = "First name should only contain letters and spaces";
    }

    if (!formData.lastName) {
      newErrors.lastName = "Last name is required";
    } else if (!/^[a-zA-Z\s]+$/.test(formData.lastName)) {
      newErrors.lastName = "Last name should only contain letters and spaces";
    }
    if (!formData.email) {
      newErrors.email = "Email is required";
    }
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(formData.email)) {
      newErrors.email = "Please enter a valid email address";
     
    }
    if (!formData.phoneNumber) {
      newErrors.phoneNumber = "Phone number is required";
    }
    else if (isNaN(formData.phoneNumber)) {
    newErrors.phoneNumber = "Phone number should be a number";
    } else if (!/^\d{10}$/.test(formData.phoneNumber)) {
    newErrors.phoneNumber = "Phone number should be a 10-digit number";
    }
    // if (formData.phoneNumber > 10) {
    //   newErrors.phoneNumber = "Phone number should be 10 digit";
    // }
    if (!formData.alternatePhone) {
      newErrors.alternatePhone = "Alternate number is required";
    }
    else if (isNaN(formData.alternatePhone)) {
    newErrors.alternatePhone = "Alternate number should be a number";
    } else if (!/^\d{10}$/.test(formData.alternatePhone)) {
    newErrors.alternatePhone = "Alternate number should be a 10-digit number";
    }
    // if (formData.alternatePhone > 10) {
    //   newErrors.alternatePhone = "Alternate number should be 10 digit";
    // }
    if (!formData.gender) {
      newErrors.gender = "Gender is required";
    }
    if (!formData.location) {
      newErrors.location = "Location is required";
    }
    if (!formData.language) {
      newErrors.language = "Language is required";
    }

    setNError(newErrors);
  };
  // for normal errors end

  // for exp
  const validateEForm = () => {
    const newErrors = {};

    const { workExpYear, workExpMonth } = workExperience;
    const { relExpYear, relExpMonth } = relevantExperience;

    if (!workExpYear) {
      newErrors.workExpYear = "Year is required";
    }
    if (!workExpMonth) {
      newErrors.workExpMonth = "Month is required";
    }
    if (!relExpYear) {
      newErrors.relExpYear = "Year is required";
    }
    if (!relExpMonth) {
      newErrors.relExpMonth = "Year is required";
    }

    setEError(newErrors);
  };
  // for exp ends

  // for job
  const validateJForm = () => {
    const newErrors = [];

    data.forEach((data, index) => {
      const error = {};
      if (!data.company) {
        error.company = "Company is required";
      }
      if (!data.jobTitle) {
        error.jobTitle = "Job title is required";
      }
      if (!data.endDate.endDateYear) {
        error.endDateYear = "Year is required";
      }
      if (!data.endDate.endDateMonth) {
        error.endDateMonth = "Month is required";
      }
      if (!data.startDate.startDateYear) {
        error.startDateYear = "Year is required";
      }
      if (!data.startDate.startDateMonth) {
        error.startDateMonth = "Month is required";
      }

      newErrors[index] = error;
    });
    setJError(newErrors);

    return newErrors.every((error) => Object.keys(error).length === 0);
  };
  // for job end

  const handleSubmit = (e) => {
    e.preventDefault();
    validateNForm();
    validateEForm();
    validateQForm();

    if (validateJForm()) {
      formData.jobHistory = data;
    } else {
      console.log("Form validation failed");
    }

    const [{ qualification, institute, passingYear, percentage }] =
      qualificationData;

    const { relExpYear, relExpMonth } = relevantExperience;
    const { workExpYear, workExpMonth } = workExperience;

    const [{ startDate, endDate, company, jobTitle, currentlyWorking }] = data;

    const { startDateYear, startDateMonth } = startDate;
    const { endDateYear, endDateMonth } = endDate;

    let language = formData.language.split(",");

    let finalData = {
      ...formData,
      language,
      workExpYear,
      workExpMonth,
      relExpYear,
      relExpMonth,
      percentage,
      institute,
      passingYear,
      qualification,
      company,
      jobTitle,
      currentlyWorking,
      startDateYear,
      startDateMonth,
      endDateYear,
      endDateMonth,
    };
 const hasErrors = Object.values(Nerror).some((error) => error !== undefined);
 console.log(hasErrors, "haserror")
     if (hasErrors) {
    toast.error("Please fix the validation errors before submitting the form");
  } else if (validateJForm() || validateQForm() || validateNForm() || validateEForm()) {
    dispatch(postJobs(finalData, id));
    setShow(true);
    
  } else {
    toast.error("Please fill in all required fields");
  }

  
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const numberOfMonths = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
  ];

  return (
    <div style={{ maxWidth: "1768px", margin: "0 auto" }}>
      <div className="container-fluid">
        <div className="header">
          <IoMdPerson className="apply-icon" />

          <h3>Fill the form</h3>
        </div>
        <hr />
        <div className="underline_wrap">
          <div className="underline_wrap_left">
            <div className="row">
              <div className="col-md-12 ">
                <div className="row">
                  <div className="col-md-6">
                    <Form.Label className="label mt-4 ">First Name <span className="text-danger">*</span></Form.Label>
                    <Form.Control
                      type="text"
                      id=""
                      aria-describedby="passwordHelpBlock"
                      placeholder="Enter your first name"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                    />
                    {Nerror.firstName && <small>{Nerror.firstName}</small>}
                  </div>
                  <div className="col-md-6">
                    <Form.Label className="label mt-4 ">Last Name <span className="text-danger">*</span></Form.Label>
                    <Form.Control
                      type="text"
                      id=""
                      aria-describedby="passwordHelpBlock"
                      placeholder="Enter your last name"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                    />
                    {Nerror.lastName && <small>{Nerror.lastName}</small>}
                  </div>
                  <div className="col-md-6">
                    <Form.Label className="label mt-4">Email id <span className="text-danger">*</span></Form.Label>
                    <Form.Control
                      type="email"
                      id=""
                      aria-describedby="passwordHelpBlock"
                      placeholder="Enter your email id"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />

                    {Nerror.email && <small>{Nerror.email}</small>}
                  </div>
                  <div className="col-md-6">
                    <Form.Label className="label mt-4">Phone Number <span className="text-danger">*</span></Form.Label>
                    <Form.Control
                      type="text"
                      id=""
                      aria-describedby="passwordHelpBlock"
                      placeholder="Enter your phone number"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                    />
                    {Nerror.phoneNumber && <small>{Nerror.phoneNumber}</small>}
                  </div>
                  <div className="col-md-6">
                    <Form.Label  htmlFor="inputPassword5" className="label mt-4">
                      Alternate phone number <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id=""
                      aria-describedby="passwordHelpBlock"
                      placeholder="Enter your alternate phone number"
                      name="alternatePhone"
                      value={formData.alternatePhone}
                      onChange={handleChange}
                    />
                    {Nerror.alternatePhone && (
                      <small>{Nerror.alternatePhone}</small>
                    )}
                  </div>
                  <div className="col-md-12 my-3">
                    <Form.Group>
                      <Form.Label className="label">Gender <span className="text-danger">*</span></Form.Label>
                      <div className="gender">
                        <Form.Check
                          type="radio"
                          label="Male"
                          name="gender"
                          value="male"
                          checked={formData.gender === "male"}
                          onChange={handleChange}
                        />
                        <Form.Check
                          type="radio"
                          label="Female"
                          name="gender"
                          value="female"
                          checked={formData.gender === "female"}
                          onChange={handleChange}
                        />
                        <Form.Check
                          type="radio"
                          label="Prefer not to say"
                          name="gender"
                          value="notSay"
                          checked={formData.gender === "notSay"}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                    {Nerror.gender && <small>{Nerror.gender}</small>}
                  </div>
                  <div className="col-md-6">
                    <Form.Label htmlFor="inputPassword5" className="label mt-4">
                      Current location <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id=""
                      aria-describedby="passwordHelpBlock"
                      placeholder="Enter your location"
                      name="location"
                      value={formData.location}
                      onChange={handleChange}
                    />
                    {Nerror.location && <small>{Nerror.location}</small>}
                  </div>
                  <div className="col-md-6">
                    <div className="lan-header">
                      <Form.Label
                        htmlFor="inputPassword5"
                        className="label mt-4 "
                      >
                        Language <span className="text-danger">*</span>
                      </Form.Label>
                      <span className="language">
                        use( , ) to add more language
                      </span>
                    </div>
                    <Form.Control
                      type="text"
                      id=""
                      name="language"
                      aria-describedby="passwordHelpBlock"
                      placeholder="Enter languages"
                      value={formData.language}
                      onChange={handleChange}
                    />
                    {Nerror.language && <small>{Nerror.language}</small>}
                  </div>
                  <div className="col-md-6 ">
                    <Form.Group className="exp">
                      <Form.Label className="label mt-4">
                        Total working experience <span className="text-danger">*</span>
                      </Form.Label>
                      <div className="inner-exp">
                        <Form.Control
                          type="text"
                          id="workYears"
                          name="workExpYear"
                          value={workExperience.years}
                          onChange={(e) => handleexp(e, "workExperience")}
                          placeholder="In years"
                          className="year"
                        />

                        <select
                          id="workMonths"
                          name="workExpMonth"
                          value={workExperience.months}
                          onChange={(e) => handleexp(e, "workExperience")}
                          className="month"
                        >
                          <option value="">In Month</option>
                          {numberOfMonths.map((months, idx) => (
                            <option key={idx} value={months}>
                              {months}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="end">
                        {Eerror.workExpYear && (
                          <small>{Eerror.workExpYear}</small>
                        )}
                        {Eerror.workExpMonth && (
                          <small>{Eerror.workExpMonth}</small>
                        )}
                      </div>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group className="exp">
                      <Form.Label className="label mt-4">
                        Relevent working experience <span className="text-danger">*</span>
                      </Form.Label>
                      <div className="inner-exp">
                        <Form.Control
                          type="text"
                          id="relevantYears"
                          name="relExpYear"
                          value={relevantExperience.years}
                          onChange={(e) => handleexp(e, "relevantExperience")}
                          placeholder="In years"
                          className="year"
                        />
                        <select
                          id="relevantMonths"
                          name="relExpMonth"
                          value={relevantExperience.months}
                          onChange={(e) => handleexp(e, "relevantExperience")}
                          className="month"
                        >
                          <option value="">In Month</option>
                          {numberOfMonths.map((months, idx) => (
                            <option key={idx} value={months}>
                              {months}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="end">
                        {Eerror.relExpYear && (
                          <small>{Eerror.relExpYear}</small>
                        )}
                        {Eerror.relExpMonth && (
                          <small>{Eerror.relExpMonth}</small>
                        )}
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="qualification_wrapper">
                    <div className="row">
                      <div className="col-md-12">
                        <Form.Label
                          htmlFor="inputPassword5"
                          className="label mt-4"
                        >
                          Qualification <span className="text-danger">*</span>
                        </Form.Label>
                      </div>
                    </div>
                    <div className="qualification">
                      {qualificationData.map((x, index) => (
                        <div key={index} className="row mb-3">
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId={`qualification${index}`}
                          >
                            <Form.Control
                              type="text"
                              name="qualification"
                              value={x.qualification}
                              onChange={(e) => handleList(e, index)}
                              placeholder="Type of qualification"
                              className="mb-2"
                            />
                            {Qerror[index] && (
                              <small>{Qerror[index].qualification}</small>
                            )}
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            md="6"
                            controlId={`passingYear${index}`}
                          >
                            <Form.Control
                              type="text"
                              name="passingYear"
                              value={x.passingYear}
                              onChange={(e) => handleList(e, index)}
                              placeholder="Passing year"
                              className="mb-2"
                            />
                            {Qerror[index] && (
                              <small>{Qerror[index].passingYear}</small>
                            )}
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            md="6"
                            controlId={`institute${index}`}
                          >
                            <Form.Control
                              type="text"
                              name="institute"
                              value={x.institute}
                              onChange={(e) => handleList(e, index)}
                              placeholder="Institute name"
                              className="mb-2"
                            />
                            {Qerror[index] && (
                              <small>{Qerror[index].institute}</small>
                            )}
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            md="6"
                            controlId={`percentage${index}`}
                          >
                            <Form.Control
                              type="text"
                              name="percentage"
                              value={x.percentage}
                              onChange={(e) => handleList(e, index)}
                              placeholder="Percentage"
                              className="mb-2"
                            />
                            {Qerror[index] && (
                              <small>{Qerror[index].percentage}</small>
                            )}
                          </Form.Group>
                          <div className="row d-flex justify-content-between">
                      <Button
                      variant="info float-end"
                      onClick={() => index === 0 ?resetFields(index):removeField(index)}
                      className="add-btn"
                      
                    >
                      Remove
                    </Button>
                    {index===qualificationData.length - 1 ? <Button
                      variant="info float-end"
                      onClick={addList}
                      className="add-btn"
                      
                    >
                      Add more
                    </Button>: null}
                    </div>
                        </div>
                        
                      ))}
                    </div>
                    
                  </div>
                  <hr />
                  <div className="underline_wrap d-block ">
                    <div className="col-md-12">
                      <Form.Label
                        htmlFor="inputPassword5"
                        className="label mt-4"
                      >
                        Job history <span className="text-danger">*</span>
                      </Form.Label>
                    </div>

                    {data.map((item, index) => (
                      <div key={index} className="row mb-3 ">
                        <div className="col-md-6">
                          <Form.Control
                            type="text"
                            aria-describedby="passwordHelpBlock"
                            placeholder="Company name"
                            value={item.company}
                            onChange={(e) =>
                              handleTextChange(index, "company", e.target.value)
                            }
                            className="mb-2"
                          />
                          {Jerror[index] && (
                            <small>{Jerror[index].company}</small>
                          )}
                        </div>
                        <div className="col-md-6">
                          <Form.Control
                            type="text"
                            aria-describedby="passwordHelpBlock"
                            placeholder="Job Title"
                            value={item.jobTitle}
                            onChange={(e) =>
                              handleTextChange(
                                index,
                                "jobTitle",
                                e.target.value
                              )
                            }
                          />
                          {Jerror[index] && (
                            <small>{Jerror[index].jobTitle}</small>
                          )}{" "}
                        </div>
                        <div className="col-md-6">
                          <Form.Group className="exp">
                            <Form.Label className="label mt-4">
                              Joining date <span className="text-danger">*</span>
                            </Form.Label>
                            <div className="inner-exp">
                              <Form.Control
                                className="year"
                                type="text"
                                aria-describedby="passwordHelpBlock"
                                placeholder="Year"
                                value={
                                  item.startDate.startDateYear
                                    ? item.startDate.startDateYear
                                    : ""
                                }
                                onChange={(e) =>
                                  handleDateChange(
                                    index,
                                    "startDateYear",
                                    e.target.value,
                                    "startDate"
                                  )
                                }
                              />

                              <select
                                value={
                                  item.startDate.startDateMonth
                                    ? item.startDate.startDateMonth
                                    : ""
                                }
                                onChange={(e) =>
                                  handleDateChange(
                                    index,
                                    "startDateMonth",
                                    e.target.value,
                                    "startDate"
                                  )
                                }
                                className="month"
                              >
                                <option value="">In Month</option>
                                {months.map((month, idx) => (
                                  <option key={idx} value={month}>
                                    {month}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="end">
                              {Jerror[index] && (
                                <small>{Jerror[index].startDateYear}</small>
                              )}
                              {Jerror[index] && (
                                <small>{Jerror[index].startDateMonth}</small>
                              )}
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Form.Group className="exp">
                            <div className="check-container mt-4">
                              <Form.Label className="label">
                                End date <span className="text-danger">*</span>
                              </Form.Label>
                              <label>
                                <input
                                  type="checkbox"
                                  checked={item.currentlyWorking}
                                  onChange={() =>
                                    handleCurrentlyWorkingChange(index)
                                  }
                                />
                                 <span> Currently Working</span>
                              </label>
                            </div>
                            <div className="inner-exp">
                              <Form.Control
                                type="text"
                                className="year"
                                aria-describedby="passwordHelpBlock"
                                placeholder="Year"
                                value={
                                  item.endDate.endDateYear
                                    ? item.endDate.endDateYear
                                    : ""
                                }
                                onChange={(e) =>
                                  handleDateChange(
                                    index,
                                    "endDateYear",
                                    e.target.value,
                                    "endDate"
                                  )
                                }
                              />
                              <select
                                value={
                                  item.endDate.endDateMonth
                                    ? item.endDate.endDateMonth
                                    : ""
                                }
                                onChange={(e) =>
                                  handleDateChange(
                                    index,
                                    "endDateMonth",
                                    e.target.value,
                                    "endDate"
                                  )
                                }
                                className="month"
                              >
                                <option value="">In Month</option>
                                {months.map((month, idx) => (
                                  <option key={idx} value={month}>
                                    {month}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="end">
                              {Jerror[index] && (
                                <small>{Jerror[index].endDateYear}</small>
                              )}
                              {Jerror[index] && (
                                <small>{Jerror[index].endDateMonth}</small>
                              )}
                            </div>
                          </Form.Group>
                        </div>
                        <div className="row d-flex justify-content-between">
                          {index>0 ? <Button
                      variant="info float-end mt-2"
                      onClick={() => handleRemoveDataPair(item.id)}
                      className="add-btn float-end"
                      
                    >
                     Remove
                    </Button>:
                    <Button
                      variant="info float-end mt-2"
                      onClick={() => handleResetDataPair(item.id)}
                      className="add-btn float-end"
                      
                    >
                     Remove
                    </Button>}
                    {index===data.length-1 ? <Button
                      variant="info float-end mt-2"
                      onClick={handleAddDataPair}
                      className="add-btn float-end"
                      
                    >
                      Add more
                    </Button> : null}
                        </div>
                      </div>

                    ))}

                    
                  </div>

                  <hr />
                </div>
              </div>
            </div>
          </div>
          <div className="underline_wrap_right d-flex justify-content-center ">
            <UploadCV getFileData={getFileData} />
          </div>
        </div>
        
        {show && <ApplyPopUp show={show} setShow={setShow} />}
      </div>
      <div className="d-flex justify-content-center mb-3">
        <Button
          type="submit"
          variant="info"
          className=" btn-primary mt-3"
          onClick={handleSubmit}
          // style={{ width: "auto" }}
          disabled={!fileData}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default ApplyJobs;
