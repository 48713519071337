import React, { useEffect } from "react";
import JobCard from "./JobCard";
import Statistics from "./Statistics";
import "../Home/Dashbord.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { jobList } from "../../Redux/JobReducer/action";
import { getTest } from "../../../src/Redux/TestReducer/action";
import NetworkErrorPage from "../error/error";
import { resultSearch } from "../../Redux/ResultReducer/action";
  const Dashboard = ({ setPath, newSidedata, onSidedataChange }) => {
  const dispatch=useDispatch()
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem("token");
  const {errorData}=useSelector(state=>state.errorReducer)
  const menuClick = () => {
    onSidedataChange(!newSidedata);
  };

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token]);

  return (
    <div className="container-fluid">
       <button className={`fa_bars  d-lg-none`} onClick={menuClick}>
        <i className="fa-solid fa-bars fs-4"></i>
      </button>
      <h4>Dashboard</h4>
      <div className="row mt-3">
        <div className="col-xl-7">
          <h3 className="current_hiring">Current Hiring Jobs</h3>
          <JobCard
            title="Xamarin Developer"
            applicants={293}
            image={"/images/ds-img-1.png"}
          />
          <JobCard
            title="Xamarin Developer"
            applicants={293}
            image={"/images/ds-img-2.png"}
          />
          <JobCard
            title="Xamarin Developer"
            applicants={293}
            image={"/images/ds-img-2.png"}
          />
          <JobCard
            title="Xamarin Developer"
            applicants={293}
            image={"/images/ds-img-2.png"}
          />
          <JobCard
            title="Senior WordPress Developer"
            applicants={293}
            image={"/images/ds-img-2.png"}
          />
        </div>
        <div className="col-xl-5">
          <h3 className="current_hiring">Statistics</h3>
          <Statistics />
        </div>
      </div>

    </div>
  );
};

export default Dashboard;
