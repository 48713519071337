import axios from "axios";
import { ADD_SOURCE_FAILURE, ADD_SOURCE_REQUEST, ADD_SOURCE_SUCCESS } from "./actionType";
import { errorCall } from "../../ErrorHandling/action";
import { toast } from 'react-toastify';

export const add_source_req = () => ({
    type: ADD_SOURCE_REQUEST
});

export const add_source_succ = (data) => ({
    type: ADD_SOURCE_SUCCESS,
    payload: data
})
export const add_source_failure = (err) => ({
    type: ADD_SOURCE_FAILURE,
    payload: err
})

export const addSourcef = (name, status, notify, navigate) => (dispatch) => {
    dispatch(add_source_req());
    if (name.trim().length === 0) {
        toast.error("Please provide source name");
        return;
    }
    const config = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
    };

    const form = {
        form: {
            name: name,
            status: status
        }
    }
        return axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/addSource`, form, config).then((res) => {
        dispatch(add_source_succ(res.data));
        // notify(res.data.message)
        toast.success("Source added successfully")
        navigate('/settings/source');
    }).catch((err) => {
        console.log(err.toJSON());
        if (err?.response?.data?.error == "Unauthenticated.") {
            localStorage.removeItem("token");
            navigate("/login");
        }
        
        dispatch(add_source_failure(err))
        if (err.toJSON().status === 422) {
            toast.error(err.response.data.message);
            return; 
        }
        if (err.message === "Network Error" || err.response.status === 500) {
            dispatch(errorCall(err))
        }
        toast.error(err.response.data.message);
    })
}

