import React, { useEffect, useState } from "react";
import "../../Pages/candidates/CreateCandidate.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-input-2";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { sourceList } from "../../Redux/settings/source_management/action";
import { editCandidte } from "../../Redux/Candidate/editcandidate/action";
import { getCandidate } from "../../Redux/Candidate/findCandidate/action";
const socialLink=["Others","Facebook.com","Twitter.com","Instagram.com","LinkedIn.com","Snapchat.com","Pinterest.com","Reddit.com","TikTok.com","YouTube.com","WhatsApp.com","Telegram.com","Tumblr.com","Discord.com","WeChat.com","Signal.com","VK.com","Flickr.com","Medium.com","Quora.com","Twitch.com"]
const GeneralData = ({
  contact,
  setContactData,
  onHandleChangeFun,
  profileData,
  cvData
}) => {
  const props = "edit";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [gender, setGender] = useState("");
  const [time, setTime] = useState([]);
  const [loader, setLoader] = useState(false);
  const [preference, setPreference] = useState([]);
  const [mainContact, setMainContact] = useState({});
  const [socialDetails, setSocialDetails] = useState([]);
  const [primaryEmailError, setEmailPrimaryError] = useState("");
  const [secondaryEmailError, setEmailSecondaryError] = useState("");
  const [primaryPhoneError, setPrimaryPhoneError] = useState("");
  const [secondaryPhoneError, setSecondaryPhoneError] = useState("");
  const[TimeArray,setTimeArray]=useState(["Anytime","12pm - 6pm","6pm-8pm","After 8pm"])
  const leadSource = useSelector((state) => state.getSourceSet);
  const editCandidateHandle = useSelector((state) => state.editCandidateData);
  const jobPreference = useSelector((state) => state?.Job_Reducer?.typeData);
  const id = useParams();

  const handleIncrementAndClone = () => {
    const social = {
      id: Date.now(),
      link: "",
      name: "",
    };
    socialDetails[socialDetails.length - 1].link === "" || socialDetails[
      socialDetails.length - 1].name==="" ? toast.error("Please fill Empty Social Media Details") :
    setSocialDetails((prev) => [...prev, social]);
  };

  const remove = (e, index, details) => {
    setSocialDetails((prevSocialDetails) => {
      const newSocialDetails = prevSocialDetails.filter((_, i) => i !== index);
      return newSocialDetails;
    });
  };  

  
  const reset = (e, index, details) => {
    setSocialDetails((prevSocialDetails) => {
      const updatedSocialDetails = [...prevSocialDetails];
      updatedSocialDetails[index].link = "";
      updatedSocialDetails[index].name = "";
      return updatedSocialDetails;
    })
  };


  const handleAllowTime = (e) => {
   
    const { checked, value } = e.target;
    var allowedTime = mainContact.contact_time || [];
    if (checked) {
      if(value=="Anytime"){
        allowedTime=["Anytime"]
        mainContact.contact_time=allowedTime

      }else{
        let newAllowTime=[]
        newAllowTime=allowedTime.filter((item)=>{return item!="Anytime"})
        newAllowTime.push(value);
        mainContact.contact_time=newAllowTime
      }
    } else {
      if (allowedTime?.length > 0) {
        mainContact.contact_time= allowedTime.filter((element) => element != value?value:"");
        
      }
    }
    //Time array maunupulation as require in validation logic
    const initial = ["Anytime", "12pm - 6pm", "6pm-8pm", "After 8pm"];
    const others = ["12pm - 6pm", "6pm-8pm", "After 8pm"];
    const timeMap = {
      "Anytime": checked ? ["Anytime"] : initial,
      "12pm - 6pm": !checked && mainContact?.contact_time?.length == 0 ? initial : others,
      "6pm-8pm": !checked && mainContact?.contact_time?.length == 0 ? initial : others,
      "After 8pm": !checked && mainContact?.contact_time?.length == 0 ? initial : others,
    };

    setTimeArray(timeMap[value] || [])
  };

  const update = async () => {
    const firstName = profileData?.first_name;
    const lastName = profileData?.last_name;
    const socialLink = socialDetails?.map((e) => e.link);
    const socialMediaName = socialDetails?.map((e) => e.name);
    if (firstName?.trim().length === 0) {
      setLoader(false);
      toast.error("Please Provide First Name");
      return;
    }

    // Additional validation for last_name
    if (!lastName || lastName.trim().length === 0) {
      setLoader(false);
      toast.error("Please Provide Last Name");
      return;
    }
    // Separate validation for lead_source
    if (!profileData?.lead_source) {
      setLoader(false);
      toast.error("Please select a Lead Source.");
      return;
    }
    // Separate validation for experience_levels
    if (!profileData?.experience_levels) {
      setLoader(false);
      toast.error("Please select an Experience Level.");
      return;
    }

    // Separate validation for designation
    if (profileData?.designation == "") {
      setLoader(false);
      toast.error("Please select a Designation.");
      return;
    }
    


    // Separate validation for Social media links
    if (socialLink.length !== 0 || socialMediaName !== 0) {
      for (let index = 0; index < socialDetails.length; index++) {
        if (socialDetails[index].link !== "") {
          // const linkRegex = /\b(?:https?|ftp):\/\/\S+/;
          const linkRegex = /\S+\./;
          const hasLink = socialDetails[index].link.match(linkRegex);
          if (!hasLink) {
            toast.error(`Please Provide correct  Social Media Links in ${index+1}`);
            setLoader(false);
            return false;
          }
        }
      }
    }



    if (
      mainContact?.email_primary?.trim().length === 0
    ) {
      setLoader(false);
      toast.error("Please Enter Primary Email");
      return;
    }
    if (mainContact?.email_primary === mainContact?.email_secondary) {
      setLoader(false);
      toast.error("Primary and secondary email cannot be same");
      return;
    }
    if (mainContact?.phone_primary) {
      if (mainContact?.phone_primary?.trim().length < 10) {
        setLoader(false);
        toast.error("Please Enter valid Primary Phone");
        return;
      }
    } else {
      setLoader(false);
      toast.error("Please Enter valid Primary Phone");
      return;
    }
    if (mainContact?.phone_secondary && mainContact?.phone_secondary?.trim().length < 10) {
        setLoader(false);
        toast.error("Please Enter valid Secondary Phone Number");
        return;
    }
    if (mainContact?.contact_time?.length === 0 || !mainContact?.contact_time) {
      setLoader(false);
      toast.error("Please Select At Least One Contact Time");
      return;
    }
    if (preference?.length === 0) {
      setLoader(false);
      toast.error("Please Select Job Preference");
      return;
    }
    if (mainContact?.city?.trim().length === 0) {
      setLoader(false);
      toast.error("Please Enter Location");
      return;
    }
    if (primaryEmailError) {
      setLoader(false);
      toast.error("Please Provide Valid Primary Email");
      return;
    }
    if (secondaryEmailError) {
      setLoader(false);
      toast.error("Please Provide Valid Secondary Email");
      return;
    }
    if (primaryPhoneError) {
      setLoader(false);
      toast.error("Please Provide Valid Primary Phone");
      return;
    }
    if (secondaryPhoneError) {
      setLoader(false);
      toast.error("Please Provide Valid Secondary Phone");
      return;
    }
    const data = {
      id: contact?.id,
      gender: gender,
      first_name: firstName,
      last_name: lastName,
      designation_id: profileData?.designation,
      lead_source: profileData?.lead_source,
      experience_level_id: profileData?.experience_levels,
      job_preference: preference,
      socials: socialDetails,
      contact: mainContact,
      resume:profileData.resume
    };
    dispatch(editCandidte(data, navigate, toast));

  };

  useEffect(() => {
    dispatch(getCandidate(id.id, navigate))
  }, [])

  const updatContact = (e) => {

    e.target.name=="email_primary"&&setEmailPrimaryError()
    e.target.name=="email_secondary"&&setEmailSecondaryError()
    const emailRegex = /^[a-zA-Z0-9]+([._][a-zA-Z0-9]+)*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/;
    
    if ((e.target.name=="email_primary"||  e.target.name=="email_secondary")&&!emailRegex?.test(e.target.value)) {
      e.target.name=="email_primary"&&setEmailPrimaryError("Please Provide Valid Email")
       e.target.name=="email_secondary"&&setEmailSecondaryError("Please Provide Valid Email")
      ;
    } 
    if((e.target.name=="email_primary" || e.target.name=="email_secondary")&&e.target.value.includes("..")){
      e.target.name=="email_primary"&&setEmailPrimaryError("Please Provide Valid Email")
       e.target.name=="email_secondary"&&setEmailSecondaryError("Please Provide Valid Email")
    }
    if((e.target.name=="email_primary" || e.target.name=="email_secondary")&&e.target.value.indexOf(".")===e.target.value.length-1){
      console.log("dot detected",e.target.value.length-1);
      e.target.name=="email_primary"&&setEmailPrimaryError("Please Provide Valid Email")
       e.target.name=="email_secondary"&&setEmailSecondaryError("Please Provide Valid Email")
    }
  
    const [localPart, domain] = e.target.value.split('@');
    if (domain) {
      if (domain.includes('.')) {
        const parts = domain.split('.');
        if (parts.length > 4) {
          e.target.name=="email_primary"&&setEmailPrimaryError("Please Provide A Valid Domain id");
           e.target.name=="email_secondary"&&setEmailSecondaryError("Please Provide A Valid Domain id");
          ;
        }
        for (let i = 1; i < parts.length - 1; i++) {
          for (let j = i + 1; j < parts.length; j++) {
            if (parts[i] === parts[j]) {
              e.target.name=="email_primary"&&setEmailPrimaryError("Please Provide A Valid Domain id");
               e.target.name=="email_secondary"&&setEmailSecondaryError("Please Provide A Valid Domain id");
              ;
            }
          }
        }
      }
    }


    // const emailRegex = /^(?!.*(?:\.com.*\.com|\.in.*\.in|\.co.*\.co))[a-zA-Z0-9+?._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    // e.target.name=="email_primary"&&setEmailPrimaryError()
    // e.target.name=="email_secondary"&&setEmailSecondaryError()
    // if (e.target.name=="email_primary"&& !emailRegex?.test(e.target.value)) {
    //   setEmailPrimaryError("Please Provide Valid Email")
    // }
    // if (e.target.name=="email_secondary" && !emailRegex?.test(e.target.value) && e.target.value.length !== 0) {
    //   setEmailSecondaryError("Please Provide Valid Email")
    // }else{
    //   setEmailSecondaryError()
    // }
    
    setMainContact((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleSocialLinkChange = (value, index) => {
    setSocialDetails((prevSocialDetails) => {
      const updatedSocialDetails = [...prevSocialDetails];
      updatedSocialDetails[index].link = value.replace(" ","");
      return updatedSocialDetails;
    });
  };

  const handleSocialNameChange = (value, index) => {
    setSocialDetails((prevSocialDetails) => {
      const updatedSocialDetails = [...prevSocialDetails];
      updatedSocialDetails[index].name = value;
      return updatedSocialDetails;
    });
  };

  const updatePhone = (e,country) => {
    setPrimaryPhoneError()
    if (e.replace(country?.dialCode,"").length<10  && country?.dialCode?.length==2 && country?.dialCode!=65 ||  !e.replace(country.dialCode,"")) {
      setPrimaryPhoneError("Please Provide Valid Phone Number")
    }else if( e.replace(country?.dialCode,"").length<8  && country?.dialCode?.length==3 && country?.dialCode!=65){
      setPrimaryPhoneError("Please Provide Valid Phone Number")
    } else if (e.match(/1234/)) {
      setPrimaryPhoneError()
    } else {
      setPrimaryPhoneError()
    }
    
    setMainContact((prev) => {
      return {
        ...prev,
        ["phone_primary"]: e,
      };
    });
  };

  const secondphone = (e,country) => {
    setSecondaryPhoneError()
    if (e.replace(country?.dialCode,"").length<10  && country?.dialCode?.length==2 && country?.dialCode!=65 ||  !e.replace(country.dialCode,"")) {
      setSecondaryPhoneError("Please Provide Valid Phone Number")
    }else if( e.replace(country?.dialCode,"").length < 8  && country?.dialCode?.length==3 && country?.dialCode!=65){
      setSecondaryPhoneError("Please Provide Valid Phone Number")
    } else if (e.match(/1234/)) {
      setSecondaryPhoneError()
    } else {
      setSecondaryPhoneError()
    }
    if (e.length === 0) {
      setSecondaryPhoneError()
    }
    setMainContact((prev) => {
      return {
        ...prev,
        ["phone_secondary"]: e,
      };
    });
  };

  const isTimeIncludes = (time1) => {
    return time?.includes(time1);
  };

  useEffect(() => {
    setMainContact({ ...contact?.contact });
    setSocialDetails(contact?.socials);
    setTime(contact?.contact?.contact_time);
    setGender(contact?.gender);
  }, [contact]);

  useEffect(()=>{
    setPreference(contact?.work_details?.job_preference);
  },[contact?.work_details?.job_preference])
  useEffect(() => { }, [socialDetails]);

  useEffect(() => {
    dispatch(sourceList(navigate));
  }, []);
  useEffect(()=>{
  if(cvData){
    setSocialDetails([cvData?.socials])
    setMainContact((prev) => {
      return {
        ...prev,
        ["phone_primary"]: cvData?.contact?.phone_primary,
        ["phone_secondary"]: cvData?.contact?.phone_secondary,
        ["city"]: cvData?.contact?.city,
        ["email_primary"]: cvData?.contact?.email_primary,
      };
    });
  }
  },[cvData])
  return (
    <>
        <div className="tab-data mt-4" step={0}>
          <div className="row">
            <div className="col-sm-6">
              <div className="grid-box">
                <div className="form-group-gap">
                  <div className="form-label-box mb-2">
                    <label className="txt__para txt__sm fw-bold">Gender</label>
                    <span className="text-danger ms-1">*</span>
                  </div>
                  <div className="form-control-box">
                    <div className="form-check form-check-inline">
                      <input
                        defaultValue="Male"
                        type="radio"
                        id="genderType1"
                        name="gender"
                        className="form-check-input"
                        onChange={(e) => setGender(e.target.value)}
                        checked={gender == "Male" ? true : false}
                        required
                      />
                      <label htmlFor="genderType1" className="form-check-label">
                        Male
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        defaultValue="Female"
                        type="radio"
                        id="genderType2"
                        name="gender"
                        className="form-check-input"
                        onChange={(e) => setGender(e.target.value)}
                        checked={gender == "Female" ? true : false}
                        required
                      />
                      <label htmlFor="genderType2" className="form-check-label">
                        Female
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        defaultValue="Unknown"
                        type="radio"
                        id="genderType3"
                        name="gender"
                        className="form-check-input"
                        onChange={(e) => setGender(e.target.value)}
                        checked={gender == "Unknown" ? true : false}
                      />
                      <label htmlFor="genderType3" className="form-check-label">
                        Prefer not to say
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6"></div>

            <div className="col-sm-6">
              <div className="grid-box">
                <div className="form-group-gap">
                  <div className="form-label-box mb-2">
                    <label className="txt__para txt__sm fw-bold">
                      Primary Email
                    </label>
                    <span className="text-danger ms-1">*</span>
                  </div>
                  <div className="form-control-box">
                    <input
                      name={"email_primary"}
                      type="email"
                      placeholder="Enter email here"
                      className="form-control-no-border"
                      value={!mainContact?.email_primary?"":mainContact?.email_primary}
                      onChange={(e) => updatContact(e)}
                    />
                  </div>
                  <p className="text-danger">{primaryEmailError}</p>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="grid-box">
                <div className="form-group-gap">
                  <div className="form-label-box mb-2">
                    <label className="txt__para txt__sm fw-bold">
                      Secondary Email
                    </label>
                  </div>
                  <div className="form-control-box">
                    <input
                      name="email_secondary"
                      type="email"
                      placeholder="Enter email here"
                      className="form-control-no-border"
                      value={mainContact?.email_secondary?mainContact?.email_secondary:""}
                      onChange={(e) => {
                        updatContact(e);
                      }}
                    />
                  </div>
                  <p className="text-danger">{secondaryEmailError}</p>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="grid-box">
                <div className="form-group-gap">
                  <div className="form-label-box mb-2">
                    <label className="txt__para txt__sm fw-bold">
                      What is the best phone number to reach you at?
                    </label>
                    <span className="text-danger ms-1">*</span>
                  </div>
                  <div className="form-control-box">
                    <PhoneInput
                      enableSearch={true}
                      name={"phone_primary"}
                      required={true}
                      country={"in"}
                      value={mainContact?.phone_primary}
                      placeholder="+91 xxxxx-xxxxx"
                      onChange={(e, country) => updatePhone(e, country)}
                    />
                  </div>
                  <p className="text-danger">{primaryPhoneError}</p>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="grid-box">
                <div className="form-group-gap">
                  <div className="form-label-box mb-2">
                    <label className="txt__para txt__sm fw-bold">
                      Add a secondary contact number
                    </label>
                  </div>
                  <div className="form-control-box">
                    <PhoneInput
                      enableSearch={true}
                      name={"phone_secondary"}
                      required={true}
                      country={"in"}
                      value={!mainContact?.phone_secondary ? "" :mainContact?.phone_secondary }
                      placeholder="+91 xxxxx-xxxxx"
                      onChange={(e, country) => secondphone(e, country)}
                    />
                  </div>
                  <p className="text-danger">{secondaryPhoneError}</p>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="grid-box">
                <div className="form-group-gap">
                  <div className="form-label-box mb-2">
                    <label className="txt__para txt__sm fw-bold">
                      What is the best time to connect with you?
                    </label>
                    <span className="text-danger ms-1">*</span>
                  </div>
                  <div className="form-control-box">
                    {TimeArray?.map((item, index) => (
                      <div className="form-check form-check-inline" key={index}>
                        {
                          <input
                            value={item}
                            type="checkbox"
                            id={`callTiming${index + 1}`}
                            name={item}
                            className="form-check-input"
                            checked={!mainContact?.contact_time?false:mainContact?.contact_time?.includes(item)}
                            onChange={(e) => handleAllowTime(e)}
                          />
                        }
                        <label
                          htmlFor={`callTiming${index + 1}`}
                          className="form-check-label"
                        >
                          {item}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="grid-box">
                <div className="form-group-gap">
                  <div className="form-label-box mb-2">
                    <label className="txt__para txt__sm fw-bold">
                      Job Preference
                    </label>
                    <span className="text-danger ms-1">*</span>
                  </div>
                  <div className="form-control-box d-flex flex-wrap">
                  {jobPreference?.map((e, index) => (
                  <div key={index} className="col-auto job_option">
                    <div className="form-check">
                     <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id={index}
                        checked={preference?.length >0?(preference[0]==e?.name || preference[0]==e?.id ?true:false):false}
                        value={e.id}
                        onChange={() => {
                          setPreference([e.id]);
                        }}
                      />
                      <label className="form-check-label" htmlFor={index}>
                        {e.name}
                      </label>
                    </div>
                  </div>
                   ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="grid-box">
                <div className="form-group-gap">
                  <div className="form-label-box mb-2">
                    <label className="txt__para txt__sm fw-bold">
                      Current Location
                    </label>
                    <span className="text-danger ms-1">*</span>
                  </div>
                  <div className="form-control-box">
                    <input
                      name="city"
                      type="text"
                      placeholder="Enter current location"
                      className="form-control-no-border"
                      onChange={(e) => {
                        updatContact(e);
                      }}
                      value={!mainContact?.city?"":mainContact?.city}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="grid-box">
                <div className="form-group-gap">
                  <div className="form-label-box mb-2">
                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                      <label className="txt__para txt__sm fw-bold">
                        Social media links
                      </label>
                      <button
                        type="button"
                        className="button-outline"
                        onClick={handleIncrementAndClone}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                  {socialDetails?.map((details, index) => (
                    <div key={index} className="mb-3">
                      <p className="d-none">{index}</p>
                      <div className="d-flex mb-2">
                        <div
                          className="form-control-box flex-shrink-0"
                          style={{ width: 150 }}
                        >
                          <select
                            placeholder="Site name"
                            className="form-control-no-border form-control-no-border-select"
                            value={details?.name}
                            onChange={(e) =>
                              handleSocialNameChange(e.target.value, index)
                            }
                          >
                            <option
                              name="name"
                              value=""
                            >
                              Site Name
                            </option>
                            {socialLink.map(     
                              (data, index) => {
                                return (
                                  <option key={index} value={data}>
                                    {data}
                                  </option>
                                );
                              }
                            )}
                          </select>
                        </div>
                        {/* <div className="form-control-box flex-grow-1">
                          <input
                            name="link"
                            onChange={(e) =>
                              handleSocialLinkChange(e.target.value, index)
                            }
                            type="text"
                            placeholder="Enter link here"
                            className="form-control-no-border"
                            value={details?.link}
                          />
                        </div> */}


                          <div className="form-control-box flex-grow-1 position-relative d-flex align-items-center" style={{ padding:'16px 36px 16px 10px' }}>
                            <input
                            name="link"
                            onChange={(e) =>
                              handleSocialLinkChange(e.target.value, index)
                            }
                            type="text"
                            placeholder="Enter link here"
                            className="form-control-no-border"
                            value={details?.link}
                          />

                        <button
                            type="button"
                            className="button_remove new_position_romove_btn"
                            onClick={(e) =>index<1?reset(e, index, details): remove(e, index, details)}
                          >
                          <i className="fa-solid fa-xmark"></i>
                        </button>

                          </div>




                      </div>
                        {/* <button
                          type="button"
                          className="btn btn-success button"
                          onClick={(e) =>index<1?reset(e, index, details): remove(e, index, details)}
                        >
                          Remove
                        </button> */}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <button
            type="button"
            className="nav-link Next_qu mt-3 "
            onClick={(e) => update(e)}
            disabled={editCandidateHandle?.isLoading}
          >
            {editCandidateHandle?.isLoading ? <Spinner /> : "Submit"}
          </button>
        </div>
       
    </>
  );
};

export default GeneralData;