import axios from "axios";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import Spiner1 from "../Spiner1";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getCandidate } from "../../Redux/Candidate/findCandidate/action";
import PopUpModel from "../popUpModel/PopUpModel";
const SkillsData = ({ contact, profileData, skillsData }) => {
  let numberOption = []
  for (let i = 0; i < 12; i++) { numberOption.push(i) }
  const [skillList, setSkillList] = useState();
  // const [experienceStart, setExperienceStart] = useState();
  // const [experienceEnd, setExperienceEnd] = useState();
  const [langauge, setLanguage] = useState([]);
  // const [rateOfLangauge, setRateOfLangauge] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [updateSkillList, setUpdateSkillList] = useState(false)
  const [skills, setSkills] = useState([]);
  let duplicateLanguage=langauge 
  const getLocaldata = () => {
    const token = localStorage.getItem("token");
    return token;
  };

  const dispatch = useDispatch();

  const getSkillList = async () => {
    setIsLoading(true);
    const mt = getLocaldata();
    const config = {
      headers: { Authorization: `Bearer ${mt}` },
    };
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/auth/skillList`, config)
      .then((res) => {
        setSkillList(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Something went wrong");
      });
  };

  const handleSkills = (e, index) => {
    let updatedData = [...skills];
    updatedData[index].skill_id = e.value;
    updatedData[index].name = e.label;
    setSkills(updatedData);
  };

  const addSkill = (e) => {
    e.preventDefault();
    let updatedData = [...skills];
    skills[skills.length - 1].skill_id ==="" ? toast.error("Please Provide Skill Name.") : 
    updatedData.push({
      currently_working: false,
      exp_in_months: "",
      exp_month: "",
      exp_year: "",
      skill_id: "",
    });
    setSkills(updatedData);
  };

  const resetSkill = ((e, index) => {
    let updatedData = [...skills];
    updatedData[index].currently_working = false;
    updatedData[index].exp_in_months = "";
    updatedData[index].exp_month = "";
    updatedData[index].exp_year = "";
    updatedData[index].skill_id = "";

    setSkills(updatedData);
  })

  const removeSkill = (e, index) => {
    setSkills((prevSkills) => prevSkills.filter((_, i) => i !== index));
  };

  const handleSkill = (e, index) => {
    let updatedData = [...skills];
    updatedData[index][e.target.name] = parseInt(e.target.value);
    setSkills(updatedData);
  };

  const handleCurrentCheck = (e, index) => {
    let updatedData = [...skills];
    updatedData[index][e.target.name] = e.target.checked;
    setSkills(updatedData);
  }

  const handleLangauge = (e, index) => {
    let updatedData = [...langauge];
    updatedData[index][e.target.name] = e.target.value;
    setLanguage(updatedData);
  };

  const addLangauge = (e) => {
    e.preventDefault();
    let updatedData = [...langauge];
    langauge[langauge.length - 1].name ==="" ? toast.error("Please Provide Language Name.") :
    updatedData.push({
      name: "",
      rating: 0,
    });
    setLanguage(updatedData);
  };

  const removeLangauge = (e, index) => {
    setLanguage(langauge.filter((_, i) => i !== index));
  };

  const resetLang = ((e, index) => {
    let updatedData = [...langauge];
    updatedData[index].name = "";
    updatedData[index].rating = 0;
    setLanguage(updatedData);
  })



  const skillSubmit = async (e) => {
    if (!profileData?.first_name) {
      toast.error('Please Provide First Name.');
      setIsLoading(false)
    } else if (!profileData?.last_name) {
      toast.error('Please Provide Last Name.');
      setIsLoading(false)
    } else if (!profileData?.designation) {
      toast.error('Please Provide Designation.');
      setIsLoading(false)
    } else if (!profileData?.experience_levels) {
      toast.error('Please Provide Experience.');
      setIsLoading(false)
    } else {
      setIsLoading(true);
      const mt = getLocaldata();
      const config = {
        headers: { Authorization: `Bearer ${mt}` },
      };

      const data = {
        id: contact?.id,
        first_name: profileData?.first_name,
        last_name: profileData?.last_name,
        designation_id: profileData?.designation,
        lead_source_id: profileData?.lead_source,
        experience_level_id: profileData?.experience_levels,
        resume: profileData.resume,
        languages: langauge,
        skills,
      };
      const axiosCall = async (datas) => {
        await axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/api/auth/candidate_update_skill`,
            datas,
            config
          )
          .then((res) => {
            setIsLoading(false);
            toast.success("Skills updated successfully");
          })
          .catch((err) => {
            setIsLoading(false);
            toast.error("Something Went Wrong");
          });
      }

      const reader = new FileReader();
      reader.onload = function (event) {
        data.resume = event.target.result;
        axiosCall(data);
      };
      if (
        profileData.resume &&
        profileData?.resume?.type &&
        profileData?.resume?.type
      ) {
        reader.readAsDataURL(profileData.resume);
      } else {
        axiosCall(data);
      }

    }
  };

  const id = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCandidate(id.id, navigate))
  }, [])

  useEffect(() => {
    setSkills(!contact?.skills_list || contact?.skills_list.length == 0 ? [{
      currently_working: false,
      exp_in_months: "",
      exp_month: "",
      exp_year: "",
      skill_id: "",
    }] : contact?.skills_list);
    // setExperienceStart();
    // setExperienceEnd();
    setLanguage(contact?.languages);
    // setRateOfLangauge();
  }, [contact]);

  useEffect(() => {
    getSkillList();
  }, []);
  useEffect(() => {
    getSkillList();
  }, [updateSkillList]);

  useEffect(() => {
    skillsData && setSkills(skillsData)
  }, [skillsData])
  return (
    <>
      <div className="tab-data mt-4" step={0}>
        <p className="text-muted txt__sm mb-3">
          If you don't find your skill then please
          <PopUpModel placeholder={"Skill Name"} title={"Skills"} header={"Click Here"} setUpdateSkillList={setUpdateSkillList} className="ms-3 me-3" />
          to add new skill
        </p>
        <div className="d-flex justify-content-end mb-2">
          {skills.length<=1 ? <button
            type="button"
            className="button-outline"
            onClick={(e) => addSkill(e)}
          >
            Add Skill
          </button> : null}
        </div>
        {/* <div className="mb-3"> */}
          {skills?.map((skill, index) => (
            <div
              key={skill?.skill_id} 
              className="mb-3">
          <div className="grid-2-md">
              <div className="grid-box">
                <div className="form-group-gap mb-2">
                  <div className="form-label-box mb-2 d-flex align-items-center justify-content-between">
                    <label className="txt__para txt__sm fw-bold mb-0">
                      Skill {index + 1}
                    </label>
                    <div className="filter-tags" />
                  </div>
                  <div className="form-control-box form-control-box-multiselect">
                    {skills?.length > 0 ? (
                      // <Select
                      //   placeholder="Select skills"
                      //   defaultValue={skill?.skill_id?{
                      //     value: skill?.skill_id,
                      //     label: skill?.name,
                      //   }:{ label: "Select Skills"}}
                      //   name="colors"
                      //   options={skillList?.filter((item)=>item.status!=0).map((skill) => ({
                      //     value: skill?.skill_id,
                      //     label: skill?.name,
                      //   }))}
                      //   classNamePrefix="select"
                      //   onChange={(e) => handleSkills(e, index)}
                      // />

                      <Select
                        placeholder="Select skills"
                        defaultValue={skill?.skill_id ? {
                          value: skill?.skill_id,
                          label: skill?.name,
                        } : { label: "Select Skills" }}
                        name="colors"
                        options={skillList
                          ?.filter((item) => item.status !== 0)
                          .filter((item) => !skills.some((skill) => skill.skill_id === item.skill_id))
                          .map((skill) => ({
                            value: skill?.skill_id,
                            label: skill?.name,
                          }))}
                        classNamePrefix="select"
                        onChange={(e) => handleSkills(e, index)}
                      />

                    ) : ("")}
                  </div>
                </div>
              </div>
              <div className="grid-box">
                <div className="form-group-gap mb-2">
                  <div className="form-label-box d-flex align-items-center justify-content-between mb-2">
                    <label className="txt__para txt__sm fw-bold mb-0">
                      Experience
                    </label>
                  </div>
                  <div className="grid-2">
                    <div className="form-control-box">
                      <select
                        className="form-control-no-border form-control-no-border-select"
                        name="exp_year"
                        onChange={(e) => handleSkill(e, index)}
                        defaultValue={skill?.exp_year ? skill?.exp_year : ""}
                      >
                        <option key={index} value="">In Years </option>
                        {numberOption.map((item, index) => <option key={index} value={item}>{item} year{item < 2 ? "" : "s"}</option>)}
                      </select>
                    </div>
                    <div className="form-control-box">
                      <select
                        className="form-control-no-border form-control-no-border-select"
                        name="exp_month"
                        onChange={(e) => handleSkill(e, index)}
                        defaultValue={skill?.exp_month ? skill?.exp_month : ""}
                      >
                        <option key={index} value="">In Months </option>
                        {numberOption.map((item, index) => <option key={index} value={item}>{item} month{item < 2 ? "" : "s"}</option>)}
                      </select>
                    </div>
                  </div>
                </div>
                <div>
                </div>
              </div>
              <div >
                <input
                  type="checkbox"
                  checked={skill?.currently_working}
                  name="currently_working"
                  onChange={(e) => { handleCurrentCheck(e, index) }} />
                {" "}Currently working on this skill
              </div>
          </div>
              <div className="col-12 d-flex justify-content-between mt-3">
                <button
                  style={{ maxWidth: "100px", minWidth: "10px" }}
                  className="btn btn-success button w-auto mb-2 me-2"
                  onClick={(e) => skills?.length == 1 ? resetSkill(e, index) : removeSkill(e, index)}
                >
                  Remove
                </button>
                {index>0 && index===skills.length-1 ? <button
            type="button"
            className="button-outline mb-4"
            onClick={(e) => addSkill(e)}
          >
            Add Skill
          </button> : null }
              </div>
            </div>
          ))}
        {/* </div> */}
        <div className="mb-4 w-100  text-end">
          {langauge?.length<=1 ? <button className="button-outline" onClick={(e) => addLangauge(e)}>
            Add Langauge
          </button> : null}
        </div>
        <div className="language-known-grid mb-4 ">
          {langauge?.map((langauge, index) => (
            <div className="grid-box row g-3" key={index}>
              <div className="col-md-4">
                <div className="form-label-box mb-1">
                  <label className="txt__para txt__sm fw-bold mb-0">
                    Language{" "}{index + 1}
                  </label>
                </div>
                <div className="form-control-box pb-3">
                  <input
                    type="text"
                    placeholder="Hindi,Bengali,French..."
                    className="form-control-no-border"
                    value={langauge.name}
                    onChange={(e) => handleLangauge(e, index)}
                    name="name"
                  />
                </div>
              </div>
              <div className="col-md-8">
                <div className="form-label-box mb-1">
                  <label className="txt__para txt__sm fw-bold mb-0">
                    Rate language
                  </label>
                </div>
                <div className="form-control-box pb-1 pt-2">
                  <span className="d-block text-end" style={{ fontSize: "10px" }}>
                    Rate: {langauge.rating} / 10
                  </span>
                  <input
                    type="range"
                    name="rating"
                    disabled={!langauge?.name}
                    className="form-range"
                    onChange={(e) => handleLangauge(e, index)}
                    min={0}
                    max={10}
                    id="customRange2"
                    value={langauge?.rating}
                  />
                </div>
              </div>
              <div className=" d-flex justify-content-between">
                <button
                className="btn btn-success button  w-auto  ms-2"
                onClick={(e) => index < 1 ? resetLang(e, index) : removeLangauge(e, index)}
              >
                Remove
              </button>
              {index>0 && index===duplicateLanguage.length-1 ? <button className="button-outline" onClick={(e) => addLangauge(e)}>
            Add Langauge
          </button> : null}
              </div>
            </div>
          ))}
          <div className="grid-box" style={{ gridColumn: "1 / 5" }}></div>
        </div>
        <div className="d-flex justify-content-end justify-content-between">
          <button
            type="button"
            disabled={isLoading ? isLoading : false}
            className="btn nav-link Next_qu"
            onClick={skillSubmit}
          >
            {isLoading ? <Spiner1 /> : "Submit"}
          </button>
        </div>
      </div>
    </>

  );
};

export default SkillsData;
