import axios from "axios";
import { PROFILE_UPDATE_FAILURE, PROFILE_UPDATE_REQUEST, PROFILE_UPDATE_SUCCESS } from "./actionType";
import { errorCall } from "../../ErrorHandling/action";
import { toast } from 'react-toastify';
export const profile_req = () => ({
    type: PROFILE_UPDATE_REQUEST
});

export const profile_succ = (data) => ({
    type: PROFILE_UPDATE_SUCCESS,
    payload: data
})

export const profile_failure = (err) => ({
    type: PROFILE_UPDATE_FAILURE,
    payload: err
})

const getLocaldata = () => {
    const token = localStorage.getItem("token");
    return token
}

export const edituser = (current_password, email, id, name, password, password_confirmation, navigate) => (dispatch) => {
    dispatch(profile_req());
    const formData = {
        current_password,
        email,
        id,
        name,
        password,
        password_confirmation
    }
    const mt = getLocaldata();

    const config = {
        headers: { Authorization: `Bearer ${mt}` }
    };
    axios.put(`${process.env.REACT_APP_BASE_URL}/api/auth/updateUserProfile`, formData, config).then((res) => {
        dispatch(profile_succ(res.data));

        // setMessage(res.data.message)
        // setShowAlert(true)
        toast.success(res.data.message);
        return true;
    }).catch((error) => {
        if (error?.response?.data?.error == "Unauthenticated.") {
            localStorage.removeItem("token");
            navigate("/login");
        }
        if (error?.response?.data?.message == "Current password doesn't match.") {
            toast.error("Please provide correct current password");
        }
        if (error?.response?.data?.message == "Current password and new password shouldn't same.") {
            toast.error("Please provide new password, different from current password");
        }
        dispatch(profile_failure(error))
        if (error?.message === "Network Error" || error?.response?.status === 500) {
            dispatch(errorCall(error))
        }
    })
}




