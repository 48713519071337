import {
  JOB_FAILURE,
  JOB_REQUEST,
  JOB_SUCCESS,
  TYPE_FAILURE,
  TYPE_REQUEST,
  TYPE_SUCCESS,
  Job_Add_REQUEST,
  Job_Add_SUCCESS,
  Job_Add_FAILURE,
  Job_Update_REQUEST,
  Job_Update_SUCCESS,
  Job_Update_FAILURE,
  CURRENCY_REQUEST,
CURRENCY_SUCCESS,
CURRENCY_FAILURE,
SPEC_JOB_REQUEST,
SPEC_JOB_SUCCESS,
SPEC_JOB_FAILURE,
  JOB_COUNT_REQUEST,
  JOB_COUNT_SUCCESS,
  JOB_COUNT_FAILURE,
  JOB_DATA_REQUEST,
JOB_DATA_SUCCESS,
JOB_DATA_FAILURE
} from "./actionType";
const initState = {

  typeData: [],
  currencyData: [],
  specficJobData: [],
  data: [],
  jobData: [],
  candidateJobData: {},
  jobCount:[],

  isLoading: false,
  isLoadingJobList:false,
  isLoadingType: false,
  isLoadingCurrency: false,
  isLoadingSpecific: false,
  isLoadingCandiJob: false,

  isErrorJobList:false,
  isErrorType: false,
  isErrorCurrency: false,
  isErrorSpecific: false,
  isErrorCandijob: false,
  isError: false,

  isJobCount:false,
  isJobCountErr:false,


  errorData: "",
  errorDataAdd:"",
  errorDataType: "",
  errorDataCurrency: "",
  errorDataSpecific: "",
  errorData: "",
 
};
export const Job_Reducer = (state = initState, action) => {
  switch (action.type) {
    case JOB_REQUEST: {
      return {
        ...state,
        isLoadingJobList: true,
        jobData: [],
        isError: false,
      };
    }
    case JOB_SUCCESS: {
      return {
        ...state,
        isLoadingJobList: false,
        jobData: action.payload,
        isError: false,
      };
    }
    case JOB_FAILURE: {
      return {
        ...state,
        isLoadingJobList: false,
        jobData: [],
        isError: true,
        errorData: action.payload,
      };
    }

    case TYPE_REQUEST: {
      return {
        ...state,
       isLoadingType: true,
        typeData: [],
        isErrorType: false,
        // errorData: action.payload.error
      };
    }
    case TYPE_SUCCESS: {
      return {
        ...state,
        isLoadingType: false,
        typeData: action.payload,
        isErrorType: false,
        // errorData: action.payload.error
      };
    }
    case TYPE_FAILURE: {
      return {
        ...state,
        isLoadingType: false,
        typeData: [],
        isErrorType: true,
        errorDataType: action.payload,
      };
    }

    
    case Job_Add_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case Job_Add_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        // errorData: action.payload.error,
      };
    }
    case Job_Add_FAILURE: {
      return {
        ...state,
        isLoading: false,
        // typeData: [],
        // isError: true,
        errorDataAdd: action.payload,
      };
    }
    case CURRENCY_REQUEST: {
      return {
        ...state,
        isLoadingCurrency: true,
        isErrorCurrency: false,
        // typeData: [],
      };
    }
    case CURRENCY_SUCCESS: {
      return {
        ...state,
        isLoadingCurrency: false,
        currencyData:action.payload,
        isErrorCurrency: false,
      };
    }
    case CURRENCY_FAILURE: {
      return {
        ...state,
        isLoadingCurrency: false,
        // typeData: [],
        isErrorCurrency: true,
        errorDataCurrency: action.payload,
      };
    }
    case SPEC_JOB_REQUEST: {
      return {
        ...state,
        isLoadingSpecific: true,
        isErrorSpecific: false,
        specficJobData: {},
      };
    }
    case SPEC_JOB_SUCCESS: {
      return {
        ...state,
        isLoadingSpecific: false,
        isErrorSpecific: false,
        specficJobData:action.payload,
      };
    }
    case SPEC_JOB_FAILURE: {
      return {
        ...state,
        isLoadingSpecific: false,
        isErrorSpecific: true,
        errorDataSpecific:action.payload,
      };
    }
    case Job_Update_REQUEST: {
      return {
        ...state,
        isLoadingUp: true,
        isErrorUp: false,
        Updata: {},
      };
    }
    case Job_Update_SUCCESS: {
      return {
        ...state,
        isLoadingUp: false,
        isErrorUp: false,
        Updata:action.payload,
      };
    }
    case Job_Update_FAILURE: {
      return {
        ...state,
        isLoadingUp: false,
        isErrorUp: true,
      };
    }
    case JOB_COUNT_REQUEST:{
      return{
        ...state,
        isJobCount:true,
        jobCount:{},
        isJobCountErr:false,
      };
    }
    case JOB_COUNT_SUCCESS:{
      return{
        ...state,
        isJobCount:false,
        jobCount:action.payload,
        isJobCountErr:false,
      };
    }
    case JOB_COUNT_FAILURE:{
      return{
        ...state,
        isJobCount:false,
        jobCount:action.payload,
        isJobCountErr:true,
      };
    }
    case JOB_DATA_REQUEST:{
      return{
        ...state,
        isLoadingCandiJob:true,
        candidateJobData:action.payload,
        isErrorCandijob:true,
      };
    }
    case JOB_DATA_SUCCESS:{
      return{
        ...state,
        isLoadingCandiJob:false,
        candidateJobData:action.payload,
        isErrorCandijob:true,
      };
    }
    case JOB_DATA_FAILURE:{
      return{
        ...state,
        isLoadingCandiJob:false,
        candidateJobData:action.payload,
        isErrorCandijob:true,
      };
    }
    default: {
      return state;
    }
  }
}