export const JOB_REQUEST = "JOB_REQUEST"
export const JOB_SUCCESS = "JOB_SUCCESS"
export const JOB_FAILURE = "JOB_FAILURE"

export const TYPE_REQUEST = "TYPE_REQUEST"
export const TYPE_SUCCESS = "TYPE_SUCCESS"
export const TYPE_FAILURE = "TYPE_FAILURE"

export const Job_Add_REQUEST = "Job_Add_REQUEST"
export const Job_Add_SUCCESS = "Job_Add_SUCCESS"
export const Job_Add_FAILURE = "Job_Add_FAILURE"

export const CURRENCY_REQUEST = "CURRENCY_REQUEST"
export const CURRENCY_SUCCESS = "CURRENCY_SUCCESS"
export const CURRENCY_FAILURE = "CURRENCY_FAILURE"

export const SPEC_JOB_REQUEST = "SPEC_JOB_REQUEST"
export const SPEC_JOB_SUCCESS = "SPEC_JOB_SUCCESS"
export const SPEC_JOB_FAILURE = "SPEC_JOB_FAILURE"

export const JOB_COUNT_REQUEST = "JOB_COUNT_REQUEST"
export const JOB_COUNT_SUCCESS = "JOB_COUNT_SUCCESS"
export const JOB_COUNT_FAILURE = "JOB_COUNT_FAILURE"

export const Job_Update_REQUEST = "Job_Update_REQUEST"
export const Job_Update_SUCCESS = "Job_Update_SUCCESS"
export const Job_Update_FAILURE = "Job_Update_FAILURE"

export const JOB_DATA_REQUEST = "JOB_DATA_REQUEST"
export const JOB_DATA_SUCCESS = "JOB_DATA_SUCCESS"
export const JOB_DATA_FAILURE = "JOB_DATA_FAILURE"