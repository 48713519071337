import axios from "axios";
import { GET_USER_LIST_FAILURE, GET_USER_LIST_REQUEST, GET_USER_LIST_SUCCESS } from "./actionType";
import { errorCall } from "../../ErrorHandling/action";

export const get_usertype_req = () => ({
  type: GET_USER_LIST_REQUEST
});

export const get_usertype_succ = (data) => ({
  type: GET_USER_LIST_SUCCESS,
  payload: data
})

export const get_usertype_failure = (err) => ({
  type: GET_USER_LIST_FAILURE,
  payload: err
})

const getLocaldata = () => {
  const token = localStorage.getItem("token");
  return token
}

export const getUserType = (navigate) => (dispatch) => {
  dispatch(get_usertype_req());
  const mt = getLocaldata();
  const config = {
    headers: { Authorization: `Bearer ${mt}` }
  };
  axios.get(`${process.env.REACT_APP_BASE_URL}/api/auth/userList`, config).then((res) => {
    dispatch(get_usertype_succ(res.data))
  }).catch((err) => {
    if (err?.response?.data?.error == "Unauthenticated.") {
      localStorage.removeItem("token");
      navigate("/login");
    }

    dispatch(get_usertype_failure(err))
    if (err.message === "Network Error") {
      dispatch(errorCall(err))
  }
  })
}
export const userTypehort = (navigate,search, status,page,perpage) => (dispatch) => {
  dispatch(get_usertype_req());
  const mt = getLocaldata();
  const config = {
    headers: { Authorization: `Bearer ${mt}` }
  };
  const formdata = {
    "search": search,
    "status": parseInt(status),
    "pageNumber": page,
    perpage
  }

  axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/sortUserTypes`, formdata, config).then((res) => {
    dispatch(get_usertype_succ(res.data))
  }).catch((err) => {
    if (err?.response?.data?.error == "Unauthenticated.") {
      localStorage.removeItem("token");
      navigate("/login");
    }
    dispatch(get_usertype_failure(err))
    if (err.message === "Network Error" || err.response.status === 500) {
      dispatch(errorCall(err))
  }
  })
}