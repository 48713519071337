import axios from "axios";
import { ADD_SKILL_LIST_FAILURE, ADD_SKILL_LIST_REQUEST, ADD_SKILL_LIST_SUCCESS } from "./actionType";
import { errorCall } from "../../ErrorHandling/action";
import { toast } from 'react-toastify';
export const add_skill_req = () => ({
    type: ADD_SKILL_LIST_REQUEST
});

export const add_skill_succ = (data) => ({
    type: ADD_SKILL_LIST_SUCCESS,
    payload: data
})
export const add_skill_failure = (err) => ({
    type: ADD_SKILL_LIST_FAILURE,
    payload: err
})

export const addSkill = (framework, name, skillId, status,notify, navigate) => async (dispatch) => {
    dispatch(add_skill_req());
    const config = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
    };
    const formData = {
        form: {
            'framework': framework,
            'name': name,
            'skillId': skillId,
            'status': status
        }
    }
        try {
        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/skill/add`, formData, config);
        dispatch(add_skill_succ(res.data));
        framework == 1 ? toast.success("Framework Added Successful") : toast.success(res.data.message);
        return res;
    } catch (err) {
        if (err?.response?.data?.error == "Unauthenticated.") {
            localStorage.removeItem("token");
            navigate("/login");
        } else if (err.response.data.errors != null) {
            err.response.data.errors.skillId.forEach(e => {
                if (e == "The skill id field is required when framework is 1.") {
                    toast.error("Please Choose Skill Name");
                }
            });
        }
        dispatch(add_skill_failure(err));
        if (err.message === "Network Error" || err.response.status === 500) {
            dispatch(errorCall(err));
        }
        toast.error(err?.response?.data?.message);
        return err;
    }
}