import React from 'react'

const Settings = ({Sidedata, onSidedataChange}) => {
  const menuClick = () => {
    onSidedataChange(!Sidedata);
  };
  return (
    <div>
      <h1> <button className="fa_bars  d-lg-none" onClick={menuClick}>
                                    <i className="fa-solid fa-bars"></i>
                                </button>   Settings</h1>
    </div>
  )
}

export default Settings
