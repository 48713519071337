import React from "react";
import { useNavigate } from "react-router-dom";

const UserHead = (props) => {
  const navigate=useNavigate()
  const menuClick = () => {
    props.newonSidedataChange(!props.newSidedata);
  };
  const hadleClick = () => {
   if(props?.navdata?.buttonText){
    navigate("/jobs/create-job")
   }
  };
  
  return (
    <div>
      <div className="container-fluid">
      <button className="fa_bars  d-lg-none me-3" onClick={menuClick}>
        <i className="fa-solid fa-bars"></i>
      </button>
        <div className="row">
          <div className="col-md-12">
            <nav className="settings_nav d-flex align-items-center justify-content-between">
              <div className="nav_wrapper d-flex align-items-center">
                <span>
                  <svg
                    width="23"
                    height="18"
                    viewBox="0 0 23 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.077 2.76953C11.4593 2.76953 11.7693 3.07949 11.7693 3.46184V5.53876C11.7693 5.92111 11.4593 6.23107 11.077 6.23107C10.6946 6.23107 10.3847 5.92111 10.3847 5.53876V3.46184C10.3847 3.07949 10.6946 2.76953 11.077 2.76953ZM5.16744 5.16769C5.43779 4.89742 5.87602 4.89742 6.14636 5.16769L7.41329 6.43322C7.5884 6.60834 7.65679 6.86357 7.5927 7.10279C7.5286 7.342 7.34175 7.52884 7.10254 7.59294C6.86333 7.65704 6.60809 7.58865 6.43298 7.41353L5.16744 6.14661C4.89718 5.87626 4.89718 5.43803 5.16744 5.16769ZM3.46159 10.3849C3.07924 10.3849 2.76929 10.6949 2.76929 11.0772C2.76929 11.4596 3.07924 11.7695 3.46159 11.7695H5.65759C6.03995 11.7695 6.3499 11.4596 6.3499 11.0772C6.3499 10.6949 6.03995 10.3849 5.65759 10.3849H3.46159ZM15.9231 11.0772C15.9231 10.6949 16.2331 10.3849 16.6154 10.3849H18.6924C19.0747 10.3849 19.3847 10.6949 19.3847 11.0772C19.3847 11.4596 19.0747 11.7695 18.6924 11.7695H16.6154C16.2331 11.7695 15.9231 11.4596 15.9231 11.0772ZM16.2374 5.17045C16.4508 4.98824 16.7682 5.00028 16.9671 5.19815V5.19676C17.1613 5.3907 17.1786 5.69959 17.0073 5.91399L12.2525 11.8637C11.9554 12.242 11.4688 12.4181 10.9984 12.3176C10.5279 12.2171 10.1558 11.8575 10.0392 11.3908C9.92259 10.9242 10.0819 10.4318 10.4497 10.1218L16.2374 5.17045Z"
                      fill="black"
                    ></path>{" "}
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6.65863 0.929328C2.61853 2.68667 0.00397069 6.67123 1.46244e-05 11.077C-0.00143192 12.3318 0.210673 13.5778 0.627245 14.7614C1.23925 16.4964 3.18186 16.9796 4.68694 16.4922C6.49386 15.9065 9.03601 15.2308 11.0769 15.2308C13.1192 15.2308 15.6586 15.9065 17.4669 16.4922C18.972 16.9796 20.9146 16.4964 21.5266 14.7614C22.9881 10.6052 21.8476 5.97788 18.6219 2.97694C15.3962 -0.0240045 10.6987 -0.828015 6.65863 0.929328ZM3.16326 5.47751C4.98065 2.90982 7.93116 1.38386 11.0769 1.38467C14.2227 1.38386 17.1732 2.90982 18.9906 5.47751C20.808 8.04519 21.2662 11.3352 20.2196 14.3018C19.9385 15.0979 18.9416 15.5147 17.8934 15.1754C16.0671 14.5828 13.3505 13.8462 11.0769 13.8462C8.8034 13.8462 6.08817 14.5842 4.26048 15.1741C3.21232 15.5147 2.2154 15.0979 1.93432 14.3018C0.887677 11.3352 1.34587 8.04519 3.16326 5.47751Z"
                      fill="black"
                    ></path>
                  </svg>
                </span>
                <h2 className="nav_heading">{props.navdata?.header}</h2>
              </div>
              <div>
                
             { props?.navdata?.buttonText? (<button className="btn btn-outline-primary border-0 bg-transparent">
                <svg
                  width="18"
                  height="20"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 18 20"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.5 16.2501C17.1262 16.0014 16.8037 15.5589 16.525 15.0001C15.4 12.7501 15 8.60012 15 7.50012C14.9996 4.52765 12.9066 1.96634 9.99375 1.37387C10.0413 0.899747 9.81524 0.439809 9.41075 0.187932C9.00626 -0.063946 8.49374 -0.063946 8.08925 0.187932C7.68476 0.439809 7.45866 0.899747 7.50625 1.37387C4.65 1.95012 2.5 4.47512 2.5 7.50012C2.5 8.60012 2.1 12.7501 0.975 15.0001C0.69625 15.5589 0.37375 16.0014 0 16.2501H17.5ZM8.75 2.39762L7.75375 2.59887C5.424 3.07356 3.75025 5.1225 3.75 7.50012C3.75 8.28512 3.5825 10.2464 3.17625 12.1776C2.97625 13.1364 2.70625 14.1351 2.3475 15.0001H15.1525C14.7938 14.1351 14.525 13.1376 14.3237 12.1776C13.9175 10.2464 13.75 8.28512 13.75 7.50012C13.7492 5.12297 12.0755 3.07471 9.74625 2.60012L8.75 2.39637V2.39762ZM8.75 20.0001C10.1307 20.0001 11.25 18.8808 11.25 17.5001H6.25C6.25 18.8808 7.36929 20.0001 8.75 20.0001Z"
                    fill="black"
                  ></path>
                </svg>
              </button>): null}
             { props?.navdata?.buttonText?( <button type="button" className="nav_button p-3" onClick={()=>hadleClick()}>
                {props?.navdata?.buttonText}
              </button>):null}
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserHead;
