// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.titel_block h2 {
    font-size: 20px;
    line-height: 22px;
    color: #000;
    font-weight: 700;
}

.titel_block a {
    background: #3ee43a;
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    color: #fff;
    border-radius: 4px;
    padding: 6px 13px 8px 9px;
}

.add_companyAdmin_link:hover,
.add_companyAdmin_link {
    color: #3ab2e4;
    font-size: 14px;
}

.job_list_search_box {
    border: 0px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    background-color: transparent;
    color: #adb5bd;
}

.list_search_fild input.form-control {
    padding: 10px !important;
    font-size: 0.8rem !important;
}

.form-control:focus {
    border-color: #9dd9f2;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(58, 178, 228, 0.25);
}

.user_initial {
    width: 34px;
    height: 34px;
    border-radius: 100%;
    flex-shrink: 0;
    margin-right: 1rem;
    background-color: #fb8f8f;
    font-size: 14px;
    color: #fff;
    font-weight: 700;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    overflow: hidden;
}
.edit_svg{
    color: red;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/setting/setting.css"],"names":[],"mappings":";AACA;IACI,eAAe;IACf,iBAAiB;IACjB,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,WAAW;IACX,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;;IAEI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,QAAQ;IACR,QAAQ;IACR,6BAA6B;IAC7B,6BAA6B;IAC7B,cAAc;AAClB;;AAEA;IACI,wBAAwB;IACxB,4BAA4B;AAChC;;AAEA;IACI,qBAAqB;IACrB,UAAU;IACV,kDAAkD;AACtD;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,cAAc;IACd,kBAAkB;IAClB,yBAAyB;IACzB,eAAe;IACf,WAAW;IACX,gBAAgB;IAChB,oBAAoB;IACpB,mBAAmB;IACnB,uBAAuB;IACvB,yBAAyB;IACzB,gBAAgB;AACpB;AACA;IACI,UAAU;AACd","sourcesContent":["\n.titel_block h2 {\n    font-size: 20px;\n    line-height: 22px;\n    color: #000;\n    font-weight: 700;\n}\n\n.titel_block a {\n    background: #3ee43a;\n    font-size: 14px;\n    line-height: 16px;\n    font-weight: 700;\n    color: #fff;\n    border-radius: 4px;\n    padding: 6px 13px 8px 9px;\n}\n\n.add_companyAdmin_link:hover,\n.add_companyAdmin_link {\n    color: #3ab2e4;\n    font-size: 14px;\n}\n\n.job_list_search_box {\n    border: 0px;\n    position: absolute;\n    right: 0;\n    top: 50%;\n    transform: translate(0, -50%);\n    background-color: transparent;\n    color: #adb5bd;\n}\n\n.list_search_fild input.form-control {\n    padding: 10px !important;\n    font-size: 0.8rem !important;\n}\n\n.form-control:focus {\n    border-color: #9dd9f2;\n    outline: 0;\n    box-shadow: 0 0 0 0.25rem rgba(58, 178, 228, 0.25);\n}\n\n.user_initial {\n    width: 34px;\n    height: 34px;\n    border-radius: 100%;\n    flex-shrink: 0;\n    margin-right: 1rem;\n    background-color: #fb8f8f;\n    font-size: 14px;\n    color: #fff;\n    font-weight: 700;\n    display: inline-flex;\n    align-items: center;\n    justify-content: center;\n    text-transform: uppercase;\n    overflow: hidden;\n}\n.edit_svg{\n    color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
