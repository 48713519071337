import axios from "axios";
import {
  JOB_FAILURE,
  JOB_REQUEST,
  JOB_SUCCESS,
  TYPE_FAILURE,
  TYPE_REQUEST,
  TYPE_SUCCESS,
  Job_Add_REQUEST,
  Job_Add_SUCCESS,
  Job_Add_FAILURE,
  CURRENCY_REQUEST,
  CURRENCY_SUCCESS,
  CURRENCY_FAILURE,
  SPEC_JOB_FAILURE,
  SPEC_JOB_REQUEST,
  SPEC_JOB_SUCCESS,
  JOB_COUNT_REQUEST,
  JOB_COUNT_SUCCESS,
  JOB_COUNT_FAILURE,
  JOB_DATA_REQUEST,
  JOB_DATA_SUCCESS,
  JOB_DATA_FAILURE
} from "./actionType";
import { toast } from "react-toastify";
import { errorCall } from "../ErrorHandling/action";

export const job_req = () => ({
  type: JOB_REQUEST,
});

export const job_succ = (data) => ({
  type: JOB_SUCCESS,
  payload: data,
});
export const job_failure = (err) => ({
  type: JOB_FAILURE,
  payload: err,
});

export const jobList =
  (
    navigate,
    token,
    experienceMax,
    experienceMin,
    salaryMax,
    salaryMin,
    search,
    skill,
    type,
    page,
    jobGroup = 'All'
  ) =>
    (dispatch) => {
      dispatch(job_req());
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/api/auth/companyJobPost/sort_jobs`,
          {
            experienceMax,
            experienceMin,
            salaryMax,
            salaryMin,
            search,
            skill,
            type,
            page,
            jobGroup
          },
          {
            headers: {
              Authorization: "Bearer" + " " + token,
            },
          }
        )
        .then((response) => {
          dispatch(job_succ(response?.data));
        })
        .catch((error) => {
          if (error?.message == "Network Error" || error?.response?.status == 500) {
            dispatch(errorCall(error))
            dispatch(job_failure(error))
            dispatch(errorCall(error))
          }
          else if (error?.response?.data?.error == "Unauthenticated.") {
            localStorage.removeItem("token");
            navigate("/login");
          }
          else { dispatch(job_failure(error?.response?.data)); }
        });
    };





export const type_req = (data) => ({
  type: TYPE_REQUEST,
});
export const type_succ = (data) => ({
  type: TYPE_SUCCESS,
  payload: data,
});
export const type_fail = (data) => ({
  type: TYPE_FAILURE,
  payload: data,
});

export const typeList = (navigate, token) => (dispatch) => {
  dispatch(type_req());
  axios
    .get(`${process.env.REACT_APP_BASE_URL}/api/auth/jobPreference`, {
      headers: {
        Authorization: "Bearer" + " " + token,
      },
    })
    .then((response) => {
      dispatch(type_succ(response?.data?.data));
    })
    .catch((error) => {
      if (error?.message == "Network Error" || error?.response?.status == 500 || error.response.status == 404) {
        dispatch(errorCall(error))
        dispatch(type_fail(error))
      }
      else if (error?.response?.data?.error == "Unauthenticated.") {
        localStorage.removeItem("token");
        navigate("/login");
      }
      else { dispatch(type_fail(error?.response?.data)); }
    });
};




export const job_add_req = (data) => ({
  type: Job_Add_REQUEST,
});
export const job_add_succ = (data) => ({
  type: Job_Add_SUCCESS,
  payload: data,
});
export const job_add_fail = (data) => ({
  type: Job_Add_FAILURE,
  payload: data,
});

export const jobAdd =
  (
    navigate,
    token,
    currency_type,
    description,
    designation_experience_id,
    designation_id,
    experience_max,
    experience_min,
    image,
    location,
    location_id,
    note,
    preference,
    salary_max,
    salary_min,
    skills_id,
    title,
    vacancy
  ) =>
    (dispatch) => {
      dispatch(job_add_req());
      return axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/api/auth/companyJobPost`,
          {
            form: {
              currency_type,
              description,
              designation_experience_id,
              designation_id,
              experience_max,
              experience_min,
              image,
              location,
              location_id,
              note,
              preference,
              salary_max,
              salary_min,
              skills_id,
              title,
              vacancy,
            }
          },
          {
            headers: {
              // "Content-Type": "multipart/form-data",
              Authorization: "Bearer" + " " + token,
            },
          }
        )
        .then((response) => {
          if (response.data.message) {
            navigate("/jobs/create-job-list")
            toast.success(response.data.message);
            dispatch(job_add_succ(response.data));
          }
          return response;
        })
        .catch((error) => {
          if (error?.message == "Network Error" || error?.response?.status == 500) {
            dispatch(errorCall(error))
            dispatch(job_add_fail(error))
          }
          else if (error?.response?.data?.error == "Unauthenticated.") {
            localStorage.removeItem("token");
            navigate("/login");
          } else if (error.message.includes("409")) {
            toast.error("Job Title already added.");
          } else {
            dispatch(job_add_fail(error?.response?.data));
            if (error.response.data.message === "Job Title must be a valid name." || error.response.data.message === "Location for the Job must be a valid.") {
              toast.warning(error.response.data.message);
            } else {
              toast.warn(error.response);
            }
          }
          return error
        });
    };





export const jobUpdate =
  (
    navigate,
    token,
    id,
    currency_type,
    description,
    designation_exp_id,
    designation_id,
    experience_max,
    experience_min,
    image,
    location,
    location_id,
    note,
    old_location,
    preference,
    salary_max,
    salary_min,
    skills,
    title,
    vacancy
  ) =>

    (dispatch) => {
      return axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/api/auth/companyJobPost/update/${id}`,
          {
            form: {
              currency_type,
              description,
              designation_exp_id,
              id,
              designation_id,
              experience_max,
              experience_min,
              image,
              location,
              location_id,
              note,
              old_location,
              preference,
              salary_max,
              salary_min,
              skills,
              title,
              vacancy,
            }
          },
          {
            headers: {
              Authorization: "Bearer" + " " + token,
            },
          }
        )
        .then((response) => {
          if (response.data.message) {
            navigate('/jobs/create-job-list')
            toast.success(response.data.message);
          }
          return response;
        })
        .catch((error) => {
          dispatch(job_failure(error))
          if (error?.message == "Network Error" || error?.response?.status == 500) {
            dispatch(errorCall(error))
          }
          else if (error?.response?.data?.error == "Unauthenticated.") {
            localStorage.removeItem("token");
            navigate("/login");
          } else if (error.message.includes("409")) {
            toast.error("Job Title already added.");
          } else {
            if (error.response.data.message === "Job Title must be a valid name." || error.response.data.message === "Location for the Job must be a valid name.") {
              toast.warning(error.response.data.message);
            } else {
              toast.warn("failed to update");
            }
            // dispatch(job_failure(error))
          }
          return error
        });
    };




export const curr_req = (data) => ({
  type: CURRENCY_REQUEST,
});
export const curr_succ = (data) => ({
  type: CURRENCY_SUCCESS,
  payload: data,
});
export const curr_fail = (data) => ({
  type: CURRENCY_FAILURE,
  payload: data,
});

export const currencyList = (navigate, token) => (dispatch) => {
  dispatch(curr_req());
  axios
    .get(`${process.env.REACT_APP_BASE_URL}/api/auth/currenyTypes`, {
      headers: {
        Authorization: "Bearer" + " " + token,
      },
    })
    .then((response) => {
      dispatch(curr_succ(response?.data?.data));
    })
    .catch((error) => {
      if (error?.message == "Network Error" || error?.response?.status == 500) {
        dispatch(errorCall(error))
        dispatch(curr_fail(error))
        return
      }
      else if (error?.response?.data?.error == "Unauthenticated.") {
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        dispatch(curr_fail(error.response));
      }
    });
};



export const spec_job_req = (data) => ({
  type: SPEC_JOB_REQUEST,
});
export const spec_job_succ = (data) => ({
  type: SPEC_JOB_SUCCESS,
  payload: data,
});
export const spec_job_fail = (data) => ({
  type: SPEC_JOB_FAILURE,
  payload: data,
});

export const specificeJob = (navigate, token, id) => (dispatch) => {
  dispatch(spec_job_req());
  axios
    .get(`${process.env.REACT_APP_BASE_URL}/api/auth/companyJobPost/edit/${id}`, {
      headers: {
        Authorization: "Bearer" + " " + token,
      },
    })
    .then((response) => {
      dispatch(spec_job_succ(response?.data?.data));
    })
    .catch((error) => {
      if (error?.message == "Network Error" || error?.response?.status == 500 || error?.response?.status == 404) {
        dispatch(errorCall(error))
        dispatch(spec_job_fail(error))
        return
      }
      else if (error?.response?.data?.error == "Unauthenticated.") {
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        dispatch(spec_job_fail(error.response));
      }
    });
};


// actions for storing job count of different type

export const job_count_req = () => ({
  type: JOB_COUNT_REQUEST,
});

export const job_count_succ = (data) => ({
  type: JOB_COUNT_SUCCESS,
  payload: data,
});
export const job_count_fail = (err) => ({
  type: JOB_COUNT_FAILURE,
  payload: err,
});

//api call to fetch job count
export const jobCount = (navigate, token) =>
  (dispatch) => {
    dispatch(job_count_req());
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/auth/companyJobPost/counts`,
        {
          headers: {
            Authorization: "Bearer" + " " + token,
          },
        }
      )
      .then((response) => {
        dispatch(job_count_succ(response?.data));
      })
      .catch((error) => {
        if (error?.message == "Network Error" || error?.response?.status == 500) {
          dispatch(errorCall(error))
          dispatch(job_count_fail(error))
        }
        else if (error?.response?.data?.error == "Unauthenticated.") {
          localStorage.removeItem("token");
          navigate("/login");
        }
        else { dispatch(job_count_fail(error?.response?.data)); }
      });
  };





export const job_data_req = () => ({
  type: JOB_DATA_REQUEST,
});

export const job_data_succ = (data) => ({
  type: JOB_DATA_SUCCESS,
  payload: data,
});
export const job_data_fail = (err) => ({
  type: JOB_DATA_FAILURE,
  payload: err,
});
export const jobsData = (id) => (dispatch) => {
  dispatch(job_data_req())
  axios.get(`${process.env.REACT_APP_BASE_URL}/api/companyJobPost/getJob/${id}`).then((response) => {
    dispatch(job_data_succ(response.data.data))
  }).catch((error) => {
    if (error.message == "Network Error" || error.response.status == 500 || error.response.status == 404) {
      dispatch(errorCall(error))
      return;
    }
    dispatch(job_data_fail(error))
    console.log(error);
  })
}