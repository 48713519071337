import axios from "axios";
import { SOURCE_LIST_FAILURE, SOURCE_LIST_REQUEST, SOURCE_LIST_SUCCESS } from "./actionType";
import { errorCall } from "../../ErrorHandling/action";

export const source_req = () => ({
  type: SOURCE_LIST_REQUEST
});

export const source_succ = (data) => ({
  type: SOURCE_LIST_SUCCESS,
  payload: data
})
export const source_failure = (err) => ({
  type: SOURCE_LIST_FAILURE,
  payload: err
})
const getLocaldata = () => {
  const token = localStorage.getItem("token");
  return token
}

export const sourceList = (navigate) => (dispatch) => {
  dispatch(source_req());
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    }
  };
  axios.get(`${process.env.REACT_APP_BASE_URL}/api/auth/sourceList`, config).then((res) => {
    dispatch(source_succ(res.data))
  }).catch((err) => {
    if (err.message === "Network Error" || err.response.status === 500) {
      dispatch(source_failure(err));
      dispatch(errorCall(err))
      return;
    }
    dispatch(source_failure(err))
    if (err?.response?.data?.error == "Unauthenticated.") {
      localStorage.removeItem("token");
      navigate("/login");
    }
  })
}

export const sourceshort = (navigate,search, status,page,perpage) => (dispatch) => {
  dispatch(source_req());
  const mt = getLocaldata()
  const config = {
    headers: { Authorization: `Bearer ${mt}` }
  };
  const formdata = {
    "search": search,
    "status": status,
    "pageNumber": page,
    perpage
  }

  axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/sortSources`, formdata, config).then((res) => {
    dispatch(source_succ(res.data))
  }).catch((err) => {
    dispatch(source_failure(err))
    if (err.message === "Network Error" || err.response.status === 500) {
      dispatch(errorCall(err))
  }
  })
}




