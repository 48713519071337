import {
    SAVE_TEMP_REQ,
    SAVE_TEMP_SUCC,
    SAVE_TEMP_FAIL,
    ALL_TEMP_REQ,
    ALL_TEMP_SUCC,
    ALL_TEMP_FAIL,
    SINGLE_TEMP_REQ,
    SINGLE_TEMP_SUCC,
    SINGLE_TEMP_FAIL,
    UPDATE_TEMP_REQ,
    UPDATE_TEMP_SUCC,
    UPDATE_TEMP_FAIL,
    DELETE_TEMP_REQ,
    DELETE_TEMP_SUCC,
    DELETE_TEMP_FAIL
  } from "./actionType";

const initialState = {
    count: 0,
    name: "",
    data: [],
    singleData: {},
    error:"",

    isloadingSave:false,
    isloadingAll:false,
    isloadingSingle:false,
    isloadingUpdate:false,
    isloadingDelete:false,

    isErrorSave:false,
    isErrorAll:false,
    isErrorSingle:false,
    isErrorUpdate:false,
    isErrorDelete:false,

    errorSaveData:"",
    errorAllData:"",
    errorSingleData:"",
    errorUpdateData:"",
    errorDeleteData:"",
  };
  

  export const email_Reducer = (state = initialState, action) => {
    switch (action.type) {


      case SAVE_TEMP_REQ: {
        return {
          ...state,
          isloadingSave: true,
          isErrorSave:false,
        };
      }
      case SAVE_TEMP_SUCC: {
        return {
          ...state,
          isloadingSave: false,
          isErrorSave:false,
        };
      }
      case SAVE_TEMP_FAIL: {
        return {
          ...state,
          isloadingSave: false,
          isErrorSave:true,
          errorSaveData: action.payload,
        };
      }


      case ALL_TEMP_REQ: {
        return {
          ...state,
          isloadingAll: true,
          isErrorAll:false,
          data:""
        };
      }
      case ALL_TEMP_SUCC: {
        return {
          ...state,
          isloadingAll: false,
          isErrorAll:false,
          data:action.payload
        };
      }
      case ALL_TEMP_FAIL: {
        return {
          ...state,
          isloadingAll: false,
          isErrorAll:true,
          errorAllData: action.payload,
        };
      }


      case SINGLE_TEMP_REQ: {
        return {
          ...state,
          isloadingSingle: true,
          isErrorSingle:false,
          data:"",
        };
      }
      case SINGLE_TEMP_SUCC: {
        return {
          ...state,
          isloadingSingle: false,
          isErrorSingle:false,
          data:action.payload
        };
      }
      case SINGLE_TEMP_FAIL: {
        return {
          ...state,
          isloadingSingle: false,
          isErrorSingle:true,
          errorAllData: action.payload,
        };
      }


      case UPDATE_TEMP_REQ: {
        return {
          ...state,
          isloadingUpdate: true,
          isErrorUpdate:false,
        };
      }
      case UPDATE_TEMP_SUCC: {
        return {
          ...state,
          isloadingUpdate: false,
          isErrorUpdate:false,
        };
      }
      case UPDATE_TEMP_FAIL: {
        return {
          ...state,
          isloadingUpdate: false,
          isErrorUpdate:true, 
          errorUpdateData: action.payload,
        };
      }
      case DELETE_TEMP_REQ: {
        return {
          ...state,
          isloadingDelete: true,
          isErrorDelete:false,
        };
      }
      case DELETE_TEMP_SUCC: {
        return {
          ...state,
          isloadingDelete: false,
          isErrorDelete:false,
        };
      }
      case DELETE_TEMP_FAIL: {
        return {
          ...state,
          isloadingDelete: false,
          isErrorDelete:true,
          errorDeleteData: action.payload,
        };
      }



      
      default: {
        return state;
      }
    }
  }