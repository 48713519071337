// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-group-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  h5, p, span {
    margin: 0;
  }
  
  .badge {
    padding: 0.5em 0.7em;
  }

  .dashboard-nav {
    display: flex;
    justify-content: space-between;
    align-items: center; /* for vertical centering, if needed */
    width: 100%; /* ensures the nav takes up the full width of its container */
  }
  .search_box{
    padding: 5px 10px;
    border-radius: 5px;
    /* border: none; */
    border: 1px solid rgb(230, 227, 227);
    
  }
  
.user_table_scroll{
  max-height: 800px;
  overflow-y: auto;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/user/UserList.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;EACrB;;EAEA;IACE,SAAS;EACX;;EAEA;IACE,oBAAoB;EACtB;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB,EAAE,sCAAsC;IAC3D,WAAW,EAAE,6DAA6D;EAC5E;EACA;IACE,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;IAClB,oCAAoC;;EAEtC;;AAEF;EACE,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":[".list-group-item {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n  \n  h5, p, span {\n    margin: 0;\n  }\n  \n  .badge {\n    padding: 0.5em 0.7em;\n  }\n\n  .dashboard-nav {\n    display: flex;\n    justify-content: space-between;\n    align-items: center; /* for vertical centering, if needed */\n    width: 100%; /* ensures the nav takes up the full width of its container */\n  }\n  .search_box{\n    padding: 5px 10px;\n    border-radius: 5px;\n    /* border: none; */\n    border: 1px solid rgb(230, 227, 227);\n    \n  }\n  \n.user_table_scroll{\n  max-height: 800px;\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
