export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST"
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS"
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE"

export const LOGOUT_USER_REQUEST = "LOGOUT_USER_REQUEST"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const LOGOUT_USER_FAILURE = "LOGOUT_USER_FAILURE"

export const TOKEN_USER_REQUEST = "TOKEN_USER_REQUEST"
export const TOKEN_USER_SUCCESS = "TOKEN_USER_SUCCESS"
export const TOKEN_USER_FAILURE = "TOKEN_USER_FAILURE"