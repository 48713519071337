import {  GET_MENU_FAILURE, GET_MENU_REQUEST, GET_MENU_SUCCESS} from "./actionType"

const initialState = {
    isLoading: false,
    data: [],
    isError: false,
    errorData: "",
    
}

export const getMenu = (state = initialState,action)=>{
     switch(action.type){
        case GET_MENU_REQUEST:{
           return{
            ...state,
            isLoading : true,
            // data : [],
            isError : false
           }
        }
        case GET_MENU_SUCCESS:{
            return{
                ...state,
                isLoading : false,
                data : action.payload,
                isError : false,
            }
        }
      
        case GET_MENU_FAILURE:{
            return{
                ...state,
                isLoading : false,
                data : [],
                isError : true,
                errorData:action.payload
            }
        }
        default : {
            return state
         }
     }
}