import { TEMP_CANDIDATE_SUCCESS, STATUS_DROPDOWN_SUCCESS,STATUS_COLOR_SUCCESS } from "./actionType";

export const tempCandidate_succ = (data) => ({
    type: TEMP_CANDIDATE_SUCCESS,
    payload: data,
});


export const GetCandidateData = (details) => (dispatch) => {
     dispatch(tempCandidate_succ(details));
};




export const statusDropdown_succ = (data) => ({
    type: STATUS_DROPDOWN_SUCCESS,
    payload: data,
});


export const GetStatusData = (details) => (dispatch) => {
    dispatch(statusDropdown_succ(details));
    return details
};