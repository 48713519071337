import {
    ACTIVE_STATUS_REQUEST,
    ACTIVE_STATUS_SUCCESS,
    ACTIVE_STATUS_FAILED,
} from "./actionType";

const initialState = {
    applicantStatus: [],

    isLoadingActive: false,
    isError: false,
};

export const activeStatus = (state = initialState, action) => {
    switch (action.type) {
        case ACTIVE_STATUS_REQUEST: {
            return {
                ...state,
                applicantStatus: [],
                isLoadingActive: true,
                isError: false,
            };
        }

        case ACTIVE_STATUS_SUCCESS: {
            return {
                ...state,
                applicantStatus: action.payload,
                isLoadingActive: false,
                isError: false,
            };
        }
        case ACTIVE_STATUS_FAILED: {
            return {
                ...state,
                applicantStatus: [],
                isLoadingActive: false,
                isError: true,
            };
        }

        default: {
            return state;
        }
    }
};
