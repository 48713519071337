import axios from "axios";
import { SKILL_UPDATE_FAILURE, SKILL_UPDATE_REQUEST, SKILL_UPDATE_SUCCESS } from "./actionType";
import {toast} from 'react-toastify'
import { errorCall } from "../../ErrorHandling/action";
export const skill_update_req = (index) => ({
    type: SKILL_UPDATE_REQUEST,
    payload:index
});

export const skill_update_succ = (data) => ({
    type: SKILL_UPDATE_SUCCESS,
    payload: data
})

export const skill_update_failure = (err) => ({
    type: SKILL_UPDATE_FAILURE,
    payload: err
})

const getLocaldata = () => {
    const token = localStorage.getItem("token");
    return token
}
export const editSkill = (id, framework, name, skillId, status, navigate,index=null) => (dispatch) => {
    dispatch(skill_update_req(index));
    const mt = getLocaldata()
    const config = {
        headers: { Authorization: `Bearer ${mt}` }
    };
    const form = {
        form: {
            "framework": framework,
            "id": id,
            "name": name,
            "skillId": skillId,
            "status": status
        }
    }
    return axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/skill/update`, form, config)
    .then((res) => {
        dispatch(skill_update_succ(res.data));
        framework==1?toast.success("Framework updated successfully"):toast.success("Skill updated successfully");
        framework==0 && navigate("/settings/skills")
        return res
    }).catch((err) => {
        if (err?.response?.data?.error == "Unauthenticated.") {
            toast.error("You must login");
            localStorage.removeItem("token");
            navigate("/login");
        }
        dispatch(skill_update_failure(err))
        if (err.message === "Network Error" || err.response.status === 500) {
            dispatch(errorCall(err))
        }
        if((err.message).includes('409')){
            toast.error("Skill name already added.")
        }
        throw err;
    })
}