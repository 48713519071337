import { APPLY_JOB_REQUEST,APPLY_JOB_FAILED, APPLY_JOB_SUCCESS } from "./actionType";

const initialState = {
    applyData :[],
    isError:false
}

export const postApplyJobs = (state=initialState, action)=>{
switch(action.type){

    case APPLY_JOB_REQUEST:{
        return{
            ...state,
            applyData : [],
            isError : false,
            isLoading:true
        }
    }

    case APPLY_JOB_SUCCESS:{
        return{
            ...state,
            applyData : action.payload,
            isError : false,
            isLoading:false
        }
    }
    case APPLY_JOB_FAILED:{
        return{
            ...state,
            applyData : [],
            isError : true,
            isLoading:false
        }
    }
   
    default :{
        return state
    }
}
}