// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bluer_box {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.loaderBox {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}
.loaderBox div {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgb(2 2 2 / 55%);
  filter: blur(8px);
}
`, "",{"version":3,"sources":["webpack://./src/Components/BlurLoader/BlurLoader.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,OAAO;EACP,MAAM;EACN,WAAW;EACX,YAAY;AACd;AACA;EACE,iBAAiB;EACjB,YAAY;EACZ,WAAW;EACX,OAAO;EACP,MAAM;EACN,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,UAAU;AACZ;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,MAAM;EACN,OAAO;EACP,kCAAkC;EAClC,iBAAiB;AACnB","sourcesContent":[".bluer_box {\n  position: absolute;\n  left: 0;\n  top: 0;\n  width: 100%;\n  height: 100%;\n}\n.loaderBox {\n  min-height: 100vh;\n  height: 100%;\n  width: 100%;\n  left: 0;\n  top: 0;\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  z-index: 2;\n}\n.loaderBox div {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n  background-color: rgb(2 2 2 / 55%);\n  filter: blur(8px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
