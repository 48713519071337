import axios from "axios";
import {
  UPDATE_DESIGNATION_FAILURE,
  UPDATE_DESIGNATION_REQUEST,
  UPDATE_DESIGNATION_SUCCESS,
} from "./actionType";
import { toast } from "react-toastify";
import { errorCall } from "../../ErrorHandling/action";

export const update_designation_req = () => ({
  type: UPDATE_DESIGNATION_REQUEST,
});

export const update_designation_succ = (data) => ({
  type: UPDATE_DESIGNATION_SUCCESS,
  payload: data,
});
export const update_designation_failure = (err) => ({
  type: UPDATE_DESIGNATION_FAILURE,
  payload: err,
});

export const addDesignation =
  (name, status, notify, navigate) => async(dispatch) => {
    dispatch(update_designation_req());
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    const form = {
      form: {
        name: name,
        status: status,
      },
    };

    return axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/auth/designation/add`,
        form,
        config
      )
      .then((res) => {
        dispatch(update_designation_succ(res.data));
        toast.success(res.data.message);
        navigate('/settings/designation');
      })
      .catch((err) => {
        if (err?.response?.data?.error == "Unauthenticated.") {
          localStorage.removeItem("token");
          navigate("/login");
        }
        dispatch(update_designation_failure(err));
        console.log(err);
        if (err.message === "Network Error" || err.response.status === 500) {
            dispatch(errorCall(err))
            return;
        }
        toast.error(err.response.data.message);
      });
  };
