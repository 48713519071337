import React, { useEffect } from "react";
import "./css/CreateTest.css";
// import SideNav from "./SideNav";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addCreateTest, getTest, testduplicate } from "../../Redux/TestReducer/action";
import { useState } from "react";
import { cateGories, deleteCategory } from "../../Redux/CategoryReducer/action";
import {
  deleteQuestion,
  getQuestion,
} from "../../Redux/QuestionReducer/action";
import { BiTrashAlt, BiCopy, BiPlus, BiInfoCircle } from "react-icons/bi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spiner1 from "../../Components/Spiner1";
import Swal from "sweetalert2";
import { BlurLoader } from "../../Components/BlurLoader/BlurLoader";
import axios from "axios";
// import datetime from "datetime";

const CreateTest = (props) => {
  const webToken = localStorage.getItem("token");
  const cateData = useSelector((state) => state?.categories);
  const location = useLocation()?.state;
  const isLoadingCategory = useSelector(
    (state) => state?.categories.isLoadingDelete
  );
  const testData = useSelector((state) => state.test.singleTest);
  const { isLoadingCreate, isLoadingGet } = useSelector((state) => state.test);
  const questionData = useSelector(
    (state) => state.question.testQuesData.result
  );
  const deleteQuesData = useSelector((state) => state.question.deleteData);
  const { deleteQuestLoading } = useSelector((state) => state.question);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [maxTime, setMaxTime] = useState(0);
  const [testVersion, setTestVersion] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isChecked1, setIsChecked1] = useState(true);
  const [radio1, setRadio1] = useState(true);
  const [ques, setQues] = useState([]);
  const [isDraft, setIsDraft] = useState();
  const [cateList, setCateList] = useState([]);
  const [onSubmitPublish, setOnSubmitPublish] = useState(false);
  const [onSubmitDraft, setOnSubmitDraft] = useState(false);
  const [toaster, setToaster] = useState(false);
  const [apiCall, setApiCall] = useState(1);
  const [draftLoading, setDraftLoading] = useState(false);
  const [publishLoading, setPublishLoading] = useState(false);
  const [questLoading, setQuestLoading] = useState(false);
  const [categLoading, setCategLoading] = useState(false);
  const [duplicateLoading, setDuplicateLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const testErrors = useSelector((state) => state.test.testError);
  // const errorFunction = () => {
  //   setError(testErrors);
  // };
  const { id } = useParams();

  const handleRadioChange = (event) => {
    if (isChecked === false) {
      setIsChecked(true);
      setIsChecked1(false);
      setRadio1(false);
    } else {
      setIsChecked(false);
      setIsChecked1(true);
      setRadio1(true);
    }
  };

  //redux
  const handleDraft = async (e) => {
    setPublishLoading(false);
    setDraftLoading(true);
    if (onSubmitDraft == false) {
      if (props.title === "Edit Test") {
        e.preventDefault();
        const draft = true;
        const shuffleQuestions = radio1;

        if (!title) {
          toast.warn("Please Enter Test Title");
        } else if (!testVersion) {
          toast.warn("Please Provide Test Version");
        } else {
          dispatch(
            addCreateTest(
              webToken,
              id,
              title,
              maxTime,
              testVersion,
              shuffleQuestions,
              draft,
              navigate
            )
          ).then(() => {
            dispatch(getTest(webToken, id, navigate));
          });
        }
      } else {
        e.preventDefault();
        const draft = e.target.value;
        const shuffleQuestions = radio1;
        if (!draft) {
          setIsDraft(false);
        } else {
          setIsDraft(true);
        }
        dispatch(
          addCreateTest(
            webToken,
            id,
            title,
            maxTime,
            testVersion,
            shuffleQuestions,
            isDraft,
            navigate
          )
        ).then(() => {
          dispatch(getTest(webToken, id, navigate));
        });
      }
      setOnSubmitDraft(true);
      setTimeout(() => {
        setOnSubmitDraft(false);
      }, 4000);
    }
  };

  const handlePublish = async (e) => {
    setDraftLoading(false);
    setPublishLoading(true);
    if (onSubmitPublish == false) {
      if (props.title === "Edit Test") {
        e.preventDefault();
        const draft = false;
        const shuffleQuestions = radio1;
        dispatch(
          addCreateTest(
            webToken,
            id,
            title,
            maxTime,
            testVersion,
            shuffleQuestions,
            draft,
            navigate
          )
        ).then(() => {
          dispatch(getTest(webToken, id, navigate));
        });
      } else {
        e.preventDefault();
        const draft = e.target.value;
        const isDraft1 = false;
        const shuffleQuestions = radio1;
        if (!draft) {
          setIsDraft(false);
        } else {
          setIsDraft(true);
        }
        dispatch(
          addCreateTest(
            webToken,
            id,
            title,
            maxTime,
            testVersion,
            shuffleQuestions,
            isDraft1,
            navigate
          )
        ).then(() => {
          dispatch(getTest(webToken, id, navigate));
        });
      }
      setOnSubmitPublish(true);
      setTimeout(() => {
        setOnSubmitPublish(false);
      }, 4000);
    }
  };

  const handleBlur = () => {
    const hideToaster = true;
    const shuffleQuestions = radio1;

    dispatch(
      addCreateTest(
        webToken,
        id,
        title,
        maxTime,
        testVersion,
        shuffleQuestions,
        isDraft,
        navigate,
        hideToaster
      )
    )
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
    }
  };


  const url = window.location.href;
  const baseUrl = process.env.REACT_APP_FRONTEDND_DOMAIN;
  const parts = url?.split(baseUrl);
  const setTestId = parts[1]?.replace("/test-edit", "");
  let splitUrl = setTestId?.split("/");
  splitUrl = splitUrl?.filter((e) => {
    return e !== "" && e;
  });
  if (splitUrl?.length > 0) {
    var urlValue = splitUrl[0];
  }

  const categoryPage = () => {
    navigate(`/test/category-add/${id}`, { state: location });
  };

  const questionPage = () => {
    navigate(`/test/question-add/${id}`, { state: location });
  };

  useEffect(() => {
    // errorFunction();
    if (apiCall === 1) {
      dispatch(cateGories(webToken, id, navigate));
      dispatch(getTest(webToken, id, navigate));
      dispatch(getQuestion(webToken, id, navigate));
      setApiCall(2);
    }
  }, [id, deleteQuesData]);

  useEffect(() => {
   setTitle(testData.title);
  }, [testData.title,id]);
  useEffect(() => {
    dispatch(getTest(webToken, id, navigate))
    .then((testDatas)=>{
      setTitle(testDatas.title);
    })
  }, [id]);

  useEffect(()=>{
    setQues(questionData);
  },[questionData])


  useEffect(()=>{
    setQues(questionData);
  },[questionData])


  useEffect(()=>{
    setMaxTime(testData.maxTime);
  },[testData.maxTime])


  useEffect(()=>{
    setTestVersion(testData.testVersion);
  },[testData.testVersion])

  useEffect(() => {
    if (deleteQuesData) {
      setQuestLoading(false);
      dispatch(getQuestion(webToken, id, navigate));
    }
  }, [deleteQuesData]);

  useEffect(() => {
    dispatch(getQuestion(webToken, id, navigate));
    setCateList(cateData);
  }, [cateData]);

  function getText(html) {
    var divContainer = document.createElement("div");
    divContainer.innerHTML = html.replace(/<[^>]+>/g, "");
    return divContainer.textContent || divContainer.innerText || "";
  }

  const handleMaxTime = (event) => {
    if ((event.key == "/[^0-9]/g") == true || (event.key == " ") == true) {
      event.preventDefault();
    } else {
      setMaxTime(parseInt(event.target.value ? event.target.value : 0));
    }
  };
  const menuClick = () => {
    props.onSidedataChange(!props.newSidedata);
  };



  const handleDeleteQuestion = async (valueId, id, valueCategoryId) => {
    const result = await Swal.fire({
      title: 'Are you sure, you want to delete the question?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3ab2e4',
      cancelButtonColor: 'grey',
      cancelButtonText: "No",
      confirmButtonText: 'Yes',

      customClass: {
        confirmButton: 'Swal_confirm',
        cancelButton: 'Swal_cancel',
        icon: 'Swal_icon',
        title: 'Swal_title'
      },
    });

    if (result.isConfirmed) {
      if (toaster === false) {
        setQuestLoading(valueId);
        dispatch(
          deleteQuestion(
            webToken,
            valueId,
            id,
            valueCategoryId,
            navigate
          )
        ).then(() => {
          dispatch(
            getQuestion(
              webToken,
              id,
              navigate
            )
          );
        });
        setToaster(true);
        setTimeout(() => {
          setToaster(false);
        }, 1000);
      }
    }
  };



  const handleDeleteCategory = async (valueId, id) => {
    const result = await Swal.fire({
      title: 'Are you sure, you want to delete the category?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3ab2e4',
      cancelButtonColor: 'grey',
      cancelButtonText: "No",
      confirmButtonText: 'Yes',

      customClass: {
        confirmButton: 'Swal_confirm',
        cancelButton: 'Swal_cancel',
        icon: 'Swal_icon',
        title: 'Swal_title'
      },
    });

    if (result.isConfirmed) {
      if (toaster === false) {
        setCategLoading(valueId);
        dispatch(
          deleteCategory(
            webToken,
            valueId,
            id,
            navigate
          )
        );
        setToaster(true);
        setTimeout(() => {
          setToaster(false);
        }, 1000);
      }
    }
  }

const duplicateTest =()=>{
  dispatch(testduplicate(navigate, webToken,id)
  )
}
const handleDelete = async (e) => {
  setDeleteLoading(true);
  try {
    await axios.put(
      `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/teststatus/${e}`,
      { isDraft: undefined, isArchive: false, status: true },
      {
        headers: {
          Authorization: `Bearer ${webToken}`,
        }
      }
    ).then(()=>{
      setDeleteLoading(false);
      toast.success("Test deleted successfully");
      navigate("/test/all-test");
    }).catch(()=>{
      setDeleteLoading(false);
    })
  } catch (error) {
    setDeleteLoading(false);
    if (error.message === "Network Error" || error.response.status === 500) {
      return;
    }
    if (error?.response?.data?.message === "Unauthenticated") {
      localStorage.removeItem("token");
      navigate("/login");
    }
  }
}
  return (
    <div className="content_app">
      <div className={`body_content ${isLoadingGet && 'body_scroll_hide'}`}>
        <div className="container-fluid px-0">
          <button className="fa_bars  d-lg-none ms-3" onClick={menuClick}>
            <i className="fa-solid fa-bars fs-4"></i>
          </button>
          <div className="row g-0">
            <div className="col-12 hjklop_right">
              <div className="container-fluid px-0 ">
                <div className="row">
                  <div className="col-12">
                    <div className="">
                      {isLoadingGet && (
                        <BlurLoader />
                      )}
                      <div className="create-test-screen px-3 px-lg-4 px-xl-5 pb-5">
                        <h3 className="create-new-test fw-bold mb-3">
                          {props.title}
                        </h3>
                        <div className="row g-3">
                          <div className="col-xl-9 col-lg-8 col-md-7 col-12 first-input-parent">
                            <div className="title-parent">
                              <label
                                htmlFor="test_title"
                                className="title mb-1"
                              >
                                Title
                              </label>
                              {props.title !== "Edit Test" ? (
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter your test’s name..."
                                  name="title"
                                  id="test_title"
                                  readOnly
                                  disabled
                                  value={title}
                                  onChange={(event) =>
                                    setTitle(event.target.value)
                                  }
                                  onKeyDown={handleKeyPress}
                                />
                              ) : (
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter your test’s name..."
                                  name="title"
                                  id="test_title"
                                  value={title}
                                  onChange={(event) =>
                                    setTitle(event.target.value)
                                  }
                                  onKeyDown={handleKeyPress}
                                />
                              )}
                            </div>
                            <div className="version-parent my-3">
                              <label
                                htmlFor="test_version mb-1"
                                className="title"
                              >
                                Version
                              </label>
                              <div>
                                {props.title !== "Edit Test" ? (
                                  <input
                                    type="text"
                                    placeholder="Enter number of version"
                                    id="test_version"
                                    className="form-control"
                                    name="testVersion"
                                    readOnly
                                    disabled
                                    value={testVersion}
                                    onChange={(event) =>
                                      setTestVersion(event.target.value)
                                    }
                                    onKeyDown={handleKeyPress}
                                  />
                                ) : (
                                  <input
                                    type="number"
                                    placeholder="Enter number of version"
                                    className="form-control"
                                    name="testVersion"
                                    value={testVersion}
                                    onChange={(event) =>
                                      setTestVersion(
                                        event.target.value.trim(" ")
                                      )
                                    }
                                    onKeyDown={handleKeyPress}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="frame-parent">
                              <div className="maximum-time-in-mins-parent">
                                <label
                                  htmlFor="test_time"
                                  className="title mb-1"
                                >
                                  <span>Maximum Time in Mins </span>
                                  <span className="we-dont-block">
                                    (We don’t block the test after the limit -
                                    any excess is just highlighted in the
                                    report)
                                    <p className="text-primary">
                                      Maximum three digit time is allowed
                                    </p>
                                  </span>
                                </label>
                                <div>
                                  <input
                                    type="text"
                                    placeholder="Enter time in minutes"
                                    className="form-control number_input"
                                    name="maxTime"
                                    id="test_time"
                                    maxLength={3}
                                    value={maxTime}
                                    pattern="\d"
                                    onChange={(event) => handleMaxTime(event)}
                                    onKeyDown={handleKeyPress}
                                    onBlur={handleBlur}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {props.title == "Edit Test" ? (
                            <div className="col-xl-3 col-lg-4 col-md-5 col-12  rectangle-parent15">
                              <div className="group-child33 h-100 p-4">
                                <div className="live-up border-bottom pb-2">
                                  <div className="status-live">
                                    <span>Status: </span>
                                    <span className="live1">
                                      {testData?.isDraft == true
                                        ? "Draft"
                                        : "Live"}
                                    </span>
                                  </div>
                                  <div className="status-live">
                                    <span>Updated: </span>
                                    <span className="live1">
                                      {testData?.updatedAt?.slice(0, 10)}
                                    </span>
                                  </div>
                                </div>
                                <div className="live_body border-bottom py-2">
                                  <button onClick={()=>duplicateTest()}className="btn p-0 duplicate_btn d-block">
                                    <span className="me-1">
                                      <BiCopy />
                                    </span>
                                    <span className="dup_test">
                                      Duplicate Test
                                    </span>
                                  </button>
                                  <button onClick={(e)=>{handleDelete(id)}} className="btn p-0 delete-test-parent d-block">
                                    <span className="me-1">
                                      <BiTrashAlt />
                                    </span>
                                    <span className="del_test">
                                      Delete Test
                                    </span>
                                  </button>
                                </div>
                                <div>
                                  <div className="pre_button mt-2">
                                    <button
                                      id="draft-btn"
                                      type="submit"
                                      className="btn left_top_button m-1"
                                      value={"draft"}
                                      disabled={isLoadingCreate}
                                      onClick={handleDraft}
                                    >
                                      {isLoadingCreate && draftLoading ? (
                                        <Spiner1 />
                                      ) : (
                                        "Move To Draft"
                                      )}
                                    </button>
                                    <button
                                      id="publish_btn"
                                      className="btn left_top_button fs-6 m-1"
                                      value={"publish"}
                                      disabled={isLoadingCreate}
                                      onClick={handlePublish}
                                    >
                                      {isLoadingCreate && publishLoading ? (
                                        <Spiner1 />
                                      ) : (props.title === "Edit Test" ? "Update & Publish" : "Publish"
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="col-xl-3 col-lg-4 col-md-5 col-12  rectangle-parent15">
                              <div className="group-child33 h-80 p-4">
                                <div>
                                  <div className="pre_button mt-2">
                                    <button
                                      id="draft-btn"
                                      type="submit"
                                      className="btn left_top_button m-1"
                                      value={"draft"}
                                      disabled={isLoadingCreate}
                                      onClick={handleDraft}
                                    >
                                      {isLoadingCreate && draftLoading ? (
                                        <Spiner1 />
                                      ) : (
                                        "Move To Draft"
                                      )}
                                    </button>
                                    <button
                                      id="publish_btn"
                                      className="btn left_top_button fs-6 m-1"
                                      value={"publish"}
                                      disabled={isLoadingCreate}
                                      onClick={handlePublish}
                                    >
                                      {isLoadingCreate && publishLoading ? (
                                        <Spiner1 />
                                      ) : (
                                        "Publish"
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        {testData.testType == "custom test" &&
                          <div className="do-you-want-to-shuffle-questio-group mt-4">
                            <div className="title mb-3">
                              Do you want to shuffle questions when a candidate
                              takes the test?
                            </div>
                            <div className="group-parent6 mb-2">
                              <div className="no-keep-the-constant-order-group">
                                <div className="arrange-the-questions-container1">
                                  <input
                                    type="radio"
                                    id="test_arrange"
                                    checked={isChecked}
                                    onChange={handleRadioChange}
                                    onKeyDown={handleKeyPress}
                                  />
                                  <label htmlFor="test_arrange">
                                    No, keep the constant order. Arrange the
                                    questions here
                                  </label>
                                  <span className="report">
                                    <a
                                      className="report"
                                    //  href="/"
                                    >
                                      {" "}
                                      ( Arrange the questions here )
                                    </a>
                                  </span>
                                </div>
                              </div>
                              <div className="group-child31" />
                            </div>
                            <div className="group-parent7">
                              <input
                                type="radio"
                                id="id_suffle"
                                checked={isChecked1}
                                onChange={handleRadioChange}
                                onKeyDown={handleKeyPress}
                              />
                              <label htmlFor="id_suffle">
                                Yes, shuffle questions in the test (Recommended)
                              </label>
                            </div>
                          </div>
                        }

                        <div className="rectangle-parent16 mt-4">
                          <div className="d-md-flex align-items-center justify-content-between border-bottom mb-4 pb-2">
                            <div>
                              <b className="questions-categories">
                                Question's Categories{" "}
                              </b>
                              <span></span>
                              <span className="report">
                                <a
                                  className="report"
                                //  href="/"
                                >
                                  ( Displayed in report ){" "}
                                </a>
                              </span>
                              <BiInfoCircle />
                            </div>
                            <div className="displayed-in-report-container mt-2 mt-md-0">
                              <button
                                className="add-category btn btn-outline-secondary px-lg-4"
                                onClick={categoryPage}
                              >
                                <span className="me-1">Add Category</span>
                                <BiPlus />
                              </button>
                            </div>
                          </div>
                          <div className="group-child37" />
                          {cateList?.data?.result ? (
                            <div className="no-questions-created">
                              <table className="table table-borderless table-sm">
                                {cateList?.data?.result?.map((value, i) => (
                                  <tbody
                                    key={i}
                                  // draggable onDragStart={(e) => dragStart(e, i)} onDragEnter={(e) => dragEnter(e, i)}onDragEnd={drop}
                                  >
                                    <tr className="tbl_row">
                                      <td className="col-md-4">
                                        {value?.categoryName}
                                      </td>
                                      <td className="col-md-4 data_2">
                                        <b>
                                          {value?.count}
                                          <span> Questions</span>
                                        </b>
                                      </td>
                                      <td className="table_icons justify-content-end">
                                        <button
                                          className="custom_table_btn edit_btn"
                                          onClick={() =>
                                            navigate(
                                              `/test/category-edit/${id}/${value?._id}`
                                            )
                                          }
                                        >
                                          <img
                                            src="/logos/edit_logo.svg"
                                            alt="logo"
                                          />
                                        </button>
                                        <button
                                          className="custom_table_btn del_btn"
                                          disabled={isLoadingCategory}
                                          onClick={() => {
                                            handleDeleteCategory(value._id, id)
                                          }}
                                        >
                                          {isLoadingCategory &&
                                            categLoading == value._id ? (
                                            <Spiner1 />
                                          ) : (
                                            <img
                                              src="/logos/delete_logo.svg"
                                              alt="logo"
                                            />
                                          )}
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                ))}
                              </table>
                            </div>
                          ) : (
                            <div className="no-questionsCreated">
                              No categories created yet!
                            </div>
                          )}
                        </div>
                        <div className="rectangle-parent17 mt-4">
                          <div className="d-md-flex align-items-center justify-content-between border-bottom mb-4 pb-2">
                            <div>
                              <b className="questions">Questions</b>
                            </div>
                            <div className=" mt-2 mt-md-0">
                              <button
                                className="add-question-parent btn btn-outline-secondary px-lg-4"
                                onClick={questionPage}
                              >
                                <span className="me-1">Add Question</span>
                                <BiPlus />
                              </button>
                            </div>
                          </div>
                          <div className="group-child37" />
                          {ques ? (
                            <div className="no-categories-created">
                              <table className="table table-borderless table-sm">
                                {ques?.map((value, i) => (
                                  <tbody key={i}>
                                    <tr className="tbl_row">
                                      <td className="col-md-4 questionText">
                                        {getText(value.question)}
                                        {/* {value.question.replace(/<[^>]+>/g, '')} */}
                                        {/* {parse(value.question)} */}
                                      </td>
                                      <td className="col-md-4 data_2">
                                        {value.qesCategory}
                                      </td>
                                      <td>
                                        <div className="table_icons justify-content-end align-items-center h-100">
                                          <button
                                            className="custom_table_btn edit_btn"
                                            onClick={() =>
                                              navigate(
                                                `/test/question-edit/${value._id}/${id}`
                                              )
                                            }
                                          >
                                            <img
                                              src="/logos/edit_logo.svg"
                                              alt="logo"
                                            />
                                          </button>
                                          <div >
                                            <button
                                              className="custom_table_btn del_btn"
                                              disabled={deleteQuestLoading}
                                              onClick={() => {
                                                handleDeleteQuestion(value._id, id, value.categoryId)
                                              }}
                                            >
                                              {deleteQuestLoading &&
                                                questLoading == value._id ? (
                                                <Spiner1 />
                                              ) : (
                                                <img
                                                  src="/logos/delete_logo.svg"
                                                  alt="logo"
                                                />
                                              )}
                                            </button>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                ))}
                              </table>
                            </div>
                          ) : (
                            <div className="no-questionsCreated">
                              No Questions created yet!
                            </div>
                          )}
                        </div>

                        <div className="frame-group"></div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateTest;
