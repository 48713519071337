import React, { useEffect, useState } from 'react';

const Avatar = ({ name, size, position, top, left, placeholder }) => {
  const [letterColor, setLetterColor] = useState('');
  const alphabetColors = {
      A: '#f44336', B: '#e91e63', C: '#9c27b0', D: '#673ab7', E: '#3f51b5',
      F: '#2196f3', G: '#03a9f4', H: '#00bcd4', I: '#009688', J: '#4caf50',
      K: '#8bc34a', L: '#cddc39', M: '#ffeb3b', N: '#ffc107', O: '#ff9800',
      P: '#ff5722', Q: '#795548', R: '#607d8b', S: '#009688', T: '#03a9f4',
      U: '#2196f3', V: '#3f51b5', W: '#673ab7', X: '#9c27b0', Y: '#e91e63',
      Z: '#f44336'
  };

  const firstLetter = name ? name[0]?.toUpperCase() : '';

  useEffect(() => {
    const letterColor = alphabetColors[firstLetter] || '#000';
    setLetterColor(letterColor);
  }, [name, alphabetColors, firstLetter]);

  const styles = {
    backgroundColor: placeholder ? "grey" : letterColor,
    minWidth: size,
    height: size,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    color: '#ffffff',
    fontSize: `${size / 2}px`,
    marginRight: '1rem',
    position: position,
    top: top,
    left: left,
  };

  return <div className={placeholder ? "placeholder" : ""} style={styles}>{firstLetter}</div>;
};

export default Avatar;
