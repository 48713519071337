// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-check {
    display: inline-block;
    width: auto;
    margin-right: 10px;
}

.section-underline {
    border-bottom: 1px solid lightgrey;
    padding-bottom: 20px;
    margin-bottom: 20px;
}
.filter_section{
    width:19%;
}


.toggle_button {
    min-width: 54px;
    height: 26px;
    border-radius: 28px;
    position: relative;
    border: 2px solid #8bdcf0; 
    background-color: rgb(255, 255, 255);
}
.toggle_button.active{
    border: 2px solid #00ff00; 
    background-color: #00ff00;
    box-shadow:none;
}

.toggle_button.active::after{
transition: 0.3s;
left: 57%;
background-color: #ffffff;
}
.toggle_button::after{
    content: '';
    width: 20px;
    height: 20px;
    background-color: #8bdcf0;
    position: absolute;
    left: 2px;
    top: 50%;
    transform: translate(0%, -50%);
    border-radius: 100px;
    transition: 0.3s;
}
#userType{
        margin: 0px 0px 20px 10px;
        min-width: 150px;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/user/UserCreate.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,kCAAkC;IAClC,oBAAoB;IACpB,mBAAmB;AACvB;AACA;IACI,SAAS;AACb;;;AAGA;IACI,eAAe;IACf,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;IAClB,yBAAyB;IACzB,oCAAoC;AACxC;AACA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,eAAe;AACnB;;AAEA;AACA,gBAAgB;AAChB,SAAS;AACT,yBAAyB;AACzB;AACA;IACI,WAAW;IACX,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,SAAS;IACT,QAAQ;IACR,8BAA8B;IAC9B,oBAAoB;IACpB,gBAAgB;AACpB;AACA;QACQ,yBAAyB;QACzB,gBAAgB;AACxB","sourcesContent":[".form-check {\n    display: inline-block;\n    width: auto;\n    margin-right: 10px;\n}\n\n.section-underline {\n    border-bottom: 1px solid lightgrey;\n    padding-bottom: 20px;\n    margin-bottom: 20px;\n}\n.filter_section{\n    width:19%;\n}\n\n\n.toggle_button {\n    min-width: 54px;\n    height: 26px;\n    border-radius: 28px;\n    position: relative;\n    border: 2px solid #8bdcf0; \n    background-color: rgb(255, 255, 255);\n}\n.toggle_button.active{\n    border: 2px solid #00ff00; \n    background-color: #00ff00;\n    box-shadow:none;\n}\n\n.toggle_button.active::after{\ntransition: 0.3s;\nleft: 57%;\nbackground-color: #ffffff;\n}\n.toggle_button::after{\n    content: '';\n    width: 20px;\n    height: 20px;\n    background-color: #8bdcf0;\n    position: absolute;\n    left: 2px;\n    top: 50%;\n    transform: translate(0%, -50%);\n    border-radius: 100px;\n    transition: 0.3s;\n}\n#userType{\n        margin: 0px 0px 20px 10px;\n        min-width: 150px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
