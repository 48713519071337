import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { nextUpdateDetails, updateQualification, updateStep, validData } from "../../../Redux/Candidate/createCandidate/action";
import Spiner1 from "../../Spiner1";
import { toast } from "react-toastify";

const EducationalData = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const qualifications = useSelector((state)=> state.createQualification);
  const candidateResponse = useSelector((state)=> state.addResponseReducer.data);
  const candidateData = useSelector((state)=> state.createCandidate);
  const upgaradeCandidate = useSelector((state) => state.updateCandidateResponse);
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 30;
  const endYear = currentYear;
  const year = [];
  for (let years = startYear; years <= endYear; years++) {
    year.push(years);
  }

  // Start Washim Code
    const handleAddQualification = () => {
  let prevQualification = [...qualifications?.qualifications];
  const lastQualification = prevQualification[prevQualification.length - 1];

  if (lastQualification.degree === "" && lastQualification.institute === "") {
    toast.error("Please enter qualification and institute name");
  } else if (lastQualification.degree === "") {
    toast.error("Please fill qualification name");
  } else if (lastQualification.institute === "") {
    toast.error("Please fill institute name");
  } else {
    prevQualification.push({
      degree: "",
      institute: "",
      percentage: "",
      session_end: "",
      session_start: "",
      status: [],
    });
    dispatch(updateQualification(prevQualification));
  }
};


    const handleDegree = (e,index)=>{
      let prevQualifications = [...qualifications?.qualifications];
      prevQualifications[index].degree = e.target.value;
      dispatch(updateQualification(prevQualifications));
    }
    const handleInstitute = (e,index)=> {
      let prevQualifications = [...qualifications?.qualifications];
      prevQualifications[index].institute = e.target.value;
      dispatch(updateQualification(prevQualifications));
    }
    const handleYear = (e, index) => {
      let prevQualifications = [...qualifications?.qualifications];
      prevQualifications[index].session_end = e.target.value;
      dispatch(updateQualification(prevQualifications));
    }
    const handlePercentag = (e, index) => {
      e.preventDefault()
      let prevQualifications = [...qualifications?.qualifications];
      prevQualifications[index].percentage =/^[0-9]*\.?[0-9]*$/.test(e.target.value)&&parseFloat(e.target.value)<101? e.target.value:""
      dispatch(updateQualification(prevQualifications));
    }
    const handleStatus = (e, index) => {
      let prevQualifications = [...qualifications?.qualifications];
      prevQualifications[index].status[0] = e.target.value;
      dispatch(updateQualification(prevQualifications));
    }
    const handleRemove = (e, indexp) => {
    let prevQualifications = [...qualifications?.qualifications];
    if (prevQualifications?.length > 1) {
      prevQualifications = prevQualifications.filter((element, index) => index !== indexp);
      dispatch(updateQualification(prevQualifications));
    }
}
    const handleReset = (e, indexp) => {
    let prevQualifications = [...qualifications?.qualifications];
      prevQualifications[indexp].degree=""
      prevQualifications[indexp].institute=""
      prevQualifications[indexp].percentage=""
      prevQualifications[indexp].session_end=""
      prevQualifications[indexp].session_start=""
      prevQualifications[indexp].status=[]
      dispatch(updateQualification(prevQualifications));
}
  // End Washim Code

  const handleNext=()=>{
    const nextTab="Hardware"
    const data = {
      first_name: candidateData.first_name,
      last_name: candidateData.last_name,
      lead_source: candidateData.lead_source,
      designation_experience: candidateData.designation_experience,
      designation: candidateData.designation,
      resume:candidateData.resume ?candidateData.resume :"",
      qualifications: qualifications.qualifications,
    };
      dispatch(nextUpdateDetails(navigate,nextTab,candidateResponse?.candidate,data))
  }


  return (
    <div className="tab-data mt-4" step={0}>
      <div className="qualification mb-4 mt-3">
        <div className="d-flex align-items-center justify-content-between">
          <p className="txt__sm fw-bold mb-3"></p>
          {qualifications?.qualifications?.length <= 1 && (
            <button
              type="button"
              className="button-outline mb-3 me-1"
              onClick={() => {
                handleAddQualification();
              }}
            >
              Add Qualification
            </button>
              )}

        </div>
      
    {
      qualifications?.qualifications?.map((data,index)=> (
        <div key={index} className="row g-3 mb-3">
          <p className="txt__sm fw-bold">Qualification{" "}{index+1}</p>
          <div className="col-sm-6">
            <div className="grid-box">
              <div className="form-control-box">
                <input
                  type="text"
                  placeholder="Type your Qualification"
                  className="form-control-no-border"
                  value={data.degree}
                  onChange={(e) => {
                    handleDegree(e, index)
                  }}
                />
              </div>
            </div>
          </div>

          <div className="col-sm-6">
            <div className="grid-box">
              <div className="form-control-box">
                <input
                  type="text"
                  placeholder="Institution name"
                  className="form-control-no-border"
                  value={data?.institute}
                  onChange={(e) => {
                    handleInstitute(e, index)
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="grid-box">
              <div className="form-control-box">
                <select
                  placeholder="Select your passout year"
                  style={!data?.session_end ? {color: " rgb(185 181 179 / 93%)" }:null}
                  className="form-control-no-border form-control-no-border-select"
                  value={data?.session_end}
                  onChange={(e) => {
                    handleYear(e, index)
                  }}
                >
                  <option defaultValue >Passout Year</option>
                  {year.map((el, i) => (
                    <option key={i} value={el}>{el}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            {" "}
            <div className="grid-box">
              <div className="form-control-box">
                <input
                  type="text"
                  placeholder="Percentage"
                  className="form-control-no-border"
                  value={data?.percentage}
                  onChange={(e) => {
                     const value = e.target.value;
                      if (/^[0-9]*$/.test(value) && value >= 0 && value <= 100) {
                    handlePercentag(e, index)
                      }
                  }}
                  onWheel={(e) => {
                    e.preventDefault();
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row d-flex mb-4">
            <div className="col-sm-6 ml-2 mt-2">
                <span className="me-3">
                  <input
                    name="Complete"
                    type="checkbox"
                    value="Complete"
                    className="me-1"
                    checked={data?.status != "Complete" ? false : true}
                    onChange={(e) => {
                      handleStatus(e, index);
                    }}
                  />
                  Completed
                </span>
                <span className="me-3">
                
                  <input
                    name="Pursuing"
                    type="checkbox"
                    className="me-1"
                    value="Pursuing"
                    checked={data?.status != "Pursuing" ? false : true}
                    onChange={(e) => {
                      handleStatus(e, index);
                    }}
                  />
                  Pursuing
                </span>
            </div>
            
            <div className=" d-flex justify-content-between"> <button
              type="button"
              className="nav-link Next_qu me-4 mt-3"
              onClick={(e) => { index>0?handleRemove(e,index):handleReset(e,index) }}
            >
            Remove
            </button>
            {index>0 && index === qualifications.qualifications.length - 1? <button type="button" className="button-outline my-3 me-1" 
          onClick={() => { handleAddQualification() }}
          >
            Add Qualification
          </button>: null}</div>
          </div>
        </div>
      ))
    }
     
        
      
      </div>
      <div className="d-flex justify-content-between">
        <button
          type="button"
          className="nav-link Next_qu me-4"
          onClick={() => {
            dispatch(updateStep("General"));
          }}
        >
          Previous
        </button>
        <button
          type="button"
          disabled={upgaradeCandidate?.isLoading}
          className="nav-link Next_qu"
          onClick={() => {
            handleNext();
          }}
        >
          {!upgaradeCandidate?.isLoading? "Next" : <Spiner1 />}
        </button>
      </div>
    </div>
  );
};

export default EducationalData;
