import React from 'react';

const StatisticsCard = ({ title, value, image }) => {
  return (
    <div className="lt-box-block  p-3 h-100 ">
     <div className="card my-2">
       <div className="card-body">
         <h5 className="card-title">{title}</h5>
         <div className="card-text">{value}</div>
       </div>
     </div>
        <h2 className=''>
          {value}<span><img src={image} alt="" /></span>
        </h2>
        <h6>{title}</h6>
      

    </div>
  );
};

export default StatisticsCard;
