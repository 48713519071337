import React, { useState } from 'react';
import Select from 'react-select';

const colourOptions = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
]

const colourOption2 = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
]

const colourOption3 = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
]

const ScreeningQuestion = () => {


    return (
        <>
            <div className="profile_items_box_content overflow-y-auto py-3 ">
                <div className="screeningQuestion_content">

                    <div className="container-fluid">
                        <div className="row g-2">

                            <div className="col-12 col-sm-6 col-md-4 col-lg-6 col-xxl-4">
                                <Select
                                    placeholder="Select Designation"
                                    defaultValue={[colourOptions[2]]}
                                    isMulti
                                    name="colors"
                                    options={colourOptions}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                />
                            </div>

                            <div className="col-12 col-sm-6 col-md-4 col-lg-6 col-xxl-4">
                                <Select
                                    placeholder="Skill"
                                    defaultValue={[colourOption2[2]]}
                                    isMulti
                                    name="colors"
                                    options={colourOption2}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                />
                            </div>

                            <div className="col-12 col-sm-6 col-md-4 col-lg-6 col-xxl-4">
                                <Select
                                    placeholder="Levels"
                                    defaultValue={[colourOption3[3]]}
                                    isMulti
                                    name="colors"
                                    options={colourOption3}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                />
                            </div>



                            <div className="col-md-12 py-3">
                                <button type="button" className="button-outline choosed">
                                    Reset
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 mb-3">
                                <div className="bg__light p-2">
                                    <span className="txt__sm fw-bold">Total questions:</span> <span className="txt__sm">31</span>
                                </div>
                            </div>
                            <div className="col-md-4 mb-3">
                                <div className="bg__light p-2">
                                    <span className="txt__sm fw-bold">My added questions:
                                    </span> <span className="txt__sm">31</span>
                                </div>
                            </div>
                            <div className="col-md-4 mb-3">
                                <div className="bg__light p-2">
                                    <span className="txt__sm fw-bold">Total added questions:</span> <span className="txt__sm">31</span>
                                </div>
                            </div>
                        </div>

                        <div className="scren-box border-top py-3">
                            <div className="row">
                                <div className="col">
                                    <h4 className='que'><span style={{ color: '#3ab2e4', marginRight: '12px' }}>Q.</span>
                                        what is the full form of rgb?
                                    </h4>
                                </div>
                                <div className="col-auto"><span className="filter_tag filter-tag-lg">
                                    Easy
                                </span>
                                </div>
                            </div>
                            <h3 className='que_ans'><span>A.</span>
                                Red, Green, and Blue
                            </h3>
                            <div className="d-flex align-items-center flex-wrap mt-2"></div>
                            <div className="d-flex align-items-center mt-3">
                                <div>
                                    <div><button type="button" className="button-outline choosed me-2">
                                        Add to Cheat Sheet
                                    </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="scren-box border-top py-3">
                            <div className="row">
                                <div className="col">
                                    <h4 className='que'><span style={{ color: '#3ab2e4', marginRight: '12px' }}>Q.</span>
                                        what is the full form of rgb?
                                    </h4>
                                </div>
                                <div className="col-auto"><span className="filter_tag filter-tag-lg">
                                    Easy
                                </span>
                                </div>
                            </div>
                            <h3 className='que_ans'><span>A.</span>
                                Red, Green, and Blue
                            </h3>
                            <div className="d-flex align-items-center flex-wrap mt-2"></div>
                            <div className="d-flex align-items-center mt-3">
                                <div>
                                    <div><button type="button" className="button-outline choosed me-2">
                                        Add to Cheat Sheet
                                    </button>
                                    </div>
                                </div>
                            </div>
                        </div>   <div className="scren-box border-top py-3">
                            <div className="row">
                                <div className="col">
                                    <h4 className='que'><span style={{ color: '#3ab2e4', marginRight: '12px' }}>Q.</span>
                                        what is the full form of rgb?
                                    </h4>
                                </div>
                                <div className="col-auto"><span className="filter_tag filter-tag-lg">
                                    Easy
                                </span>
                                </div>
                            </div>
                            <h3 className='que_ans'><span>A.</span>
                                Red, Green, and Blue
                            </h3>
                            <div className="d-flex align-items-center flex-wrap mt-2"></div>
                            <div className="d-flex align-items-center mt-3">
                                <div>
                                    <div><button type="button" className="button-outline choosed me-2">
                                        Add to Cheat Sheet
                                    </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div >
        </>
    )
}

export default ScreeningQuestion;