import { JOB_DETAILS_REQUEST, JOB_DETAILS_SUCCESS } from "./actionType";

const initialState = {
  jobDetails: [],

  isLoading: false,
  isError: false,
};

export const viewJobDetails = (state = initialState, action) => {
  switch (action.type) {
    case JOB_DETAILS_REQUEST: {
      return {
        ...state,
        jobDetails: [],
        isLoading: true,
        isError: false,
      };
    }
    case JOB_DETAILS_SUCCESS: {
      return {
        ...state,
        jobDetails: action.payload,
        isLoading: false,
        isError: false,
      };
    }

    default: {
      return state;
    }
  }
};
