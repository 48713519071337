import axios from "axios";
import { UPDATE_PROFILE_IMAGE_FAILURE, UPDATE_PROFILE_IMAGE_REQUEST, UPDATE_PROFILE_IMAGE_SUCCESS } from "./actionType";
import { errorCall } from "../ErrorHandling/action";

export const update_profile_image_req = () => ({
    type: UPDATE_PROFILE_IMAGE_REQUEST
});

export const update_profile_image_succ = (data) => ({
    type: UPDATE_PROFILE_IMAGE_SUCCESS,
    payload: data
})
export const update_profile_image_failure = (err) => ({
    type: UPDATE_PROFILE_IMAGE_FAILURE,
    payload: err
})

export const updateProfileImage = (data,navigate) => async (dispatch) => {
    dispatch(update_profile_image_req());
    const config = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
    };
    const formData = new FormData();
    formData.append('profile_image', data.Img); 
    // const formdata = {
    //     form: {
    //         'framework': framework,
    //         'name': name,
    //         'skillId': skillId,
    //         'status': status
    //     }
    // }
        return await axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/candidate/profile/${data?.id}`, formData, config).then((res) => {
            
        dispatch(update_profile_image_succ(res?.data))
        return res
    }).catch((err) => {
        if (err?.message === "Network Error" || err?.response?.status === 500  || err?.response?.status === 404) {
            dispatch(errorCall(err))
            return;
        }  
        if (err?.response?.data?.error == "Unauthenticated.") {
            localStorage.removeItem("token");
            navigate("/login");
        }
        dispatch(update_profile_image_failure(err))
    })
}









