import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';

const Permission = (isAuthenticated) => {
  const array = ["65968372f78bc5ceb87bc1d1", "65968372f78bc5ceb87bc1d2"];
  const usersPermission = localStorage?.getItem("userPermission")
  const permissionList = usersPermission?.split(',');

  return (
    <>
      {/* {array?.includes(isAuthenticated.isAuthesnticated) ? <Outlet /> : <Navigate to="*" replace />} */}
      {permissionList?.some(element => isAuthenticated?.id?.includes(element)) ? <Outlet /> : <Navigate to="*" replace />}
    </>
  )
}

export default Permission