import axios from "axios";
import { CANDIDATE_EDIT_FAILURE, CANDIDATE_EDIT_REQUEST, CANDIDATE_EDIT_SUCCESS } from "./actionType";
import { errorCall } from "../../ErrorHandling/action";

export const edit_candidate_req = ()=>({
    type: CANDIDATE_EDIT_REQUEST
});

export const edit_candidate_succ = (data)=>({
    type: CANDIDATE_EDIT_SUCCESS,
    payload : data
})

export const edit_candidate_failure = (err)=>({
    type: CANDIDATE_EDIT_FAILURE,
    payload : err
})

const getLocaldata = ()=>{
    const token = localStorage.getItem("token");
    return token
}

export const editCandidte = (data, navigate,toast)=>(dispatch)=>{

    try{
        const newData={...data}
  const updatedData = { ...newData };
const axiosCall=(data)=>{
    dispatch( edit_candidate_req());
    const mt = getLocaldata();
    const config = {
        headers: { Authorization: `Bearer ${mt}` }
      };
    axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/candidate_update_contact`,data,config).then((res)=>{
        toast.success("Updated Successfully");
        dispatch(edit_candidate_succ(res.data))
    }).catch((err)=>{
        dispatch(edit_candidate_failure(err));
        if (err.response?.data?.error === "Unauthenticated.") {
            localStorage.removeItem("token");
            navigate("/login");
            return
        }
        if (err.message === "Network Error" || err.response.status === 500) {
            dispatch(errorCall(err))
            return
        }
    })
    
}
    const reader = new FileReader();
    reader.onload = function (event) {
    updatedData.resume = event.target.result

        axiosCall(updatedData)
    }
    if (updatedData.resume && updatedData?.resume?.type && updatedData?.resume?.type ) {
        reader.readAsDataURL(updatedData.resume);
      } else {
        axiosCall(newData);
      }
} catch(error){
console.log(error);
}

}





