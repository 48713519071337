import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const ApplyPopUp = ({ show, setShow }) => {
  const handleShow = () => {
    setShow(false);

    window.location.replace("https://klizos.com/careers/");
  };
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      animation={false}
      className="pop"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="pop-body">Form Submitted Successfully</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => handleShow()}>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ApplyPopUp;
