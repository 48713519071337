import axios from "axios";
import { LOGIN_USER_FAILURE, LOGIN_USER_REQUEST, LOGIN_USER_SUCCESS, LOGOUT_USER_REQUEST, LOGOUT_USER_SUCCESS, LOGOUT_USER_FAILURE, TOKEN_USER_REQUEST, TOKEN_USER_SUCCESS, TOKEN_USER_FAILURE } from "./actionType";
import { useNavigate } from "react-router-dom";
import { errorCall } from "../ErrorHandling/action";

export const login_req = () => ({
    type: LOGIN_USER_REQUEST
});

export const login_succ = (data) => ({
    type: LOGIN_USER_SUCCESS,
    payload: data
})
export const login_failure = (err) => ({
    type: LOGIN_USER_FAILURE,
    payload: err
})

// export const loginCall = (email, password) => (dispatch) => {
//     dispatch(login_req());
//     axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/login`, { email: email, password: password })
//         .then((response) => {
//             // localStorage.setItem("token", loginData.data);
//             dispatch(login_succ(response.data.access_token));
//             return response;
//         })
//         .catch((error) => {
//             dispatch(login_failure(error?.response?.data))
//         });
// }



export const loginCall = (cipher, navigate) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(login_req());
        axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/securelogin`, { cipher })
            .then((response) => {
                if(response?.data?.access_token != undefined && response?.data?.access_token != null && response?.data?.access_token != '' ){
                    dispatch(login_succ(response?.data?.access_token));
                    resolve(response);
                }else{
                    dispatch(login_failure("Unable to generate access token"));
                    reject({token_error:"Something went wrong,unable to generate access token"})
                }
                
            })
            .catch((error) => {
                if (error?.message === "Network Error" || error?.response?.status === 500) {
                    dispatch(errorCall(error))
                    return;
                }
                dispatch(login_failure(error?.response?.data));
                reject(error);
            });
    });
};



export const logout_req = () => ({
    type: LOGOUT_USER_REQUEST
});

export const logout_succ = (data) => ({
    type: LOGOUT_USER_SUCCESS,
    payload: data
})
export const logout_failure = (err) => ({
    type: LOGOUT_USER_FAILURE,
    payload: err
})


// export const logoutCall = (navigate) => (dispatch) => {
//     dispatch(logout_req());
//     axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/logout`, {}, {
//         headers: {
//             Authorization: `Bearer ${localStorage.getItem("token")}`,
//         },
//     })
//         .then((response) => {
//             dispatch(logout_succ(response.data));
//             localStorage.removeItem("token");
//             navigate('/login')
//         })
//         .catch((error) => {
//             dispatch(logout_failure(error?.response?.data))
//         });
// }




export const logoutCall = (navigate) => (dispatch) => {
    dispatch(logout_req());
    axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/logout`, {}, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    }).then((response) => {
        localStorage.removeItem("token");
        if (response?.data?.message == 'User successfully signed out') {
            localStorage.removeItem("token");
            localStorage.removeItem("userPermission");
            navigate('/login')
        }
        // dispatch(logout_succ(response.data));
    })
        .catch((error) => {
            if (error?.response?.data?.error == "Unauthenticated.") {
                localStorage.removeItem("token");
                navigate("/login");
            } 
            else if (error?.message === "Network Error" || error?.response?.status === 500) {
                dispatch(errorCall(error))
                return;
            }
            dispatch(logout_failure(error));
        });
};


export const token_req = () => ({
    type: TOKEN_USER_REQUEST
});

export const token_succ = (data) => ({
    type: TOKEN_USER_SUCCESS,
    payload: data
})
export const token_failure = (err) => ({
    type: TOKEN_USER_FAILURE,
    payload: err
})


export const tokenCall = (grantType, username, password, navigate) => (dispatch) => {
    dispatch(token_req());
    axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/token`, { grant_type: grantType, username: username, password: password })
        .then((response) => {
            dispatch(token_succ(response?.data?.access_token));
            return response;
        })
        .catch((error) => {
            if (error?.message === "Network Error" || error?.response?.status === 500) {
                dispatch(errorCall(error))
                return;
            }
            dispatch(token_failure(error))
        });
} 