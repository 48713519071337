import React, { useCallback, useEffect, useState } from 'react';

import './setting/setting.css';
import SettingsNavbar from '../Components/SettingsNavbar';
import { useDispatch, useSelector } from 'react-redux';
import { skilList, skillShort } from '../Redux/settings/skill/action';
import { Link, useNavigate } from 'react-router-dom';
import Avatar from '../Components/settings/Avatar';
import NetworkErrorPage from './error/error';
import { globalDebouncing } from '../Debouncing/globalDebouncing';
import Paginate from '../TestPortal/components/Pagination/Pagination';
import { Page } from 'react-pdf';




const Skill = ({ Sidedata, onSidedataChange }) => {
    const [search, setSearch] = useState("");
    const [status, setStatus] = useState("");
    const [type, setType] = useState("");  // filter using type is not in used now
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const skillList = useSelector(state => state.getSkillSet);
    const totalPages = useSelector(
        (state) => state.getSkillSet.data.total_page
    );
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const debounce = (func, delay) => {
        let timeoutId;
        return function (...args) {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    const debounceDelay = 800; // Adjust the delay as per your requirement

    const debouncedSearch = useCallback(
        debounce((value, status, type) => {
            dispatch(skillShort(navigate, value, status, type));
        }, debounceDelay),
        []
    );

    const usersPermission = localStorage?.getItem("userPermission");
    const permissionList = usersPermission?.split(',');

    const handleInputChange = (e) => {
        setSearch(e.target.value);
        globalDebouncing(dispatch, skillShort, navigate, e.target.value, status, type, 1, perPage);
        setPage(1);
    };
    const handlestatusInputChange = (e) => {
        setStatus(e.target.value);
        setPage(1);
    };
    const handleTypeInputChange = (e) => {
        setType(e.target.value);
        setPage(1);
    };
    const pageChange = (e) => {
        setPage(e);
        dispatch(skillShort(navigate, search, status, type, e, perPage));
    };
    const handlePageSizeChange = (e) => {
        setPage(1);
        setPerPage(parseInt(e.target.value));
    }
    const clearfilter = (e) => {
        e.preventDefault();
        setPage(1);
        setType("");
        setSearch("");
        setStatus("");
        dispatch(skillShort(navigate, null, null, null, 1, perPage));
    };
    const deleteSkill = (e, id) => {
        e.preventDefault();
    };
    useEffect(() => {
        dispatch(skillShort(navigate, search, status, type, page, perPage));
    }, [status, type, perPage]);


    return (
        <div>
            {/* {skillList?.isError && skillList?.errorData?.message === "Network Error" || skillList?.response?.status === 500 ? <NetworkErrorPage/> :  <>*/}
            <SettingsNavbar newSidedata={Sidedata} newonSidedataChange={onSidedataChange} navdata={{ header: "Skill", buttonText: "Skill Button", buttonPath: "/settings/skillsuggetion" }} />
            <div className="container-fluid">
                <div className="row g-3">
                    {permissionList?.includes("65bb92c853e762c89b9af16a") &&
                        <div className="col-md-12">
                            <div className="companyAdmin_action_area mt-4">
                                <div className="add_companyAdmins">
                                    <Link to={"/settings/add-skill"} className="add_companyAdmin_link d-flex align-items-cente txt__sm ">
                                        <span className="icon d-flex align-items-center">
                                            <svg
                                                width="14px"
                                                height="16px"
                                                viewBox="0 0 14 16"
                                                version="1.1"
                                                className='me-2'
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                            >
                                                <g id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                    <g id="add-skill" fill="#000000" fillRule="nonzero">
                                                        <path
                                                            d="M7,7 C7.27614237,7 7.5,7.22385763 7.5,7.5 L7.5,9 L9,9 C9.27614237,9 9.5,9.22385763 9.5,9.5 C9.5,9.77614237 9.27614237,10 9,10 L7.5,10 L7.5,11.5 C7.5,11.7761424 7.27614237,12 7,12 C6.72385763,12 6.5,11.7761424 6.5,11.5 L6.5,10 L5,10 C4.72385763,10 4.5,9.77614237 4.5,9.5 C4.5,9.22385763 4.72385763,9 5,9 L6.5,9 L6.5,7.5 C6.5,7.22385763 6.72385763,7 7,7 Z"
                                                            id="Path"
                                                        />{" "}
                                                        <path
                                                            d="M3,1.5 L2,1.5 C0.8954305,1.5 0,2.3954305 0,3.5 L0,14 C0,15.1045695 0.8954305,16 2,16 L12,16 C13.1045695,16 14,15.1045695 14,14 L14,3.5 C14,2.3954305 13.1045695,1.5 12,1.5 L11,1.5 L11,2.5 L12,2.5 C12.5522847,2.5 13,2.94771525 13,3.5 L13,14 C13,14.5522847 12.5522847,15 12,15 L2,15 C1.44771525,15 1,14.5522847 1,14 L1,3.5 C1,2.94771525 1.44771525,2.5 2,2.5 L3,2.5 L3,1.5 Z"
                                                            id="Path"
                                                        />{" "}
                                                        <path
                                                            d="M8.5,1 C8.77614237,1 9,1.22385763 9,1.5 L9,2.5 C9,2.77614237 8.77614237,3 8.5,3 L5.5,3 C5.22385763,3 5,2.77614237 5,2.5 L5,1.5 C5,1.22385763 5.22385763,1 5.5,1 L8.5,1 Z M5.5,0 C4.67157288,0 4,0.671572875 4,1.5 L4,2.5 C4,3.32842712 4.67157288,4 5.5,4 L8.5,4 C9.32842712,4 10,3.32842712 10,2.5 L10,1.5 C10,0.671572875 9.32842712,0 8.5,0 L5.5,0 Z"
                                                            id="Shape"
                                                        />
                                                    </g>
                                                </g>
                                            </svg>
                                            Add New Skill
                                        </span>
                                    </Link>

                                </div>
                            </div>
                        </div>
                    }
                    {/* start form section */}
                    <div className="col-12">
                        <div className="bg-white p-3 rounded">

                            <div className="row g-2">
                                <div className="col-md-4">
                                    <div className="position-relative list_search_fild">
                                        <input type="text" onChange={(e) => handleInputChange(e)} name="query" placeholder="Search"
                                            aria-label="" className="form-control h-100" value={search} />
                                        <button className="job_list_search_box">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                fill="currentColor" viewBox="0 0 16 16" className="bi bi-search">
                                                <path
                                                    d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z">
                                                </path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <select className="col-sm-4  form-control form-control-custom" value={status} onChange={(e) => handlestatusInputChange(e)}>
                                        <option value="" hidden>Select status</option>
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                    </select>
                                </div>

                                <div className="col-9 col-md-3  d-flex align-items-center">
                                    {
                                        search === "" &&
                                            type === "" &&
                                            status === "" ? null :
                                            <button className="button_remove" onClick={clearfilter}>Clear Filter</button>
                                    }
                                </div>

                                <div className="col-3 col-md-1 d-flex align-items-center justify-content-end">
                                    <select className="col-sm-4  form-control form-control-custom" value={perPage}  onChange={(e) => handlePageSizeChange(e)} style={{ width: '61px' }}>
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                    </select>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* end form section */}

                    {/* start table section */}
                    <div className="col-12">
                        <div className="table_box p-3">
                            <div className="table-responsive ">
                                <table className="table  table-striped setting_ table-striped-border">
                                    <thead>
                                        <tr>
                                            <th className="txt__md  fw-bold">Skills</th>
                                            <th className="txt__md  fw-bold text-center text-md-start">Frameworks</th>
                                            <th className="txt__md  fw-bold text-center text-md-start">Status</th>
                                            {permissionList?.includes("65bb930e53e762c89b9af16b") &&
                                                <th className="txt__md  fw-bold text-center">Actions</th>
                                            }
                                        </tr>
                                    </thead>

                                    {
                                        skillList?.isLoading && !skillList?.isError ?
                                            <tbody>
                                                <tr>
                                                    <td colSpan={4}>
                                                        <div className='text-center py-3'>
                                                            <span className="loader"></span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            : !skillList?.isLoading && !skillList?.isError ?
                                                <tbody>
                                                    {skillList?.data?.data?.map((data, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    {/* <div className="user_initial">A</div> */}
                                                                    <Avatar name={data?.name} size={34} />
                                                                    <p className=" mb-0 txt__para txt__sm">{data?.name}</p>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="d-flex align-items-center justify-content-center justify-content-md-start flex-wrap ">
                                                                    {data?.linked_framework?.length > 0 ?
                                                                        data?.linked_framework?.map((fameworks, i) => (
                                                                                <div  key={i+index} >
                                                                                <span className={`text-center text-md-start ${fameworks.status==0?"text-danger":"text-success"}`}style={{ fontSize: '12px'}}>
                                                                                    &nbsp;{`${fameworks.name} `}
                                                                                     {i !== data.linked_framework.length - 1 && ","}
                                                                                </span>
                                                                                </div>
                                                                        )) :
                                                                        <span>

                                                                        </span>
                                                                    }
                                                                </div>
                                                            </td>

                                                            {data?.status == 1 ? (
                                                                <td className="align-start text-success text-center text-md-start" style={{whiteSpace:'nowrap'}}>
                                                                    <span className="statusBadge bg-success"></span>
                                                                    Active
                                                                </td>
                                                            ) : (
                                                                <td className="align-start text-danger text-center text-md-start" style={{whiteSpace:'nowrap'}}>
                                                                    <span className="statusBadge bg-danger"></span>
                                                                    Inactive
                                                                </td>
                                                            )}
                                                            {permissionList?.includes("65bb930e53e762c89b9af16b") &&
                                                                <td className="text-center">
                                                                    <a
                                                                        href="/admin/source/61badb881f42ac719822abe3/update"
                                                                        className=""
                                                                    ></a>
                                                                    <Link to={`/settings/update-skill/${data.id}`}>
                                                                        <svg
                                                                            width={15}
                                                                            height={15}
                                                                            className='edit_svg'
                                                                            viewBox="0 0 15 15"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                fillRule="evenodd"
                                                                                clipRule="evenodd"
                                                                                d="M14.502 2.64639C14.6965 2.45126 14.6965 2.13553 14.502 1.94039V1.93939L13.209 0.646393C13.0137 0.451202 12.6972 0.451202 12.502 0.646393L11.459 1.69039L13.459 3.69039L14.502 2.64639ZM10.752 2.39639L12.752 4.39639V4.39739L5.93897 11.2114C5.88384 11.2661 5.81673 11.3072 5.74297 11.3314L3.32897 12.1364C3.23921 12.1662 3.14034 12.1428 3.07347 12.0759C3.00661 12.009 2.98319 11.9101 3.01297 11.8204L3.81797 9.40639C3.8425 9.33253 3.88393 9.26542 3.93897 9.21039L10.752 2.39639Z"
                                                                                fill="#708098"
                                                                            />
                                                                            <path
                                                                                d="M0 13.5C0 14.3284 0.671573 15 1.5 15H12.5C13.3284 15 14 14.3284 14 13.5V7.5C14 7.22386 13.7761 7 13.5 7C13.2239 7 13 7.22386 13 7.5V13.5C13 13.7761 12.7761 14 12.5 14H1.5C1.22386 14 1 13.7761 1 13.5V2.5C1 2.22386 1.22386 2 1.5 2H8C8.27614 2 8.5 1.77614 8.5 1.5C8.5 1.22386 8.27614 1 8 1H1.5C0.671573 1 0 1.67157 0 2.5L0 13.5Z"
                                                                                fill="#708098"
                                                                            />
                                                                        </svg>
                                                                    </Link>
                                                                    {/* <a href='#' onClick={(e)=>deleteSkill(e,data.id)}>
                                                                    <i className="fa-regular fa-trash-can"></i>
                                                                </a> */}
                                                                </td>
                                                            }


                                                        </tr>


                                                    ))}
                                                </tbody> :
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={4}>
                                                            <p className=' text-center'>No Data</p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                        // <NetworkErrorPage/>
                                    }
                                </table>
                            </div>
                        </div>
                    </div>
                    {totalPages == undefined ? null : (
                        <>
                            <div className="parentPage">
                                <Paginate
                                    currentPage={page}
                                    totalPages={totalPages}
                                    onPageChange={(e) => pageChange(e)}
                                />
                            </div>
                        </>
                    )}
                    <div className="pagination-container"></div>
                </div>
            </div>
            {/* </>} */}
        </div>
    )
}

export default Skill
