import { FIND_EXPERIENCE_FAILURE, FIND_EXPERIENCE_REQUEST, FIND_EXPERIENCE_SUCCESS } from "./actionType"

const initialState = {
    isLoading: false,
    data: [],
    isError: false,
    errorData: ""
}

export const findExperiences = (state = initialState,action)=>{
     switch(action.type){
        case FIND_EXPERIENCE_REQUEST:{
           return{
            isLoading : true,
            data : [],
            isError : false
           }
        }
        case FIND_EXPERIENCE_SUCCESS:{
            return{
                isLoading : false,
                data : action.payload,
                isError : false,
            }
        }
        case FIND_EXPERIENCE_FAILURE:{
            return{
                isLoading : false,
                data : [],
                isError : true,
                errorData:action.payload
            }
        }
        default : {
            return state
         }
     }
}