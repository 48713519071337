import React, { useEffect, useState, useRef, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import ProfileDetails from "./ProfileDetails";

import ScreeningQuestion from "./ScreeningQuestion";
import TestResults from "./TestResults";
import CallRecording from "./CallRecording";
import Note from "./Note";
import CheatSheet from "./CheatSheet";
import { useDispatch, useSelector } from "react-redux";
import { candidate } from "../../Redux/CandidateReducer/action";
import { GetCandidateData, GetStatusData } from "../../Redux/Tempcandidate/action";
import axios from "axios";
import { toast } from "react-toastify";
import './candidate.css';
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';
import Select from 'react-select'
import { errorCall } from "../../Redux/ErrorHandling/action";
import InfiniteScroll from "./InfiniteScroll";
import { BiFullscreen } from "react-icons/bi";
import { BiExitFullscreen } from "react-icons/bi";
import { debouncedHandleSearch } from "../../Debouncing/globalDebouncing";

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' }
]


const Detailview = ({ viewList }) => {
  const { setSearch } = viewList
  const [showData, setShowData] = useState(false)
  const navigate = useNavigate();

  const [page, setPage] = useState(1)
  const [isFetching, setIsFetching] = useState(false)
  const dispatch = useDispatch();
  // const [searchData, setSearchData] = useState("");
  const [sort, setSort] = useState("");
  const [statusChange, setStatusChange] = useState(false);
  const [firstApiHit, setFirstApiHit] = useState(0);
  const [detailShow, setDetailShow] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);

  const candidateLists = useSelector(state => state.Candidate_Reducer);

  var candidateLoading = useSelector(state => state.Candidate_Reducer.isCandidateLoading);
  const candidateLoadingStatus = useSelector(state => state.Candidate_Reducer.jobStatus);
  const [candidateArray, setCandidateArray] = useState([]);
  const tempCandidate = useSelector(state => state?.tempCandidate?.data);
  const dropdownData = useSelector(state => state?.tempDropdown?.status);
  const usersPermission = localStorage?.getItem("userPermission")
  const permissionList = usersPermission?.split(',');

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const format = 'h:mm a';

  const [title, setTitle] = useState('');
  const [startDate, setStartDate] = useState("");
  const [interviewMode, setInterviewMode] = useState('');
  const [interviewType, setInterviewType] = useState('interview');
  const [interviewTime, setInterviewTime] = useState('');
  const [candidateEmail, setCandidateEmail] = useState("");
  const [interviewers, setInterviewers] = useState([{ id: 1, email: '' }]);
  const [link, setLink] = useState('');
  const [note, setNote] = useState("");
  const [venue, setVenue] = useState('');

  const token = localStorage.getItem("token");
  const interviewerEmail = interviewers?.map(e => (e?.email))
  const date = new Date(startDate);
  const year = date.getFullYear();
  const day = date.getDate();
  const month = date.getMonth();
  const interviewDate = `${year}-${month}-${day}`;
  const isValidEmail = (email, i) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {

      toast.warn(`Provide Valid Interviewer Email ${i + 1}`);
    }
    return emailRegex.test(email)
  };
  const isEveryElementBlank = (array) => {
    return array.some(element => element === '' || element === null || element === undefined);
  };
  const isEveryEmailValid = (array) => {
    return array.some((element, i) => !isValidEmail(element, i));
  }

  const tempCandidateId = tempCandidate?.id
  const testNote = { note: note }

  const emailData = {
    navigate, token, title, interviewDate, candidateEmail, link, testNote, venue, interviewerEmail, tempCandidateId, interviewMode, interviewTime, interviewType
  }

  const [titleError, setTitleError] = useState('');
  const [dateError, setDateError] = useState('');
  const [timeError, setTimeError] = useState('');
  const [candidateEmailError, setCandidateEmailError] = useState('');
  const [interviewerEmailError, setInterviewerEmailError] = useState('');
  const [interviewerModeError, setInterviewerModeError] = useState('');

  const handleFullScreen = () => {
    setFullScreen(!fullScreen)
  }
  const sendEmail = (e) => {
    e.preventDefault();
    if (!title) {
      setTitleError("Title Is Required");
      // toast.warn("Title Is Required");
    } if (!startDate) {
      setDateError("Interview Date Is Required");
      // toast.warn("Interview Date Is Required");
    } if (!interviewTime) {
      setTimeError("Interview Time Is Required");
      // toast.warn("Interview Time Is Required");
    } if (!candidateEmail) {
      setCandidateEmailError("Candidate Email Is Required");
      // toast.warn("Candidate Email Is Required");
    } if (!isValidEmail(candidateEmail)) {
      toast.warn("Provide Valid Candidate Email");
    } if (!interviewerEmail || isEveryElementBlank(interviewerEmail)) {
      setInterviewerEmailError("Interviewer Email Is Required");
      // toast.warn("Interviewer Email Is Required");
    } if (isEveryEmailValid(interviewerEmail)) {
      return
    } if (!interviewMode) {
      setInterviewerModeError("Please Choose Interview Mode");
      // toast.warn("Please Choose Interview Mode");
    } else {
      dispatch(sendEmail(emailData))
    }
  }
  // console.log(isEveryElementBlank, ":isEveryElementBlank")
  // console.log(interviewerEmail, ":interviewerEmail")

  useEffect(() => {
    setCandidateEmail(tempCandidate?.contact?.email_primary)
  }, [tempCandidate]);

  useEffect(() => {
    if (interviewMode == "Online") {
      setVenue("");
    }
    if (interviewMode == "Offline") {
      setLink("");
    }
  }, [interviewMode])

  function onChange(value) {
    setInterviewTime(value && value.format(format));
  }

  const addRow = () => {
    setInterviewers([...interviewers, { id: Date.now(), email: '' }]);
  };

  const removeRow = (id) => {
    setInterviewers(interviewers.filter((interviewer) => interviewer.id !== id));
  };

  const handleEmailChange = (id, email) => {
    setInterviewers((prevInterviewers) =>
      prevInterviewers.map((interviewer) =>
        interviewer.id === id ? { ...interviewer, email } : interviewer
      )
    );
  };

  useEffect(() => {
    if (!detailShow) {
      if (candidateLists?.candidateData?.length > 0) {
        setFirstApiHit(firstApiHit + 1);
        dispatch(GetCandidateData(candidateLists?.candidateData[0]))
        dispatch(GetStatusData(candidateLists?.candidateData[0]))
      }
    }
  }, [candidateLists?.candidateData])

  // const searchValue = (e) => {
  //   console.log();
  //   const newValue = e.target.value;
  //   setSearch(newValue);
  //   // setSearch(searchData)
  //   // if (e.target.value === "") {
  //     dispatch(candidate(e.target.value, viewList.designation, viewList.skill, viewList.experience, viewList.cities, viewList.rating, viewList.status, sort, 1, viewList.rangeValues, navigate, viewList.onSkillCheck, setIsFetching));
  //     setPage(1)
  //   // }
  // };

  const debouncedSearch = useCallback(
    debouncedHandleSearch((newValue) => {
      dispatch(candidate(newValue, viewList.designation, viewList.skill, viewList.experience, viewList.cities, viewList.rating, viewList.status, sort, 1, viewList.rangeValues, navigate, viewList.onSkillCheck, setIsFetching));
      setPage(1);
    }, 600), // Adjust the delay (in milliseconds) as per your requirement
    [dispatch, navigate, viewList.designation, viewList.skill, viewList.experience, viewList.cities, viewList.rating, viewList.status, sort, viewList.rangeValues, viewList.onSkillCheck, setIsFetching]
  );
  const searchValue = (e) => {
    const newValue = e.target.value;
    setSearch(newValue);
    debouncedSearch(newValue);
  };
  const handleSearch = (e) => {
    viewList.setClearSortAndSearch(false);
    e.preventDefault();
    dispatch(
      candidate(viewList.search, viewList.designation, viewList.skill, viewList.experience, viewList.cities, viewList.rating, viewList.status, sort, 1, viewList.rangeValues, navigate, viewList.onSkillCheck, setIsFetching)
    );
    setPage(1)
  };
  const handleKeyPress = (event) => {
    // setSearch(searchData)
    if (event.key === "Enter") {
      if (viewList.search != 0) {
        handleSearch(event);
      }
    }
  };

  const [currentProfileMenu, updateprofileMenu] = useState("Details");
  const profileMenu = (e) => {
    updateprofileMenu(e);
  };

  useEffect(() => {
    if (viewList.viewList === 'Grid' || viewList.viewList === 'List') {
      updateprofileMenu('');
      setSearch('');
    }
    else if (viewList.viewList === 'Details') {
      updateprofileMenu('Details');
    }
  }, [viewList.viewList]);


  const handleSort = (e) => {
    viewList.setClearSortAndSearch(false);
    const values = e.target.value ? e.target.value : ""
    if (values != "") {
      setSort(values)
    }
    viewList?.setSorting(values)
    dispatch(candidate(viewList.search, viewList.designation, viewList.skill, viewList.experience, viewList.cities, viewList.rating, viewList.status, values, 1, viewList.rangeValues, navigate, viewList.onSkillCheck));
    setPage(1);
  }

  const [loading, setLoading] = useState(false);
  const scrollContainerRef = useRef(null);

  const copyLink = () => {
    const path = `/candidates/profile/${tempCandidate?.id}`;
    const fullUrl = window.location.origin + path;

    navigator.clipboard.writeText(fullUrl).then(() => {
      toast.success("Link Copied!!");
    }).catch(err => {
      toast.error('Failed To Copy');
    });
  }

  useEffect(() => {
    // candidateArray<=5&& setCandidateArray(candidateLists?.candidateData);
    setCandidateArray(candidateLists?.candidateData);
  }, [candidateLists?.candidateData, viewList.status]);


  const fetchData = (page) => {
    const pageAfterSearch = candidateLists?.candidateDataAllResponse?.meta?.total > 5 ? page : 1
    //"%22%22%7D" this type values are provided as required in backend and the backend api is unstructured
    const sortValue = sort ? `${JSON.stringify(sort)}}` : "%22%22%7D";
    const setSkill = viewList.skill ? viewList.skill : [];
    if (candidateLists?.candidateDataAllResponse?.data?.length > 0) {
      axios.get(`${process.env.REACT_APP_BASE_URL}/api/auth/candidate?page=${page}&search=${viewList.search}&selected=%7B%22designations%22:${JSON.stringify(viewList.designation)},%22designation_exp%22:${JSON.stringify(viewList.experience)},%22exp_designation_max%22:${20},%22exp_designation_min%22:0,%22cities%22:${JSON.stringify(viewList.cities)},%22ratings%22:${JSON.stringify(viewList.rating)},%22statuses%22:${JSON.stringify(viewList.status)},%22skills%22:${JSON.stringify(setSkill)},%22sort%22:${sortValue}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
      )
        .then((response) => {
          setCandidateArray(prevData => [...prevData, ...response?.data?.data]);
          setLoading(false);
          setPage(prevPage => prevPage + 1);
        })
        .catch((error) => {
          if (error?.message === "Network Error" || error?.response?.status === 500) {
            dispatch(errorCall(error))
            return;
          }
          if (error?.response?.data?.error == "Unauthenticated.") {
            navigate("/login");
          }
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    if (candidateLists?.candidateDataAllResponse?.data?.length > 0 && candidateLists?.candidateDataAllResponse?.meta?.last_page >= page) {
      const handleScroll = () => {
        if (
          scrollContainerRef.current.scrollHeight - scrollContainerRef.current.scrollTop <=
          scrollContainerRef.current.clientHeight + 10
        ) {
          if (!loading) {
            setLoading(true);
            fetchData(page + 1)
          }
        }
      };

      if (scrollContainerRef.current) {
        scrollContainerRef.current.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (scrollContainerRef.current) {
          scrollContainerRef.current.removeEventListener('scroll', handleScroll);
        }
      }
    }
  }, [fetchData, page, scrollContainerRef, loading]);


  useEffect(() => {
    if (viewList.clearSortAndSearch === true) {
      viewList?.setSorting(null);
      // setSearch("");
      setSort("");
      setSearch("");
      dispatch(candidate("", [], [], [], [], [], [], "", 1, [0, 20]));
    }
  }, [viewList.clearSortAndSearch]);

  useEffect(() => {
    if (candidateLoading === true) {
      setLoading(false)
    }
  }, [candidateLoading, loading]);
  useEffect(() => {
    setPage(1)
  }, [viewList.viewList])
  return (
    <>
      {
        // !candidateLists?.isCandidateLoading ?
        //  (
        // candidateLists?.candidateDataAllResponse?.data?.length > 0 ?
        <div className="row g-3">
          {candidateLoading && firstApiHit === 0 ? (
            <div className='d-flex align-items-center justify-content-center' style={{ textAlign: "center", height: '100px' }}>
              <div className="loader"></div>
            </div>
          ) : (<>
            {viewList.viewList === "Details" ? (
              <>
                <div
                  className={
                    fullScreen === true
                      ? "col-12"
                      : viewList.viewList === "Grid" || viewList.viewList === "List"
                        ? ""
                        : "col-xl-7"
                  }
                >
                  {(tempCandidate && candidateLists?.candidateDataAllResponse?.data?.length != 0) ? (

                    <div>
                      <div id="detailed_view_tab" className="top_section d-block ">
                        <div className="row g-3">
                          <div className="col-sm-3 ">
                            {
                              tempCandidate?.profile_image &&
                              <div className="img_profile">
                                {/* <img src={tempCandidate?.profile_image.replace("\/uploads\/profile\/", `${process.env.REACT_APP_BASE_URL}/uploads/profile/`)} alt="" /> */}
                                <img src={`${tempCandidate?.profile_image}`} alt="" />
                              </div>
                            }
                            {
                              tempCandidate?.profile_image == "" && tempCandidate?.gender == "Female" &&
                              <div className="img_profile">
                                <img src="/images/female.png" alt="" />
                              </div>
                            }
                            {
                              tempCandidate?.profile_image == "" && tempCandidate?.gender == "Male" &&
                              <div className="img_profile">
                                <img src="/images/male.jpg" alt="" />
                              </div>
                            }
                            {
                              tempCandidate?.profile_image == "" && tempCandidate?.gender == "Unknown" &&
                              <div className="img_profile">
                                <img src="/images/user-alt.png" alt="" />
                              </div>
                            }
                            {
                              !tempCandidate &&
                              <div className="img_profile">
                                <img src="/images/user-alt.png" alt="" />
                              </div>
                            }
                          </div>
                          <div className="col-sm-9">
                            <div className="d-flex flex-wrap justify-content-between align-items-center pro_nam" style={{ columnGap: '10px' }}>
                              <div className=" d-flex align-items-center ">
                                <h2 className="me-0 me-sm-2 mb-0 mb-sm-0">
                                  <span></span>{tempCandidate?.gender == "Female" ? "Ms." : "Mr."}  {tempCandidate?.first_name} {tempCandidate?.last_name}
                                </h2>
                                <span className="d-flex">
                                  <span className="mb-0 mx-1 p-1 rounded border-0  " style={{ backgroundColor: statusChange === true ? candidateLoadingStatus?.color : tempCandidate?.color_candidate || "green", color: "white", minWidth: "60px", textAlign: "center" }}>
                                    {statusChange !== true &&
                                      <>
                                        {tempCandidate?.status == "0" && "New"}
                                        {tempCandidate?.status == "1" && "Reviewed"}
                                        {tempCandidate?.status == "2" && "Good Fit"}
                                        {tempCandidate?.status == "3" && "Interview Scheduled"}
                                        {tempCandidate?.status == "4" && "Offer made"}
                                        {tempCandidate?.status == "5" && "Hired"}
                                        {tempCandidate?.status == "6" && "Rejected"}
                                        {tempCandidate?.status == "7" && "On Hold"}
                                      </>
                                    }
                                    {statusChange === true && (
                                      <>
                                        {candidateLoadingStatus?.data == "0" && "New"}
                                        {candidateLoadingStatus?.data == "1" && "Reviewed"}
                                        {candidateLoadingStatus?.data == "2" && "Good Fit"}
                                        {candidateLoadingStatus?.data == "3" && "Interview Scheduled"}
                                        {candidateLoadingStatus?.data == "4" && "Offer made"}
                                        {candidateLoadingStatus?.data == "5" && "Hired"}
                                        {candidateLoadingStatus?.data == "6" && "Rejected"}
                                        {candidateLoadingStatus?.data == "7" && "On Hold"}
                                      </>)}
                                  </span>
                                  <div className="copy-link-button white" title="Copy link" onClick={() => copyLink()}>
                                    <button type="button" data-original-title="null" className="has_tooltip">
                                      <i className="fa-solid fa-link fa_link text-dark "></i>
                                    </button>
                                  </div>
                                </span>
                              </div>
                              <div className="d-none d-lg-block">
                                <button onClick={handleFullScreen} className='bg-transparent border-0'>
                                  {fullScreen ? (<BiExitFullscreen className="text-white fa-lg pe-auto" />) : (
                                    <BiFullscreen className="text-white fa-lg pe-auto" />
                                  )}
                                </button>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-6 pro_nam">
                                <h3>
                                  {tempCandidate?.experience_levels?.abbreviation} {tempCandidate?.designation?.name}
                                </h3>
                                <h4>Applied {tempCandidate?.created_at}
                                </h4>
                              </div>
                              <div className="col-sm-6 position-md-relative res_div_in">
                                <div className="rt_dec position-md-absolute  d-flex justify-content-between  responsiveDivIn">

                                  <div className="d-flex  justify-content-sm-end mt-0 mt-sm-5 ">
                                    {permissionList?.includes("65bb924853e762c89b9af169") &&
                                      <Link to={`/candidates/edit-candidates/${tempCandidate == null ? candidateLists?.candidateData[0]?.id : tempCandidate?.id}`} className="btn btn-primary" title="Edit">
                                        <i aria-hidden="true" className="fa fa-pencil"></i>
                                      </Link>
                                    }
                                    <Link to={`/candidates/all-candidates/profile/${tempCandidate?.id}`} className="btn btn-primary" title="View">
                                      <i aria-hidden="true" className="fa fa-eye"></i>
                                    </Link>
                                    <button type="button" onClick={handleShow}>
                                      Interview Schedule
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                      <Modal
                        show={show}
                        onHide={handleClose}
                        size="lg"
                        className="scheduleModal"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                      >
                        <Modal.Header closeButton className="border-0 px-4">
                          <Modal.Title>Interview Schedule</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="scheduleModalBody px-2">
                            <div className="row g-2">
                              <div className="col-12">
                                <label htmlFor="title" className="form-label">Title<span className="text-danger">*</span></label>
                                <input type="text" className="form-control" id="title" placeholder="Enter title" name="title" onChange={(e) => { setTitle(e.target.value) }} />
                                {title ? null : <span className="text-danger">{titleError}</span>}
                              </div>
                              <div className="col-lg-6">
                                <label htmlFor="" className="form-label">Interview Date<span className="text-danger">*</span></label>
                                <div className="position-relative w-100">
                                  <DatePicker
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)} className="form-control w-100 pe-4 shadow-none " />
                                  <span className="icon position-absolute end-0 top-0 py-2 px-2" style={{ opacity: '0.5' }} >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 1024 1024"
                                      width="1em"
                                      height="1em"
                                    >
                                      <path d="M940.218182 107.054545h-209.454546V46.545455h-65.163636v60.50909H363.054545V46.545455H297.890909v60.50909H83.781818c-18.618182 0-32.581818 13.963636-32.581818 32.581819v805.236363c0 18.618182 13.963636 32.581818 32.581818 32.581818h861.090909c18.618182 0 32.581818-13.963636 32.581818-32.581818V139.636364c-4.654545-18.618182-18.618182-32.581818-37.236363-32.581819zM297.890909 172.218182V232.727273h65.163636V172.218182h307.2V232.727273h65.163637V172.218182h176.872727v204.8H116.363636V172.218182h181.527273zM116.363636 912.290909V442.181818h795.927273v470.109091H116.363636z" />
                                    </svg>
                                  </span>
                                </div>
                                {startDate ? null : <span className="text-danger">{dateError}</span>}
                              </div>
                              <div className="col-lg-6">
                                <label htmlFor="" className="form-label">Interview Time<span className="text-danger">*</span></label>
                                <div className="position-relative w-100">
                                  <TimePicker
                                    showSecond={false}
                                    className="form-control"
                                    onChange={onChange}
                                    format={format}
                                    use12Hours
                                    inputReadOnly
                                  />
                                  <span className="icon position-absolute end-0 top-0 py-2 px-2" style={{ opacity: '0.5' }} >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 1024 1024"
                                      width="1em"
                                      height="1em"
                                    >
                                      <path d="M940.218182 107.054545h-209.454546V46.545455h-65.163636v60.50909H363.054545V46.545455H297.890909v60.50909H83.781818c-18.618182 0-32.581818 13.963636-32.581818 32.581819v805.236363c0 18.618182 13.963636 32.581818 32.581818 32.581818h861.090909c18.618182 0 32.581818-13.963636 32.581818-32.581818V139.636364c-4.654545-18.618182-18.618182-32.581818-37.236363-32.581819zM297.890909 172.218182V232.727273h65.163636V172.218182h307.2V232.727273h65.163637V172.218182h176.872727v204.8H116.363636V172.218182h181.527273zM116.363636 912.290909V442.181818h795.927273v470.109091H116.363636z" />
                                    </svg>
                                  </span>
                                </div>
                                {interviewTime ? null : <span className="text-danger">{timeError}</span>}

                              </div>
                              <div className="col-12">
                                <label htmlFor="candidate" className="form-label">Candidate<span className="text-danger">*</span></label>
                                <input type="text" id="candidate" disabled value={`${tempCandidate?.first_name} ${tempCandidate?.last_name}`} className="form-control" />
                              </div>
                              <div className="col-12">
                                <label htmlFor="canEmail" className="form-label">Candidate Email<span className="text-danger">*</span></label>
                                <input type="email" id="canEmail" className="form-control" placeholder="Enter email" value={candidateEmail} onChange={(e) => setCandidateEmail(e.target.value)} />
                                {candidateEmail ? null : <span className="text-danger">{candidateEmailError}</span>}
                              </div>
                              <>
                                <div className="col-12 mt-3">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <label htmlFor="" className="form-label">Interviewer<span className="text-danger">*</span></label>
                                    <button className="btn btn-outline-secondary px-4" onClick={addRow} >Add</button>
                                  </div>
                                  {interviewers.map((interviewer, i) => (
                                    <div key={interviewer.id} className="row g-3 mt-1 justify-content-between">
                                      <div className="col-9">
                                        <input
                                          type="email"
                                          placeholder="Enter interviewer email"
                                          className="form-control"
                                          value={interviewer.email}
                                          onChange={(e) => handleEmailChange(interviewer.id, e.target.value)}
                                        />
                                      </div>
                                      {i > 0 &&
                                        <div className="col-auto">
                                          <button className="btn btn-danger" onClick={() => removeRow(interviewer.id)}>
                                            Remove
                                          </button>
                                        </div>
                                      }
                                    </div>
                                  ))}
                                  {interviewerEmail[0] === "" ? <span className="text-danger">{interviewerEmailError}</span> : null}

                                </div>
                                <div className="col-12">
                                  <label htmlFor="" className="form-label">Interview Mode<span className="text-danger">*</span></label>
                                  <div className="row g-3">
                                    <div className="col-auto">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="flexRadioDefault2"
                                          id="flexRadioDefault3"
                                          onClick={() => setInterviewMode('Online')}
                                        />
                                        <label className="form-check-label" htmlFor="flexRadioDefault3">
                                          Online
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-auto">
                                      <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="flexRadioDefault2"
                                          id="flexRadioDefault4"
                                          onClick={() => setInterviewMode('Offline')}
                                        />
                                        <label className="form-check-label" htmlFor="flexRadioDefault4">
                                          Offline
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  {interviewMode ? null : <span className="text-danger">{interviewerModeError}</span>}

                                </div>
                                <div className="col-12">
                                  {interviewMode === "Online" && <>
                                    <label htmlFor="venue" className="form-label">Link</label>
                                    <input type="text" className="form-control" placeholder="Enter link" onChange={(e) => setLink(e.target.value)} />
                                  </>}
                                  {interviewMode === "Offline" && <>
                                    <label htmlFor="venue" className="form-label">Venue</label>
                                    <input type="text" className="form-control" placeholder="Enter venue" onChange={(e) => setVenue(e.target.value)} />
                                  </>}
                                </div>
                                <div id="qwerty1" className="col-12">
                                  <label htmlFor="qwerty1" className="form-label">Note</label>
                                  <textarea name="" id="" rows="2" className="form-control" placeholder="Enter note..." onChange={(e) => setNote(e.target.value)}></textarea>
                                </div>
                              </>
                              {interviewType === 'Technical' && <>
                                <div id="qwerty12" className="col-12">
                                  <label htmlFor="qwerty12" className="form-label">Test name</label>
                                  <Select options={options} />
                                </div>
                                <div className="col-12">
                                  <label htmlFor="" className="form-label">Text Link</label>
                                  <input type="text" disabled placeholder="Enter test link" className="form-control" />
                                </div>
                                <div className="col-12">
                                  <label htmlFor="" className="form-label">Short Description</label>
                                  <textarea name="" id="" rows="2" className="form-control" placeholder="Enter note..."></textarea>
                                </div>
                                <div className="col-12">
                                  <label htmlFor="" className="form-label">Long Description</label>
                                  <textarea name="" id="" rows="2" className="form-control" placeholder="Enter note..."></textarea>
                                </div>
                                <div className="col-12">
                                  <label htmlFor="" className="form-label">Note</label>
                                  <textarea name="" id="" rows="2" className="form-control" placeholder="Enter note..."></textarea>
                                </div>
                              </>}
                              <div className="col-12 mt-4">
                                <button className="btn btn-success choosed" onClick={sendEmail}>Save</button>
                              </div>
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>

                      <div id="Profile" role="tabpanel" aria-labelledby="Profile-tab" className="tab-pane fade show active">
                        <ul id="myTab" role="tablist" className="nav nav-tabs">

                          <li className="nav-item">
                            <a
                              id="Skills-tab"
                              data-bs-toggle="tab"
                              href="#Skills"
                              role="tab"
                              aria-controls="Skills"
                              aria-selected="false"
                              className={`nav-link new_nav_link ${currentProfileMenu === "Details" ? "active" : ""}`}
                              onClick={() => profileMenu("Details")}
                            >
                              Profile Details
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              id="Contacts-tab"
                              data-bs-toggle="tab"
                              href="#Contacts"
                              role="tab"
                              aria-controls="Contacts"
                              aria-selected="false"
                              className={`nav-link new_nav_link ${currentProfileMenu === "Question" ? "active" : ""}`}
                              onClick={() => profileMenu("Question")}
                            >
                              Screening Question
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              id="Hardware-tab"
                              data-bs-toggle="tab"
                              href="#Hardware"
                              role="tab"
                              aria-controls="Hardware"
                              aria-selected="true"
                              className={`nav-link new_nav_link ${currentProfileMenu === "Results" ? "active" : ""}`}
                              onClick={() => profileMenu("Results")}
                            >
                              Test Results
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              id="work_details-tab"
                              data-bs-toggle="tab"
                              href="#work_details"
                              role="tab"
                              aria-controls="work_details"
                              aria-selected="true"
                              className={`nav-link new_nav_link ${currentProfileMenu === "Note" ? "active" : ""}`}
                              onClick={() => profileMenu("Note")}
                            >
                              Note
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              id="Experience-tab"
                              data-bs-toggle="tab"
                              href="#Experience"
                              role="tab"
                              aria-controls="Experience"
                              aria-selected="true"
                              className={`nav-link new_nav_link ${currentProfileMenu === "recording" ? "active" : ""}`}
                              onClick={() => profileMenu("recording")}
                            >
                              Call recording
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              id="Social-tab"
                              data-bs-toggle="tab"
                              href="#Social"
                              role="tab"
                              aria-controls="Social"
                              aria-selected="false"
                              className={`nav-link new_nav_link ${currentProfileMenu === "Sheet" ? "active" : ""}`}
                              onClick={() => profileMenu("Sheet")}
                            >
                              Cheat Sheet
                            </a>
                          </li>
                        </ul>
                      </div>


                      <div className="box-bg-white">

                        {currentProfileMenu === "Details" ? <ProfileDetails value={tempCandidate} /> : null}

                        {currentProfileMenu === "Question" ? <ScreeningQuestion /> : null}

                        {currentProfileMenu === "Results" ? <TestResults /> : null}

                        {currentProfileMenu === "Note" ? <Note candidate={tempCandidate} /> : null}

                        {currentProfileMenu === "recording" ? <CallRecording contact={tempCandidate?.contact} /> : null}

                        {currentProfileMenu === "Sheet" ? <CheatSheet /> : null}
                      </div>
                    </div>
                  ) :
                    <div>
                      <div className="noDataStyle border py-2" >
                        <p className="px-3 noDataStyle">No Candidate</p>
                      </div>
                      <div className={`d-flex mt-5 ${window.innerWidth < 1200
                          ? "justify-content-center"
                          : "justify-content-end"
                        }`}>
                        <p>No Data Found</p>
                      </div>
                    </div>
                  }
                </div>
              </>
            ) : null}
            {(!fullScreen || viewList.viewList === "Grid" || viewList.viewList === "List") && <InfiniteScroll viewList={viewList} handleSort={handleSort} sort={sort} searchData={viewList.search} searchValue={searchValue} handleKeyPress={handleKeyPress} handleSearch={handleSearch} scrollContainerRef={scrollContainerRef} setShowData={setShowData} candidateArray={candidateArray} candidateLoading={candidateLoading} loading={loading} setStatusChange={setStatusChange} tempCandidate={tempCandidate} setSearchData={setSearch} setSort={setSort} page={page} setDetailShow={setDetailShow}
              setPage={setPage} updateprofileMenu={updateprofileMenu} lastPage={candidateLists?.candidateDataAllResponse?.meta?.last_page} />}
          </>)}
        </div>
        // : <h5 className="text-danger text-center">No Data</h5>
        // )
        //   : <div className='d-flex align-items-center justify-content-center' style={{ textAlign: "center", height: '100px' }}>
        //     <div className="loader"></div>
        //   </div>
      }
    </>
  );
};

export default Detailview;