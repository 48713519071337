
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {toast} from 'react-toastify';
import Spiner1 from '../Spiner1';
import { useNavigate } from 'react-router-dom';
const HardwareData = ({ contact, profileData }) => {

const navigate = useNavigate();

  const [computerType, setComputerType] = useState("");
  const [processorType, setProcessorType] = useState("");
  const [ram, setRam] = useState("");
  const [internetType, setInternetType] = useState("");
  const [downLoadSpeed, setDownloadSpeed] = useState("");
  const [uploadSpeed, setUploadSpeed] = useState("");
  const [upgradeInternet, setUpgradeInternet] = useState();
  const [upgradeRam, setUpgradeRam] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const getLocaldata = () => {
    const token = localStorage.getItem("token");
    return token
  }
  const notify = (message) => toast(message);
  const updateH = async () => {
      setIsLoading(true);
      const mt = getLocaldata();
      const config = {
        headers: { Authorization: `Bearer ${mt}` }
      };

      const data = {
      id: contact?.id,
      first_name: profileData?.first_name,
      last_name: profileData?.last_name,
      designation_id:profileData?.designation,
      lead_source_id:profileData?.lead_source,
      experience_level_id:profileData?.experience_levels,
      resume:profileData.resume,
        system_hardware: {
          computer: computerType,
          processor: processorType,
          ram: ram,
          internet_type: internetType,
          internet_download: downLoadSpeed,
          internet_upload: uploadSpeed,
          upgrade_internet: upgradeInternet,
          upgrade_ram: upgradeRam,
        }
      }

      const firstName = profileData?.first_name;
    const lastName = profileData?.last_name;
    // const socialLink = socialDetails?.map((e) => e.link);
    // const socialMediaName = socialDetails?.map((e) => e.name);
    if (firstName?.trim().length === 0) {
      setIsLoading(false);
      toast.error("Please Provide First Name");
      return;
    }

    // Additional validation for last_name
    if (!lastName || lastName.trim().length === 0) {
      setIsLoading(false);
      toast.error("Please Provide Last Name");
      return;
    }
    // Separate validation for lead_source
    if (!profileData?.lead_source) {
      setIsLoading(false);
      toast.error("Please select a Lead Source.");
      return;
    }
    // Separate validation for experience_levels
    if (!profileData?.experience_levels) {
      setIsLoading(false);
      toast.error("Please select an Experience Level.");
      return;
    }

    // Separate validation for designation
    if (profileData?.designation == "") {
      setIsLoading(false);
      toast.error("Please select a Designation.");
      return;
    }
     const axiosCall = async (datas) => {
       await axios
         .post(
           `${process.env.REACT_APP_BASE_URL}/api/auth/candidate_update_hardware`,
           datas,
           config
         )
         .then((res) => {
           setIsLoading(false);
           toast.success("Updated Successfuly");
         })
         .catch((err) => {
           setIsLoading(false);
           if (err.response?.data?.message === "Unauthenticated.") {
             localStorage.removeItem("token");
             navigate("/login");
           }
         });
     };

     const reader = new FileReader();
     reader.onload = function (event) {
       data.resume = event.target.result;
       axiosCall(data);
     };
     if (
       profileData.resume &&
       profileData?.resume?.type &&
       profileData?.resume?.type
     ) {
       reader.readAsDataURL(profileData.resume);
     } else {
       axiosCall(data);
     }
      
  }

  useEffect(() => {
    setComputerType(contact?.system_hardware?.computer);
    setProcessorType(contact?.system_hardware?.processor);
    setRam(contact?.system_hardware?.ram);
    setInternetType(contact?.system_hardware?.internet_type);
    setDownloadSpeed(contact?.system_hardware?.internet_download);
    setUploadSpeed(contact?.system_hardware?.internet_upload);
    setUpgradeInternet(contact?.system_hardware?.upgrade_internet);
    setUpgradeRam(contact?.system_hardware?.upgrade_ram);

  }, [contact])
  return (
    <div className="tab-data mt-4" step={0}>
      <div className="grid_2_md">
        <div className="grid-box">
          <div className="form-group-gap">
            <div className="form-label-box mb-2">
              <label className="txt__para txt__sm fw-bold">
                Computer type?
              </label>
            </div>
            <div className="form-control-box">
              <select className="form-control-no-border form-control-no-border-select" value={computerType} onChange={(e) => setComputerType(e.target.value)}>
                <option value="" >
                  Select Computer Type
                </option>
                <option>Desktop</option>
                <option>Desktop Mac</option>
                <option>Laptop</option>
                <option>Laptop MacBook</option>
              </select>
            </div>
          </div>
        </div>
        <div className="grid-box">
          <div className="form-group-gap">
            <div className="form-label-box mb-2">
              <label className="txt__para txt__sm fw-bold">
                Processor type ?
              </label>
            </div>
            <div className="form-control-box">
              <input
                type="text"
                placeholder="Your computer's processor"
                className="form-control-no-border"
                onChange={(e) => setProcessorType(e.target.value)}
                value={processorType}
              />
            </div>
          </div>
        </div>
        <div className="grid-box">
          <div className="form-group-gap">
            <div className="form-label-box mb-2">
              <label className="txt__para txt__sm fw-bold">
                RAM (in GB) ?
              </label>
            </div>
            <div className="form-control-box">
              <input
                type="text"
                placeholder={4}
                className="form-control-no-border"
                onChange={(e) => setRam(e.target.value)}
                value={ram}
              />
            </div>
          </div>
          <div className="form-group-gap">
            <div className="form-label-box">
              <div className="custom-control custom-checkbox">
                <input
                 
                  type="checkbox"
                  id="customCheck754"
                  className="form-check-input"
                  onClick={() => setUpgradeRam(prevState => !prevState)}
                  defaultChecked = {upgradeRam}
                />
                <label
                  htmlFor="customCheck754"
                  className="form-check-label"
                >
                  Candidate will upgrade hardware as per their
                  requirements.
                </label>
              </div>
            </div>
          </div>
          <div className="form-group-gap">
            <div className="form-label-box">
              <div className="custom-control custom-checkbox d-flex">
                <input
                  
                  type="checkbox"
                  id="customCheck1"
                  className="form-check-input flex-shrink-0"
                  defaultChecked = {upgradeInternet}
                  onClick={()=>setUpgradeInternet(prevState => !prevState)}
                />
                <label
                  htmlFor="customCheck1"
                  className="form-check-label ms-1"
                >
                  Mobile internet but candidate can get broadband if
                  he/she get selected.
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="grid-box">
          <div className="form-group-gap">
            <div className="form-label-box mb-2">
              <div className="d-flex align-items-center flex-wrap">
                <label className="txt__para txt__sm fw-bold me-3">
                  Internet bandwidth (In mbps) ?
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      defaultValue="Mobile"
                      type="radio"
                      id="internetType1"
                      name="internet_type"
                      className="form-check-input"
                      checked={internetType ? internetType == "Mobile" : ""}
                      onChange={(e) => setInternetType(e.target.value)}
                    />
                    <label
                      htmlFor="internetType1"
                      className="form-check-label"
                    >
                      Mobile
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      defaultValue="Broadband"
                      type="radio"
                      id="internetType2"
                      name="internet_type"
                      className="form-check-input"
                      checked={internetType && internetType === "Broadband" ? true : false}
                      onChange={(e) => setInternetType(e.target.value)}
                    />
                    <label
                      htmlFor="internetType2"
                      className="form-check-label"
                    >
                      Broadband
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid_2">
              <div className="grid-box">
                <div className="form-group-gap mb-2">
                  <div className="form-control-box d-flex align-items-center">
                    <label className="txt__para txt__sm mb-0 me-4">
                      DOWNLOAD
                    </label>
                    <input
                      type="text"
                      placeholder="00 Mbps"
                      className="form-control-no-border"
                      value={downLoadSpeed}
                      onChange={(e) => setDownloadSpeed(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="grid-box">
                <div className="form-group-gap mb-2">
                  <div className="form-control-box d-flex align-items-center">
                    <label className="txt__para txt__sm mb-0 me-4">
                      UPLOAD
                    </label>
                    <input
                      type="text"
                      placeholder="00 Mbps"
                      className="form-control-no-border"
                      value={uploadSpeed}
                      onChange={(e) => setUploadSpeed(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <p className="txt__sm txt__light">
              Go to
              <a href="https://www.speakeasy.net" target="_blank">
                speakeasy.net
              </a>
              , and do an online speedtest. Example: 30mbps upload and
              30 mbps download.
            </p>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <button type="button" disabled={isLoading ? isLoading : false} className="btn nav-link Next_qu" onClick={updateH}>
          {isLoading ? <Spiner1/> : "Submit"}
        </button>
      </div>
    </div>
  )
}

export default HardwareData
