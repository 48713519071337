import {
  // RESULT_DATA_REQUEST,
  // RESULT_DATA_SUCCESS,
  // RESULT_DATA_FAILURE,
  RESULT_SINGLE_REQUEST,
  RESULT_SINGLE_SUCCESS,
  RESULT_SINGLE_FAILURE,
  RESULT_SINGLE_APPLICANT_REQUEST,
  RESULT_SINGLE_APPLICANT_SUCCESS,
  RESULT_SINGLE_APPLICANT_FAILURE,
  // RESULT_REQUEST,
  // RESULT_SUCCESS,
  // RESULT_FAILURE,
  // PAGINATION_REQUEST,
  // PAGINATION_SUCCESS,
  // PAGINATION_FAILURE,
  POST_RESULT_REQUEST,
  POST_RESULT_SUCCESS,
  POST_RESULT_FAILURE,
  UPDATE_RESULT_REQUEST,
  UPDATE_RESULT_SUCCESS,
  UPDATE_RESULT_FAILURE,
  UPDATE_MARKS_REQUEST,
  UPDATE_MARKS_SUCCESS,
  UPDATE_MARKS_FAILURE,
  ARCHIVE_REQUEST,
  ARCHIVE_SUCCESS,
  ARCHIVE_FAILURE,
  UNARCHIVE_ALL_REQUEST,
  UNARCHIVE_ALL_SUCCESS,
  UNARCHIVE_ALL_FAILURE,
  // DOWNLOAD_RESULT_REQUEST,
  // DOWNLOAD_RESULT_SUCCESS,
  // DOWNLOAD_RESULT_FAILURE,
  // AVERAGE_RESULT_REQUEST,
  // AVERAGE_RESULT_SUCCESS,
  // AVERAGE_RESULT_FAILURE,
  RESULT_SEARCH_REQUEST,
  RESULT_SEARCH_SUCCESS,
  RESULT_SEARCH_FAILURE,
  ALL_RESULT_REQUEST,
  ALL_RESULT_SUCCESS,
  ALL_RESULT_FAILURE,
  ANS_SHEET_REQUEST,
  ANS_SHEET_SUCCESS,
  ANS_SHEET_FAILURE,
} from "./actionType";

const initialState = {
  count: 0,
  name: "",
  applicant: "",
  qesCategoriesCount: "",
  questionsCount: "",
  // data: [],
  // dataPage: "",
  dataMark: "",
  // result: [],
  localStorageDAta: [],
  createResultData: {},
  validationError: "",
  testId: "",
  singleResult: "",
  // downloadResult: null,
  resultData: [],
  allResult: [],
  ansData: {},

  // averageData: [],


  // isLoadingData: false,
  isLoadingSingle: false,
  isLoadingApplicant: false,
  // isLoadingResult: false,
  // isLoadingPagin: false,
  isLoadingPost: false,
  isLoadingUpdate: false,
  isLoadingMarks: false,
  isLoadingArchive: false,
  isLoadingUnArchive: false,
  // isLoadingDownload: false,
  // isLoadingAverage: false,
  isLoadingSearch: false,
  isLoadingAllRes: false,
  isLoadingAns: true,

  // isErrorData: false,
  isErrorSingle: false,
  isErrorApplicant: false,
  // isErrorResult: false,
  // isErrorPagin: false,
  isErrorPost: false,
  isErrorUpdate: false,
  isErrorMarks: false,
  isErrorArchive: false,
  isErrorUnArchive: false,
  // isErrorDownload: false,
  // isErrorAverage: false,
  isErrorSearch: false,
  isErrorAllRes: false,



  errorData: "",
  errorSingleData: "",
  errorApplicantData: "",
  errorResultData: "",
  errorPaginData: "",
  errorPostData: "",
  errorMarksData: "",
  errorArchiveData: "",
  errorUnArchiveData: "",
  errorDownloadData: "",
  errorAverageData: "",
  errorSearchData: "",
  errorSearchAllRes: "",
  errorAnsData: "",
  errorAllResult: ""
};


export const result_Reducer = (state = initialState, action) => {
  switch (action.type) {




    // case RESULT_DATA_REQUEST: {
    //   return {
    //     ...state,
    //     isLoadingData: true,
    //     data: [],
    //     isErrorData: false,
    //   };
    // }
    // case RESULT_DATA_SUCCESS: {
    //   return {
    //     ...state,
    //     isLoadingData: false,
    //     data: action.pload,
    //     isErrorData: false,
    //   };
    // }
    // case RESULT_DATA_FAILURE: {
    //   return {
    //     ...state,
    //     isLoadingData: false,
    //     errorData: action.payload,
    //     isErrorData: true,
    //   };
    // }


    case ALL_RESULT_REQUEST: {
      return {
        ...state,
        isLoadingAllRes: true,
        allResult: [],
        isErrorAllRes: false,
      };
    }
    case ALL_RESULT_SUCCESS: {
      return {
        ...state,
        isLoadingAllRes: false,
        allResult: action.payload,
        isErrorAllRes: false,
      };
    }
    case ALL_RESULT_FAILURE: {
      return {
        ...state,
        isLoadingAllRes: false,
        errorAllResult: action.payload,
        isErrorAllRes: true,
      };
    }


    case RESULT_SINGLE_REQUEST: {
      return {
        ...state,
        isLoadingSingle: true,
        singleResult: {},
        isErrorSingle: false,
      };
    }
    case RESULT_SINGLE_SUCCESS: {
      return {
        ...state,
        isLoadingSingle: false,
        singleResult: action.payload,
        isErrorSingle: false,
      };
    }
    case RESULT_SINGLE_FAILURE: {
      return {
        ...state,
        isLoadingSingle: false,
        errorData: action.payload,
        isErrorSingle: true,
      };
    }



    case RESULT_SINGLE_APPLICANT_REQUEST: {
      return {
        ...state,
        isLoadingApplicant: true,
        singleResult: {},
        isErrorApplicant: false,
      };
    }
    case RESULT_SINGLE_APPLICANT_SUCCESS: {
      return {
        ...state,
        isLoadingApplicant: false,
        singleResult: action.payload,
        isErrorApplicant: false,
      };
    }
    case RESULT_SINGLE_APPLICANT_FAILURE: {
      return {
        ...state,
        isLoadingApplicant: false,
        errorApplicantData: action.payload,
        isErrorApplicant: true,
      };
    }

    // case RESULT_REQUEST: {
    //   return {
    //     ...state,
    //     isLoadingResult: true,
    //     result: [],
    //     isErrorResult: false,
    //   };
    // }
    // case RESULT_SUCCESS: {
    //   return {
    //     ...state,
    //     isLoadingResult: false,
    //     result: action.payload,
    //     isErrorResult: false,
    //   };
    // }
    // case RESULT_FAILURE: {
    //   return {
    //     ...state,
    //     isLoadingResult: false,
    //     errorResultData: action.payload,
    //     isErrorResult: true,
    //   };
    // }


    // case PAGINATION_REQUEST: {
    //   return {
    //     ...state,
    //     isLoadingPagin: true,
    //     dataPage: [],
    //     isErrorPagin: false,
    //   };
    // }
    // case PAGINATION_SUCCESS: {
    //   return {
    //     ...state,
    //     isLoadingPagin: false,
    //     dataPage: action.payload,
    //     isErrorPagin: false,
    //   };
    // }
    // case PAGINATION_FAILURE: {
    //   return {
    //     ...state,
    //     isLoadingPagin: false,
    //     errorPaginData: action.payload,
    //     isErrorPagin: true,
    //   };
    // }



    case POST_RESULT_REQUEST: {
      return {
        ...state,
        isLoadingPost: true,
        dataPost: [],
        isErrorPost: false,
      };
    }
    case POST_RESULT_SUCCESS: {
      return {
        ...state,
        isLoadingPost: false,
        dataPost: action.payload,
        isErrorPost: false,
      };
    }
    case POST_RESULT_FAILURE: {
      return {
        ...state,
        isLoadingPost: false,
        errorPostData: action.payload,
        isErrorPost: true,
      };
    }



    case UPDATE_RESULT_REQUEST: {
      return {
        ...state,
        isLoadingUpadte: true,
        isErrorUpdate: false,
      };
    }
    case UPDATE_RESULT_SUCCESS: {
      return {
        ...state,
        isLoadingUpadte: false,
        isErrorUpdate: false,
      };
    }
    case UPDATE_RESULT_FAILURE: {
      return {
        ...state,
        isLoadingUpadte: false,
        errorUpdateData: action.payload,
        isErrorUpdate: true,
      };
    }



    case UPDATE_MARKS_REQUEST: {
      return {
        ...state,
        isLoadingMarks: true,
        dataMArk: {},
        isErrorMarks: false,
      };
    }
    case UPDATE_MARKS_SUCCESS: {
      return {
        ...state,
        isLoadingMarks: false,
        dataMark: action.payload,
        isErrorMarks: false,
      };
    }
    case UPDATE_MARKS_FAILURE: {
      return {
        ...state,
        isLoadingMarks: false,
        errorMarksData: action.payload,
        isErrorMarks: true,
      };
    }


    case ARCHIVE_REQUEST: {
      return {
        ...state,
        isLoadingArchive: true,
        isErrorArchive: false,
      };
    }
    case ARCHIVE_SUCCESS: {
      return {
        ...state,
        isLoadingArchive: false,
        isErrorArchive: false,
      };
    }
    case ARCHIVE_FAILURE: {
      return {
        ...state,
        isLoadingArchive: false,
        errorArchiveData: action.payload,
        isErrorArchive: true,
      };
    }


    case UNARCHIVE_ALL_REQUEST: {
      return {
        ...state,
        isLoadingUnArchive: true,
        isErrorUnArchive: false,
      };
    }
    case UNARCHIVE_ALL_SUCCESS: {
      return {
        ...state,
        isLoadingUnArchive: false,
        isErrorUnArchive: false,
      };
    }
    case UNARCHIVE_ALL_FAILURE: {
      return {
        ...state,
        isLoadingUnArchive: false,
        errorUnArchiveData: action.payload,
        isErrorUnArchive: true,
      };
    }


    // case DOWNLOAD_RESULT_REQUEST: {
    //   return {
    //     ...state,
    //     isLoadingDownload: true,
    //     downloadResult: null,
    //     isErrorDownload: false,
    //   };
    // }
    // case DOWNLOAD_RESULT_SUCCESS: {
    //   return {
    //     ...state,
    //     isLoadingDownload: false,
    //     downloadResult: action.payload,
    //     isErrorDownload: false,
    //   };
    // }
    // case DOWNLOAD_RESULT_FAILURE: {
    //   return {
    //     ...state,
    //     isLoadingDownload: false,
    //     errorDownloadData: action.payload,
    //     isErrorDownload: true,
    //   };
    // }


    // case AVERAGE_RESULT_REQUEST: {
    //   return {
    //     ...state,
    //     isLoadingAverage: true,
    //     averageData: [],
    //     isErrorAverage: false,
    //   };
    // }
    // case AVERAGE_RESULT_SUCCESS: {
    //   return {
    //     ...state,
    //     isLoadingAverage: false,
    //     averageData: action.payload,
    //     isErrorAverage: false,
    //   };
    // }
    // case AVERAGE_RESULT_FAILURE: {
    //   return {
    //     ...state,
    //     isLoadingAverage: false,
    //     errorAverageData: action.payload,
    //     isErrorAverage: true,
    //   };
    // }



    case RESULT_SEARCH_REQUEST: {
      return {
        ...state,
        isLoadingSearch: true,
        resultData: [],
        isErrorSearch: false,
      };
    }
    case RESULT_SEARCH_SUCCESS: {
      return {
        ...state,
        isLoadingSearch: false,
        resultData: action.payload,
        isErrorSearch: false,
      };
    }
    case RESULT_SEARCH_FAILURE: {
      return {
        ...state,
        isLoadingSearch: false,
        errorSearchData: action.payload,
        isErrorSearch: true,
      };
    }


    case ANS_SHEET_REQUEST: {
      return {
        ...state,
        isLoadingAns: true,
        ansData: {},
      };
    }
    case ANS_SHEET_SUCCESS: {
      return {
        ...state,
        isLoadingAns: false,
        ansData: action.payload,
      };
    }
    case ANS_SHEET_FAILURE: {
      return {
        ...state,
        isLoadingAns: false,
        errorAnsData: action.payload,
      };
    }


    default: {
      return state;
    }
  }
}