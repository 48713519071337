import axios from "axios";
import { EXPERIENCE_LIST_FAILURE, EXPERIENCE_LIST_REQUEST, EXPERIENCE_LIST_SUCCESS } from "./actionType";
import { errorCall } from "../../ErrorHandling/action";

export const exp_req = () => ({
  type: EXPERIENCE_LIST_REQUEST
});

export const exp_succ = (data) => ({
  type: EXPERIENCE_LIST_SUCCESS,
  payload: data
})
export const exp_failure = (err) => ({
  type: EXPERIENCE_LIST_FAILURE,
  payload: err
})
const getLocaldata = () => {
  const token = localStorage.getItem("token");
  return token
}
export const expList = (navigate) => (dispatch) => {
  dispatch(exp_req());
  const mt = getLocaldata()
  const config = {
    headers: { Authorization: `Bearer ${mt}` }
  };
  axios.get(`${process.env.REACT_APP_BASE_URL}/api/auth/experienceLevelList`, config).then((res) => {
    dispatch(exp_succ(res.data))
  }).catch((err) => {
    dispatch(exp_failure(err));
    if (err?.response?.data?.error == "Unauthenticated.") {
      localStorage.removeItem("token");
      navigate("/login");
    }
    if (err.message === "Network Error" || err.response.status === 500) {
      dispatch(errorCall(err))
      return
      }
  })
}


export const experienceshort = (navigate,search, status,page,perpage) => (dispatch) => {
  dispatch(exp_req());
  const mt = getLocaldata();
  const config = {
    headers: { Authorization: `Bearer ${mt}` }
  };
  const formdata = {
    "search": search,
    "status": status,
    "pageNumber":page,
    perpage
  }

  axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/sortExperienceLevels`, formdata, config).then((res) => {
    dispatch(exp_succ(res.data))
  }).catch((err) => {
    if (err?.response?.data?.error == "Unauthenticated.") {
      localStorage.removeItem("token");
      navigate("/login");
    }
   
    dispatch(exp_failure(err))
    if (err.message === "Network Error" || err.response.status === 500) {
      dispatch(errorCall(err))
  }
  })
}






