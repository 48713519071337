import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { statusUpdate } from "../../Redux/CandidateReducer/action";
import { toast } from "react-toastify";

const ListCard = (props) => {
  const { loading, data, loader ,page, lastPage } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleStatusUpdate = (e) => {
    const value = e.target.value
    const id = e.target.name
    dispatch(statusUpdate(value, id, navigate))
    .then((res)=>{
      const val=data.findIndex((e)=>e.id==id)
      data[val].color_candidate=res?.color
      return val
    })
    .catch((err)=>{
      console.log(err);
      dispatch((err))})
  }

  const usersPermission = localStorage?.getItem("userPermission")
  const permissionList = usersPermission?.split(',');

  const copyLink = (e) => {
    const path = `/candidates/profile/${e}`;
    const fullUrl = window.location.origin + path;

    navigator.clipboard.writeText(fullUrl).then(() => {
      toast.success("Link Copied");
    }).catch(err => {
      toast.error('Failed To Copy');
    });
  }

  return (
    <>
        <div className="row g-3 scroll_profile_card" ref={props.value}>
          {
            loader == false &&
            (data?.length > 0 ? (data?.map((element, index) => (
              <div className="h-100" key={index}>
                <div className="all_profile_box h-100" style={{ borderLeft: `5px solid ${element?.color_candidate}` }}>
                  <div className="row g-2">
                    <div className="col-auto col-lg-4">
                      <div className="top_sec d-xl-flex ">
                        <div className="me-5 mb-3">
                          {
                            element?.profile_image &&
                            <img src={`${element?.profile_image}`} alt=""/>
                          }
                          {
                            element?.profile_image == "" && element?.gender == "Female" &&

                            <img src="/images/female.png" alt="" />

                          }
                          {
                            element?.profile_image == "" && element?.gender == "Male" &&

                            <img src="/images/male.jpg" alt="" />

                          }
                          {
                            element?.profile_image == "" && element?.gender == "Unknown" &&

                            <img src="/images/user-alt.png" alt="" />

                          }
                        </div>
                        <div className="">
                          <div className="d-flex align-items-center ">
                            <p className="txt__md fw-bold me-1 mb-0">
                              <span>{element?.gender == "Female" ? "Ms." : "Mr."} {element?.first_name} {element?.last_name}</span>
                            </p>
                            <div className="copy-link-button" onClick={() => copyLink(element?.id)}>
                              <button
                                type="button"
                                data-original-title="null"
                                className="list_has_tooltip"
                              >
                                <i className="fa-solid fa-link"></i>
                              </button>
                            </div>
                            {permissionList?.includes("65bb924853e762c89b9af169") &&
                              <Link
                                to={`/candidates/edit-candidates/${element?.id}`}
                                className="list_has_tooltip"
                                data-original-title="null"
                              >
                                <i className="fa-solid fa-pencil"></i>
                              </Link>
                            }
                            <Link
                              to={`/candidates/all-candidates/profile/${element?.id}`}
                              className="list_has_tooltip ms-2"
                              data-original-title="null"
                            >
                              <i className="fa-solid fa-eye"></i>
                            </Link>
                          </div>
                          <div>
                            <p className="txt__sm txt__light mb-1">
                              {element?.experience_levels?.abbreviation} {element?.designation?.name}
                            </p>
                            {element?.job_histories?.length == 0 ? (
                              <div>
                                <p className="txt__sm txt__light mb-1">
                                  <span>No Job history found</span>
                                </p>
                              </div>) : (<> {element?.job_histories?.map((e, index) => (
                                <div key={index}>
                                  < p className="txt__sm txt__light mb-1">{e?.company} {e?.position} {e?.joining_month} {e?.joining_year} to {e?.termination_month} {e?.termination_year} </p>
                                </div>
                              ))}</>
                            )}
                            <div>
                              <p className="txt__sm txt__light mb-0">
                                {element?.contact?.phone_primary}
                              </p>
                              {element?.contact?.phone_secondary?.length > 0 &&
                                <p className="txt__sm txt__light mb-0">
                                  Emergency Phone No : {element?.contact?.phone_secondary}
                                </p>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-auto col-lg-6">
                      <div className="candidate-card-bottom mt-0 d-flex flex-wrap  align-items-center">
                        <select
                          className="form-control form-control-sm form-control-custom me-2"
                          style={{ fontSize: "12px", width: "140px" }}
                          onChange={handleStatusUpdate} name={element?.id} defaultValue={element?.status}
                        >
                          <option
                            value="default"
                            defaultValue="selected"
                            disabled="disabled"
                          >
                            Choose
                          </option>
                          <option value="0">New</option>
                          <option value="1">Reviewed</option>
                          <option value="2">Good Fit</option>
                          <option value="3">Interview Scheduled</option>
                          <option value="4">Offer made</option>
                          <option value="5">Hired</option>
                          <option value="6">Rejected</option>
                          <option value="7">On Hold</option>
                        </select>
                        {element?.update_date && <p className="txt__sm txt__light mb-0">
                          {element?.update_date}, Last Updated
                        </p>}
                      </div>
                    </div>

                    <div className="col-auto col-lg-2">
                      <div className="apply-date">
                        {element?.apply_date && <p className="txt__sm txt__light  mb-2">
                          Applied on {element?.apply_date}
                        </p>}
                        <p className="txt__sm txt__light">{element?.contact?.city}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))) : <p className="text-center">No Data</p>)}
          {(loading || loader )&&
          [1,2,3].map((e, index) => (
            <div key={index}className="h-70 placeholder-glow w-100 border-secondary border-0 ">
       <div className="all_profile_box h-100 placeholder w-100 " style={{ borderLeft: `5px solid `,background:"grey" }}>
        <div className="row g-2 ">
            <div className="col-auto col-lg-4">
                <div className="top_sec d-xl-flex ">
                    <div className="me-5 mb-3">
                        <img className="placeholder rounded-circle" src="/images/user-alt.png"  />
                    </div>
                    <div className="">
                        <div className="d-flex align-items-center ">
                            <p className="txt__md fw-bold me-1 mb-0 placeholder">
                                <span className="placeholder">abcbefghijklmnopqrstuvwxyzabcbefghijklmnopqrstuvwxyz</span>
                            </p>
                        </div>
                        <div>
                            <p className="txt__sm txt__light mb-1 placeholder">
                                <span className="placeholder">Placeholder Bio</span>
                            </p>
                            <div>
                                <p className="txt__sm txt__light mb-1">
                                    <span className="placeholder">No Job history found</span>
                                </p>
                            </div>
                            <div>
                                <p className="txt__sm txt__light mb-0">
                                    <span className="placeholder">element?.contact?.phone_primary</span>
                                </p>
                                <p className="txt__sm txt__light mb-0">
                                    <span className="placeholder">Emergency Phone No</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-sm-auto col-lg-6">
                <div className="candidate-card-bottom mt-0 d-flex flex-wrap align-items-center">
                </div>
            </div>
            <div className="col-auto col-lg-2">
                <div className="apply-date">
                    <p className="txt__sm txt__light mb-2 placeholder">
                        Applied on
                    </p>
                    <p className="txt__sm txt__light placeholder">element?.contact?.cit</p>
                </div>
            </div>
        </div>
    </div>
        </div>
          ))
          }
         {(data?.length != 0 && lastPage===page)&&
                <div className='d-flex align-items-center justify-content-center' style={{ textAlign: "center", height: '100px' }}>
                <div >No More Data</div>
          </div>}
          {/* {loader && (
            <div className='d-flex align-items-center justify-content-center' style={{ textAlign: "center", height: '100px' }}>
              <div className="loader"></div>
            </div>
          )} */}
        </div>
    </>
  );
};

export default ListCard;