import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addSkill } from "../../Redux/settings/add_skill/action";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { skills } from "../../Redux/CandidateReducer/action";
const PopUpModel = (props) => {
  const loadingData = useSelector((state) => state.addSkillSet.isLoading);
  const [show, setShowModel] = useState(false);
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notify = (message) => {
    toast(message);
  };

  const handleCloseSave = async (btnClick) => {
    // as required value send for save skill name
    if (name != "" && btnClick == "save") {
      const framework = 0;
      const skillId = null;
      const status = 1;
      dispatch(addSkill(framework, name, skillId, status, notify, navigate)).then((res)=>{
        if (res === undefined) {
          setShowModel(true);
        }else if(res){
          setName("")
          setShowModel(false);
          dispatch(skills(navigate))
        }
      });
      await props.setUpdateSkillList(true);
      
    } else {
      setShowModel(false);
    }
  };

  const handleShow = () => setShowModel(true);
  const nonNumericStart = (e) => {
    return !e.target.value || !/^\d/.test(e.target.value) ? e.target.value : "";
  };

  return (
    <>
     {props.title === "Skills" ? (
    <a href="#" onClick={handleShow}  className="m-1 text-primary">
      {props.header}
    </a>
  ) : (
    <Button variant="primary" onClick={handleShow}>
      {props.header}
    </Button>
  )}

      <Modal
        show={show}
        onHide={() => handleCloseSave("hide")}
        aria-labelledby="exampleModalLabel"
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            className="form-control"
            placeholder={props.placeholder}
            value={name ? name?.trimStart() : ""}
            onChange={(e) => {
              // checking wheather the value is non numeric
              setName(nonNumericStart(e));
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleCloseSave("close")}>
            Close
          </Button>
          <Button
            variant="primary"
            disabled={loadingData}
            onClick={() => handleCloseSave("save")}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PopUpModel;
