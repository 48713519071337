import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useDispatch, useSelector } from 'react-redux';
import NotImplemented from './NotImplemented';
import { errorCall } from '../../Redux/ErrorHandling/action';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Notfound from '../Notfound/Notfound';

const NetworkErrorPage = (props) => {
  var count = 1
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const path = useLocation().pathname
  const [paths, setpaths] = useState(path)
  const { errorData } = useSelector(state => state.errorReducer)
  console.log(errorData);
  const handleRefreshClick = () => {
    // this squence should not change
    // 1st
    navigate("/");
    // 2nd
    props?.setNotFoundSidebar(false);
    //3rd
    dispatch(errorCall());
  };
  const tryAgain = () => {
    window.location.reload();
  }
  useEffect(() => {
    props?.setNotFoundSidebar(!props?.notFoundSidebar);
  }, [])
  window.onpopstate = function (event) {
    window.location.reload();
    // dispatch(errorCall())
    // alert("Back button was clicked!",event); 
  };
  return (
    <>
      {errorData?.message === "Network Error" && <div className="network_eror_page position-relative">
        <img src="/images/network_img.png" alt="" className='network_img' />
        <img className=' error_log' src="/site_logo.png" alt="" />
        <div className='network_error_box d-flex align-items-center justify-content-center h-100 p-4'>

          <div className='d-flex align-items-center justify-content-center p-0 pt-md-5 ' style={{ minHeight: '490px', }}>
            <img src="/network.png" alt="" className='network_logo' />

            <div className=" error_content mt-5">
              <h2 className="display-4">Network Error!</h2>
              <p className=" text-start">We're sorry, but it seems there's a hiccup in our connection.d
                Please check your internet connection and try again. If the
                issue persists, our tech team is on it!</p>
              <div className='network_btn d-flex align-items-center justify-content-start '>
                <button onClick={(e) => tryAgain(e)}>
                  Try Again
                </button>
                <button onClick={(e) => handleRefreshClick(e)}>
                  Go Back To Home
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>}
      {errorData?.response?.status === 500 && <NotImplemented notFoundSidebar={props?.notFoundSidebar} setNotFoundSidebar={props?.setNotFoundSidebar} />}
      {errorData?.response?.status === 404 && <Notfound notFoundSidebar={props?.notFoundSidebar} setNotFoundSidebar={props?.setNotFoundSidebar} />}
    </>
  );
};

export default NetworkErrorPage;

