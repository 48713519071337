import React, { useEffect, useState } from 'react'
import SettingsNavbar from '../Components/SettingsNavbar'
import { Link, useNavigate } from 'react-router-dom'
import Avatar from '../Components/settings/Avatar'
import { BsFillArrowLeftCircleFill } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { addExperience } from '../Redux/settings/addExperience/action'
import { toast } from 'react-toastify'
import Spiner1 from '../Components/Spiner1'

const AddExperience = (props) => {
    const [name, SetName] = useState("");
    const [abbreviation, setAbbreviation] = useState("");
    const [SortingOrder, setSortingOrder] = useState("");
    const {isLoading}= useSelector((state)=> state.addExperienceSet)
    const [status, setStatus] = useState("1");
    const [note, setNote] = useState("");
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const handleName = (e) => {
        SetName(e.target.value);
    }
    const handleAbbreviation = (e) => {
        setAbbreviation(e.target.value);
    }
    const handleSortingOrder = (e) => {
        setSortingOrder(e.target.value);
    }
    const handlestatus = (e) => {
        setStatus(e.target.value);
    }
    const handlenote = (e) => {
        setNote(e.target.value);
    }
    const addExperiences = (e) => {
        e.preventDefault();
        SetName(name.trim());
        setAbbreviation(abbreviation.trim());
        setNote(note.trim());
        setSortingOrder(SortingOrder.trim());
        if (!name.trim()) {
            toast.warn("Please Provide Experience Level");
            return;
        }
    
        if (!abbreviation.trim()) {
            toast.warn("Please Provide Abbreviation");
            return;
        }
    
        if (!note.trim()) {
            toast.warn("Please Provide Note");
            return;
        }
    
        // if (SortingOrder.length === 0) {
        //     toast.warn("Please Select a Sorting Order");
        //     return;
        // }
            dispatch(addExperience(abbreviation, name, note, SortingOrder, status, navigate)).then((res)=>{
                setAbbreviation("");
                SetName("");
                setNote("");
                setSortingOrder("");
            });
        
    };

    return (
        <div>
            <SettingsNavbar navdata={{ header: "Add Experience", buttonText: "Experience Button", buttonPath: "/settings/skillsuggetion" }}      newSidedata={props.Sidedata} newonSidedataChange={props.onSidedataChange} />
            <div className="user-action-area">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Link to={"/settings/experience"}> <BsFillArrowLeftCircleFill /> Experience List</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="create-company-form">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form">
                                <div className="row g-2">
                                    <div className="col-md-4">
                                        <div className="position-relative list_search_fild">
                                            <input type="text" name="query" placeholder="Expereience Level Name"
                                                aria-label="" className="form-control coustom_P h-100" onChange={(e) => handleName(e)} value={name}/>
                                            <Avatar name={name} size={"34px"} position="absolute" top="3px" left="5px" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="position-relative list_search_fild">
                                            <input type="text" name="query" placeholder="Abbreviation"
                                                aria-label="" className="form-control coustom_P h-100" onChange={(e) => handleAbbreviation(e)} value={abbreviation}/>
                                            <Avatar name={abbreviation} size={"34px"} position="absolute" top="3px" left="5px" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <select className="col-sm-4 mb-2 form-control form-control-custom" value={status} onChange={(e) => handlestatus(e)}>
                                            <option value="1">Active</option>
                                            <option value="0">Inactive</option>
                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="position-relative list_search_fild">
                                            <input type="textArea" name="query" placeholder="Add Notes..."
                                                aria-label="" className="form-control h-100" onChange={(e) => handlenote(e)} value={note}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-success button-outline choosed mt-3" disabled={isLoading} onClick={(e) => addExperiences(e)}>
                                {isLoading ? <Spiner1/> : "Register"}
                            </button>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default AddExperience
