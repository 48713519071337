import React, { useEffect, useState } from 'react'
import "../Components/sidebar.css";
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logoutCall, tokenCall } from '../Redux/AuthReducer/action';
import Swal from 'sweetalert2';
import { specUserData } from '../Redux/User/action';

const Sidebar = ({ Sidedata, onSidedataChange, showSide, notFoundSidebar }) => {
    const navigate = useNavigate()
    const [locationPath, setLocationPath] = useState(null);
    const location = useLocation();
    const pathname = location.pathname;
    const dispatch = useDispatch();
    useEffect(() => {
        setLocationPath(pathname);
    }, [pathname]);

    const handleClick = () => {
        onSidedataChange(!Sidedata);
    };

    const parts = pathname.split('/');
    const childIndex = parts[1];

    const handleLogout = () => {
        dispatch(logoutCall(navigate))
    };

    const handleDeleteClick = async () => {
        const result = await Swal.fire({
            title: 'Are you sure, you want to logout?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3ab2e4',
            cancelButtonColor: 'grey',
            cancelButtonText: "No",
            confirmButtonText: 'Yes',

            customClass: {
                confirmButton: 'Swal_confirm',
                cancelButton: 'Swal_cancel',
                icon: 'Swal_icon',
                title: 'Swal_title'
            },
        });

        if (result.isConfirmed) {
            handleLogout()
        }
    };

    const usersPermission = localStorage.getItem("userPermission")
    const permissionList = usersPermission?.split(',');

    return (
        <>
            {showSide ? "" :
                <section className={`sidebar ${Sidedata ? 'active' : ''} ${notFoundSidebar ? 'notFoundSidebar' : ''}`}>
                    <button className='fa_xmark d-block d-lg-none' onClick={handleClick}>
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 px-0">
                                <Link to="/">
                                    <img src="/images/dashboard-logo.png" className="logo_sidebar" alt="" />
                                </Link>
                            </div>
                            <div className="col-12 px-0">
                                <div className="sideber_menu">
                                    <ul className="list-unstyled">
                                        <li className={locationPath === '/' ? 'active' : ''}>

                                            <span>
                                                <Link to="/" aria-current="page"
                                                    className="d-flex align-items-center router-link-exact-active router-link-active">
                                                    <span title="Dashboard" className="mnu">
                                                        <img src="/images/menu-icon1.png" alt="" />
                                                    </span>
                                                    Dashboard
                                                </Link>
                                            </span>
                                        </li>
                                        {(permissionList?.includes("60f6aebe1b38a2120c54a723") || permissionList?.includes("60f6aebe1b38a2120c54a724")) &&
                                            <li className="has_dropdown">
                                                <Link to="#candidateSubmenu" data-bs-toggle="collapse" aria-expanded="false"
                                                    className="dropdown-toggle d-flex align-items-center">
                                                    <span className="mnu">
                                                        <img src="/images/menu-icon3.png" alt="" />
                                                    </span>
                                                    Candidates
                                                </Link>
                                                <ul id="candidateSubmenu" className={`list-unstyled show_menu ${locationPath === '/candidates/all-candidates' || childIndex === 'all-candidates' || childIndex === 'edit-candidates' || locationPath === '/candidates/add-candidates' ? 'show  collapse' : 'collapse '}`}>
                                                    {/* {permissionList?.some(element => "60f6aebe1b38a2120c54a723".includes(element)) && */}
                                                    {permissionList?.includes("60f6aebe1b38a2120c54a723") &&
                                                        <li className={locationPath === '/candidates/all-candidates' || childIndex === '/candidates/edit-candidates' || childIndex === 'all-candidates' || location?.pathname.startsWith("/candidates/edit-candidates") || location?.pathname.startsWith("/candidates/all-candidates/") ? 'active' : ''}>
                                                            <span>
                                                                <Link to="/candidates/all-candidates" className="router-link-exact-active router-link-active" aria-current="page"> All Candidates </Link>
                                                            </span>
                                                        </li>
                                                    }
                                                    {/* {permissionList?.some(element => "60f6aebe1b38a2120c54a724".includes(element)) && */}
                                                    {permissionList?.includes("60f6aebe1b38a2120c54a724") &&
                                                        <li className={locationPath === '/candidates/add-candidates' ? 'active' : ''}>
                                                            <span>
                                                                <Link to="/candidates/add-candidates" className="router-link-exact-active router-link-active" aria-current="page"> Add Candidates </Link>
                                                            </span>
                                                        </li>
                                                    }
                                                </ul>
                                            </li>
                                        }
                                        {(permissionList?.includes("60f6aebe1b38a2120c54a728") || permissionList?.includes("60f6aebe1b38a2120c54a729") || permissionList?.includes("60f6aebe1b38a2120c54a72a") || permissionList?.includes("60f6aebe1b38a2120c54a72b") || permissionList?.includes("60f6aebe1b38a2120c54a72c") || permissionList?.includes("6113f2034b847d2e0e11d622") || permissionList?.includes("6113f2034b847d2e0e11d623") || permissionList?.includes("6113f2034b847d2e0e11d624")) &&

                                            <li className="has_dropdown">
                                                <Link to="#seetingSubmenu" data-bs-toggle="collapse" aria-expanded="false"
                                                    className="dropdown-toggle d-flex align-items-center">
                                                    <span className="mnu">
                                                        <img src="/images/menu-icon5.png" alt="" />
                                                    </span>Settings
                                                </Link>
                                                <ul id="seetingSubmenu" className={` list-unstyled show_menu ${locationPath === '/settings/skills' || locationPath === '/settings/designation'
                                                    || locationPath === '/settings/experience' || locationPath === '/settings/source' || locationPath === '/settings/user-profile' || locationPath === '/settings/user-permission'
                                                    || locationPath === '/settings/user-management' || locationPath === '/settings/menu-management' || childIndex === 'settings' ? 'show  collapse' : 'collapse '}`}>

                                                    {/* {permissionList?.some(element => "60f6aebe1b38a2120c54a728".includes(element)) && */}
                                                    {permissionList?.includes("60f6aebe1b38a2120c54a728") &&
                                                        <li className={locationPath === '/settings/skills' || locationPath === '/settings/add-skill' || parts[2] === 'update-skill' ? 'active' : ''}>
                                                            <span>
                                                                <Link to="/settings/skills" className="router-link-exact-active router-link-active" aria-current="page"> Skill </Link>
                                                            </span>
                                                        </li>
                                                    }
                                                    {/* {permissionList?.some(element => "60f6aebe1b38a2120c54a729".includes(element)) && */}
                                                    {permissionList?.includes("60f6aebe1b38a2120c54a729") &&
                                                        <li className={locationPath === '/settings/designation' || locationPath === '/settings/add-designation' || parts[2] === 'update-designation' ? 'active' : ''}>
                                                            <span>
                                                                <Link to="/settings/designation" className="router-link-exact-active router-link-active" aria-current="page"> Designation </Link>
                                                            </span>
                                                        </li>
                                                    }
                                                    {/* {permissionList?.some(element => "60f6aebe1b38a2120c54a72a".includes(element)) && */}
                                                    {permissionList?.includes("60f6aebe1b38a2120c54a72a") &&
                                                        <li className={locationPath === '/settings/experience' || locationPath === '/settings/add-experience' || parts[2] === 'update-experience' ? 'active' : ''}>
                                                            <span>
                                                                <Link to="/settings/experience" className="router-link-exact-active router-link-active" aria-current="page">  Experience Levels </Link>
                                                            </span>
                                                        </li>
                                                    }
                                                    {/* {permissionList?.some(element => "60f6aebe1b38a2120c54a72b".includes(element)) && */}
                                                    {permissionList?.includes("60f6aebe1b38a2120c54a72b") &&
                                                        <li className={locationPath === '/settings/source' || locationPath === '/settings/add-source' || parts[2] === 'edit-source' ? 'active' : ''}>
                                                            <span>
                                                                <Link to="/settings/source" className="router-link-exact-active router-link-active" aria-current="page"> Source Management </Link>
                                                            </span>
                                                        </li>
                                                    }
                                                    {/* {permissionList?.some(element => "60f6aebe1b38a2120c54a72c".includes(element)) && */}
                                                    {permissionList?.includes("60f6aebe1b38a2120c54a72c") &&
                                                        <li className={locationPath === '/settings/user-profile' ? 'active' : ''}>
                                                            <span>
                                                                <Link to="/settings/user-profile" className="router-link-exact-active router-link-active" aria-current="page"> My Profile </Link>
                                                            </span>
                                                        </li>
                                                    }
                                                    {/* {permissionList?.some(element => "6113f2034b847d2e0e11d622".includes(element)) && */}
                                                    {permissionList?.includes("6113f2034b847d2e0e11d622") &&
                                                        <li className={locationPath === '/settings/user-permission' || parts[2] === 'update-user-permission' ? 'active' : ''}>
                                                            <span>
                                                                <Link to="/settings/user-permission" className="router-link-exact-active router-link-active" aria-current="page"> User Type Permission </Link>
                                                            </span>
                                                        </li>
                                                    }
                                                    {/* {permissionList?.some(element => "6113f2034b847d2e0e11d623".includes(element)) && */}
                                                    {permissionList?.includes("6113f2034b847d2e0e11d623") &&
                                                        <li className={locationPath === '/settings/user-management' || locationPath === '/settings/add-user-type' || parts[2] === 'update-user-type' ? 'active' : ''}>
                                                            <span>
                                                                <Link to="/settings/user-management" className="router-link-exact-active router-link-active" aria-current="page"> User Type Management </Link>
                                                            </span>
                                                        </li>
                                                    }
                                                    {/* {permissionList?.some(element => "6113f2034b847d2e0e11d624".includes(element)) && */}
                                                    {permissionList?.includes("6113f2034b847d2e0e11d624") &&
                                                        <li className={locationPath === '/settings/menu-management' || locationPath === '/settings/add-management' || parts[2] === 'update-menu-management' ? 'active' : ''}>
                                                            <span>
                                                                <Link to="/settings/menu-management" className="router-link-exact-active router-link-active" aria-current="page"> Menu Management </Link>
                                                            </span>
                                                        </li>
                                                    }
                                                </ul>
                                            </li>
                                        }
                                        <span>

                                        </span>
                                        {(permissionList?.includes("61ae06f5edb406550227b942") || permissionList?.includes("61ae06f5edb406550227b943")) &&
                                            < li className="has_dropdown">
                                                <Link to="#jobSubmenu" data-bs-toggle="collapse" aria-expanded="false"
                                                    className="dropdown-toggle d-flex align-items-center">
                                                    <span className="mnu">
                                                        <img src="/logos/jobsLogo.png" alt="" />
                                                    </span>Jobs
                                                </Link>
                                                <ul id="jobSubmenu" className={`list-unstyled show_menu  ps-0 ${locationPath === '/jobs/create-job' || parts[1] === 'jobs' || locationPath === '/jobs/create-job-list' ? 'show  collapse' : 'collapse '}`} >
                                                    {/* {permissionList?.some(element => "61ae06f5edb406550227b942".includes(element)) && */}
                                                    {permissionList?.includes("61ae06f5edb406550227b942") &&
                                                        <li className={locationPath === '/jobs/create-job' || parts[2] === 'edit-job' ? 'active' : ''}>
                                                            <span>
                                                                <Link to="/jobs/create-job" className="router-link-exact-active"> Create Job </Link>
                                                            </span>
                                                        </li>
                                                    }
                                                    {/* {permissionList?.some(element => "61ae06f5edb406550227b943".includes(element)) && */}
                                                    {permissionList?.includes("61ae06f5edb406550227b943") &&
                                                        <li className={locationPath === '/jobs/create-job-list' ? 'active' : ''}>
                                                            <span>
                                                                <Link to="/jobs/create-job-list" className="router-link-exact-active"> Job List </Link>
                                                            </span>
                                                        </li>
                                                    }
                                                </ul>
                                            </li>
                                        }
                                        {/* {permissionList?.some(element => "610118960b660a49d05022a2".includes(element)) && */}
                                        {permissionList?.includes("610118960b660a49d05022a2") &&
                                            <li className={locationPath === '/user/user-list' || parts[2] === 'user-edit' || parts[2] === 'user-create' ? 'active' : ''}>

                                                <span><Link to="/user/user-list">
                                                    <span title="Users list" className="mnu"><img src="/images/user_icon.png"
                                                        alt="user" /></span>
                                                    Users
                                                </Link>
                                                </span>

                                            </li>
                                        }
                                        {(permissionList?.includes("65968372f78bc5ceb87bc1d1") || permissionList?.includes("659683c1f78bc5ceb87bc1d2")) &&
                                            <li className={locationPath === '/test/all-test' ? 'active' : ''}>
                                                <Link to="/test/all-test"
                                                    className=" d-flex align-items-center " >
                                                    <span className="mnu">
                                                        <img src="/logos/testLogo.png" alt="" />
                                                    </span>
                                                    Tests</Link>
                                                {/* <ul id="testSubmenu" className={`list-unstyled show_menu  ps-0 ${locationPath === '/test/all-test' || parts[1] === 'test' || locationPath === '/test/custom-test' ? 'show  collapse' : 'collapse '}`} >
                                                    {permissionList?.includes("65968372f78bc5ceb87bc1d1") &&
                                                        <li className={locationPath === '/test/all-test' || parts[2] === 'edit-job' || location?.state?.from === '/test/all-test' ? 'active' : ''}>
                                                            <span>
                                                                <Link to="/test/all-test" className="router-link-exact-active"> All Test</Link>
                                                            </span>
                                                        </li>
                                                    }
                                                    {permissionList?.includes("659683c1f78bc5ceb87bc1d2") &&
                                                        <li className={locationPath === '/test/custom-test' || location?.state?.from === '/test/custom-test' ? 'active' : ''}>
                                                            <span>
                                                                <Link to="/test/custom-test" className="router-link-exact-active"> Custom Test </Link>
                                                            </span>
                                                        </li>
                                                    }
                                                </ul> */}
                                            </li>
                                        }
                                        {(permissionList?.includes("65968433f78bc5ceb87bc1d4") || permissionList?.includes("659684abf78bc5ceb87bc1d5")) &&
                                            <li className="has_dropdown">
                                                <Link to="#resultSubmenu" data-bs-toggle="collapse" aria-expanded="false"
                                                    className="dropdown-toggle d-flex align-items-center" >
                                                    <span className="mnu">
                                                        <img src="/logos/resultLogo.png" alt="" />
                                                    </span>
                                                    Result</Link>
                                                <ul id="resultSubmenu" className={`list-unstyled show_menu  ps-0 ${locationPath === '/result/test-result' || location?.state?.from === '/result/test-result' || parts[1] === 'result' || locationPath === '/result/archive-result' ? 'show  collapse' : 'collapse '}`} >
                                                    {/* {permissionList?.some(element => "65968433f78bc5ceb87bc1d4".includes(element)) && */}
                                                    {permissionList?.includes("65968433f78bc5ceb87bc1d4") &&
                                                        <li className={locationPath === '/result/test-result' || location?.state?.from === "/result/test-result" || parts[2] === 'edit-job' ? 'active' : ''}>
                                                            <span>
                                                                <Link to="/result/test-result" className="router-link-exact-active"> Test Result </Link>
                                                            </span>
                                                        </li>
                                                    }
                                                    {/* {permissionList?.some(element => "659684abf78bc5ceb87bc1d5".includes(element)) && */}
                                                    {permissionList?.includes("659684abf78bc5ceb87bc1d5") &&
                                                        <li className={locationPath === '/result/archive-result' || location?.state?.from === "/result/archive-result" ? 'active' : ''}>
                                                            <span>
                                                                <Link to="/result/archive-result" className="router-link-exact-active"> Archive Result </Link>
                                                            </span>
                                                        </li>
                                                    }
                                                </ul>
                                            </li>
                                        }
                                        {(permissionList?.includes("659684edf78bc5ceb87bc1d6") || permissionList?.includes("6596851af78bc5ceb87bc1d7")) &&
                                            <li className="has_dropdown">
                                                <Link to="#emailSubmenu" data-bs-toggle="collapse" aria-expanded="false"
                                                    className="dropdown-toggle d-flex align-items-center" >
                                                    <span className="mnu">
                                                        <img src="/logos/emailLogo.png" alt="" />
                                                    </span>
                                                    Email</Link>
                                                <ul id="emailSubmenu" className={`list-unstyled show_menu  ps-0 ${locationPath === '/email/create-template' || parts[1] === 'email' || locationPath === '/email/template-list' ? 'show  collapse' : 'collapse '}`} >
                                                    {/* {permissionList?.some(element => "659684edf78bc5ceb87bc1d6".includes(element)) && */}
                                                    {permissionList?.includes("659684edf78bc5ceb87bc1d6") &&
                                                        <li className={locationPath === '/email/create-template' || parts[2] === 'edit-job' ? 'active' : ''}>
                                                            <span>
                                                                <Link to="/email/create-template" className="router-link-exact-active">Create Template</Link>
                                                            </span>
                                                        </li>
                                                    }
                                                    {/* {permissionList?.some(element => "6596851af78bc5ceb87bc1d7".includes(element)) && */}
                                                    {permissionList?.includes("6596851af78bc5ceb87bc1d7") &&
                                                        <li className={locationPath === '/email/template-list' || location?.state?.from === "/email/template-list" ? 'active' : ''}>
                                                            <span>
                                                                <Link to="/email/template-list" className="router-link-exact-active">Template List</Link>
                                                            </span>
                                                        </li>
                                                    }
                                                </ul>
                                            </li>
                                        }
                                        <li onClick={handleDeleteClick}>
                                            <Link className="" >
                                                <span title="Logout" className="mnu">
                                                    <img src="/images/log_out.png" alt="icon" />
                                                </span>Logout
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >}
        </>
    )
}

export default Sidebar