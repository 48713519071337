import React, { useEffect, useState } from 'react';
import SettingsNavbar from '../Components/SettingsNavbar';
import { Link, useNavigate } from 'react-router-dom';
import { BsFillArrowLeftCircleFill } from 'react-icons/bs';
import Avatar from '../Components/settings/Avatar';
import { useDispatch, useSelector } from 'react-redux';
import { addSkill } from '../Redux/settings/add_skill/action';
import { skilList } from '../Redux/settings/skill/action';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spiner1 from './../Components/Spiner1';
const AddSkill = (props) => {
    const [Handleradio, setHandleRadio] = useState("0");
    const [skillname, setSkillname] = useState("");
    const [status1, setStatus] = useState(1);
    const [skillId, setskillId] = useState("");
    const skillList = useSelector(state => state.getSkillSet);
    const {isLoading} = useSelector(state => state.addSkillSet);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(skilList(navigate));
    }, []);
    const handleRadio = (e) => {
        setHandleRadio(e.target.value)
        if (e.target.value == "0") {
            setskillId(null)
        }
    }
    const changeSkilName = (e) => {
        setSkillname(e.target.value);
    }
    const addSkillA = async(e) => {
        e.preventDefault()
        setSkillname(skillname.trim());
         if (skillname.trim().length > 0) {
            if(skillId == null && Handleradio == 1){
                toast.warn("Please Choose Skill Name");
               return;
            }
            dispatch(addSkill(Handleradio, skillname, skillId, status1, navigate))
          
        }else if((skillname == null || skillname == "") && Handleradio == 1  ){
            toast.warn("Please Enter Framework Name");
        }
        else{
            toast.warn("Please Enter Skill Name");
        }
    }
    return (
        <div>
            <SettingsNavbar navdata={{ header: "Skill", buttonText: "Skill Button", buttonPath: "/settings/skillsuggetion" }} newSidedata={props.Sidedata} newonSidedataChange={props.onSidedataChange} />
            <div>

            </div>
            <div className="user-action-area">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Link to={"/settings/skills"}> <BsFillArrowLeftCircleFill /> Skill List</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="create-company-form">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="type">
                                <span className='font-weight-bold ' >Type</span>
                            </div>
                            <div className="radio_buttons">
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="inlineRadioOptions"
                                        id="inlineRadio1"
                                        defaultValue="0"
                                        onChange={(e) => handleRadio(e)}
                                        defaultChecked={Handleradio === "0"}
                                    />
                                    <label className="form-check-label" htmlFor="inlineRadio1">
                                        Skill
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="inlineRadioOptions"
                                        id="inlineRadio2"
                                        defaultValue="1"
                                        onChange={(e) => handleRadio(e)}
                                        defaultChecked = {Handleradio === "1"}
                                    />
                                    <label className="form-check-label" htmlFor="inlineRadio2">
                                        Framework
                                    </label>
                                </div>
                            </div>
                            {Handleradio === '0' ? <div className="form">
                                <div className="row g-2">
                                    <div className="col-md-4">
                                        <div className="position-relative list_search_fild">
                                            <input type="text" name="query" placeholder="Skill Name"
                                                aria-label="" value={skillname} className="form-control coustom_P h-100" onChange={(e) => changeSkilName(e)} required/>
                                            <Avatar name={skillname} size={"34px"} position="absolute" top="3px" left="5px" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <select className="col-sm-4 mb-2 form-control form-control-custom" value={status1}onChange={(e) => setStatus(e.target.value)} >
                                            <option value={1}>Active</option>
                                            <option value={0}>Inactive</option>
                                        </select>
                                    </div>
                                </div>
                            </div> : <div className="form">
                                <div className="row g-2">
                                    <div className="col-md-4">
                                        <div className="position-relative list_search_fild">
                                            <input type="text" name="query" placeholder="Framework Name"
                                                aria-label="" value={skillname} className="form-control coustom_P h-100" onChange={(e) => changeSkilName(e)} />
                                            <Avatar name={skillname} size={"34px"} position="absolute" top="3px" left="5px" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <select className="col-sm-4 mb-2 form-control form-control-custom" value={skillId} onChange={(e) => setskillId(e.target.value)}>
                                            <option value="" hidden >Select Skill </option>
                                            {
                                                skillList?.data?.data?.map((skill,index) => (
                                                    skill?.framework==0
                                                    ?<option key={index} value={skill?.id}>{skill?.name}</option>
                                                    :null
                                                    
                                                ))
                                            }
                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <select className="col-sm-4 mb-2 form-control form-control-custom"value={status1} onChange={(e) => setStatus(e.target.value)}>
                                            <option value={1} >Active</option>
                                            <option value={0}>Inactive</option>
                                        </select>
                                    </div>
                                </div>
                            </div>}
                            <button disabled={isLoading} className="btn btn-success button-outline choosed mt-3" onClick={(e) => addSkillA(e)}>
                                {isLoading ? <Spiner1/> : "Register" }
                            </button>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default AddSkill