import { UPDATE_PERMISSION_FAILURE, UPDATE_PERMISSION_REQUEST, UPDATE_PERMISSION_SUCCESS } from "./actionType"

const initialState = {
    isLoading: false,
    data: [],
    isError: false,
    errorData: ""
}

export const updatePermission = (state = initialState,action)=>{
     switch(action.type){
        case UPDATE_PERMISSION_REQUEST:{
           return{
            isLoading : true,
            data : [],
            isError : false
           }
        }
        case UPDATE_PERMISSION_SUCCESS:{
            return{
                isLoading : false,
                data : action.payload,
                isError : false,
            }
        }
        case UPDATE_PERMISSION_FAILURE:{
            return{
                isLoading : false,
                data : [],
                isError : true,
                errorData:action.payload
            }
        }
        default : {
            return state
         }
     }
}