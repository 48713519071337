import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { statusUpdate } from '../../Redux/CandidateReducer/action';
import { toast } from 'react-toastify';

const GridCard = (props) => {
    const { loading, value, data, loader } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const candidateLists = useSelector(state => state.Candidate_Reducer);

    const usersPermission = localStorage?.getItem("userPermission")
    const permissionList = usersPermission?.split(',');

    const handleStatusUpdate = (e) => {
        const value = e.target.value
        const id = e.target.name
        dispatch(statusUpdate(value, id, navigate)).then((res)=>{
            const val=data.findIndex((e)=>e.id==id)
            data[val].color_candidate=res?.color
            return val
        })
        .catch((err)=>{
            console.log(err);
            dispatch((err))})
    }

    const copyLink = (e) => {
        const path = `/candidates/profile/${e}`;
        const fullUrl = window.location.origin + path;

        navigator.clipboard.writeText(fullUrl).then(() => {
            toast.success("Link Copied");
        }).catch(err => {
            toast.error('Failed To Copy');
        });
    }

    return (
        <>
            {candidateLists?.candidateDataAllResponse?.meta?.current_page > candidateLists?.candidateDataAllResponse?.meta?.last_page ?
                "No Data"
                : <div className="row  g-3 grid_scroll_profile_card" ref={value}>
                    {loader == false &&
                        (data?.length > 0 ? (data?.map((element, index) => (
                            <div className="col-sm-6 col-xl-4" key={index}>
                                <div className="h-100">
                                    <div className="all_profile_box h-100" style={{ borderLeft: `5px solid ${element?.color_candidate}` }}>
                                        <div className="row ">
                                            <div className="col-12">
                                                <div className="top_sec d-xl-flex ">
                                                    {
                                                        element?.profile_image &&
                                                        <div className="me-4">
                                                            {/* <img src={element?.profile_image.replace("\/uploads\/profile\/", `${process.env.REACT_APP_BASE_URL}/uploads/profile/`)} alt="" /> */}
                                                            <img src={`${element?.profile_image}`} alt="" />
                                                        </div>
                                                    }
                                                    {
                                                        element?.profile_image == "" && element?.gender == "Female" &&
                                                        <div className="me-4">
                                                            <img src="/images/female.png" alt="" />
                                                        </div>
                                                    }
                                                    {
                                                        element?.profile_image == "" && element?.gender == "Male" &&
                                                        <div className="me-4">
                                                            <img src="/images/male.jpg" alt="" />
                                                        </div>
                                                    }
                                                    {
                                                        element?.profile_image == "" && element?.gender == "Unknown" &&
                                                        <div className="me-4">
                                                            <img src="/images/user-alt.png" alt="" />
                                                        </div>
                                                    }
                                                    <div className="">
                                                        <div className="d-flex align-items-center=">
                                                            <p className="txt__md fw-bold me-1 mb-0">
                                                                <span>{element?.gender == "Female" ? "Ms." : "Mr."} {element?.first_name} {element?.last_name}</span>
                                                            </p>
                                                            <div className="copy-link-button" onClick={() => copyLink(element?.id)}>
                                                                <button
                                                                    type="button"
                                                                    data-original-title="null"
                                                                    className="list_has_tooltip"
                                                                >
                                                                    <i className="fa-solid fa-link"></i>
                                                                </button>
                                                            </div>
                                                            {permissionList?.includes("65bb924853e762c89b9af169") &&
                                                                <Link
                                                                    to={`/candidates/edit-candidates/${element?.id}`}
                                                                    className="list_has_tooltip"
                                                                    data-original-title="null"
                                                                >
                                                                    <i className="fa-solid fa-pencil"></i>
                                                                </Link>
                                                            }
                                                            <Link
                                                                to={`/candidates/all-candidates/profile/${element?.id}`}
                                                                className="list_has_tooltip ms-2"
                                                                data-original-title="null"
                                                            >
                                                                <i className="fa-solid fa-eye"></i>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <div className='mb-2'>
                                                    <p className="txt__sm txt__light mb-1">
                                                        {element?.experience_levels?.abbreviation} {element?.designation?.name}
                                                    </p>
                                                    {element?.job_histories?.length == 0 ? (
                                                        <div>
                                                            <p className="txt__sm txt__light mb-1">
                                                                <span>No Job history found</span>
                                                            </p>
                                                        </div>) : (<> {element?.job_histories?.map((e, index) => (
                                                            <div key={index}>
                                                                < p className="txt__sm txt__light mb-1">{e?.company} {e?.position}  {e?.joining_month} {e?.joining_year} to {e?.termination_month} {e?.termination_year} </p>
                                                            </div>
                                                        ))}</>
                                                    )}
                                                    <div>
                                                        <p className="txt__sm txt__light mb-0">
                                                            {element?.contact?.phone_primary}
                                                        </p>
                                                        {element?.contact?.phone_secondary?.length > 0 &&
                                                            <p className="txt__sm txt__light mb-0">
                                                                Emergency Phone No : {element?.contact?.phone_secondary}
                                                            </p>}
                                                    </div>
                                                </div>
                                                <div className="candidate-card-bottom mt-0 d-flex flex-wrap  align-items-center">
                                                    <select
                                                        className="form-control form-control-sm form-control-custom me-2"
                                                        style={{ fontSize: "12px", width: "140px" }}
                                                        onChange={handleStatusUpdate} name={element?.id} defaultValue={element?.status}
                                                    >
                                                        <option
                                                            value="default"
                                                            defaultValue="selected"
                                                            disabled="disabled"
                                                        >
                                                            Choose
                                                        </option>
                                                        <option value="0">New</option>
                                                        <option value="1">Reviewed</option>
                                                        <option value="2">Good Fit</option>
                                                        <option value="3">Interview Scheduled</option>
                                                        <option value="4">Offer made</option>
                                                        <option value="5">Hired</option>
                                                        <option value="6">Rejected</option>
                                                        <option value="7">On Hold</option>
                                                    </select>
                                                    {element?.update_date && <p className="txt__sm txt__light mb-0">
                                                        {element?.update_date}, Last Updated
                                                    </p>}
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))) : <p className="text-center">No Data</p>)}
                    {loading && (
                        <div className='d-flex align-items-center justify-content-center' style={{ textAlign: "center", height: '100px' }}>
                            <div className="loader"></div>
                        </div>
                    )}
                    {/* {loader && (
                        <div className='d-flex align-items-center justify-content-center' style={{ textAlign: "center", height: '100px' }}>
                            <div className="loader"></div>
                        </div>
                    )} */}
                </div>}
        </>
    )
}

export default GridCard;