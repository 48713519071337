import React, { useState } from "react";
import SettingsNavbar from "../Components/SettingsNavbar";
import { Link, useNavigate } from "react-router-dom";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import Avatar from "../Components/settings/Avatar";
import { useDispatch, useSelector } from "react-redux";
import { addDesignation } from "../Redux/settings/add_designation/action";
import Spiner1 from "../Components/Spiner1";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddDesignation = (props) => {
  const [designationName, setDesignationName] = useState("");
  const [designationStatus, setDesignationStatus] = useState("1");
  const dispatch = useDispatch();
  const userAdd = useSelector((state) => state.addDesignationset);
  const navigate = useNavigate();

  const notify = (message) => toast(message);
  const changeDesignationName = (e) => {
    e.preventDefault();
    setDesignationName(e.target.value);
  };
  const addDesignationS = (e) => {
    e.preventDefault();
    const trimmedDesignationName = designationName.trim();
    setDesignationName(trimmedDesignationName);
    // Validate the input data
    if (trimmedDesignationName.length === 0) {
      toast.warn("Please Enter designation name");
      return;
    }

    dispatch(addDesignation(trimmedDesignationName, designationStatus, notify, navigate)).then((res) => {
      setDesignationName("")
    })
  };
  return (
    <div>
      <SettingsNavbar
        navdata={{
          header: "Add Designation",
          buttonText: "designation Button",
          buttonPath: "/settings/skillsuggetion",
        }}
        newSidedata={props.Sidedata} newonSidedataChange={props.onSidedataChange}
      />
      <div className="user-action-area">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <Link to={"/settings/designation"}>
                {" "}
                <BsFillArrowLeftCircleFill /> Designation List
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="create-company-form">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="form">
                <div className="row g-2">
                  <div className="col-md-4">
                    <div className="position-relative list_search_fild">
                      <input
                        type="text"
                        name="query"
                        placeholder="Designation Name"
                        aria-label=""
                        value={designationName?designationName:""}
                        className="form-control coustom_P h-100"
                        onChange={(e) => changeDesignationName(e)}
                      />
                      <Avatar
                        name={designationName?designationName:""}
                        size={"34px"}
                        position="absolute"
                        top="3px"
                        left="5px"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <select
                    value={designationStatus?designationStatus:""}
                      className="col-sm-4 mb-2 form-control form-control-custom"
                      onChange={(e) => setDesignationStatus(e.target.value)}
                    >
                      <option value="1">
                        Active
                      </option>
                      <option value="0">Inactive</option>
                    </select>
                  </div>
                </div>
              </div>
              <button
                className="btn btn-success button-outline choosed mt-3"
                disabled={userAdd?.isLoading ? true : false}
                onClick={(e) => addDesignationS(e)}
              >
                {userAdd?.isLoading ? <Spiner1 /> : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDesignation;
