import React, { useCallback, useEffect, useState } from 'react'
import SettingsNavbar from '../Components/SettingsNavbar'
import { useDispatch, useSelector } from 'react-redux';
import { sourceList, sourceshort } from '../Redux/settings/source_management/action';
import Avatar from '../Components/settings/Avatar';
import { Link, useNavigate } from 'react-router-dom';
import { globalDebouncing } from '../Debouncing/globalDebouncing';
import Paginate from '../TestPortal/components/Pagination/Pagination';

const SourceManagement = ({ Sidedata, onSidedataChange }) => {
    const [search, setSearch] = useState("");
    const [status, setStatus] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const Source = useSelector(state => state.getSourceSet);
    const [page,setPage]=useState(1);
    const [perPage,setPerPage]=useState(10);
    const totalPages = useSelector(
        state => state.getSourceSet?.data?.total_page
    );

    // const debounceDelay = 800; // Adjust the delay as per your requirement
    const usersPermission = localStorage?.getItem("userPermission")
    const permissionList = usersPermission?.split(',');

    // const debouncedSearch = useCallback(
    //     debounce((value, status) => {
    //         dispatch(sourceshort(navigate,value, status));
    //     }, debounceDelay),
    //     []
    // );

    const clearfilter=(e)=>{
        e.preventDefault();
        setPage(1);
        setSearch("");
        setStatus("");
        dispatch(sourceshort(navigate,"","", 1,perPage));
    };
    const pageChange=(e)=>{
        setPage(e);
        dispatch(sourceshort(navigate,search, status, e,perPage));
        
    };
    const handleInputChange = (e) => {
        setSearch(e.target.value);
        globalDebouncing(dispatch,sourceshort,navigate,e.target.value, status,1,perPage);
        setPage(1);
    };
    const handlestatusInputChange = (e) => {
        setStatus(e.target.value);
        setPage(1);
        // dispatch(skillShort(search, status, type));
    };
    
    const handlePageSizeChange=(e)=>{
        setPage(1);
        setPerPage(parseInt(e.target.value));
    }
    useEffect(() => {
        dispatch(sourceshort(navigate,search, status,page,perPage));
    }, [status,perPage]);
    return (
        <div>
            <SettingsNavbar newSidedata={Sidedata} newonSidedataChange={onSidedataChange} navdata={{ header: "Lead Sources", buttonText: "Sources Suggetion", buttonPath: "/settings/skillsuggetion" }} />
            <div className="container-fluid">
                <div className="row g-3">
                    {permissionList?.includes("65bb94d653e762c89b9af170") &&
                        <div className="col-md-12">
                            <div className="companyAdmin_action_area mt-4">
                                <div className="add_companyAdmins">
                                    <Link
                                        to={"/settings/add-source"}
                                        className="add_companyAdmin_link d-flex align-items-center"
                                    >
                                        <span className="icon d-flex align-items-center">
                                            <svg
                                                width="20px"
                                                height="12px"
                                                viewBox="0 0 20 12"
                                                version="1.1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                            >
                                                <g id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                                    <g id="add-lead-source" fill="#000000" fillRule="nonzero">
                                                        <g id="people">
                                                            <path
                                                                d="M15,12 C15,12 16,12 16,11 C16,10 15,7 11,7 C7,7 6,10 6,11 C6,12 7,12 7,12 L15,12 Z M7.022,11 C7.01461289,10.9989816 7.00727282,10.997647 7,10.996 C7.001,10.732 7.167,9.966 7.76,9.276 C8.312,8.629 9.282,8 11,8 C12.717,8 13.687,8.63 14.24,9.276 C14.833,9.966 14.998,10.733 15,10.996 L14.992,10.998 C14.9873513,10.998787 14.9826831,10.9994539 14.978,11 L7.022,11 Z M11,5 C12.1045695,5 13,4.1045695 13,3 C13,1.8954305 12.1045695,1 11,1 C9.8954305,1 9,1.8954305 9,3 C9,4.1045695 9.8954305,5 11,5 Z M14,3 C14,4.07179678 13.4282033,5.06217787 12.5,5.59807626 C11.5717968,6.13397466 10.4282032,6.13397466 9.5,5.59807626 C8.57179674,5.06217787 8,4.07179678 8,3 C8,1.34314575 9.34314575,-1.11022302e-15 11,-1.11022302e-15 C12.6568542,-1.11022302e-15 14,1.34314575 14,3 L14,3 Z M6.936,7.28 C6.53598164,7.15403836 6.12363525,7.07123384 5.706,7.033 C5.47133201,7.01068507 5.23572636,6.99967235 5,6.9999929 C1,6.9999929 0,9.9999929 0,10.9999929 C0,11.667 0.333,11.9999929 1,11.9999929 L5.216,11.9999929 C5.0677551,11.6878153 4.99382358,11.3455397 5,10.9999929 C5,9.99 5.377,8.958 6.09,8.096 C6.333,7.802 6.616,7.527 6.936,7.28 L6.936,7.28 Z M4.92,8 C4.32815398,8.88927177 4.00844009,9.93181708 4,11 L1,11 C1,10.74 1.164,9.97 1.76,9.276 C2.305,8.64 3.252,8.02 4.92,8.001 L4.92,8 Z M1.5,3.5 C1.5,1.84314575 2.84314575,0.5 4.5,0.5 C6.15685425,0.5 7.5,1.84314575 7.5,3.5 C7.5,5.15685425 6.15685425,6.5 4.5,6.5 C2.84314575,6.5 1.5,5.15685425 1.5,3.5 Z M4.5,1.5 C3.3954305,1.5 2.5,2.3954305 2.5,3.5 C2.5,4.6045695 3.3954305,5.5 4.5,5.5 C5.6045695,5.5 6.5,4.6045695 6.5,3.5 C6.5,2.3954305 5.6045695,1.5 4.5,1.5 Z"
                                                                id="Shape"
                                                            />
                                                        </g>{" "}
                                                        <path
                                                            d="M17.5,3 C17.7761424,3 18,3.22385763 18,3.5 L18,5 L19.5,5 C19.7761424,5 20,5.22385763 20,5.5 C20,5.77614237 19.7761424,6 19.5,6 L18,6 L18,7.5 C18,7.77614237 17.7761424,8 17.5,8 C17.2238576,8 17,7.77614237 17,7.5 L17,6 L15.5,6 C15.2238576,6 15,5.77614237 15,5.5 C15,5.22385763 15.2238576,5 15.5,5 L17,5 L17,3.5 C17,3.22385763 17.2238576,3 17.5,3 Z"
                                                            id="Path"
                                                        />
                                                    </g>
                                                </g>
                                            </svg>



                                        </span>
                                        <span className="txt__sm " style={{ marginLeft: 10 }}>
                                            Add New Lead Source
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    }
                    {/* start form section */}
                    <div className="col-12">
                        <div className="bg-white p-3 rounded">

                            <div className="row g-2">
                                <div className="col-md-4">
                                    <div className="position-relative list_search_fild">
                                        <input type="text" name="query" placeholder="Search"
                                            aria-label="" className="form-control h-100" onChange={(e) => handleInputChange(e)} value={search}/>
                                        <button className="job_list_search_box">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                fill="currentColor" viewBox="0 0 16 16" className="bi bi-search">
                                                <path
                                                    d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z">
                                                </path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <select className="col-sm-4  form-control form-control-custom" onChange={(e) => handlestatusInputChange(e)} value={status}>
                                        <option value="" defaultValue="selected">Select status</option>
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                    </select>
                                </div>
                                <div className="col-9 col-md-3  d-flex align-items-center">
                                {
                                    search==="" &&
                                    status===""?null:
                                        <button className="button_remove" onClick={clearfilter}>Clear Filter</button>
                                }
                                </div>
                                <div className="col-3 col-md-1 d-flex align-items-center justify-content-end">
                                    <select className="col-sm-4  form-control form-control-custom" onChange={(e) => handlePageSizeChange(e)} style={{width:'61px'}}>
                                        <option value={10}>10</option>
                                        <option value={20}>20</option> 
                                        <option value={50}>50</option> 
                                        <option value={100}>100</option>
                                    </select>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* end form section */}

                    {/* start table section */}
                    <div className="col-12">
                        <div className="table_box p-3">
                            <div className="table-responsive">
                                <table className="table table-striped setting_ table-striped-border">
                                    <thead>
                                        <tr>
                                            <th className="txt__md fw-bold">Name</th>
                                            <th className="txt__md fw-bold text-center text-md-start">Status</th>
                                            {permissionList?.includes("65bb951c53e762c89b9af171") &&
                                                <th className="txt__md fw-bold text-center">Action</th>
                                            }
                                        </tr>
                                    </thead>
                                    {
                                        Source?.isLoading && !Source?.isError ?
                                            <tbody>
                                                <tr><td colSpan={3}>
                                                    <div className='text-center py-3'>
                                                        <span className="loader"></span>
                                                    </div>
                                                </td>

                                                </tr>
                                            </tbody> : !Source?.isLoading && !Source?.isError ?

                                                <tbody>
                                                    {
                                                        Source?.data?.data?.map((data, index) => (

                                                            <tr key={index}>
                                                                <td>
                                                                    <div className="d-flex align-items-center">
                                                                        <Avatar name={data?.name} size={"34px"} />
                                                                        <p className=" mb-0 txt__para txt__sm">{data.name}</p>
                                                                    </div>
                                                                </td>

                                                                {data?.status == 1 ? (
                                                                    <td className="align-start text-success text-center text-md-start">
                                                                        <span className="statusBadge bg-success"></span>
                                                                        Active
                                                                    </td>
                                                                ) : (
                                                                    <td className="align-start text-danger text-center text-md-start">
                                                                        <span className="statusBadge bg-danger"></span>
                                                                        Inactive
                                                                    </td>
                                                                )}
                                                                {permissionList?.includes("65bb951c53e762c89b9af171") &&
                                                                    <td className="text-center">

                                                                        <Link
                                                                            to={`/settings/edit-source/${data?.id}`}
                                                                        >
                                                                            <svg
                                                                                width={15}
                                                                                height={15}
                                                                                viewBox="0 0 15 15"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <path
                                                                                    fillRule="evenodd"
                                                                                    clipRule="evenodd"
                                                                                    d="M14.502 2.64639C14.6965 2.45126 14.6965 2.13553 14.502 1.94039V1.93939L13.209 0.646393C13.0137 0.451202 12.6972 0.451202 12.502 0.646393L11.459 1.69039L13.459 3.69039L14.502 2.64639ZM10.752 2.39639L12.752 4.39639V4.39739L5.93897 11.2114C5.88384 11.2661 5.81673 11.3072 5.74297 11.3314L3.32897 12.1364C3.23921 12.1662 3.14034 12.1428 3.07347 12.0759C3.00661 12.009 2.98319 11.9101 3.01297 11.8204L3.81797 9.40639C3.8425 9.33253 3.88393 9.26542 3.93897 9.21039L10.752 2.39639Z"
                                                                                    fill="#708098"
                                                                                />
                                                                                <path
                                                                                    d="M0 13.5C0 14.3284 0.671573 15 1.5 15H12.5C13.3284 15 14 14.3284 14 13.5V7.5C14 7.22386 13.7761 7 13.5 7C13.2239 7 13 7.22386 13 7.5V13.5C13 13.7761 12.7761 14 12.5 14H1.5C1.22386 14 1 13.7761 1 13.5V2.5C1 2.22386 1.22386 2 1.5 2H8C8.27614 2 8.5 1.77614 8.5 1.5C8.5 1.22386 8.27614 1 8 1H1.5C0.671573 1 0 1.67157 0 2.5L0 13.5Z"
                                                                                    fill="#708098"
                                                                                />
                                                                            </svg>
                                                                        </Link>
                                                                    </td>
                                                                }
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody> : <tbody>
                                                    <tr>
                                                        <td colSpan={3}><p className='text-center notable_data_txt my-4'>No Data</p></td>
                                                    </tr>
                                                </tbody>
                                    }

                                </table>



                            </div>
                        </div>
                    </div>
                    {totalPages == undefined ? null : (
                                <>
                                  <div className="parentPage">
                                    <Paginate
                                      currentPage={page}
                                      totalPages={totalPages}
                                      onPageChange={(e) => pageChange(e)}
                                    />
                                  </div>
                                </>
                              )}
                    <div className="pagination-container"></div>
                </div>
            </div>





        </div>
    )
}

export default SourceManagement
