import React, { useEffect } from 'react';
import "./Notfound.jsx";
import { useNavigate } from 'react-router-dom';

const Notfound = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        props?.setNotFoundSidebar(!props?.notFoundSidebar);
    }, [])

    const webToken = localStorage.getItem("token");

    useEffect(() => {
        if (!webToken) {
            navigate('/login')
        }
    }, [webToken])

    return (
        <div className="container-fluid d-flex justify-content-center align-items-center vh-100 notfound-bg">
            <div className="row text-center">
                <div className="col">
                    <div className="display-1 text-secondary font-weight-bold">404</div>
                    <p className="display-4">Page Not Found</p>
                    <p>We couldn't find what you were looking for. Try going back to the home page.</p>
                    <a href="/" className="btn btn-primary">Back to Home</a>
                </div>
            </div>
        </div>
    );
}

export default Notfound;
