import {  GET_USER_TYPE_FAILURE, GET_USER_TYPE_REQUEST, GET_USER_TYPE_SUCCESS } from "./actionType"

const initialState = {
    isLoading: false,
    data: {},
    isError: false,
    errorData: "",
    
}

export const getUserType = (state = initialState,action)=>{
     switch(action.type){
        case GET_USER_TYPE_REQUEST:{
           return{
            ...state,
            isLoading : true,
            data : [],
            isError : false
           }
        }
        case GET_USER_TYPE_SUCCESS:{
            return{
                ...state,
                isLoading : false,
                data : action.payload,
                isError : false,
            }
        }
      
        case GET_USER_TYPE_FAILURE:{
            return{
                ...state,
                isLoading : false,
                data : [],
                isError : true,
                errorData:action.payload
            }
        }
        default : {
            return state
         }
     }
}