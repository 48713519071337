import axios from "axios";
import { SKILL_LIST_FAILURE, SKILL_LIST_REQUEST, SKILL_LIST_SUCCESS } from "./actionType";
import { errorCall } from "../../ErrorHandling/action";

export const skill_req = () => ({
    type: SKILL_LIST_REQUEST
});

export const skill_succ = (data) => ({
    type: SKILL_LIST_SUCCESS,
    payload: data
})
export const skill_failure = (err) => ({
    type: SKILL_LIST_FAILURE,
    payload: err
})
const getLocaldata = () => {
    const token = localStorage.getItem("token");
    return token
}
export const skilList = (navigate) => (dispatch) => {
    dispatch(skill_req())
    const mt = getLocaldata();
    const config = {
        headers: { Authorization: `Bearer ${mt}` }
    };
    axios.get(`${process.env.REACT_APP_BASE_URL}/api/auth/skillList`, config).then((res) => {
        dispatch(skill_succ(res.data))
    }).catch((err) => {
        if (err?.response?.data?.error == "Unauthenticated.") {
            localStorage.removeItem("token");
            navigate("/login");
        }
        dispatch(skill_failure(err.toJSON()))
        console.log(err.toJSON());
        if (err.message === "Network Error" || err.response.status === 500) {
            dispatch(errorCall(err))
        }
    })
}

export const skillShort = (navigate,search, status, type,page,perpage) => (dispatch) => {
    dispatch(skill_req());
    const mt = getLocaldata();
    const config = {
        headers: { Authorization: `Bearer ${mt}` }
    };
    const formdata = new FormData();
    formdata.append("search", search);
    formdata.append("status", status);
    formdata.append("type", type);
    formdata.append("pageNumber",page);

    axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/sortSkills`, { search: search, status: status, type: type, pageNumber:page,perpage}, config).then((res) => {
        dispatch(skill_succ(res.data))
    }).catch((err) => {
        if (err?.response?.data?.error == "Unauthenticated.") {
            localStorage.removeItem("token");
            navigate("/login");
        }
        dispatch(skill_failure(err))
        if (err.message === "Network Error" || err.response.status === 500) {
            dispatch(errorCall(err))
        }
        
    })
}


