import { FIND_SKILL_FAILURE, FIND_SKILL_REQUEST, FIND_SKILL_SUCCESS, MODIFY_FRAMEWORK} from "./actionType"

const initialState = {
    isLoading: false,
    data: [],
    isError: false,
    errorData: "",

}

export const findSkillS = (state = initialState,action)=>{
     switch(action.type){
        case FIND_SKILL_REQUEST:{
           return{
            isLoading : true,
            data : [],
            isError : false,

           }
        }
        case FIND_SKILL_SUCCESS:{
            return{
                isLoading : false,
                data : action.payload,
                isError : false,

            }
        }
        case FIND_SKILL_FAILURE:{
            return{
                isLoading : false,
                data : [],
                isError : true,
                errorData:action.payload,
                
            }
        }
        case MODIFY_FRAMEWORK:{
            const { index, key, value } = action.payload;
            const updatedLinkedFramework = [...state.data.data.linked_framework]; 
            
            updatedLinkedFramework[index] = {
                ...updatedLinkedFramework[index],
                [key]: value 
            };
            return {
                ...state,
                data: {
                    ...state.data,
                    data:{
                        ...state.data.data,
                        linked_framework: updatedLinkedFramework 
                    }
                    
                },
            };
            
        }
        default : {
            return state
         }
     }
}