import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import SettingsNavbar from '../Components/SettingsNavbar';
import { BsFillArrowLeftCircleFill } from 'react-icons/bs';
import Avatar from '../Components/settings/Avatar';
import Spiner1 from '../Components/Spiner1';
import { toast } from 'react-toastify';
import axios from 'axios';
import { errorCall } from '../Redux/ErrorHandling/action';
import { useDispatch } from 'react-redux';

const UpdateMenu = (props) => {
    const navigate = useNavigate();
    const [addMenu,setAddMenu] = useState("");
    const [status,setStatus] = useState("1");
    const [menudes, setMenudes] = useState("");
    const [loading, setLoading] = useState(false);
    const dispatch=useDispatch()
    const {id} = useParams();
    const notify = (message) => toast(message);
    const changeDesignationName = (e)=>{
        e.preventDefault();
        setAddMenu(e.target.value);
    }


    const getLocaldata = ()=>{
        const token = localStorage.getItem("token");
        return token
    }


    const addData = async(e)=>{
        e.preventDefault();
        setAddMenu(addMenu.trim());
        setMenudes(menudes.trim());
        if(addMenu.trim()==""){
            return toast.warn("Please Provide Menu Name");
        }else if(menudes.trim()==""){
            return toast.warn("Please Provide a description");
        }
        setLoading(true)
        const mt = getLocaldata();
        const config = {
            headers: { Authorization: `Bearer ${mt}` }
        };
        const form = {
            form:{
                "description":menudes,
                "name": addMenu,
                "status": status,
                "id": id
            }
        }
        axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/menuList/update`,form,config).then((res)=>{
            toast.success(res.data.message)
            setLoading(false)
            navigate("/settings/menu-management");
        }).catch((err)=>{
            toast.error(err.response.data.message)
            if (err.message === "Network Error" || err.response.status === 500 || err.response.status === 404) {
                dispatch(errorCall(err))
                return;
            }
            setLoading(false)
            if (err.response?.data?.message === "Unauthenticated.") {
                localStorage.removeItem("token");
                navigate("/login");
              }
              if (err.message === "Network Error" || err.response.status === 500 || err.response.status === 404) {
                dispatch(errorCall(err))
            }
        })
        
    }


    const findData = async()=>{
        setLoading(true);
        const mt = getLocaldata();
        const config = {
            headers: { Authorization: `Bearer ${mt}` }
        };
        await axios.get(`${process.env.REACT_APP_BASE_URL}/api/auth/industryMenu/${id}`,config).then((res)=>{
            setAddMenu(res.data.data.name);
            setStatus(res.data.data.status);
            setMenudes(res.data.data.Description);
            setLoading(false)
        }).catch((err)=>{
            if (err.message === "Network Error" || err.response.status === 500 || err.response.status === 404) {
                dispatch(errorCall(err))
                return;
            }
            setLoading(false)
            if (err.response?.data?.message === "Unauthenticated.") {
                localStorage.removeItem("token");
                navigate("/login");
              }
            if (err.message === "Network Error" || err.response.status === 500 || err.response.status === 404) {
                dispatch(errorCall(err))
            }
        })
        setLoading(false)
    }



    useEffect(() => {
        findData();
    }, [])


  return (
    <div>
      <SettingsNavbar navdata={{ header: "Update Menu", buttonText: "designation Button", buttonPath: "/settings/skillsuggetion" }}         newSidedata={props.Sidedata} newonSidedataChange={props.onSidedataChange}/>
            <div className="user-action-area">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Link to={"/settings/menu-management"}> <BsFillArrowLeftCircleFill /> Menu List</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="create-company-form placeholder-glow">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                             <div className="form">
                                <div className="row g-2">
                                    <div className="col-md-4">
                                        <div className="position-relative list_search_fild">
                                            <input type="text" name="query" placeholder="Menu Name"
                                                aria-label="" value={addMenu} className={`form-control coustom_P h-100 ${loading?"placeholder":""}`} onChange={(e) => changeDesignationName(e)} />
                                            <Avatar placeholder={loading?" placeholder":""} name={addMenu} size={"34px"} position="absolute" top="3px" left="5px" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="position-relative list_search_fild">
                                            <input type="text" name="query" placeholder="Menu Description"
                                            aria-label="" value={menudes} className={`form-control coustom_P h-100 ${loading?"placeholder":""}`}onChange={(e) => setMenudes(e.target.value)} />
                                            <Avatar  placeholder={loading?" placeholder":""} name={menudes} size={"34px"} position="absolute" top="3px" left="5px" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                    <select className={`col-sm-4 mb-2 form-control form-control-custom ${loading?"placeholder":""}`} value={status} onChange={(e) => setStatus(e.target.value)}>
                                       <option value="1">Active</option>
                                       <option value="0">Inactive</option>
                                   </select>
                                    </div>
                                </div>
                            </div> 
                            <button className={`btn btn-success button-outline choosed mt-3 ${loading?"placeholder":""}`} disabled= {loading ? true : false} onClick={(e)=>addData(e)}>
                            { loading ? <Spiner1/> : "Update"}
                            </button>
                        </div>
                       
                    </div>
                </div>
            </div>
    </div>
  )
}

export default UpdateMenu
