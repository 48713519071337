import {  CANDIDATE_EDIT_FAILURE, CANDIDATE_EDIT_REQUEST, CANDIDATE_EDIT_SUCCESS } from "./actionType"

const initialState = {
    isLoading: false,
    data: {},
    isError: false,
    errorData: "",
    
}

export const editCandidateData = (state = initialState,action)=>{
     switch(action.type){
        case CANDIDATE_EDIT_REQUEST:{
           return{
            ...state,
            isLoading : true,
            data : {},
            isError : false
           }
        }
        case CANDIDATE_EDIT_SUCCESS:{
            return{
                ...state,
                isLoading : false,
                data : action.payload,
                isError : false,
            }
        }
      
        case CANDIDATE_EDIT_FAILURE:{
            return{
                ...state,
                isLoading : false,
                data : {},
                isError : true,
                errorData:action.payload
            }
        }
        default : {
            return state
         }
     }
}