import axios from "axios";
import { errorCall } from "../ErrorHandling/action";


import { JOB_DETAILS_REQUEST, JOB_DETAILS_SUCCESS } from "./actionType";

export const jobDetailsRequest = () => ({
  type: JOB_DETAILS_REQUEST,
  payload: [],
  isLoading: true,
});

export const jobDetailsSuccess = (data) => ({
  type: JOB_DETAILS_SUCCESS,
  payload: data,
  isLoading: false,
});

export const postJobDetails = (data) => async (dispatch) => {
  const token = localStorage.getItem("token");
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    await dispatch(jobDetailsRequest());
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/auth/view-job-details/${data}`,
      data,
      header
    );
    await dispatch(jobDetailsSuccess(res.data));
   
  } catch (err) {
    
    if (err?.message == "Network Error" || err?.response?.status == 500) {
      dispatch(errorCall(err));
    }
  }
};
