export const USER_REQUEST = "USER_REQUEST"
export const USER_SUCCESS = "USER_SUCCESS"
export const USER_FAILURE = "USER_FAILURE"

export const ACCESS_REQUEST = "ACCESS_REQUEST"
export const ACCESS_SUCCESS = "ACCESS_SUCCESS"
export const ACCESS_FAILURE = "ACCESS_FAILURE"


export const SPEC_USER_REQUEST = "SPEC_USER_REQUEST"
export const SPEC_USER_SUCCESS = "SPEC_USER_SUCCESS"
export const SPEC_USER_FAILURE = "SPEC_USER_FAILURE"

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE"


export const USER_ADD_SUCCESS = "USER_ADD_SUCCESS"
export const USER_ADD_FAILURE = "USER_ADD_FAILURE"
export const USER_ADD_REQUEST = "USER_ADD_REQUEST"
