import axios from 'axios';
import React, { useState } from 'react'
import { toast } from 'react-toastify';
import SettingsNavbar from '../Components/SettingsNavbar';
import { Link, useNavigate } from 'react-router-dom';
import { BsFillArrowLeftCircleFill } from 'react-icons/bs';
import Avatar from '../Components/settings/Avatar';
import Spiner1 from '../Components/Spiner1';
import { errorCall } from '../Redux/ErrorHandling/action';
import { useDispatch } from 'react-redux';

const AddMenu = ({ onSidedataChange, Sidedata }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [addMenu, setAddMenu] = useState("");
    const [status, setStatus] = useState("1");
    const [menudes, setMenudes] = useState("");
    const [loading, setLoading] = useState(false);
    const changeDesignationName = (e) => {
        e.preventDefault();
        setAddMenu(e.target.value);
    }
    const getLocaldata = () => {
        const token = localStorage.getItem("token");
        return token
    }
    const addDesignationS = async (e) => {
        e.preventDefault();
        const trimmedMenudes = menudes.trim();
        const trimmedAddMenu = addMenu.trim();
        setMenudes(trimmedMenudes);
        setAddMenu(trimmedAddMenu);

        // Validate the input data
        if (trimmedAddMenu == "") {
            toast.warn("Please provide Menu Name");
            setLoading(false);
            return;
        } else if (trimmedMenudes == "") {
            toast.warn("Please provide Description");
            setLoading(false);
            return;
        } else if (!status) {
            toast.warn("Please select status");
            setLoading(false);
            return;
        }

        // Additional validation checks if needed...

        setLoading(true);

        const mt = getLocaldata();
        const config = {
            headers: { Authorization: `Bearer ${mt}` },
        };

        const formData = {
            form: {
                "description": trimmedMenudes,
                "name": trimmedAddMenu,
                "status": status,
            },
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/menuList/add`, formData, config);
            toast.success(response.data.message);
            navigate('/settings/menu-management');
        } catch (error) {
            if (error.message === "Network Error" || error.response.status === 500) {
                dispatch(errorCall(error))
                return;
            }
            toast.error(error.response.data.message);
            if (error?.response?.data?.message === "Unauthenticated.") {
                localStorage.removeItem("token");
                navigate("/login");
            }
        } finally {
            setLoading(false);
        }

    }
    return (
        <div>
            <SettingsNavbar navdata={{ header: "Add Menu", buttonText: "designation Button", buttonPath: "/settings/skillsuggetion" }} newSidedata={Sidedata} newonSidedataChange={onSidedataChange} />
            <div className="user-action-area">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Link to={"/settings/menu-management"}> <BsFillArrowLeftCircleFill /> Menu List</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="create-company-form">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form">
                                <div className="row g-2">
                                    <div className="col-md-4">
                                        <div className="position-relative list_search_fild">
                                            <input type="text" name="query" placeholder="Menu Name"
                                                aria-label="" value={addMenu} className="form-control coustom_P h-100" onChange={(e) => changeDesignationName(e)} />
                                            <Avatar name={addMenu} size={"34px"} position="absolute" top="3px" left="5px" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="position-relative list_search_fild">
                                            <input type="text" name="query" placeholder="menu description"
                                                aria-label="" value={menudes} className="form-control coustom_P h-100" onChange={(e) => setMenudes(e.target.value)} />
                                            <Avatar name={menudes} size={"34px"} position="absolute" top="3px" left="5px" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <select className="col-sm-4 mb-2 form-control form-control-custom" onChange={(e) => setStatus(e.target.value)} >
                                            <option value="1" selected>Active</option>
                                            <option value="0">Inactive</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-success button-outline choosed mt-3" disabled={loading ? true : false} onClick={(e) => addDesignationS(e)}>
                                {loading ? <Spiner1 /> : "Submit"}
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddMenu
