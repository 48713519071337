import axios from "axios";
import { DESIGNATION_UPDATE_FAILURE, DESIGNATION_UPDATE_REQUEST, DESIGNATION_UPDATE_SUCCESS } from "./actionType";
import { toast } from 'react-toastify';
import { errorCall } from "../../ErrorHandling/action";

export const designation_update_req = () => ({
    type: DESIGNATION_UPDATE_REQUEST
});

export const designation_update_succ = (data) => ({
    type: DESIGNATION_UPDATE_SUCCESS,
    payload: data
})

export const designation_update_failure = (err) => ({
    type: DESIGNATION_UPDATE_FAILURE,
    payload: err
})

const getLocaldata = () => {
    const token = localStorage.getItem("token");
    return token
}

export const editDesignation = (id, name, status, navigate) => (dispatch) => {
    dispatch(designation_update_req());
    const mt = getLocaldata();
    const config = {
        headers: { Authorization: `Bearer ${mt}` }
    };

    const form = {
        form: {
            "id": id,
            "name": name,
            "status": status
        }
    }
    axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/designation/update`, form, config).then((res) => {
        dispatch(designation_update_succ(res.data))
        toast.success('Designation updated successfully')
        navigate("/settings/designation");
        return true
    }).catch((err) => {
        toast.error(err.response.data.message)
        if (err?.response?.data?.error == "Unauthenticated.") {
            toast.error('You are not allowed to access this application')
            localStorage.removeItem("token");
            navigate("/login");
        }
        dispatch(designation_update_failure(err))
        if (err.message === "Network Error" || err.response.status === 500) {
            dispatch(errorCall(err))
        }
    })
}




