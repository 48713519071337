import React, { useEffect, useRef, useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import "../../../Pages/candidates/candidates.css";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
// import { storeCandiadte } from "../../../Redux/CandidateReducer/action";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  UpdateNoticePeriod,
  nextUpdateDetails,
  updateAvailable,
  updateCertificates,
  updateCurrentCTC,
  updateCurrentCompany,
  updateCurrentPosition,
  updateCurrentlyWorking,
  updateExpectedCTC,
  updateExperienceInMonth,
  updateExperienceInYear,
  updateJobHistory,
  updateJoiningDateMonth,
  updateJoiningDateYear,
  updateLastCTC,
  updateLastCompany,
  updateLastPosition,
  updateNoticePeriod,
  updateProjectDetails,
  updateStep,
  updateTerminationDateMonth,
  updateTerminationDateYear,
  updateTotalExperienceInMonths,
  updateWhySwitching,
  updateWhyUnemployed,
  updateWorkNote,
  validData,
} from "../../../Redux/Candidate/createCandidate/action";
import { updateNoticePeriodNegotiable } from "./../../../Redux/Candidate/createCandidate/action";
import Spiner1 from "../../Spiner1";

let monthOptions = [
  { value:"", label: "Month" },
  { value: 1, label: "January" },
  { value: 2, label: "February" },
  { value: 3, label: "March" },
  { value: 4, label: "April" },
  { value: 5, label: "May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October" },
  { value: 11, label: "November" },
  { value: 12, label: "December" },
];
const currentYear = new Date().getFullYear();
const startYear = currentYear - 30;
const endYear = currentYear
const yearOptions = [{ value:"", label:"Year" }];
for (let year = startYear; year <= endYear; year++) {
  yearOptions.push({ value: year, label: year.toString() });
}

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused ? "2px solid #41b883" : "#000",
    //   backgroundColor: "#fff",
    backgroundColor: state.isDisabled ? "#ccc" : "#fff",
    borderRadius: "0.375rem",
    minHeight: "45px",
    boxShadow: state.isFocused && "none",
    color: state.isdefaultValue ? "#41b883" : "#000",
    fontSize: "14px",
    "&:hover": {
      borderColor: state.isFocused ? "#41b883" : "#41b883",
      boxShadow: "none",
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#333",
  }),
  input: (provided) => ({
    ...provided,
    color: "#333",
    fontSize: "14px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor:
      state.isFocused || state.isdefaultValue ? "#41b883" : "#fff",
    color: state.isdefaultValue ? "white" : "#333",
    fontSize: "14px",
    "&:hover": {
      color: state.isFocused ? "#fff" : "#fff",
      boxShadow: "none",
    },
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#eee",
    borderRadius: "0.375rem",
  }),
  menuList: (provided) => ({
    ...provided,
    backgroundColor: "#fff",
    color: "#eee",
    borderRadius: "0.375rem",
  }),
};
const ExperienceData = (props) => {
  const expRef=[useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null),
    useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)]
  const histRef=[useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null),
    useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)]
  const navigate = useNavigate();
  const candidateResponse = useSelector((state)=> state.addResponseReducer.data);
  const errorData = useSelector((state) => state?.addValidation);
  const candidateData = useSelector((state)=> state.createCandidate);
  const workExperience = useSelector((state) => state.updateworkDetails);
  const projectDetails = useSelector((state) => state.updateProjectDetails);
  const upgaradeCandidate = useSelector((state) => state.updateCandidateResponse);
  const certifications = useSelector(
    (state) => state.updateCertifications.certifications
  );
  const totalExperience = useSelector((state) => state.updateTotalExperience);
  const jobHistories = useSelector((state) => state.updateJobHistory);
  const addPeriod = useSelector((state) => state.addPeriod);
  const {inputRef,errorGenerate,errorMessage } = props;
  const dispatch = useDispatch();
  const [warnYearJoin, setWarnYearJoin] = useState("");

  // Start washim code
  const handleCurrentWorking = (data) => {
    delete errorMessage?.currently_working
    dispatch(updateCurrentlyWorking(data));
  };

  const handleLookChange = (e) => {
    dispatch(updateWhySwitching(e.target.value));
  };

  const handleToatlExpYear = (e) => {
    // dispatch(validData())
    delete errorMessage?.total_experience
    delete errorMessage?.total_experience_No
    const yearValue = parseInt(e.target.value);
    if (!isNaN(yearValue)) {
      dispatch(updateExperienceInYear(yearValue));
      const totalMonths = yearValue * 12 + totalExperience?.month;
      dispatch(updateTotalExperienceInMonths(totalMonths));
    }
  };

  const handleToatlExpMonth = (e) => {
    delete errorMessage?.total_experience_No
    // dispatch(validData())
    dispatch(updateExperienceInMonth(parseInt(e.target.value)));
    let month = parseInt(e.target.value) + totalExperience?.year * 12;
    dispatch(updateTotalExperienceInMonths(month));
  };

  const handleCurrentCompany = (e) => {
    delete errorMessage?.current_company
    // dispatch(validData())
    dispatch(updateCurrentCompany(e.target.value));
  };

  const handleCurrentPossition = (e) => {
    delete errorMessage?.current_position
    dispatch(updateCurrentPosition(e.target.value));
  };
  const handleJoiningDateMonth = (e) => {
    dispatch(updateJoiningDateMonth(e.target.value));
  };

  const handleJoiningDateYear = (e) => {
    if(workExperience?.termination_date_year < e.target.value && workExperience?.termination_date_year>0){
      dispatch(validData("termination_date_year","The year you entered must be less than the termination year"))
    }else{
      // dispatch(validData())
      delete errorMessage?.termination_date_year
    }
    dispatch(updateJoiningDateYear(e.target.value));
  };


  const handleAvailNow = (e) => {
      dispatch(updateAvailable(e.target.checked));
  };

  const handleNoticePeriod=(e)=>{
   dispatch(UpdateNoticePeriod(e.target.value))
  }
  const handleNegotiate = (e) => {
    dispatch(updateNoticePeriodNegotiable(e));
  };
  const handleCurrentCtc = (e) => {
    dispatch(updateCurrentCTC(e.target.value));
  };
  const handleExpectedCtc = (e) => {
    dispatch(updateExpectedCTC(e.target.value));
  };
  const handleWhyUnEmp = (e) => {
    dispatch(updateWhyUnemployed(e.target.value));
  };
  const handleLastCompany = (e) => {
    dispatch(updateLastCompany(e.target.value));
  };
  const handleLastPosition = (e) => {
    dispatch(updateLastPosition(e.target.value));
  };
  const handleTerminMonth = (e) => {
    dispatch(updateTerminationDateMonth(e.target.value));
  };
  const handelEndYear = (e) => {
      delete errorMessage?.termination_date_year
    dispatch(updateTerminationDateYear(e.target.value));
  };
  const handleHowSoonJoin = (e) => {
    dispatch(updateNoticePeriod(e.target.value));
  };
  const handlelastCtc = (e) => {
    dispatch(updateLastCTC(e.target.value));
  };


  const handleAddHistory = () => {
    let jobHistory = [...jobHistories?.job_histories];
    const newJobHistory = {
      company: "",
      joining_month: "",
      joining_year: "",
      position: "",
      termination_month: "",
      termination_year: "",
    };
    if(jobHistories?.job_histories.length<15)
    {jobHistory[jobHistory.length-1].company===""?
    toast.error("Please Fill Company Name") : 
    jobHistory.push(newJobHistory);
    dispatch(updateJobHistory(jobHistory))}
  };


  const handleRemoveHist = (indexp) => {
    let jobHistory = [...jobHistories?.job_histories];
    jobHistory = jobHistory.filter((job, index) => index !== indexp);
    dispatch(updateJobHistory(jobHistory));
  };

  const handleResetHist = (indexp) => {
    let jobHistory = [...jobHistories?.job_histories];
    jobHistory[indexp]={company:"",joining_month:"",joining_year:"",position:"",termination_month:"",termination_year:""}
    dispatch(updateJobHistory(jobHistory));
  };

  const handleCompanyName = (e, index) => {
    let jobHistory = [...jobHistories?.job_histories];
    jobHistory[index].company = e.target.value;
    dispatch(updateJobHistory(jobHistory));
  };


  const handleHistoryPosition = (e, indexp) => {
    let jobHistory = [...jobHistories?.job_histories];
    jobHistory[indexp].position = e.target.value;
    dispatch(updateJobHistory(jobHistory));
  };


  const handleHistoryJoinMonth = (value, index) => {
    let jobHistory = [...jobHistories?.job_histories];
    jobHistory[index].joining_month = value;
    dispatch(updateJobHistory(jobHistory));
  };


  const handleHistoryJoinYear = (value, index) => {
    let jobHistory = [...jobHistories?.job_histories];
    jobHistory[index].joining_year = value;
    // jobHistory[index].termination_year = "Year";
    const termination=jobHistory[index].termination_year
    if(value>termination &&termination ){
      // setWarnYearJoin(index)
      jobHistory[index].error = "The year you entered must be less than the ending year";
    }else{
      // setWarnYearJoin("")
      delete jobHistory[index].error
    }
    dispatch(updateJobHistory(jobHistory));
  };


  const handleHistoryEndMonth = (value, index) => {
    let jobHistory = [...jobHistories?.job_histories];
    jobHistory[index].termination_month = value;
    dispatch(updateJobHistory(jobHistory));
  };


  const handleHistoryEndYear = (value, index) => {
    let jobHistory = [...jobHistories?.job_histories];
    jobHistory[index].termination_year = value;
    jobHistory[index].error=""
    dispatch(updateJobHistory(jobHistory));
  };


  const HandleWorkNote = (e) => {
    dispatch(updateWorkNote(e.target.value));
  };


  const handleProject = () => {
    let projectlist = [...projectDetails?.project_details];
    let newProject = {
      name: "",
      link: "",
    };
    projectlist[projectlist.length-1].name===""?
    toast.error("Please Fill Project Name") :
    projectlist.push(newProject);
    dispatch(updateProjectDetails(projectlist));
  };

  const removeProject = (indexp) => {
    let projectlist = [...projectDetails?.project_details];
    projectlist = projectlist.filter((project, index) => index !== indexp);
    dispatch(updateProjectDetails(projectlist));
  };
  const resetProject = (indexp) => {
    let projectlist = [...projectDetails?.project_details];
    projectlist[indexp] = {
      name: "",
      link: "",
    };
    dispatch(updateProjectDetails(projectlist));
  };


  const handleProjectName = (e, index) => {
    let projectlist = [...projectDetails?.project_details];
    projectlist[index].name = e.target.value;
    dispatch(updateProjectDetails(projectlist));
  };


  const handleProjectLink = (e, index) => {
    let projectlist = [...projectDetails?.project_details];
    projectlist[index].link = e.target.value;

     const validLink=/^[a-zA-Z0-9\-]+$/
    if(validLink.test(e.target.value) ||  e.target.value.charAt( e.target.value.length - 1) === '.'  || e.target.value.charAt( e.target.value.length - 1) === ','){
      projectlist[index].message ="Invalid Link";
    }else{
      projectlist[index].message =null;
    }
    
    dispatch(updateProjectDetails(projectlist));
  };


  const handleCertificate = () => {
    let cirtificateList = [...certifications];
    const newCirtificate = {
      course_name: "",
      institute_name: "",
      completion_year: "",
    };
    cirtificateList[cirtificateList.length-1].course_name==="" ? toast.error("Please Fill Certificate Name") :
    cirtificateList?.push(newCirtificate);
    dispatch(updateCertificates(cirtificateList));
  };

  const removeCertificate = (index) => {
    let certificateList = [...certifications];
    certificateList = certificateList.filter((ele, indexc) => indexc !== index);
    dispatch(updateCertificates(certificateList));
  };
  const resetCertificate = (index) => {
    let certificateList = [...certifications];
    certificateList[index] = {
      course_name: "",
      institute_name: "",
      completion_year: " ",
    };
    dispatch(updateCertificates(certificateList));
  };
  const handleCertificateYear = (data, index) => {
    let certificateList = [...certifications];
    certificateList[index].completion_year = data;
    dispatch(updateCertificates(certificateList));
  };
  const handleCertificateInstitute = (e, index) => {
    let certificateList = [...certifications];
    certificateList[index].institute_name = e.target.value;
    dispatch(updateCertificates(certificateList));
  };
  const handleCertificateName = (e, index) => {
    let certificateList = [...certifications];
    certificateList[index].course_name = e.target.value;
    dispatch(updateCertificates(certificateList));
  };
  // End Washim Code
  const validYear=(error)=>{
    const index = error.findIndex(item => 
      item.joining_year > item.termination_year && item.termination_year!="" 
        );
  
    // If index is -1 (meaning not found), return null; otherwise, return the index.
    return index === -1 ? null : index;
  }
  const handleNext = () => {
    const nextTab = "Skill";

    const data = {
      first_name: candidateData.first_name,
      last_name: candidateData.last_name,
      lead_source: candidateData.lead_source,
      designation_experience: candidateData.designation_experience,
      designation: candidateData.designation,
      work_details:workExperience,
      resume:candidateData.resume ?candidateData.resume :"",
      notice_period:addPeriod,
      job_histories: jobHistories.job_histories,
      certifications: certifications,
      project_details: projectDetails.project_details,
      total_experience: totalExperience,
    };
    if(workExperience?.currently_working ==="Yes" && totalExperience?.year=="0" && totalExperience?.month==0 ){
      errorGenerate("total_experience","Please Provide Experienced Year Or Month")
    } if(workExperience?.currently_working ==="Yes" && !workExperience?.current_company){
      errorGenerate("current_company","Please Provide The Company Name")
    } if(workExperience?.currently_working ==="Yes" && !workExperience?.current_position){
      errorGenerate("current_position","Please Provide Current Position")
    } if(workExperience?.currently_working ==="No" && totalExperience?.year=="0" && totalExperience?.month==0){
      errorGenerate("total_experience_No","Please Provide Experienced Year Or Month")
    }
     if(workExperience?.currently_working ==="No" &&workExperience?.termination_date_year < workExperience?.joining_date_year){
      errorGenerate("termination_date_year","The year you entered must be less than the termination year")
      
    } if(workExperience?.currently_working ==="No" &&workExperience?.termination_date_year < workExperience?.joining_date_year){
      errorGenerate("termination_date_year","The year you entered must be less than the termination year")
      
    } if(validYear(jobHistories?.job_histories)>=0 &&validYear(jobHistories?.job_histories)!=null){
      errorGenerate("termination_date_year","The year you entered must be less than the termination year")
      // toast.error("The year you entered must be less than the termination year")
    }
if(workExperience?.currently_working ==="Yes" && totalExperience?.year=="0" && totalExperience?.month==0 ){
  expRef[0].current.focus()
}else if(workExperience?.currently_working ==="Yes" && !workExperience?.current_company){
  expRef[3]?.current?.focus()
}else if(workExperience?.currently_working ==="Yes" && !workExperience?.current_position){
  expRef[4]?.current.focus()
}else if(workExperience?.currently_working ==="No" && totalExperience?.year=="0" && totalExperience?.month==0){
  expRef[5]?.current?.focus()
}
else if(workExperience?.currently_working ==="No" &&workExperience?.termination_date_year < workExperience?.joining_date_year){
  expRef[6]?.current?.focus()
}else if(validYear(jobHistories?.job_histories)>=0 &&validYear(jobHistories?.job_histories)!=null){
  histRef[validYear(jobHistories?.job_histories)]?.current?.focus()
  // toast.error("The year you entered must be less than the termination year")
}else{
     dispatch(
      nextUpdateDetails(navigate, nextTab, candidateResponse?.candidate, data)
    );}
  };
  return (
    <div className="tab-data mt-4" step={0}>
      <div className="grid_2_md ">
        <div className="grid-box">
          <div className="form-group-gap">
            <div className="form-label-box">
              <label className="txt__para txt__sm fw-bold mb-2">
                Currently Working (Yes/No)
              </label>
              <span className="text-danger ms-1">*</span>
            </div>
            <div className="form-control-box">
              <div className="form-check form-check-inline">
                <input
                 ref={inputRef[13]}
                  type="radio"
                  id="customRadioInline10"
                  value="Yes"
                  name="remote-job"
                  className="form-check-input"
                  checked={workExperience?.currently_working == "Yes"}
                  onChange={(e) => {
                    handleCurrentWorking("Yes");
                  }}
                />
                <label
                  htmlFor="customRadioInline10"
                  className="form-check-label"
                >
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  id="customRadioInline20"
                  name="remote-job"
                  value="No"
                  className="form-check-input"
                  checked={workExperience?.currently_working == "No"}
                  onChange={(e) => {
                    handleCurrentWorking("No");
                  }}
                />
                <label
                  htmlFor="customRadioInline20"
                  className="form-check-label"
                >
                  No
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  type="radio"
                  id="customRadioInline30"
                  name="remote-job"
                  value="Fresher"
                  className="form-check-input"
                  checked={workExperience?.currently_working == "Fresher"}
                  onChange={(e) => {
                    handleCurrentWorking("Fresher");
                  }}
                />
                <label
                  htmlFor="customRadioInline30"
                  className="form-check-label"
                >
                  Fresher
                </label>
              </div>
            </div>
            <p className="text-danger m-2">
            {errorMessage?.currently_working}
            </p>
          </div>
        </div>
      </div>
      {workExperience?.currently_working === "Yes" ? (
        <div>
          <div className="currently_working">
            <div className="row g-3">
              <div className="col-sm-6">
                <div className="grid-box">
                  <div className="form-group-gap">
                    <div className="form-label-box">
                      <label className="mb-2 txt__para txt__sm fw-bold">
                        Why looking for change?
                      </label>
                    </div>
                    <div className="form-control-box">
                      <textarea
                        rows="1"
                        style={{minHeight:"21px",maxHeight:"21px"}}
                        placeholder="Type here..."
                        className="form-control-no-border"
                        value={workExperience?.why_switching}
                        onChange={(e) => {
                          handleLookChange(e);
                        }}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="grid-box">
                  <div className="form-group-gap">
                    <div className="form-label-box d-flex align-items-center justify-content-between mb-2">
                      <label className="txt__para txt__sm fw-bold mb-0">
                        Total experience
                        <span className="text-danger ms-1">*</span>
                      </label>
                    </div>
                    <div className="grid-2">
                      <div className="form-control-box">
                        <select
                          ref={expRef[0]}
                          className="form-control-no-border form-control-no-border-select"
                          value={totalExperience?.year}
                          onChange={(e) => {
                            handleToatlExpYear(e);
                          }}
                        >
                          <option value="0" >
                            In years
                          </option>
                          <option value="0">0 year</option>
                          <option value="1">1 year</option>
                          <option value="2">2 years</option>
                          <option value="3">3 years</option>
                          <option value="4">4 years</option>
                          <option value="5">5 years</option>
                          <option value="6">6 years</option>
                          <option value="7">7 years</option>
                          <option value="8">8 years</option>
                          <option value="9">9 years</option>
                          <option value="10">10 years</option>
                          <option value="11">11 years</option>
                          <option value="12">12 years</option>
                          <option value="13">13 years</option>
                          <option value="14">14 years</option>
                          <option value="15">15 years</option>
                          <option value="16">16 years</option>
                          <option value="17">17 years</option>
                          <option value="18">18 years</option>
                          <option value="19">19 years</option>
                          <option value="20">20 years</option>
                          <option value="21">21 years</option>
                        </select>
                      </div>
                      <div className="form-control-box">
                        <select
                         ref={expRef[1]}
                          className="form-control-no-border form-control-no-border-select"
                          value={totalExperience?.month}
                          onChange={(e) => {
                            handleToatlExpMonth(e);
                          }}
                        >
                          <option value="0" >
                            In months
                          </option>
                          <option value="0">0 month</option>
                          <option value="1">1 month</option>
                          <option value="2">2 months</option>
                          <option value="3">3 months</option>
                          <option value="4">4 months</option>
                          <option value="5">5 months</option>
                          <option value="6">6 months</option>
                          <option value="7">7 months</option>
                          <option value="8">8 months</option>
                          <option value="9">9 months</option>
                          <option value="10">10 months</option>
                          <option value="11">11 months</option>
                        </select>
                      </div>
                    </div>
                 </div>
                </div>
                <p className="text-danger m-2">
                {errorMessage?.total_experience}
              </p>
              </div>
              <div className="col-sm-6">
                <div className="grid-box">
                  <div className="form-group-gap">
                    <div className="form-label-box">
                      <label className="mb-2 txt__para txt__sm fw-bold">
                        Current company name
                      </label>
                      <span className="text-danger ms-1">*</span>
                    </div>
                    <div className="form-control-box">
                      <input
                       ref={expRef[3]}
                        type="text"
                        placeholder="Company name"
                        className="form-control-no-border"
                        value={workExperience?.current_company}
                        onChange={(e) => {
                          handleCurrentCompany(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <p className="text-danger m-2">
                {errorMessage?.current_company}
                </p>
              </div>
              <div className="col-sm-6">
                <div className="grid-box">
                  <div className="form-group-gap">
                    <div className="form-label-box">
                      <label className=" mb-2 txt__para txt__sm fw-bold">
                        Job title in current company
                      </label>
                      <span className="text-danger ms-1">*</span>
                    </div>
                    <div className="form-control-box">
                      <input
                       ref={expRef[4]}
                        type="text"
                        placeholder="Web Developer..."
                        className="form-control-no-border"
                        value={workExperience?.current_position}
                        onChange={(e) => {
                          handleCurrentPossition(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <p className="text-danger m-2">
                {errorMessage?.current_position}
                </p>
              </div>
              <div className="col-sm-6">
                <div className="grid-box">
                  <div className="form-group-gap">
                    <div className="form-label-box">
                      <label className=" mb-2 txt__para txt__sm fw-bold">
                        Joining date
                      </label>
                    </div>
                    <div className="grid-2">
                      <div className="form-control-box">
                        <select
                          className="form-control-no-border form-control-no-border-select"
                          value={workExperience?.joining_date_month}
                          onChange={(e) => {
                            handleJoiningDateMonth(e);
                          }}
                        >
                          {/* <option defaultValue value="">
                            Month
                          </option> */}
                          {monthOptions.map((item,index)=>
                        <option   key={index} value={item.label}>
                          {item.label}
                        </option>
                        )}
                        </select>
                      </div>
                      <div className="form-control-box">
                        <select
                        
                          className="form-control-no-border form-control-no-border-select"
                          value={workExperience?.joining_date_year}
                          onChange={(e) => {
                            handleJoiningDateYear(e);
                          }}
                        >
                          {/* <option value="" >
                            Year
                          </option> */}
                          {yearOptions.map((item,index)=><option key={index} value={item.label} >
                            {item.label}
                          </option>)}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="grid-box">
                  <div className="form-group-gap">
                    <div className="form-label-box">
                      <label className=" mb-2 txt__para txt__sm fw-bold">
                        Notice period?{" "}
                      </label>
                      <input
                        type="checkbox"
                        value="Available"
                        className="txt__para txt__sm ml-sm-2 m-2"
                        defaultChecked={workExperience?.available}
                        onChange={(e) => {
                          handleAvailNow(e);
                        }}
                      />
                      Available now
                    </div>
                    <div className="form-control-box">
                      <input
                        type="number"
                        placeholder="Notice period in days"
                        className="form-control-no-border"
                        value={workExperience?.notice_period}
                        onChange={(e) => {
                          handleNoticePeriod(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="grid-box">
                  <div className="form-group-gap">
                    <div className="form-label-box">
                      <label className=" mb-2 txt__para txt__sm fw-bold">
                        Notice period negotiable (Yes/No)?
                      </label>
                    </div>
                    <div className="form-control-box">
                      <div className="form-check form-check-inline">
                        <input
                          type="radio"
                          id="noticePeriodNegotiable"
                          value="Yes"
                          name="negotiable-job"
                          className="form-check-input"
                          defaultChecked={
                            workExperience?.notice_period_negotiable === "Yes"
                          }
                          onChange={(e) => {
                            handleNegotiate("Yes");
                          }}
                        />
                        <label
                          htmlFor="noticePeriodNegotiable"
                          className="form-check-label"
                        >
                          Yes
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          type="radio"
                          id="noticePeriodNotNegotiable"
                          name="negotiable-job"
                          value="No"
                          className="form-check-input"
                          defaultChecked={
                            workExperience?.notice_period_negotiable === "No"
                          }
                          onChange={(e) => {
                            handleNegotiate("Yes");
                          }}
                        />
                        <label
                          htmlFor="noticePeriodNotNegotiable"
                          className="form-check-label"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="grid-box">
                    <div className="form-group-gap">
                      <div className="form-label-box">
                        <label className="txt__para txt__sm fw-bold mb-1">
                          Current CTC
                        </label>
                        <p className="txt__xs txt__light">
                          This is the monthly salary in INR: Example 20000
                        </p>
                      </div>
                      <div className="form-control-box">
                        <input
                          type="number"
                          placeholder="20000"
                          className="form-control-no-border"
                          value={workExperience?.current_ctc}
                          onChange={(e) => {
                            handleCurrentCtc(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="grid-box">
                    <div className="form-group-gap">
                      <div className="form-label-box">
                        <label className="txt__para txt__sm fw-bold mb-1">
                          Expected CTC
                        </label>
                        <p className="txt__xs txt__light">
                          This is the monthly salary in INR: Example 20000
                        </p>
                      </div>
                      <div className="form-control-box">
                        <input
                          type="number"
                          placeholder="20000"
                          className="form-control-no-border"
                          value={workExperience?.expected_ctc}
                          onChange={(e) => {
                            handleExpectedCtc(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {workExperience?.currently_working === "No" ? (
        <div>
          <div className="currently_notworking">
            <div className="row g-3">
              <div className="col-sm-6">
                <div className="form-group-gap">
                  <div className="form-label-box d-flex align-items-center justify-content-between mb-2">
                    <label className="txt__para txt__sm fw-bold mb-0">
                      Total experience
                      <span className="text-danger ms-1">*</span>
                    </label>
                  </div>
                  <div className="grid-2">
                    <div className="form-control-box">
                      <select
                        ref={expRef[5]}
                        className="form-control-no-border form-control-no-border-select"
                        value={totalExperience?.year}
                        onChange={(e) => {
                          handleToatlExpYear(e);
                        }}
                      >
                        <option value="" hidden>
                          In year
                        </option>
                        <option value="0">0 year</option>
                        <option value="1">1 year</option>
                        <option value="2">2 years</option>
                        <option value="3">3 years</option>
                        <option value="4">4 years</option>
                        <option value="5">5 years</option>
                        <option value="6">6 years</option>
                        <option value="7">7 years</option>
                        <option value="8">8 years</option>
                        <option value="9">9 years</option>
                        <option value="10">10 years</option>
                        <option value="11">11 years</option>
                        <option value="12">12 years</option>
                        <option value="13">13 years</option>
                        <option value="14">14 years</option>
                        <option value="15">15 years</option>
                        <option value="16">16 years</option>
                        <option value="17">17 years</option>
                        <option value="18">18 years</option>
                        <option value="19">19 years</option>
                        <option value="20">20 years</option>
                        <option value="21">21 years</option>
                      </select>
                    </div>
                    <div className="form-control-box">
                      <select
                        className="form-control-no-border form-control-no-border-select"
                        value={totalExperience?.month}
                        onChange={(e) => {
                          handleToatlExpMonth(e);
                        }}
                      >
                        <option value="" hidden>
                          In month
                        </option>
                        <option value="0">0 month</option>
                        <option value="1">1 month</option>
                        <option value="2">2 months</option>
                        <option value="3">3 months</option>
                        <option value="4">4 months</option>
                        <option value="5">5 months</option>
                        <option value="6">6 months</option>
                        <option value="7">7 months</option>
                        <option value="8">8 months</option>
                        <option value="9">9 months</option>
                        <option value="10">10 months</option>
                        <option value="11">11 months</option>
                        {/* <option value="12">12 months</option> */}
                      </select>
                    </div>
                  </div>
                </div>
                <p className="text-danger m-2">
                {errorMessage?.total_experience_No}
                </p>
              </div>
              <div className="col-sm-6">
                <div className="form-group-gap">
                  <div className="form-label-box">
                    <label className="mb-2 txt__para txt__sm fw-bold">
                      Why unemployed?
                    </label>
                  </div>
                  <div className="form-control-box">
                    <textarea
                      rows="1"
                      placeholder="Type here..."
                      style={{minHeight:"21px",maxHeight:"21px"}}
                      className="form-control-no-border"
                      value={workExperience?.why_unemployed}
                      onChange={(e) => {
                        handleWhyUnEmp(e);
                      }}
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group-gap">
                  <div className="form-label-box">
                    <label className="mb-2 txt__para txt__sm fw-bold">
                      Last company name
                    </label>
                  </div>
                  <div className="form-control-box">
                    <input
                      type="text"
                      placeholder="Company name"
                      className="form-control-no-border"
                      value={workExperience?.last_company}
                      onChange={(e) => {
                        handleLastCompany(e);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group-gap">
                  <div className="form-label-box">
                    <label className="mb-2 txt__para txt__sm fw-bold">
                      Job title in last company
                    </label>
                  </div>
                  <div className="form-control-box">
                    <input
                      type="text"
                      placeholder="Web Developer..."
                      className="form-control-no-border"
                      value={workExperience?.last_position}
                      onChange={(e) => {
                        handleLastPosition(e);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group-gap">
                  <div className="form-label-box">
                    <label className="mb-2 txt__para txt__sm fw-bold">
                      {" "}
                      Joining date{" "}
                    </label>
                  </div>
                  <div className="grid-2">
                    <div className="form-control-box">
                      <select
                        className="form-control-no-border form-control-no-border-select"
                        value={workExperience?.joining_date_month}
                        onChange={(e) => {
                          handleJoiningDateMonth(e);
                        }}
                      >
                        {/* <option defaultValue value="">
                          Month
                        </option> */}
                        {monthOptions.map((item,index)=>
                        <option key={index} value={item.label}>
                          {item.label}
                        </option>
                        )}
                      </select>
                    </div>
                    <div className={`form-control-box  ${!workExperience?.joining_date_month && 'disabled_active'}`}>
                      <select
                        className={`form-control-no-border form-control-no-border-select`}
                        value={workExperience?.joining_date_year}
                        disabled={!workExperience?.joining_date_month}
                        onChange={(e) => {
                          handleJoiningDateYear(e);
                        }}
                      >
                        {/* <option value="" hidden>
                          Year
                        </option> */}
                        {yearOptions.map((item,index)=><option  key={index} value={item.label}>{item.label}</option>)}
                      </select>
                    </div>
                  </div>
                </div>
                <p className="text-danger m-2">
                {errorMessage?.termination_date_year}
                </p>
              </div>
              <div className="col-sm-6">
                <div className="form-group-gap">
                  <div className="form-label-box">
                    <label className="mb-2 txt__para txt__sm fw-bold">
                      {" "}
                      End date{" "}
                    </label>
                  </div>
                  <div className="grid-2">
                    <div className={`form-control-box ${!workExperience?.joining_date_year && 'disabled_active'}`} >
                      <select
                        disabled={!workExperience?.joining_date_year}
                        className="form-control-no-border form-control-no-border-select"
                        value={workExperience?.termination_date_month}
                        onChange={(e) => {
                          handleTerminMonth(e);
                        }}
                      >
                        {/* <option value=""  hidden>
                          Month
                        </option> */}
                        {monthOptions.map((item,index)=><option  key={index} value={item.label} >
                          {item.label}
                        </option>)}
                      </select>
                    </div>
                    <div className={`form-control-box ${!workExperience?.termination_date_month && 'disabled_active'}`}>
                      <select
                        ref={expRef[6]}
                        className="form-control-no-border form-control-no-border-select"
                        value={workExperience?.termination_date_year}
                        disabled={!workExperience?.termination_date_month}
                        onChange={(e) => {
                          handelEndYear(e);
                        }}
                      >
                        <option value="" hidden>
                          {workExperience?.termination_date_year || "Year"}
                        </option>
                        { monthOptions.findIndex((month) => month.label === workExperience?.joining_date_month)
                        >=monthOptions.findIndex((month) => month.label === workExperience?.termination_date_month)?
                        yearOptions.map((item,index) => {
                           if (item.label > workExperience?.joining_date_year) {
                             return <option  key={index} value={item.label}>{item.label}</option>;
                           }
                           return null;
                         }): yearOptions.map((item,index) => {
                           if (item.label >= workExperience?.joining_date_year) {
                             return <option  key={index} value={item.label}>{item.label}</option>;
                           }
                           return null;
                         })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group-gap">
                  <div className="form-label-box">
                    <label className=" mb-2 txt__para txt__sm fw-bold mr-2">
                      How soon can you join?{"  "}
                    </label>
                    <input
                      type="checkbox"
                      value="Available"
                      className="txt__para txt__sm ml-sm-2 m-2 "
                      defaultChecked={workExperience?.available}
                      onChange={(e) => {
                        handleAvailNow(e);
                      }}
                    />
                    Available now
                  </div>
                  <div className="form-control-box">
                    <input
                      type="number"
                      placeholder="7 days"
                      className="form-control-no-border"
                      defaultValue={workExperience?.notice_period}
                      onChange={(e) => {
                        handleHowSoonJoin(e);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row g-3">
                <div className="col-sm-6">
                  <div className="form-group-gap">
                    <div className="form-label-box">
                      <label className="txt__para txt__sm fw-bold mb-1">
                        Last CTC
                      </label>
                      <p className="txt__xs txt__light">
                        This is the monthly salary in INR: Example 20000
                      </p>
                    </div>
                    <div className="form-control-box">
                      <input
                        type="number"
                        placeholder="20000"
                        className="form-control-no-border"
                        value={workExperience?.last_ctc}
                        onChange={(e) => {
                          handlelastCtc(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group-gap">
                    <div className="form-label-box">
                      <label className="txt__para txt__sm fw-bold mb-1">
                        Expected CTC
                      </label>
                      <p className="txt__xs txt__light">
                        This is the monthly salary in INR: Example 20000
                      </p>
                    </div>
                    <div className="form-control-box">
                      <input
                        type="number"
                        placeholder="20000"
                        className="form-control-no-border"
                        value={workExperience?.expected_ctc}
                        onChange={(e) => {
                          handleExpectedCtc(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {workExperience?.currently_working === "Fresher" ? (
        <div>
          <div className="fresher">
            <div className="row g-3">
              <div className="col-sm-6">
                <div className="form-group-gap">
                  <div className="form-label-box">
                    <label className="mb-2 txt__para txt__sm fw-bold">
                      How soon can you join?{"  "}
                    </label>
                    <input
                      type="checkbox"
                      value="Available"
                      defaultChecked={workExperience?.available === "Available"}
                      className="txt__para txt__sm ml-sm-2  m-2"
                      onChange={(e) => {
                        handleAvailNow(e);
                      }}
                    />
                    Available now
                  </div>
                  <div className="form-control-box">
                    <input
                      type="number"
                      placeholder="7 days"
                      className="form-control-no-border"
                      value={workExperience?.notice_period}
                      onChange={(e) => {
                        handleHowSoonJoin(e);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group-gap">
                  <div className="form-label-box">
                    <label className="mb-2 txt__para txt__sm fw-bold mb-1">
                      Expected CTC
                    </label>
                  </div>
                  <div className="form-control-box">
                    <input
                      type="text"
                      placeholder="20000"
                      className="form-control-no-border"
                      defaultValue={workExperience?.expected_ctc}
                      onChange={(e) => {
                        handleExpectedCtc(e);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="workExTab">
        <Tabs defaultActiveKey="first">
          <Tab eventKey="first" title="Job History">
            <div className="workTabBody">
              <div className="row g-3 align-items-center justify-content-between mb-4 mt-1">
                <div className="col-auto">
                  <h6>Job histories</h6>
                </div>
                <div className="col-auto">
                  {jobHistories?.job_histories.length<=1 ?<button
                    className="btn-outline"
                    onClick={() => handleAddHistory()}
                  >
                    Add Job History
                  </button>: null}
                </div>
              </div>

              {jobHistories?.job_histories?.map((element, index) => (
                <div className="job-history" key={index + 1}>
                  <h6 className="jobHistoryTitle">Job History {index + 1}</h6>
                  <div className="row g-3">
                    <div className="col-sm-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Company name"
                        value={element.company}
                        onChange={(e) => handleCompanyName(e, index)}
                      />
                    </div>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Job Title"
                        value={element.position}
                        onChange={(e) => {
                          handleHistoryPosition(e, index);
                        }}
                      />
                    </div>
                    <div className="col-lg-6">
                      <div className="row g-3 align-items-end">
                        <div className="col-sm-6">
                          <label htmlFor="" className="form-label">
                            Joining date
                          </label>
                          <Select
                            name=""
                            id=""
                            className=""
                            options={monthOptions}
                            styles={customStyles}
                            placeholder="Month"
                            value={monthOptions?.find(
                              (el) => el.label == element.joining_month
                            )|| ""}
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                            onChange={(e) => {
                              handleHistoryJoinMonth(e.label, index);
                            }}
                          />
                        </div>
                        <div className="col-sm-6">
                          <Select
                            ref={histRef[index]}
                            name=""
                            id=""
                            className=""
                            isDisabled={!element?.joining_month || element?.joining_month=="Month"}
                            options={yearOptions}
                            styles={customStyles}
                            placeholder="Year"
                            value={yearOptions.find(
                              (month) => month.label == element.joining_year
                            )|| ""}
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                            onChange={(e) => {
                              handleHistoryJoinYear(e.value, index);
                            }}
                          />
                        </div>
                        <p className="text-danger m-2">
                        {element?.error}
                      </p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="row g-3 align-items-end">
                        <div className="col-sm-6">
                          <label htmlFor="" className="form-label">
                            End date
                          </label>
                          <Select
                            name=""
                            id=""
                            className=""
                            isDisabled={element.joining_year ? false : true}
                            options={monthOptions}
                            styles={customStyles}
                            placeholder="Month"
                            value={monthOptions.find(
                              (month) =>
                                month.label == element.termination_month
                            )|| ""}
                            onChange={(e) => {
                              handleHistoryEndMonth(e.label, index);
                            }}
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                          />
                        </div>
                        <div className="col-sm-6">
                          <Select
                            name=""
                            id=""
                            className=""
                            isDisabled={element.joining_year && element.termination_month? false : true}
                            options={ monthOptions.findIndex((month) => month.label === element.joining_month)>=monthOptions.findIndex((month) => month.label ===element.termination_month)
                              ?
                              yearOptions.filter((item) => {
                                 if (item.label > element.joining_year) {
                                   return <option value={item.label}>{item.label}</option>;
                                 }
                                 return null;
                               }): yearOptions.filter((item) => {
                                 if (item.label >= element.joining_year) {
                                   return <option value={item.label}>{item.label}</option>;
                                 }
                                 return null;
                               })}
                            styles={customStyles}
                            placeholder="Year"
                            value={yearOptions.find(
                              (month) => month.label == element.termination_year
                            ) || ""}
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                            onChange={(e) => {
                              handleHistoryEndYear(e.value, index);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <button
                      type="button"
                      className="nav-link Next_qu"
                      onClick={() => {
                        index>0?handleRemoveHist(index):handleResetHist(index);
                      }}
                    >
                   Remove
                    </button>
                    {index>0 && index===jobHistories?.job_histories.length-1 ? 
                    <button
                    className="btn-outline nav-link Next_qu"
                    onClick={() => handleAddHistory()}
                  >
                    Add Job History
                  </button>: null}
                  </div>
                </div>
              ))}
            </div>
          </Tab>
          <Tab eventKey="second" title="Work Note">
            <div className="workTabBody">
              <div className="row g-3 align-items-center justify-content-between mb-4 mt-1">
                <div className="col-auto">
                  <h6>Add work note</h6>
                </div>
              </div>
              <div className="row g-3">
                <div className="col-md-12">
                  <label htmlFor="" className="form-label">
                    Work note
                  </label>
                  <textarea
                    name=""
                    id=""
                    rows="5"
                    className="form-control"
                    defaultValue={workExperience?.work_note}
                    onChange={(e) => {
                      HandleWorkNote(e);
                    }}
                  ></textarea>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="third" title="Project Links">
            <div className="workTabBody">
              <div className="row g-3 align-items-center justify-content-between mb-4 mt-1">
                <div className="col-auto">
                  <h6>Projects</h6>
                </div>
                <div className="col-auto">
                  {projectDetails?.project_details.length<=1 ? <button
                    className="btn-outline"
                    onClick={() => {
                      handleProject();
                    }}
                    // {updateProjectDetails}
                  >
                    Add
                  </button>:null}
                </div>
              </div>

              {projectDetails?.project_details?.map((element, index) => (
                <div className="job-history" key={index}>
                  <h6 className="jobHistoryTitle">Project {index + 1}</h6>
                  <div className="row g-3">
                    <div className="col-sm-6">
                      <label htmlFor="" className="form-label">
                        Project name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Project name"
                        value={element.name}
                        onChange={(e) => {
                          handleProjectName(e, index);
                        }}
                      />
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="" className="form-label">
                        Project link
                      </label>
                      <input
                        type="text"
                        disabled={!element.name}
                        className={
                         " form-control"
                        }
                        placeholder="Project link"
                        value={element.link}
                        onChange={(e) => {
                          handleProjectLink(e, index);
                        }}
                      />
                     <div className="text-danger m-2">{element.message}</div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">

                  
                    <button
                      type="button"
                      className="nav-link Next_qu"
                      onClick={() => {
                        index>0 ? removeProject(index): resetProject(index);
                      }}
                    >
                      Remove
                    </button>
                  
                  {index>0 && index===projectDetails?.project_details.length-1 ? <button
                    className="btn-outline nav-link Next_qu"
                    onClick={() => {
                      handleProject();
                    }}
                    // {updateProjectDetails}
                  >
                    Add
                  </button>: null}
                  </div>

                </div>
              ))}
            </div>
          </Tab>
          <Tab eventKey="fourth" title="Certificate">
            <div className="workTabBody">
              <div className="row g-3 align-items-center justify-content-between mb-4 mt-1">
                <div className="col-auto">
                  <h6>Certificates</h6>
                </div>
                <div className="col-auto">
                  {certifications.length<=1 ? <button
                    className="btn-outline"
                    onClick={() => {
                      handleCertificate();
                    }}
                  >
                    Add
                  </button> : null}
                </div>
              </div>

              {certifications?.map((element, index) => (
                <div className="job-history" key={index}>
                  <h6 className="jobHistoryTitle">Certificate {index + 1}</h6>
                  <div className="row g-3">
                    <div className="col-sm-6">
                      <label htmlFor="" className="form-label">
                        Certificate for
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Certificate name"
                        value={element.course_name}
                        onChange={(e) => {
                          handleCertificateName(e, index);
                        }}
                      />
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="" className="form-label">
                        Instituition name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Instituition name"
                        value={element.institute_name}
                        onChange={(e) => {
                          handleCertificateInstitute(e, index);
                        }}
                      />
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="" className="form-label">
                        Course completion year
                      </label>
                      <Select
                        name=""
                        id=""
                        className=""
                        options={yearOptions}
                        styles={customStyles}
                        placeholder="Year"
                        value={yearOptions.find(
                          (month) => month.label == element.completion_year
                        ) || ""}
                        onChange={(e) => {
                          handleCertificateYear(e.value, index);
                        }}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">

                    <button
                      type="button"
                      className="nav-link Next_qu"
                      onClick={() => {
                      index>0 ? removeCertificate(index) : resetCertificate(index);
                      }}
                    >
                      Remove
                    </button>
                   {index>0 && index===certifications.length-1? <button
                    className="btn-outline nav-link Next_qu"
                    onClick={() => {
                      handleCertificate();
                    }}
                  >
                    Add
                  </button>: null}
                  </div>
                
                </div>
              ))}
            </div>
          </Tab>
        </Tabs>
      </div>

      <div className="d-flex justify-content-between">
        <button
          type="button"
          className="nav-link Next_qu me-4"
          onClick={() => {
            dispatch(updateStep("Hardware"));
          }}
        >
          Previous
        </button>
        <button
          type="button"
          disabled={upgaradeCandidate?.isLoading}
          className="nav-link Next_qu"
          onClick={(e) => {
            handleNext(e);
          }}
        >
          {!upgaradeCandidate?.isLoading? "Next" : <Spiner1 />}
        </button>
      </div>
    </div>
  );
};

export default ExperienceData;
