import React from 'react'

const CandidateHeader = () => {
  return (
    <div className="logo_area">
        <img src="/images/dashboard-logo.png" alt="logo" className='img-fluid site_logo bg-primary p-2' />
    </div>
  )
}

export default CandidateHeader
