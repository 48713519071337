import axios from "axios";
import { toast } from "react-toastify";
import { getCandidate } from "../findCandidate/action";

export const updateStep = (step) => ({
  type: "UPDATE_STAPE",
  payload: step,
});
// start Update qualification
export const updateQualification = (qualification) => ({
  type: "UPDATE_QUALIFICATION",
  payload: qualification,
});
// end Update qualification
export const updateFirstName = (firstName) => ({
  type: "UPDATE_FIRST_NAME",
  payload: firstName,
});

export const updateLastName = (lastName) => ({
  type: "UPDATE_LAST_NAME",
  payload: lastName,
});

export const updateLeadSource = (leadSource) => ({
  type: "UPDATE_LEAD_SOURCE",
  payload: leadSource,
});

export const updateDesignationExperience = (designationExperience) => ({
  type: "UPDATE_DESIGNATION_EXPERIENCE",
  payload: designationExperience,
});

export const updateDesignation = (designation) => ({
  type: "UPDATE_DESIGNATION",
  payload: designation,
});

export const updateGender = (gender) => ({
  type: "UPDATE_GENDER",
  payload: gender,
});

export const updatePrimaryEmail = (email) => ({
  type: "UPDATE_PRIMARY_EMAIL",
  payload: email,
});

export const updateSecondaryEmail = (email) => ({
  type: "UPDATE_SECONDARY_EMAIL",
  payload: email,
});

export const updatePrimaryPhone = (phone) => ({
  type: "UPDATE_PRIMARY_PHONE",
  payload: phone,
});

export const updateSecondaryPhone = (phone) => ({
  type: "UPDATE_SECONDARY_PHONE",
  payload: phone,
});

export const updateContactTime = (timeArray) => ({
  type: "UPDATE_CONTACT_TIME",
  payload: timeArray,
});

export const updateCurrentLocation = (location) => ({
  type: "UPDATE_CURRENT_LOCATION",
  payload: location,
});

export const updateJobPreference = (preferenceArray) => ({
  type: "UPDATE_JOB_PREFERENCE",
  payload: preferenceArray,
});

export const updateSocials = (socialArray) => ({
  type: "UPDATE_SOCIALS",
  payload: socialArray,
});

export const updateResume = (resume) => (
  {
    type: "UPDATE_RESUME",
    payload: resume,
  }
);

export const updateProfileImage = (image) => ({
  type: "UPDATE_PROFILE_IMAGE",
  payload: image,
});

// start sctions for hardware
// Action creators for UPDATE_COMPUTER
export const updateComputer = (payload) => ({
  type: "UPDATE_COMPUTER",
  payload,
});

// Action creators for UPDATE_INTERNET_DOWNLOAD
export const updateInternetDownload = (payload) => ({
  type: "UPDATE_INTERNET_DOWNLOAD",
  payload,
});

// Action creators for UPDATE_INTERNET_TYPE
export const updateInternetType = (payload) => ({
  type: "UPDATE_INTERNET_TYPE",
  payload,
});

// Action creators for UPDATE_INTERNET_UPLOAD
export const updateInternetUpload = (payload) => ({
  type: "UPDATE_INTERNET_UPLOAD",
  payload,
});

// Action creators for UPDATE_PROCESSOR
export const updateProcessor = (payload) => ({
  type: "UPDATE_PROCESSOR",
  payload,
});

// Action creators for UPDATE_RAM
export const updateRAM = (payload) => ({
  type: "UPDATE_RAM",
  payload,
});

// Action creators for UPDATE_UPGRADE_INTERNET
export const updateInternetUpgrade = (payload) => ({
  type: "UPDATE_INTERNET",
  payload,
});

// Action creators for UPDATE_UPGRADE_RAM
export const updateRAMUpgrade = (payload) => ({
  type: "UPDATE_UPGRADE_RAM",
  payload,
});
// end actions for hardware
// start action for update experience
export const updateTotalExperienceInMonths = (payload) => ({
  type: "UPDATE_TOTAL_EXPERIENCE_IN_MONTH",
  payload,
});

export const updateExperienceInYear = (payload) => ({
  type: "UPDATE_EXPERIENCE_IN_YEAR",
  payload,
});

export const updateExperienceInMonth = (payload) => ({
  type: "UPDATE_EXPERIENCE_IN_MONTH",
  payload,
});
// end actions for update experience
// start action for update jobHistory
export const updateJobHistory = (payload) => ({
  type: "UPDATE_JOB_HISTORY",
  payload,
});
// end actions for update jobHistory
// start action for work details

export const updateAvailable = (payload) => ({
  type: "UPDATE_AVAILABLE",
  payload,
});
export const UpdateNoticePeriod = (payload) => ({
  type: "UPDATE_NOTICE_PERIOD",
  payload,
});

export const updateCurrentCompany = (payload) => ({
  type: "UPDATE_CURRENT_COMPANY",
  payload,
});

export const updateCurrentCTC = (payload) => ({
  type: "UPDATE_CURRENT_CTC",
  payload,
});

export const updateCurrentPosition = (payload) => ({
  type: "UPDATE_CURRENT_POSITION",
  payload,
});

export const updateCurrentlyWorking = (payload) => ({
  type: "UPDATE_CURRENTLY_WORKING",
  payload,
});

export const updateExpectedCTC = (payload) => ({
  type: "UPDATE_EXPECTED_CTC",
  payload,
});

export const updateWorkJobPreference = (payload) => ({
  type: "UPDATE_JOB_PREFERENCE",
  payload,
});

export const updateJoiningDateMonth = (payload) => ({
  type: "UPDATE_JOINING_DATE_MONTH",
  payload,
});

export const updateJoiningDateYear = (payload) => ({
  type: "UPDATE_JOINING_DATE_YEAR",
  payload,
});

export const updateLastCompany = (payload) => ({
  type: "UPDATE_LAST_COMPANY",
  payload,
});

export const updateLastCTC = (payload) => ({
  type: "UPDATE_LAST_CTC",
  payload,
});

export const updateLastPosition = (payload) => ({
  type: "UPDATE_LAST_POSITION",
  payload,
});

export const updateNoticePeriod = (payload) => ({
  type: "UPDATE_NOTICE_PERIOD",
  payload,
});

export const updateNoticePeriodNegotiable = (payload) => ({
  type: "UPDATE_NOTICE_PERIOD_NEGOTIABLE",
  payload,
});

export const updateTerminationDateMonth = (payload) => ({
  type: "UPDATE_TERMINATION_DATE_MONTH",
  payload,
});

export const updateTerminationDateYear = (payload) => ({
  type: "UPDATE_TERMINATION_DATE_YEAR",
  payload,
});

export const updateWhySwitching = (payload) => ({
  type: "UPDATE_WHY_SWITCHING",
  payload,
});

export const updateWhyUnemployed = (payload) => ({
  type: "UPDATE_WHY_UNEMPLOYED",
  payload,
});

export const updateWorkNote = (payload) => ({
  type: "UPDATE_WORK_NOTE",
  payload,
});

// end action for work details
// start actions for update project details
export const updateProjectDetails = (payload) => ({
  type: "UPDATE_PROJECT_DETAILS",
  payload,
});
// end action for update project details

// start action for update certificate
export const updateCertificates = (payload) => (
  {
  type: "UPDATE_CERTIFICATES",
  payload,
});
// end action for update certificate

// start action for update step
// export const increseStep = (step) => (dispatch) => {
//   dispatch(updateStep(step));
// };
// end action for update step
//start of skill section
export const updateSkill=(data)=>(
  {
  type:'UPDATE_SKILL',
  payload:data
})
export const updateLanguage = (data) => ({
  type: 'UPDATE_LANGUAGE',
  payload: data
});
//skill section end
//validation start
export const validData=(field,message)=>({
  type:"ADD_VALIDATION",
  field,
  message
})
//validation start
//add candidate section
export const add_req=(data)=>({
  type:"ADD_REQUEST",
  payload:data,
})
export const add_succ=(data)=>({
  type:"ADD_SUCCESS",
  payload:data,
})
export const add_fail=(data)=>({
  type:"ADD_FAILURE",
  payload:data,
})

export const nextGeneralDetails = (navigate, data) => async (dispatch) => {
  dispatch(add_req());
  try {
  const updatedData = { ...data };
  const reader = new FileReader();
  reader.onload = function (event) {
    updatedData.resume = event.target.result;
    if(data.socials.length==1 && data.socials[0].link=="" && data.socials[0].name==""){
      updatedData.socials =[];
    }
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/auth/candidate/add_candidate`,
        updatedData,
        config
      )
      .then((res) => {
        dispatch(add_succ(res.data));
        dispatch(updateStep("Education"));
        localStorage.setItem("id", res?.data?.candidate);
      })
      .catch((error) => {
        dispatch(add_fail(error));
        if (error?.response?.data?.error == "Unauthenticated.") {
          localStorage.removeItem("token");
          navigate("/login");
        }
       
        if(error?.response?.data?.errors?.length>0){error?.response?.data?.errors.forEach((errorObject) => {
          let index = 0;
          Object.entries(errorObject).forEach(([fields, errorMessage]) => {
            if (index == 0) {
              index = ++index;
              toast.error(`${errorMessage}`);
            }
          });
        }
        );} else if(error?.response?.data?.message ){
          toast.error(
            error?.response?.data?.message)
        }
      });
      
  };
  reader.readAsDataURL(updatedData.resume);

  } catch (error) {
    console.log(error);
  }
};

//add candidate section end

//update candidate section
export const update_req=(data)=>({
  type:"UPDATE_REQUEST",
})
export const update_succ=(data)=>({
  type:"UPDATE_SUCCESS",
  payload:data,
})
export const update_fail=(data)=>({
  type:"UPDATE_FAILURE",
  payload:data,
})

export const nextUpdateDetails =
  (navigate, changeTab, id, data) => async (dispatch) => {
    const newData={...data}
    if (
      newData?.socials?.length == 1 &&
      newData?.socials[0]?.link == "" &&
      newData?.socials[0]?.name == ""
    ) {
      newData.socials = [];
    }
    try {
      dispatch(update_req())
      const axiosCall = (upData) => {
       
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        };
        axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/api/auth/candidate/upgrade_candidate/${id}`,
            upData,
            config
          )
          .then((res) => {
            dispatch(update_succ(res.data));
            // dispatch(increseStep("Education"))
            if (changeTab == "Submit") {
              // clearing id from redux and local storage
              localStorage.removeItem("id");
              // dispatch(add_succ(""));
              dispatch(resetCandidateData()).then(() => {
                navigate("/candidates/all-candidates");
                dispatch(updateStep("General"));
              });
            } else {
              dispatch(updateStep(changeTab));
            }
            return res;
          })
          .catch((error) => {
            dispatch(update_fail(error));
            if (error?.response?.data?.error == "Unauthenticated.") {
              localStorage.removeItem("token");
              navigate("/login");
            }
            if (error?.response?.data?.message) {
              toast.error(error?.response?.data?.message);
            }
          });
      };
      const updateData = { ...newData };

      // data = newData;
      const reader = new FileReader();
      reader.onload = function (event) {
        updateData.resume = event.target.result;
        axiosCall(updateData);
      };
      if (updateData.resume && updateData?.resume?.type|| changeTab == "Submit" && updateData?.resume?.type ) {
        reader.readAsDataURL(updateData.resume);
      } else {
        axiosCall(newData);
      }
    } catch (error) {
      console.log(error);
    }
  };


export const dataFill=(res)=>async (dispatch)=>{
 // parent componant
 dispatch(updateFirstName(res?.data?.first_name));
 dispatch(updateLastName(res?.data?.last_name));
 dispatch(updateLeadSource(res?.data?.lead_source));
 dispatch(
   updateDesignationExperience(res?.data?.experience_level_id)
 );
 dispatch(updateDesignation(res?.data?.designation_id));
 // AS resume is a link of s3 bucket and not an binary data it creating issue while data extraction after hard refresh
  dispatch(updateResume(res?.data?.resume))
 dispatch(updateProfileImage(res?.data?.profile_image || ""))
 //general data section
 dispatch(updateGender(res?.data?.gender));
 dispatch(updatePrimaryEmail(res?.data?.contact?.email_primary));
 dispatch(updateSecondaryEmail(res?.data?.contact.email_secondary));
 dispatch(updatePrimaryPhone(res?.data?.contact.phone_primary));
 dispatch(updateSecondaryPhone(res?.data?.contact.phone_secondary));
 dispatch(updateContactTime(res?.data?.contact.contact_time));
 dispatch(
   updateJobPreference(res?.data?.work_details?.job_preference)
 );
 dispatch(updateCurrentLocation(res?.data?.contact.city));
 dispatch(updateSocials(res?.data?.socials));
 //education section
 dispatch(updateQualification(res?.data?.qualifications));
 dispatch(updateComputer(res?.data?.system_hardware?.computer));
 dispatch(updateProcessor(res?.data?.system_hardware?.processor));
 dispatch(updateRAM(res?.data?.system_hardware?.ram));
 dispatch(updateRAMUpgrade(res?.data?.system_hardware?.upgrade_ram));
 dispatch(updateInternetUpgrade(res?.data?.system_hardware?.upgrade_internet));
 dispatch(updateInternetType(res?.data?.system_hardware?.internet_type));
 dispatch(updateInternetDownload(res?.data?.system_hardware?.internet_download));
 dispatch(updateInternetUpload(res?.data?.system_hardware?.internet_upload));
 //hardware requirement section
 dispatch(updateAvailable(res?.data?.work_details?.available));
 dispatch(updateCurrentCompany(res?.data?.work_details?.current_company));
 dispatch(updateCurrentlyWorking(res?.data?.work_details?.currently_working));
 dispatch(updateWhySwitching(res?.data?.work_details?.why_switching));
 dispatch(updateExperienceInYear(res?.data?.total_experience?.year));
 dispatch(updateTotalExperienceInMonths(res?.data?.total_experience?.total_in_months));
 dispatch(updateExperienceInMonth(parseInt(res?.data?.total_experience?.month)));
 dispatch(updateCurrentPosition(res?.data?.work_details?.current_position));
 dispatch(updateJoiningDateMonth(res?.data?.work_details?.joining_date_month));
 dispatch(updateJoiningDateYear(res?.data?.work_details?.joining_date_year));
 dispatch(updateNoticePeriodNegotiable(res?.data?.work_details?.notice_period_negotiable));
 dispatch(updateCurrentCTC(res?.data?.work_details?.current_ctc));
 dispatch(updateExpectedCTC(res?.data?.work_details?.expected_ctc));
 dispatch(updateWhyUnemployed(res?.data?.work_details?.why_unemployed));
 dispatch(updateLastCompany(res?.data?.work_details?.last_company));
 dispatch(updateLastPosition(res?.data?.work_details?.last_position));
 dispatch(updateTerminationDateMonth(res?.data?.work_details?.termination_date_month));
 dispatch(updateTerminationDateYear(res?.data?.work_details?.termination_date_year));
 dispatch(updateNoticePeriod(res?.data?.work_details?.notice_period));
 dispatch(updateLastCTC(res?.data?.work_details?.last_ctc));
 dispatch(updateWorkNote(res?.data?.work_details?.work_note));
 dispatch(updateJobHistory(res?.data?.job_histories));
 dispatch(updateProjectDetails((res?.data?.project_details)));
 dispatch(updateCertificates(res?.data?.certifications));
 // skill section
 //skills data format is not appropiat for showing data in add skills section
 // dispatch(updateSkill(res?.data?.skills))
 // dispatch(updateLanguage(res?.data?.languages))
}
export const dataFillById = (navigate,id) => async (dispatch) => {
    return dispatch(getCandidate(id, navigate))?.then((res) => {
    dispatch(dataFill(res))
    return res?.data
  }
  );
};
export const resetCandidateData = () => async (dispatch) => {
        // stored id from redux
         dispatch(add_succ(""))
        // parent componant
        dispatch(updateFirstName(""));
        dispatch(updateLastName(""));
        dispatch(updateLeadSource(""));
        dispatch( updateDesignationExperience(""));
        dispatch(updateDesignation(""));
        dispatch(updateResume(""))
        dispatch(updateProfileImage(""))
        //general data section
        dispatch(updateGender(""));
        dispatch(updatePrimaryEmail(""));
        dispatch(updateSecondaryEmail(""));
        dispatch(updatePrimaryPhone(""));
        dispatch(updateSecondaryPhone(""));
        dispatch(updateContactTime([""]));
        dispatch(updateJobPreference([""]));
        dispatch(updateCurrentLocation(""));
        dispatch(updateSocials([{link:" ",name:" " }]));
        //education section
        dispatch(updateQualification([ {
          degree: "",
          year: "0",
          session_end: "0",
          institute: "0",
          percentage: "0",
          session_start: null,
          status: [""]
        },]
    ));
        dispatch(updateComputer(""));
        dispatch(updateProcessor(""));
        dispatch(updateRAM(""));
        dispatch(updateRAMUpgrade(false));
        dispatch(updateInternetUpgrade(false));
        dispatch(updateInternetType(""));
        dispatch(updateInternetDownload(""));
        dispatch(updateInternetUpload(false));
        //hardware requirement section
        dispatch(updateAvailable(""));
        dispatch(updateCurrentCompany(""));
        dispatch(updateCurrentlyWorking(""));
        dispatch(updateWhySwitching(""));
        dispatch(updateExperienceInYear(0));
        dispatch(updateTotalExperienceInMonths(0));
        dispatch(updateExperienceInMonth(parseInt(0)));
        dispatch(updateCurrentPosition(""));
        dispatch(updateJoiningDateMonth(""));
        dispatch(updateJoiningDateYear(""));
        dispatch(updateNoticePeriodNegotiable(""));
        dispatch(updateCurrentCTC(""));
        dispatch(updateExpectedCTC(""));
        dispatch(updateWhyUnemployed(""));
        dispatch(updateLastCompany(""));
        dispatch(updateLastPosition(""));
        dispatch(updateTerminationDateMonth(""));
        dispatch(updateTerminationDateYear(""));
        dispatch(updateNoticePeriod(""));
        dispatch(updateLastCTC(""));
        dispatch(updateWorkNote(""));
        dispatch(updateJobHistory([{
            company: "",
            joining_month: "",
            joining_year: "",
            position: "",
            termination_month: "",
            termination_year: ""
          }]));
        dispatch(updateProjectDetails([
          {
            name: "",
            link: ""
          }
      ]));
        dispatch(updateCertificates([
          {
            course_name: "",
            institute_name: "",
            completion_year: ""
          }
        ]));
        // skill section
        //skills data format is not appropiat for showing data in add skills section
        dispatch(updateSkill([
          {
            currently_working: false,
            exp_month: 0,
            exp_year: 0,
            skill_id: []
          }
      ]))
        dispatch(updateLanguage( [
          {
              name: "",
              rating: 0
          }
      ]))
      return 
};
