import React, { useEffect, useState } from "react";
import Select from "react-select";
import { resetCandiadte, skills,
  //  storeCandiadte 
  } from "../../../Redux/CandidateReducer/action";
import { useDispatch, useSelector } from "react-redux";
import PopUpModel from "../../popUpModel/PopUpModel";
import { Link, useNavigate } from "react-router-dom";
import {nextUpdateDetails, updateLanguage,updateSkill, updateStep, validData } from "../../../Redux/Candidate/createCandidate/action";
import Spiner1 from "../../Spiner1";
import { toast } from "react-toastify";

const SkillsData = (props) => {
  const skillsArray = useSelector((state)=> state.createSkills.skills);
  const upgaradeCandidate = useSelector((state) => state.updateCandidateResponse);
  const candidateResponse = useSelector((state)=> state.addResponseReducer.data);
  const candidateData = useSelector((state)=> state.createCandidate);
  const workExperience = useSelector((state) => state.updateworkDetails);
  const languageArray = useSelector((state)=> state.createLanguage.languages);
  const qualifications = useSelector((state)=> state.createQualification);
  const updateHardware = useSelector(state => state.updateHardware);
  const jobHistories = useSelector((state) => state.updateJobHistory);
  const addPeriod = useSelector((state) => state.addPeriod);
  const certifications = useSelector(
    (state) => state.updateCertifications.certifications
  );
  const totalExperience = useSelector((state) => state.updateTotalExperience);
  const projectDetails = useSelector((state) => state.updateProjectDetails);
  const navigate = useNavigate()
  const { inputRef,errorGenerate, errorMessage} = props;
  const skillList = useSelector((state) => state.Candidate_Reducer.skillsData);
  const dispatch = useDispatch();
  const [updateSkillList, setUpdateSkillList] = useState(false)
  
  const colourOption2 = () => {
    return skillList?.filter((item)=>item.status!=0).map((e) => ({ value: e.id, label: e.name }));
  };

  const handleAddSkill = () => {
    let data = {
      currently_working: false,
      exp_month: 0,
      exp_year: 0,
      skill_id: [],
    }
    skillsArray[skillsArray.length-1].skill_id.length ===0 ? toast.error("Please select atleast one skill") :
      dispatch(updateSkill([...skillsArray,data]));
  };

  const handleSkill = (value, index) => {
    const Choosed = value?.map((el) => {
      return skillList.find((element) => {
        if (element.id == el.value) {
          return element;
        }
      });
    });


    const inputArray = [...skillsArray];
    inputArray[index].skill_id = Choosed;
    dispatch(updateSkill(inputArray))
  };
  const handleExpYear = (e, index) => {
    const inputArray = [...skillsArray];
    inputArray[index].exp_year = e.target.value;
    dispatch(updateSkill(inputArray))
  };
  const handleExpMonth = (e, index) => {
    const inputArray = [...skillsArray];
    inputArray[index].exp_month = e.target.value;
    dispatch(updateSkill(inputArray))
  };
  const handleCurrentCheck = (e, index) => {
    const inputArray = [...skillsArray];
    inputArray[index].currently_working = e.target.checked;
    dispatch(updateSkill(inputArray))
  };
  const removeSkills = (index) => {
    dispatch(updateSkill(skillsArray.filter((_, i) => i !== index)))
  };

  const resetSkills = (index) => {
    const inputArray = [...skillsArray];
    inputArray[index]= {
      currently_working: false,
      exp_month: 0,
      exp_year: 0,
      skill_id: [],
    };
      dispatch(updateSkill(inputArray));
  };

  
  const handleLanguage = () => {
    const data = {
      name: "",
      rating: 0,
    };
    languageArray[languageArray.length-1].name === "" ? toast.error("Please Provide Language Name") :
    dispatch(updateLanguage([...languageArray, data]));
  };

  const handleLanguageName = (data, index) => {
    const inputArray = [...languageArray];
    inputArray[index].name = data;
    dispatch(updateLanguage(inputArray))
  };

  const handleLanguageRatting = (data, index) => {
    const inputArray = [...languageArray];
    inputArray[index].rating = data;
    dispatch(updateLanguage(inputArray))
  };


  const languageRemove = (index) => {
    const delVal = [...languageArray];
    delVal.splice(index, 1);
    dispatch(updateLanguage(delVal));
  };
  const resetLanguage = (index) => {
    const delVal = [...languageArray];
   delVal[index]={
      name: "",
      rating: 0,
    };
    dispatch(updateLanguage(delVal));
  };

  const handleSubmit = (e) => {
    e.preventDefault()
      const nextTab="Submit"
      const data = {
        first_name: candidateData.first_name,
        last_name: candidateData.last_name,
        lead_source: candidateData.lead_source,
        designation_experience: candidateData.designation_experience,
        designation: candidateData.designation,
        resume: candidateData.resume ? candidateData.resume : "",
        skills: skillsArray,
        languages: languageArray,
        qualifications: qualifications.qualifications,
        system_hardware: updateHardware,
        work_details: workExperience,
        notice_period: addPeriod,
        job_histories: jobHistories.job_histories,
        certifications: certifications,
        project_details: projectDetails.project_details,
        total_experience: totalExperience,
      };
      if(!candidateData.resume){
        errorGenerate("resume","Please Provide The Resume")
        // dispatch(validData("resume","Please Provide The Resume"))
        // inputRef[0].current.focus()
      }
       if(!candidateData.first_name){
        errorGenerate("first_name","Please Provide The First name")
        // inputRef[1].current.focus()
      }
       if(!candidateData.last_name){
        errorGenerate("last_name","Please Provide  The Last name")
        // inputRef[2].current.focus()
      }
       if(!candidateData.lead_source){
        errorGenerate("lead_source","Please Provide The source")
        // inputRef[3].current.focus()
      }
       if(!candidateData.designation_experience){
        errorGenerate("designation_experience","Please The Provide Experience")
        //  inputRef[4].current.focus()
      }
       if(!candidateData.designation){
        //  inputRef[5].current.focus()
        errorGenerate("designation","Please Provide The Designation")
      }
        
      if(!candidateData.resume){
        // dispatch(validData("resume","Please Provide The Resume"))
        inputRef[0].current.focus()
      }
      else if(!candidateData.first_name){
        // dispatch(validData("first_name","Please Provide The First name"))
        inputRef[1].current.focus()
      }
      else if(!candidateData.last_name){
        // dispatch(validData("last_name","Please Provide  The Last name"))
        inputRef[2].current.focus()
        
      }
      else if(!candidateData.lead_source){
        // dispatch(validData("lead_source","Please Provide The source"))
        inputRef[3].current.focus()
      }
      else if(!candidateData.designation_experience){
        
        // dispatch(validData("designation_experience","Please The Provide Experience"))
         inputRef[4].current.focus()
      }
      else if(!candidateData.designation){
        
         inputRef[5].current.focus()
        // dispatch(validData("designation","Please Provide The Designation"))
      }
      else if(!workExperience.currently_working){
        dispatch(updateStep("Experience")) 
        errorGenerate("currently_working","Please Provide Current Working Status")
        setTimeout(() => {
          inputRef[13].current.focus()
        }, 300);
        dispatch(validData("currently_working","Please Provide The Currently Working Or Not"))
      }
      else{
      dispatch(nextUpdateDetails(navigate,nextTab,candidateResponse?.candidate,data))
    }
  }

  useEffect(() => {
    dispatch(skills(navigate));
  }, [updateSkillList]); 

  return (
    <div className="tab-data mt-4" step={0}>
      <p className="text-muted txt__sm mb-3 mt-3">
        If you don't find your skill then please
        <PopUpModel placeholder={"Skill Name"} title={"Skills"} header={"Click Here"} setUpdateSkillList={setUpdateSkillList} className="ms-3 me-3" /> 
        to add new skill
      </p>
      <div className="d-flex justify-content-end mb-2">
        {skillsArray.length<=1?<button
          type="button"
          className="button-outline"
          onClick={() => handleAddSkill()}
        >
          Add Skill
        </button>: null}
      </div>
      {skillsArray?.map((element, index) => (
        <div key={index} className="mb-3">
          <div className="grid-2-md">
            <div className="grid-box">
              <div className="form-group-gap mb-2">
                <div className="form-label-box mb-2 d-flex align-items-center justify-content-between">
                  <label className="txt__para txt__sm fw-bold mb-0">
                    Skill {index + 1}
                  </label>
                  <div className="filter-tags" />
                </div>
                <div className="form-control-box form-control-box-multiselect">
                  <Select
                    id={index}
                    placeholder="Select skills"
                    value={element?.skill_id?.map((el) => ({value: el.id,label: el.name}))}
                    isMulti
                    name="colors"
                    options={colourOption2()}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => {
                      handleSkill(e, index);
                    }}
                    isClearable
                  />
                </div>
              </div>
            </div>
            <div className="grid-box">
              <div className="form-group-gap mb-2">
                <div className="form-label-box d-flex align-items-center justify-content-between mb-2">
                  <label className="txt__para txt__sm fw-bold mb-0">
                    Experience
                  </label>
                </div>
                <div className="grid-2 select_disable">
                  <div className="form-control-box">
                    <select
                      disabled={element?.skill_id?.length==0}
                      className="form-control-no-border form-control-no-border-select"
                      value={element.exp_year}
                      onChange={(e) => {
                        handleExpYear(e, index);
                      }}
                    >
                      <option value={0}>0 year</option>
                      <option value={1}>1 year</option>
                      <option value={2}>2 years</option>
                      <option value={3}>3 years</option>
                      <option value={4}>4 years</option>
                      <option value={5}>5 years</option>
                      <option value={6}>6 years</option>
                      <option value={7}>7 years</option>
                      <option value={8}>8 years</option>
                      <option value={9}>9 years</option>
                      <option value={10}>10 years</option>
                      <option value={11}>11 years</option>
                      <option value={12}>12 years</option>
                      <option value={13}>13 years</option>
                      <option value={14}>14 years</option>
                      <option value={15}>15 years</option>
                      <option value={16}>16 years</option>
                      <option value={17}>17 years</option>
                      <option value={18}>18 years</option>
                      <option value={19}>19 years</option>
                      <option value={20}>20 years</option>
                      <option value={21}>21 years</option>
                    </select>
                  </div>
                  <div className="form-control-box">
                    <select
                    disabled={element?.skill_id?.length==0}
                      className="form-control-no-border form-control-no-border-select "
                      value={element?.exp_month}
                      onChange={(e) => {
                        handleExpMonth(e, index);
                      }}
                    >
                      <option value={0}>0 month</option>
                      <option value={1}>1 month</option>
                      <option value={2}>2 months</option>
                      <option value={3}>3 months</option>
                      <option value={4}>4 months</option>
                      <option value={5}>5 months</option>
                      <option value={6}>6 months</option>
                      <option value={7}>7 months</option>
                      <option value={8}>8 months</option>
                      <option value={9}>9 months</option>
                      <option value={10}>10 months</option>
                      <option value={11}>11 months</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <input
                type="checkbox"
                disabled={element?.skill_id?.length==0}
                checked={element?.currently_working}
                onChange={(e) => {
                  handleCurrentCheck(e, index);
                }}
              />{" "}
              Currently working on this skill
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <button
              className="nav-link Next_qu"
              onClick={() => index>0?removeSkills(index):resetSkills(index)}
            >
             Remove
            </button>
            {index>0 && index===skillsArray.length-1 ? <button
          type="button"
          className="button-outline nav-link Next_qu"
          onClick={() => handleAddSkill()}
           >
          Add Skill
           </button>: null}
          </div>
        </div>
      ))
      }
      <div className="d-flex align-items-center justify-content-end">
        {languageArray.length<=1 ? <button
          type="button"
          className="button-outline  "
          onClick={() => {
            handleLanguage();
          }}
        >
          Add language
        </button> : null}
      </div>

      {
        languageArray.map((element, index) => (
          <div className="language-known-grid mb-4" key={index}>
            <div className="grid-box row g-3">
              <div className="col-md-4">
                <div className="form-label-box mb-1">
                  <label className="txt__para txt__sm fw-bold mb-0">
                    Language {index + 1}
                  </label>
                </div>
                <div className="form-control-box pb-3">
                  <input
                    type="text"
                    placeholder="Hindi,Bengali,French..."
                    className="form-control-no-border"
                    value={element.name}
                    onChange={(e) => {
                      handleLanguageName(e.target.value, index);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-8">
                <div className="form-label-box mb-1">
                  <label className="txt__para txt__sm fw-bold mb-0">
                    Rate language
                  </label>
                </div>
                <div className="form-control-box pb-1 pt-2">
                  <span className="d-block text-end" style={{ fontSize: "10px" }}>
                    Rate: ( {element.rating} / 10)
                  </span>
                  <input
                    type="range"
                    disabled={!element.name}
                    className="form-range"
                    min={0}
                    max={10}
                    id="customRange2"
                    value={element.rating}
                    onChange={(e) => {
                      handleLanguageRatting(e.target.value, index);
                    }}
                  />
                </div>
              </div>
              
                <div className="d-flex justify-content-between">
                  <button
                  style={{width: "30px"}}
                  className="nav-link Next_qu "
                  onClick={() => index>0 ? languageRemove(index) : resetLanguage(index)}
                >
                  Remove
                </button>
             {index>0 && index===languageArray.length-1 ? <button
                type="button"
                className="button-outline  my-3"
                onClick={() => {
                  handleLanguage();
                }}
              >
                 Add language
             </button>: null}
                </div>
            </div>
          </div>
        ))
      }

      <div className="d-flex justify-content-end justify-content-between">
        <button
          type="button"
          className="nav-link Next_qu me-4"
          onClick={() => {
            dispatch(updateStep("Experience"))
          }}
        >
          Previous
        </button>
        <button
          type="button"
          disabled={upgaradeCandidate?.isLoading}
          className="nav-link Next_qu"
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          {!upgaradeCandidate?.isLoading? "Submit" : <Spiner1 />}
        </button>
      </div>
    </div >
  );
};

export default SkillsData;
