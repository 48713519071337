import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Spiner1 from "../Spiner1";
const EducationalData = ({ contact, profileData,cvData,tabData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [qualification, setQualification] = useState([ {
    id: Date.now(),
    degree: "",
    institute: "",
    session_end: "",
    percentage: "0",
    status: [],
  }]);
  let duplicateQualification=qualification //for putting condition in add button
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 30;
  const endYear = currentYear;
  const year = [];
  for (let years = startYear; years <= endYear; years++) {
    year.push(years);
  }


  const getLocaldata = () => {
    const token = localStorage.getItem("token");
    return token;
  };

  const submitEducation = async () => {
    if(!profileData?.first_name){
      toast.error('Please Provide First Name.');
      setIsLoading(false)
    }else if(!profileData?.last_name){
      toast.error('Please Provide Last Name.');
      setIsLoading(false)
    }else if(!profileData?.designation){
      toast.error('Please Provide Designation.');
      setIsLoading(false)
    }else if(!profileData?.experience_levels){
      toast.error('Please Provide Experience.');
      setIsLoading(false)
    }else{
    setIsLoading(true);

    const axiosCall=async (datas)=>{
      const config = {
        headers: { Authorization: `Bearer ${mt}` },
      };
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/api/auth/candidate_update_qualification`,
          datas,
          config
        )
        .then((res) => {
          setIsLoading(false);
          toast.success("Updated Success");
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }



    const mt = getLocaldata();
    
   

    const reader = new FileReader();
    reader.onload = function (event) {
    const data = {
      first_name: profileData?.first_name,
      last_name: profileData?.last_name,
      designation_id: profileData?.designation,
      lead_source_id: profileData?.lead_source,
      experience_level_id: profileData?.experience_levels,
      id: contact?.id,
      qualifications: qualification,
      resume:event.target.result
    };
        axiosCall(data)
    }
    if (profileData.resume && profileData.resume?.type && profileData.resume?.type ) {
        reader.readAsDataURL(profileData.resume);
      } else {
        const data = {
          first_name: profileData?.first_name,
          last_name: profileData?.last_name,
          designation_id: profileData?.designation,
          lead_source_id: profileData?.lead_source,
          experience_level_id: profileData?.experience_levels,
          id: contact?.id,
          qualifications: qualification,
          resume:profileData.resume
        };
        axiosCall(data);
      }


    setIsLoading(false);}
  };

const addQualification = (e, index) => {
  const qualifications = {
    id: Date.now(),
    degree: "",
    institute: "",
    session_end: "",
    percentage: "0",
    status: [],
  };

  if (qualification.length > 0) {
    const lastQualification = qualification[qualification.length - 1];

    if (lastQualification.degree === "" && lastQualification.institute === "") {
      toast.error("Please enter qualification and institute name");
    } else if (lastQualification.degree === "") {
      toast.error("Please fill qualification name");
    } else if (lastQualification.institute === "") {
      toast.error("Please fill institute name");
    } else {
      setQualification([...qualification, qualifications]);
    }
  } else {
    setQualification([qualifications]);
  }
};

  const removeQualification = (e, index) => {
    if (qualification?.length > 1) {
      cvData=cvData&&cvData.filter((_, i) => i !== index )
      setQualification((prevQuqlification) => {
        const newQualification = prevQuqlification.filter(
          (_, i) => i !== index
        );
        return newQualification;
      });
    }
  };
  const reset = (value, index) => {
    setQualification((prevQuqlification) => {
        return prevQuqlification.map((qualification, i) => {
            if (i !== index) {
                return qualification;
            }
            return {
                ...qualification,
                degree: "",
                institute: "",
                session_end: "",
                percentage: "0",
                status: []
            };
        });
    });
};


  const handleQualificationChange = (value, index) => {
    const name = value.target.name;

    const values = value.target.value;

    setQualification((prevQuqlification) => {
      const updatedQualification = [...prevQuqlification];
      updatedQualification[index][name] = name=="percentage"?parseFloat(values || 0): values;
      return updatedQualification;
    });
  };

  const handleCheckbox = (value, index) => {
    const name = value.target.name;
    const values = value.target.value;
    setQualification((prevQuqlification) => {
      const updatedQualification = [...prevQuqlification];
      updatedQualification[index].status[0] = name;
      return updatedQualification;
    });

  }
  useEffect(() => {
    setQualification()
    if (contact?.qualifications.length>0) {
      setQualification(contact?.qualifications);
    }
  }, [contact?.qualifications]);
  useEffect(()=>{
    cvData.length>0&&setQualification(cvData?.qualifications)
  },[cvData])
  return (
    <div className="tab-data" step={0}>
      <div className="qualification my-4">
        <div className="d-flex align-items-center justify-content-end">
          {qualification.length<=1 ? <button
            type="button"
            onClick={(e) => addQualification(e)}
            className="button-outline mb-3"
          >
            Add Qualification
          </button> : null}
        </div>
        {qualification?.map((qualification, index) => (
          <div className="row g-3 mb-3" key={index}>
            <p className="txt__sm fw-bold mb-3">Qualification {index+1}</p>
            <div className="col-sm-6">
              <div className="grid-box">
                <div className="form-control-box">
                  <input
                    type="text"
                    placeholder="Type your Qualification"
                    className="form-control-no-border"
                    onChange={(e) => {
                      handleQualificationChange(e, index);
                    }}
                    value={qualification.degree}
                    name="degree"
                  />
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="grid-box">
                <div className="form-control-box">
                  <input
                    type="text"
                    placeholder="Institution name"
                    className="form-control-no-border"
                    onChange={(e) => {
                      handleQualificationChange(e, index);
                    }}
                    value={qualification.institute}
                    name="institute"
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="grid-box">
                <div className="form-control-box">
                  <select
                    placeholder="Select your passout year"
                    name="session_end"
                    style={!qualification?.session_end ? {color: " rgb(185 181 179 / 93%)" }:null}
                    className="form-control-no-border form-control-no-border-select"
                    value={!qualification?.session_end?"Select Year" :qualification?.session_end}
                    onChange={(e) => {
                      handleQualificationChange(e, index);
                    }}
                  >
                    <option>
                    Select your passout year
                    </option>
                    {year.map((el, index) => (
                      <option key={index} value={el}>
                        {el}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              {" "}
              <div className="grid-box">
                <div className="form-control-box">
                <input
                    min={0}
                    max={100}
                    pattern="[0-9]*" 
                    type="text"
                    placeholder="Percentage"
                    className="form-control-no-border"
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^[0-9]*$/.test(value) && value >= 0 && value <= 100) {
                          handleQualificationChange(e, index);
                      }
                  }}
                    value={qualification?.percentage}
                    name="percentage"
                  />
                </div>
              </div>
            </div>


            <div>

                  <label className="me-2">
                    <input type="checkbox"
                      name="Complete"
                      checked={qualification.status[0] === "Complete"}
                      onChange={(e) => { handleCheckbox(e, index) }}
                      defaultValue={qualification.status[0]}
                    /> Completed
                  </label>
                  <label>
                    <input type="checkbox"
                      name="Pursuing"
                      checked={qualification.status[0] === "Pursuing"}
                      onChange={(e) => { handleCheckbox(e, index) }}
                      defaultValue={qualification.status[0]}
                    /> Pursuing
                  </label>
            </div>



            <div className=" d-flex align-items-center justify-content-between">
              <button
                className="btn btn-success button"
                onClick={(e) =>index<1?reset(e, index): removeQualification(e, index)}
              >
                Remove
              </button>
              {index>0 && index === duplicateQualification?.length - 1 ? <button
                type="button"
                onClick={(e) => addQualification(e)}
                className="button-outline mb-3"
              >
                Add Qualification
              </button> : null}
            </div>
          </div>
        ))}
      </div>
      <div className="d-flex justify-content-between">
        <button
          type="button"
          disabled={isLoading ? isLoading : false}
          className="btn nav-link Next_qu"
          onClick={submitEducation}
        >
          {isLoading ? <Spiner1 /> : "Submit"}
        </button>
      </div>
    </div>
  );
};

export default EducationalData;
