
const initialState = {
  first_name: "",
  last_name: "",
  lead_source: "",
  designation_experience: "",
  designation: "",
  gender: "",
  contact: {
    email_primary: "",
    email_secondary: "",
    phone_primary: "",
    phone_secondary: "",
    contact_time: [],
    city: ""
  },
  job_preference: [],
  city: "",
  socials: [
    {
      name:"",
      link: ""
    }
  ],
  resume: null,
  profile_image: null,
}
const stepIntialState = {
  step: "General"
}
const qualificationStates = {
  qualifications: [
    {
      degree: "",
      institute: "",
      percentage: "",
      session_end: "",
      session_start: "",
      status: []
    }
  ]
}
// start hardware State 
const hardwareStates = {
  computer: "",
  internet_download: "",
  internet_type: "",
  internet_upload: "",
  processor: "",
  ram: "",
  upgrade_internet: false,
  upgrade_ram: false
}
const work_details = {
  available: false,
  current_company: "",
  current_ctc: 0,
  current_position: "",
  currently_working: "",
  expected_ctc: 0,
  job_preference: [],
  joining_date_month: "",
  joining_date_year: "",
  last_company: "",
  last_ctc: 0,
  last_position: "",
  notice_period: "",
  notice_period_negotiable: "",
  termination_date_month: "",
  termination_date_year: "",
  why_switching: "",
  why_unemployed: "",
  work_note: ""
}
const projectDetailsState = {
  project_details : [
      {
        name: "",
        link: ""
      }
  ]
}
const certificationsState = {
  certifications: [
    {
      course_name: "",
      institute_name: "",
      completion_year: ""
    }
  ] 
}
const  total_experience = {
  month: 0,
  year: 0,
  total_in_months: 0
}
const jobHistoriesState = {
  job_histories:[ 
    {
      company: "",
      joining_month: "",
      joining_year: "",
      position: "",
      termination_month: "",
      termination_year: ""
    }
]
}
//skill section
const skillState = {
  skills:[
    {
      currently_working: false,
      exp_month: 0,
      exp_year: 0,
      skill_id: []
    }
]}

const languageState = {
  languages: [
    {
        name: "",
        rating: 0
    }
]}
const addResponse={
  data:"",
  isLoading:false,
  isErrorLoading:false,
  isErrorData:"",

}

const validDatas={
  field:"",
  message:""
}
const noticeData={
  notice_period:0
}
// start updateJobHistory
export const updateJobHistory = (state = jobHistoriesState, action) => {
  switch(action.type){
    case "UPDATE_JOB_HISTORY":
      return{
        ...state,
        job_histories: action.payload
      }
    default:
      return state
  }
}
// start action for total Experience
export const updateTotalExperience = (state = total_experience, action) => {
  switch (action.type){
    case "UPDATE_TOTAL_EXPERIENCE_IN_MONTH":
      return{
        ...state,
        total_in_months: action.payload
      }
    case "UPDATE_EXPERIENCE_IN_YEAR":
      return{
        ...state,
        year: action.payload
      }
    case "UPDATE_EXPERIENCE_IN_MONTH":
      return{
        ...state,
        month: action.payload
      }
    default:
      return state
  }
}
// end action for total experience
// start Cirtification
  export const updateCertifications = (state = certificationsState, action) => {
    switch (action.type) {
      case "UPDATE_CERTIFICATES":
        return{
          ...state,
          certifications: action.payload
        }
      default:
        return state
        
    }
  }
// end Cirtification
// Start Project Details
  export const updateProjectDetails = (state = projectDetailsState,action) => {
    switch(action.type){
      case "UPDATE_PROJECT_DETAILS":
        return{
          ...state,
          project_details: action.payload
        }
      default:
        return state
    }
  }
// End Project Details

export const updateworkDetails = (state = work_details, action) => {
  switch (action.type){
    case "UPDATE_AVAILABLE":
      return {
        ...state,
        available: action.payload
      }
    case "UPDATE_CURRENT_COMPANY":
      return{
        ...state,
        current_company: action.payload
      }
    case "UPDATE_CURRENT_CTC":
      return{
        ...state,
        current_ctc: action.payload
      }
    case "UPDATE_CURRENT_POSITION": 
      return{
        ...state,
        current_position: action.payload
      }
    case "UPDATE_CURRENTLY_WORKING":
      return{
        ...state,
        currently_working: action.payload
      }
    case "UPDATE_EXPECTED_CTC":
      return{
        ...state,
        expected_ctc: action.payload
      }
    case "UPDATE_JOB_PREFERENCE":
      return{
        ...state,
        job_preference: action.payload
      }
    case "UPDATE_JOINING_DATE_MONTH":
      return{
        ...state,
        joining_date_month: action.payload
      }
    case "UPDATE_JOINING_DATE_YEAR":
      return{
        ...state,
        joining_date_year: action.payload
      }
    case "UPDATE_LAST_COMPANY":
      return{
        ...state,
        last_company: action.payload
      }
    case "UPDATE_LAST_CTC":
      return{
        ...state,
        last_ctc: action.payload
      }
    case "UPDATE_LAST_POSITION":
      return{
        ...state,
        last_position: action.payload
      }
    case "UPDATE_NOTICE_PERIOD":
      return{
        ...state,
        notice_period: action.payload
      }
    case "UPDATE_NOTICE_PERIOD_NEGOTIABLE":
      return{
        ...state,
        notice_period_negotiable: action.payload
      }
    case "UPDATE_TERMINATION_DATE_MONTH":
      return{
        ...state,
        termination_date_month: action.payload
      }
    case "UPDATE_TERMINATION_DATE_YEAR":
      return{
        ...state,
        termination_date_year: action.payload
      }
    case "UPDATE_WHY_SWITCHING":
      return{
        ...state,
        why_switching: action.payload
      }
    case "UPDATE_WHY_UNEMPLOYED":
      return{
        ...state,
        why_unemployed: action.payload
      }
    case "UPDATE_WORK_NOTE":
      return{
        ...state,
        work_note: action.payload
      }
    default: 
      return state
      

  }
}

export const updateHardware = ( state = hardwareStates,action) => {
  switch(action.type){
    case "UPDATE_COMPUTER":
      return {
        ...state,
        computer: action.payload
      }
    case "UPDATE_INTERNET_DOWNLOAD":
      return{
        ...state,
        internet_download: action.payload
      }
    case "UPDATE_INTERNET_TYPE":
      return{
        ...state,
        internet_type: action.payload
      }
    case "UPDATE_INTERNET_UPLOAD": 
      return{
        ...state,
        internet_upload: action.payload
      }
    case "UPDATE_PROCESSOR": 
      return{
        ...state,
        processor: action.payload
      }
    case 'UPDATE_RAM':
      return{
        ...state,
        ram: action.payload
      }
    case 'UPDATE_INTERNET':
      return{
        ...state,
        upgrade_internet: action.payload
      }
    case 'UPDATE_UPGRADE_RAM':
      return{
        ...state,
        upgrade_ram: action.payload
      }
    default: 
      return state
  }
}
// end hardware State
export const createQualification = (state = qualificationStates, action) => {
  switch (action.type) {
    case "UPDATE_QUALIFICATION":
      return {
        ...state,
        qualifications: action.payload
      }
    default:
      return state
  }
}

export const createSkills = (state = skillState, action) => {
  switch (action.type) {
    case "UPDATE_SKILL":
      return {
        ...state,
        skills: action.payload
      }
    default:
      return state
  }
}


export const createLanguage = (state = languageState, action) => {
  switch (action.type) {
    case "UPDATE_LANGUAGE":
      return {
        ...state,
        languages: action.payload
      }
    default:
      return state
  }
}

export const changeStep = (state = stepIntialState, action) => {
  switch (action.type) {
    case 'UPDATE_STAPE':
      return {
        ...state,
        step: action.payload
      }
    default:
      return state;
  }
}

export const createCandidate = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_FIRST_NAME':
      return {
        ...state,
        first_name: action.payload
      };
    case 'UPDATE_LAST_NAME':
      return {
        ...state,
        last_name: action.payload
      };
    case 'UPDATE_LEAD_SOURCE':
      return {
        ...state,
        lead_source: action.payload
      };
    case 'UPDATE_DESIGNATION_EXPERIENCE':
      return {
        ...state,
        designation_experience: action.payload
      };
    case 'UPDATE_DESIGNATION':
      return {
        ...state,
        designation: action.payload
      };
    case 'UPDATE_GENDER':
      return {
        ...state,
        gender: action.payload
      };
    case 'UPDATE_PRIMARY_EMAIL':
      return {
        ...state,
        contact: {
          ...state.contact,
          email_primary: action.payload
        }
      };
    case 'UPDATE_SECONDARY_EMAIL':
      return {
        ...state,
        contact: {
          ...state.contact,
          email_secondary: action.payload
        }
      };
    case 'UPDATE_PRIMARY_PHONE':
      return {
        ...state,
        contact: {
          ...state.contact,
          phone_primary: action.payload
        }
      };
    case 'UPDATE_SECONDARY_PHONE':
      return {
        ...state,
        contact: {
          ...state.contact,
          phone_secondary: action.payload
        }
      };
    case 'UPDATE_CONTACT_TIME':
      return {
        ...state,
        contact: {
          ...state.contact,
          contact_time: action.payload
        }
      };
    case 'UPDATE_CURRENT_LOCATION':
      return {
        ...state,
        contact: {
          ...state.contact,
          city: action.payload
        }
      };
    case 'UPDATE_JOB_PREFERENCE':
      return {
        ...state,
        job_preference: action.payload
      };
    case 'UPDATE_SOCIALS':
      return {
        ...state,
        socials: action.payload
      };
    case 'UPDATE_RESUME':
      return {
        ...state,
        resume: action.payload
      };
    case 'UPDATE_PROFILE_IMAGE':
      return {
        ...state,
        profile_image: action.payload
      };
    // case 'UPDATE_SKILL_ID':
    //   return {
    //     ...state,
    //     skill_id: action.payload
    //   }
    case 'UPDATE_CURRENTLY_WORKING':
      return {
        ...state,
        currently_working: action.payload
      }
    case 'UPDATE_EXP_MONTH':
      return {
        ...state,
        exp_month: action.payload
      }
    case 'UPDATE_EXP_YEAR':
      return {
        ...state,
        exp_year: action.payload
      }
    // case 'UPDATE_LANGUAGE':
    //   return {
    //     ...state,
    //     language: action.payload
    //   }
    case 'UPDATE_RATING':
      return {
        ...state,
        rating: action.payload
      }
    default:
      return state;
  }

}

export const addCandidateResponse = (state = addResponse, action) => {
  switch (action.type) {
    case "ADD_REQUEST":
      return {
        ...state,
        data: "",
        isLoading:true,
        isErrorLoading:true,
        isErrorData: "",
      };
    case "ADD_SUCCESS":
      return {
        ...state,
        data:action.payload,
        isLoading:false,
        isErrorLoading:true,
        isErrorData: "",
      };
    case "ADD_FAILURE":
      return {
        ...state,
        data: "",
        isLoading:false,
        isErrorLoading:false,
        isErrorData:action.payload,
      };
    default:
      return state;
  }
};
export const updateCandidateResponse = (state = addResponse, action) => {
  switch (action.type) {
    case "UPDATE_REQUEST":
      return {
        ...state,
        data: "",
        isLoading:true,
        isErrorLoading:true,
        isErrorData: "",
      };
    case "UPDATE_SUCCESS":
      return {
        ...state,
        data:action.payload,
        isLoading:false,
        isErrorLoading:true,
        isErrorData: "",
      };
    case "UPDATE_FAILURE":
      return {
        ...state,
        data: "",
        isLoading:false,
        isErrorLoading:false,
        isErrorData:action.payload,
      };
    default:
      return state;
  }
};

  export const addValidation=(state = validDatas, action)=>{
    switch (action.type) {
      case "ADD_VALIDATION":
        return {
          ...state,
          field:action.field,
          message:action.message
        };
      default:
        return state;
    }
  }
  export const addPeriod=(state = noticeData, action)=>{
    switch (action.type) {
      case "UPDATE_NOTICE_PERIOD":
        return {
          ...state,
          notice_period:action.payload
        };
      default:
        return state;
    }
  }