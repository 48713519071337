import axios from "axios";
import { errorCall } from "../ErrorHandling/action";

import {
    ACTIVE_STATUS_FAILED,
    ACTIVE_STATUS_REQUEST,
    ACTIVE_STATUS_SUCCESS,
} from "./actionType";

export const activeStatusRequest = () => ({
    type: ACTIVE_STATUS_REQUEST,
    payload: [],
    isLoading: true,
});

export const activeStatusSuccess = (dataActive) => ({
    type: ACTIVE_STATUS_SUCCESS,
    payload: dataActive,
    isLoading: false,
});
export const activeStatusFailed = (err) => ({
    type: ACTIVE_STATUS_FAILED,
    payload: [],
    isLoading: false,
    isError: true,
});

export const postActiveStatus =
    (id, activeStatus) => async (dispatch) => {
        const token = localStorage.getItem("token");
        const headers = {
            Authorization: `Bearer ${token}`,
        };

        const params = {
            status: activeStatus
        };

        try {
            await dispatch(activeStatusRequest());

            const res = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/api/auth/companyJobPost/statusUpdate/${id}`,
                params,
                { headers }
            );

            await dispatch(activeStatusSuccess(res.data));
        } catch (err) {
            console.log(err.message);
            if (err?.message == "Network Error" || err?.response?.status == 500) {
                dispatch(errorCall(err));
            }
        }
    };
