export const CATEGORIES_REQUEST = "CATEGORIES_REQUEST"
export const CATEGORIES_SUCCESS = "CATEGORIES_SUCCESS"
export const CATEGORIES_FAILURE = "CATEGORIES_FAILURE"

export const CATEGORIES_SINGLE_REQUEST = "CATEGORIES_SINGLE_REQUEST"
export const CATEGORIES_SINGLE_SUCCESS = "CATEGORIES_SINGLE_SUCCESS"
export const CATEGORIES_SINGLE_FAILURE = "CATEGORIES_SINGLE_FAILURE"

export const CATEGORY_ADD_REQUEST = "CATEGORIES_ADD_REQUEST"
export const CATEGORY_ADD_SUCCESS = "CATEGORIES_ADD_SUCCESS"
export const CATEGORY_ADD_FAILURE = "CATEGORIES_ADD_FAILURE"

export const CATEGORY_EDIT_REQUEST = "CATEGORIES_EDIT_REQUEST"
export const CATEGORY_EDIT_SUCCESS = "CATEGORIES_EDIT_SUCCESS"
export const CATEGORY_EDIT_FAILURE = "CATEGORIES_EDIT_FAILURE"

export const CATEGORY_STATUS_REQUEST = "CATEGORIES_STATUS_REQUEST"
export const CATEGORY_STATUS_SUCCESS = "CATEGORIES_STATUS_SUCCESS"
export const CATEGORY_STATUS_FAILURE = "CATEGORIES_STATUS_FAILURE"

export const CATEGORY_DELETE_REQUEST = "CATEGORY_DELETE_REQUEST"
export const CATEGORY_DELETE_SUCCESS = "CATEGORY_DELETE_SUCCESS"
export const CATEGORY_DELETE_FAILURE = "CATEGORY_DELETE_FAILURE"