import { ERROR_REQUEST, ERROR_SUCCESS, ERROR_FAIL } from "./actionType";

export const err_req = (data) => ({
  type: ERROR_REQUEST,
});
export const err_succ = (data) => ({
  type: ERROR_SUCCESS,
  payload: data,
});
export const err_fail = () => ({
  type: ERROR_FAIL,
  payload: "",
});

export const errorCall = (data) => {
  return async function test(dispatch, getState) {
    try {
      dispatch(err_req());
      if (data) {
        dispatch(err_succ(data));
      } else {
        dispatch(err_fail());
      }
    } catch (error) {}
  };
};
