import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { nextUpdateDetails, updateComputer, updateInternetDownload, updateInternetType, updateInternetUpgrade, updateInternetUpload, updateProcessor, updateRAM, updateRAMUpgrade, updateStep, validData } from '../../../Redux/Candidate/createCandidate/action';
import Spiner1 from '../../Spiner1';

const HardwareData = (props) => {
  const {inputRef}=props
  const dispatch=useDispatch()
  const navigate=useNavigate()
  const updateHardware = useSelector(state => state.updateHardware);
  const candidateResponse = useSelector((state)=> state.addResponseReducer.data);
  const candidateData = useSelector((state)=> state.createCandidate);
  const upgaradeCandidate = useSelector((state) => state.updateCandidateResponse);
  
  const handleComputerType = (e) => {
    dispatch(updateComputer(e.target.value))
  }
  const handleProccessorType = (e) => {
    dispatch(updateProcessor(e.target.value))
  }
  const handleRam = (e) => {
    dispatch(updateRAM(e.target.value))
  }
  const handleUpgradeRam = (e) => {
    dispatch(updateRAMUpgrade(e.target.checked))
  }
  const handleUpgradeInternet = (e) => {
    dispatch(updateInternetUpgrade(e.target.checked));
  }
  const handleInternetType = (e) => {
    dispatch(updateInternetType(e))
  }
  const handleSetDownload = (e) => {
    dispatch(updateInternetDownload(e.target.value))
  }
  const handleSetUpload = (e) => {
    dispatch(updateInternetUpload(e.target.value))
  }
  const handleNext=()=>{
    const nextTab="Experience"
    const data = {
      first_name: candidateData.first_name,
      last_name: candidateData.last_name,
      lead_source: candidateData.lead_source,
      designation_experience: candidateData.designation_experience,
      designation: candidateData.designation,
      resume:candidateData.resume ?candidateData.resume :"",
      system_hardware:updateHardware
    };
      dispatch(nextUpdateDetails(navigate,nextTab,candidateResponse?.candidate,data))
  }
  return (
    <div className="tab-data mt-4" step={0}>
              <div className="grid_2_md">
                <div className="grid-box">
                  <div className="form-group-gap">
                    <div className="form-label-box mb-2">
                      <label className="txt__para txt__sm fw-bold">
                        Computer type?
                      </label>
                    </div>
                    <div className="form-control-box">
                      <select className="form-control-no-border form-control-no-border-select" 
                      defaultValue={updateHardware?.computer}
                       onClick={(e)=>{handleComputerType(e)}}
                       >
                        <option value="" defaultValue>
                          Select your computer type
                        </option>
                        <option value={"Desktop"}>Desktop</option>
                        <option value={"Desktop Mac"}>Desktop Mac</option>
                        <option value={"Laptop"}>Laptop</option>
                        <option value={"Laptop MacBook"}>Laptop MacBook</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="grid-box">
                  <div className="form-group-gap">
                    <div className="form-label-box mb-2">
                      <label className="txt__para txt__sm fw-bold">
                        Processor type ?
                      </label>
                    </div>
                    <div className="form-control-box">
                      <input
                        type="text"
                        placeholder="Your computer's processor"
                        className="form-control-no-border"
                        value={updateHardware?.processor}
                        onChange={(e)=>handleProccessorType(e)}
                      />
                    </div>
                  </div>
                </div>
                <div className="grid-box">
                  <div className="form-group-gap">
                    <div className="form-label-box mb-2">
                      <label className="txt__para txt__sm fw-bold">
                        RAM (in GB) ?
                      </label>
                    </div>
                    <div className="form-control-box">
                      <input
                        type="number"
                        placeholder={4}
                        className="form-control-no-border"
                        defaultValue={updateHardware?.ram}
                        onChange={(e)=>{handleRam(e)}}
                      />
                    </div>
                  </div>
                  <div className="form-group-gap">
                    <div className="form-label-box">
                      <div className="custom-control custom-checkbox">
                        <input
                          defaultValue={1}
                          type="checkbox"
                          id="customCheck754"
                          className="form-check-input"
                          checked={updateHardware?.upgrade_ram}
                          onChange={(e)=>{handleUpgradeRam(e)}}
                        />
                        <label
                          htmlFor="customCheck754"
                          className="form-check-label"
                        >
                          Candidate will upgrade hardware as per their
                          requirements.
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group-gap">
                    <div className="form-label-box">
                      <div className="custom-control custom-checkbox d-flex">
                        <input
                          // defaultValue={1}
                          type="checkbox"
                          id="customCheck1"
                          className="form-check-input flex-shrink-0"
                          checked={updateHardware?.upgrade_internet}
                          onChange={(e)=>{handleUpgradeInternet(e)}}
                        />
                        <label
                          htmlFor="customCheck1"
                          className="form-check-label ms-1"
                        >
                          Mobile internet but candidate can get broadband if
                          he/she get selected.
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid-box">
                  <div className="form-group-gap">
                    <div className="form-label-box mb-2">
                      <div className="d-flex align-items-center flex-wrap">
                        <label className="txt__para txt__sm fw-bold me-3">
                          Internet bandwidth (In mbps) ?
                        </label>
                        <div>
                          <div className="form-check form-check-inline">
                            <input
                              defaultValue="Mobile"
                              type="radio"
                              id="internetType1"
                              name="internet_type"
                              className="form-check-input"
                              checked={updateHardware?.internet_type === "Mobile"}
                              onChange={()=>{handleInternetType("Mobile")}}
                            />
                            <label
                              htmlFor="internetType1"
                              className="form-check-label"
                            >
                              Mobile
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              defaultValue="Broadband"
                              type="radio"
                              id="internetType2"
                              name="internet_type"
                              className="form-check-input"
                              checked={updateHardware?.internet_type === "Broadband"}
                              onChange={()=>{handleInternetType("Broadband")}}
                            />
                            <label
                              htmlFor="internetType2"
                              className="form-check-label"
                            >
                              Broadband
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="grid_2">
                      <div className="grid-box">
                        <div className="form-group-gap mb-2">
                          <div className="form-control-box d-flex align-items-center">
                            <label className="txt__para txt__sm mb-0 me-4">
                              DOWNLOAD
                            </label>
                            <input
                              type="number"
                              placeholder="00 Mbps"
                              className="form-control-no-border"
                              value={updateHardware?.internet_download}
                              onChange={(e)=>{handleSetDownload(e)}}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="grid-box">
                        <div className="form-group-gap mb-2">
                          <div className="form-control-box d-flex align-items-center">
                            <label className="txt__para txt__sm mb-0 me-4">
                              UPLOAD
                            </label>
                            <input
                              type="number"
                              placeholder="00 Mbps"
                              className="form-control-no-border"
                              value={updateHardware?.internet_upload}
                              onChange={(e)=>{handleSetUpload(e)}}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="txt__sm txt__light">
                      Go to
                      <Link to="https://www.speakeasy.net" target="_blank">
                        speakeasy.net
                      </Link>
                      , and do an online speedtest. Example: 30mbps upload and
                      30 mbps download.
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <button type="button" className="nav-link Next_qu me-4"
                onClick={() => {
                  dispatch(updateStep("Education"));
                }}
                 >
                  Previous
                </button>
                <button
                    type="button"
                    disabled={upgaradeCandidate?.isLoading}
                    className="nav-link Next_qu"
                    onClick={(e) => {
                      handleNext(e);
                    }}
                  >
                {!upgaradeCandidate?.isLoading? "Next" : <Spiner1 />}
              </button>
              </div>
            </div>
  )
}

export default HardwareData
