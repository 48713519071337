import axios from "axios";
import { PROFILE_LIST_FAILURE, PROFILE_LIST_REQUEST, PROFILE_LIST_SUCCESS } from "./actionType";
import { errorCall } from "../../ErrorHandling/action";

export const profile_req = () => ({
    type: PROFILE_LIST_REQUEST
});

export const profile_succ = (data) => ({
    type: PROFILE_LIST_SUCCESS,
    payload: data
})

export const profile_failure = (err) => ({
    type: PROFILE_LIST_FAILURE,
    payload: err
})

const getLocaldata = () => {
    const token = localStorage.getItem("token");
    return token
}

export const getuser = (navigate) => (dispatch) => {
    dispatch(profile_req());
    const mt = getLocaldata();
    const config = {
        headers: { Authorization: `Bearer ${mt}` }
    };
    axios.get(`${process.env.REACT_APP_BASE_URL}/api/auth/getAuthUser`, config).then((res) => {
        dispatch(profile_succ(res.data))
    }).catch((err) => {
        if (err?.response?.data?.error == "Unauthenticated.") {
            localStorage.removeItem("token");
            navigate("/login");
        }
        
        dispatch(profile_failure(err))
        if (err.message === "Network Error" || err.response.status === 500) {
            dispatch(errorCall(err))
        }
    })
}