import React from 'react'

const Section = (title, children) => {
    return (
        <div className="mb-4 section-underline">
          <h5 className="mb-3">{title.title}</h5>
          <div className="d-flex flex-wrap">{title.children}</div>
        </div>
      );
}

export default Section
