import React, { useEffect, useState } from "react";
import "./css/AddCategory.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  cateAdd,
  editCategory,
  singleCategories,
} from "../../Redux/CategoryReducer/action";
import { HiChevronLeft } from "react-icons/hi";
import { toast } from "react-toastify";
import Spiner1 from "../../Components/Spiner1";


const AddCategory = (props) => {
  const sessionTokenValue = localStorage.getItem("token");
  const webToken = sessionTokenValue
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, cateid } = useParams();

  const [title, setTitle] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [onSubmit, setOnSubmit] = useState(false);
  const [toaster, setToaster] = useState(false);

  const cateData = useSelector((state) => state.categories.singleCate);
  const loading = useSelector((state) => state.categories.isLoadingcategAdd);
  const editLoading = useSelector((state) => state.categories.isLoadingCategEdit);
  const loadingCateSingle=useSelector((state) => state.categories.isLoadingCateSingle);
  const url = window.location.href;
  const baseUrl = process.env.REACT_APP_FRONTEDND_DOMAIN
  const parts = url?.split(baseUrl);
  const setTestId = parts[1]?.replace("/test-edit", "")
  let splitUrl = setTestId?.split('/')
  splitUrl = splitUrl?.filter((e) => {
    return e !== "" && e
  })

  const addCategory = () => {
    if (!title) {
      setToaster(true);
      toast.warn("Please Provide Category");
      setTimeout(() => {
        setToaster(false);
      }, 4000);
    } else if (title[0] == 0) {
      setToaster(true);
      toast.warn("Please Provide Category");
      setTimeout(() => {
        setToaster(false);
      }, 4000);
    } else {
      setToaster(false);
      if (onSubmit === false) {
        setOnSubmit(true);
        dispatch(cateAdd(webToken, id, title, navigate)).then(() => {
          navigate(`/test/create-test-screen/${id}`);
        });
      }
    }
  };
  const onEnterAdd = (event) => {
    if (event.key === "Enter") {
      if (toaster === false) {
        addCategory(event);
      }
    }
  };
  const EditCategory = () => {
    if (!categoryName) {
      setToaster(true);
      toast.warn("Please Provide Category");
      setTimeout(() => {
        setToaster(false);
      }, 4000);
    }
    else if (categoryName[0] == 0) {
      setToaster(true);
      toast.warn("Please Provide Category");
      setTimeout(() => {
        setToaster(false);
      }, 4000);
    } else {
      if (onSubmit === false) {
        setOnSubmit(true);
        dispatch(editCategory(webToken, cateid, categoryName, navigate)).then(() => {
          navigate(`/test/create-test-screen/${id}`);
        });
      }
    }
  };
  
  const onEnterEdit = (event) => {
    if (event.key === "Enter") {
      if (toaster === false) {
        EditCategory(event);
      }
    }
  };

  const  backToPreviousPage = () => {
    // navigate(`/test/test-edit/${id}`)
    window.history.back();
  };
  useEffect(() => {
    if (props.title == "Edit Category") {
      dispatch(singleCategories(webToken, cateid, navigate));
    }
  }, [cateid, props.title]);

  useEffect(()=>{
    if(cateData?.categoryName) setCategoryName(cateData?.categoryName)
  },[cateData?.categoryName])

  const menuClick = () => {
    props.onSidedataChange(!props.newSidedata);
  };
  return (
    <div className="body_content">
      <div className="container-fluid">
        <button className="fa_bars  d-lg-none" onClick={menuClick}>
          <i className="fa-solid fa-bars fs-4"></i>
        </button>
        <div className="row">
          <div className="COL-12 hjklop_right">
            <div className="container-fluid px-0 px-lg-4 my-4 my-lg-5">
              <div className="top_header border-bottom pb-2">
                <div className="d-flex align-items-center">
                  <img
                    id="top_icon"
                    className="gray_round "
                    src="/logos/update_test.png"
                    alt="img"
                  />
                  <Link
                    onClick={backToPreviousPage}
                    className="back_to_text fw-bold text-dark d-flex align-items-center mt-0"
                  >
                    <HiChevronLeft className="fs-3" />
                    Back To Test
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-6 col-lg-8">
                  <div className="inner_div my-4">
                    <div className="">
                      <b className="add_new_text">{props.title}</b>
                    </div>
                    {props.title !== "Edit Category" ? (
                      <div className="my-3">
                        <input
                          value={title.trimStart()}
                          onChange={(e) => {
                            setTitle(e.target.value);
                          }}
                          onKeyDown={onEnterAdd}
                          type="text"
                          className="single_input form-control"
                          placeholder="Please Enter Title Here"
                        />
                      </div>
                    ) : (
          
                        <div className="my-3">
                        <input
                          type="text"
                          className="single_input form-control"
                          placeholder="Please Enter Category Here"
                          value={loadingCateSingle?"":categoryName.trimStart()}
                          onChange={(e) => {
                            setCategoryName(e.target.value);
                          }}
                          onKeyDown={onEnterEdit}
                        />
                      </div>
                      
                    )}
                    <div className="">
                      {props.title !== "Edit Category" ? (
                        <button id="save_btn" disabled={loading} onClick={addCategory}>
                          {loading || toaster ? <Spiner1 /> : "Save"}
                        </button>
                        // )

                      ) : (
                        <button id="save_btn" disabled={editLoading} onClick={EditCategory}>
                          {editLoading || toaster ? <Spiner1 /> : "Edit"}
                        </button>
                        // )
                      )}
                      <button
                        onClick={(e) => {
                          window.history.back();
                        }}
                        id="cancel_btn"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCategory;