export const debouncedHandleSearch = (func, delay) => {
  let timeoutId;
  return function (...args) {
    const context = this;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
};

export const globalDebouncing = debouncedHandleSearch(
  async (dispatch,searchFunction,navigate,token,searchTerm,filterOptions,sortOrder,page,pageSize,additionalFilter,customFilter,customSearch,filterParameter) => {
    await dispatch(searchFunction(navigate,token,searchTerm,filterOptions,sortOrder,page,pageSize,additionalFilter,customFilter,customSearch,filterParameter));
  },
  800
);
 