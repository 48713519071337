import React from "react";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
const CheatSheet = () => {


    return (
        <>
            <div className="profile_items_box_content p-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <div className="top-site next-top-site mb-1">
                                <Form className="row g-2">
                                    <div className="col-sm-6">
                                        <select className="form-control">
                                            <option value="">Select Designation</option>
                                            <option>
                                                Laravel developer
                                            </option>
                                            <option>
                                                Project Manager
                                            </option>
                                            <option>
                                                Graphics Designer
                                            </option>
                                            <option>
                                                PHP developer
                                            </option>
                                            <option>
                                                WordPress developer
                                            </option>
                                            <option>
                                                Team Leader
                                            </option>
                                            <option>
                                                Java developer
                                            </option>
                                            <option>
                                                Web developer
                                            </option>
                                            <option>
                                                Asp .net developer
                                            </option>
                                            <option>
                                                Seo executive
                                            </option>
                                            <option>
                                                Social Maketing Manager
                                            </option>
                                            <option>
                                                content writter
                                            </option>
                                            <option>
                                                Content writer
                                            </option>
                                            <option>
                                                Digital marketer
                                            </option>
                                            <option>
                                                Jr. developer
                                            </option>
                                            <option>
                                                Css
                                            </option>
                                            <option>
                                                Swift
                                            </option>
                                            <option>
                                                Devops engineer
                                            </option>
                                            <option>
                                                Social media manager
                                            </option>
                                            <option>
                                                Full stack developer
                                            </option>
                                            <option>
                                                Software Developer
                                            </option>
                                            <option>
                                                Content Writer
                                            </option>
                                            <option>
                                                New
                                            </option>
                                            <option>
                                                Reviewed
                                            </option>
                                            <option>
                                                software developer
                                            </option>
                                            <option>
                                                fullstack developer
                                            </option>
                                            <option>
                                                Qa  test Analyst
                                            </option>
                                            <option>
                                                HTML developer
                                            </option>
                                            <option>
                                                QA analyst
                                            </option>
                                            <option>
                                                Front End Developer
                                            </option>
                                            <option>
                                                App Developer
                                            </option>
                                            <option>
                                                Magento Developer
                                            </option>
                                        </select>
                                    </div>
                                    <div className="col-sm-6">
                                        <select className="form-control ms-2">
                                            <option value="">Question level</option>
                                            <option>
                                                Easy
                                            </option>
                                            <option>
                                                Medium
                                            </option>
                                            <option>
                                                Hard
                                            </option>
                                            <option>
                                                Beginner
                                            </option>
                                            <option>
                                                Expert
                                            </option>
                                            <option>
                                                Advanced
                                            </option>
                                            <option>
                                                Intermediate
                                            </option>
                                        </select>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="profile_items_box_content p-3 mt-3">
                <div className="next_q_box w-100">
                    <h6>No question is selected for this candidate.</h6>
                    <div className='chats_Sheet'>

                        <div className="row">
                            <div className="col">
                                <h2 className="chats_Sheet_h2">Q. what is the full form of rgb?</h2>
                            </div>
                            <div className="col-auto"><span className="filter-tag filter-tag-lg">Easy</span></div>
                        </div>
                        <p className="mb-0">A. Red, Green, and Blue</p>
                        <div className="d-flex justify-content-between pt-2 mb-3">
                            <div className="d-flex align-items-center flex-wrap"><span className="skill-tag">
                                Photoshop
                            </span>
                            </div>
                        </div>
                        <div className="bottom-area d-flex align-items-center justify-content-between">
                            <div>
                                <span>
                                    <button type="button" className="save">
                                        Correct
                                    </button>
                                </span>
                                <span>
                                    <button type="button" className="ms-2 button_remove">
                                        Incorrect
                                    </button>
                                </span>
                            </div>
                            <div>
                                <button type="button" className="button_remove me-2">
                                    Remove
                                </button> <button type="button" className="save">
                                    Save
                                </button>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </>
    );
};
export default CheatSheet;
