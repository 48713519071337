import axios from "axios";
import { GET_PERMISSION_FAILURE, GET_PERMISSION_REQUEST, GET_PERMISSION_SUCCESS } from "./actionType";
import { errorCall } from "../../ErrorHandling/action";

export const get_permission_req = () => ({
    type: GET_PERMISSION_REQUEST
});

export const get_permission_succ = (data) => ({
    type: GET_PERMISSION_SUCCESS,
    payload: data
})

export const get_permission_failure = (err) => ({
    type: GET_PERMISSION_FAILURE,
    payload: err
})

const getLocaldata = () => {
    const token = localStorage.getItem("token");
    return token
}


export const getPermission = (navigate) => (dispatch) => {
    dispatch(get_permission_req());
    const mt = getLocaldata()
    const config = {
        headers: { Authorization: `Bearer ${mt}` }
    };
    return axios.get(`${process.env.REACT_APP_BASE_URL}/api/auth/permission`, config).then((res) => {
        dispatch(get_permission_succ(res.data))
        return res
    }).catch((err) => {
        if (err?.response?.data?.error == "Unauthenticated.") {
            localStorage.removeItem("token");
            navigate("/login");
        }
        dispatch(get_permission_failure(err))
        if (err.message === "Network Error" || err.response.status === 500 || err.response.status === 404) {
            dispatch(errorCall(err))
        }
    })
}




