// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo_sidebar {
    margin: 30px;
}

a {
    text-decoration: none;
}

.fa_xmark {
    background-color: transparent;
    color: aliceblue;
    font-size: 25px;
    border: 0px;
    position: absolute;
    right: 0;
    top: 0;
}

.sideber_menu ul li {
    margin-top: 10px;
}

.show_menu li.active,
.sideber_menu ul li.active {
    background-color: #5151513d;
}

.sideber_menu ul li a {
    color: #fff;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    position: relative;
    padding: 16px 0px 16px 0px;
    margin-left: 30px;
    /* margin-top: 10px; */
}

.sideber_menu ul li a img {
    margin-right: 15px;
}

.sideber_menu ul li a::after {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
}

.show_menu li a {
    margin-left: 70px !important;
}

/* .sideber_menu ul li.active,
.sideber_menu ul li:hover {
    font-weight: bolder;
    background-color: rgb(40 124 159);
} */
`, "",{"version":3,"sources":["webpack://./src/Components/sidebar.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,6BAA6B;IAC7B,gBAAgB;IAChB,eAAe;IACf,WAAW;IACX,kBAAkB;IAClB,QAAQ;IACR,MAAM;AACV;;AAEA;IACI,gBAAgB;AACpB;;AAEA;;IAEI,2BAA2B;AAC/B;;AAEA;IACI,WAAW;IACX,eAAe;IACf,iBAAiB;IACjB,aAAa;IACb,kBAAkB;IAClB,0BAA0B;IAC1B,iBAAiB;IACjB,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,QAAQ;IACR,2BAA2B;AAC/B;;AAEA;IACI,4BAA4B;AAChC;;AAEA;;;;GAIG","sourcesContent":[".logo_sidebar {\n    margin: 30px;\n}\n\na {\n    text-decoration: none;\n}\n\n.fa_xmark {\n    background-color: transparent;\n    color: aliceblue;\n    font-size: 25px;\n    border: 0px;\n    position: absolute;\n    right: 0;\n    top: 0;\n}\n\n.sideber_menu ul li {\n    margin-top: 10px;\n}\n\n.show_menu li.active,\n.sideber_menu ul li.active {\n    background-color: #5151513d;\n}\n\n.sideber_menu ul li a {\n    color: #fff;\n    font-size: 14px;\n    line-height: 16px;\n    display: flex;\n    position: relative;\n    padding: 16px 0px 16px 0px;\n    margin-left: 30px;\n    /* margin-top: 10px; */\n}\n\n.sideber_menu ul li a img {\n    margin-right: 15px;\n}\n\n.sideber_menu ul li a::after {\n    position: absolute;\n    right: 20px;\n    top: 50%;\n    transform: translateY(-50%);\n}\n\n.show_menu li a {\n    margin-left: 70px !important;\n}\n\n/* .sideber_menu ul li.active,\n.sideber_menu ul li:hover {\n    font-weight: bolder;\n    background-color: rgb(40 124 159);\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
