import { GLOBAL_SEARCH_FAILURE, GLOBAL_SEARCH_REQUEST, GLOBAL_SEARCH_SUCCESS } from "./actionType"

const initialState = {
    isLoading: false,
    data: [],
    isError: false,
    errorData: ""
}

export const searchGlobalReducer = (state = initialState, action) => {
    switch (action.type) {
        case GLOBAL_SEARCH_REQUEST: {
            return {
                isLoading: true,
                data: [],
                isError: false
            }
        }
        case GLOBAL_SEARCH_SUCCESS: {
            return {
                isLoading: false,
                data: action.payload,
                isError: false,
            }
        }
        case GLOBAL_SEARCH_FAILURE: {
            return {
                isLoading: false,
                data: [],
                isError: true,
                errorData: action.payload
            }
        }
        default: {
            return state
        }
    }
}