import React, { useCallback, useEffect, useState } from 'react'
import SettingsNavbar from '../Components/SettingsNavbar'
import { useDispatch, useSelector } from 'react-redux'
import {userTypehort } from '../Redux/settings/user_type_manage_get/action';
import Avatar from '../Components/settings/Avatar';
import { Link, useNavigate } from 'react-router-dom';
import { globalDebouncing } from '../Debouncing/globalDebouncing';
import Paginate from '../TestPortal/components/Pagination/Pagination';

const UserTypeManagement = ({ Sidedata, onSidedataChange }) => {
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userTypeList = useSelector(state => state?.getUserManageList);
  const [page,setPage]=useState(1);
  const [perPage,setPerPage]=useState(10);
  const totalPages = useSelector(
      state => state.getUserManageList?.data?.total_page
  );
  const usersPermission = localStorage.getItem("userPermission")
  const permissionList = usersPermission?.split(',');

  const clearfilter = (e) => {
    e.preventDefault();
    setPage(1);
    setSearch("");
    setStatus("");
    dispatch(userTypehort(navigate,"", "", 1,perPage));
  };
  const handleInputChange = (e) => {
    setSearch(e.target.value);
    globalDebouncing(dispatch,userTypehort,navigate,e.target.value, status,1,perPage);
    setPage(1);
  };
  const handlestatusInputChange = (e) => {
    setStatus(e.target.value);
    setPage(1);
  };
  const handlePageSizeChange = (e) => {
    setPage(1);
    setPerPage(parseInt(e.target.value));
  }
  const pageChange=(e)=>{
        setPage(e);
        dispatch(userTypehort(navigate,search, status, e,perPage));
        
    };
  useEffect(() => {
    dispatch(userTypehort(navigate,search, status, page,perPage));
  }, [status,perPage]);
  return (
    < div >
      <SettingsNavbar newSidedata={Sidedata} newonSidedataChange={onSidedataChange} navdata={{ header: "User Types", buttonText: "User Types Button", buttonPath: "/settings/skillsuggetion" }} />
      <div className="container-fluid">
        <div className="row g-3">
          <div className="col-md-12">
            <div className="companyAdmin_action_area mt-4">
              <div className="add_companyAdmins">
                {permissionList?.includes("65bb95c253e762c89b9af174") &&
                  <Link
                    to={"/settings/add-user-type"}
                    className="add_companyAdmin_link d-flex align-items-center"
                  >
                    <span className="icon d-flex align-items-center">
                      <svg
                        width="17px"
                        height="15px"
                        viewBox="0 0 17 15"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                      >
                        <g id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                          <g id="add-user-type" fillRule="nonzero">
                            <path
                              d="M1.40625,0.9375 C1.14736652,0.9375 0.9375,1.14736652 0.9375,1.40625 L0.9375,4.21875 C0.9375,4.47763348 0.727633476,4.6875 0.46875,4.6875 C0.209866524,4.6875 0,4.47763348 0,4.21875 L0,1.40625 C0,0.629599571 0.629599571,0 1.40625,0 L4.21875,0 C4.47763348,0 4.6875,0.209866524 4.6875,0.46875 C4.6875,0.727633476 4.47763348,0.9375 4.21875,0.9375 L1.40625,0.9375 Z M0.46875,10.3125 C0.727633476,10.3125 0.9375,10.5223665 0.9375,10.78125 L0.9375,13.59375 C0.9375,13.8526335 1.14736652,14.0625 1.40625,14.0625 L4.21875,14.0625 C4.47763348,14.0625 4.6875,14.2723665 4.6875,14.53125 C4.6875,14.7901335 4.47763348,15 4.21875,15 L1.40625,15 C0.629599571,15 0,14.3704004 0,13.59375 L0,10.78125 C0,10.5223665 0.209866524,10.3125 0.46875,10.3125 Z M14.53125,10.3125 C14.7901335,10.3125 15,10.5223665 15,10.78125 L15,13.59375 C15,14.3704004 14.3704004,15 13.59375,15 L10.78125,15 C10.5223665,15 10.3125,14.7901335 10.3125,14.53125 C10.3125,14.2723665 10.5223665,14.0625 10.78125,14.0625 L13.59375,14.0625 C13.8526335,14.0625 14.0625,13.8526335 14.0625,13.59375 L14.0625,10.78125 C14.0625,10.5223665 14.2723665,10.3125 14.53125,10.3125 Z"
                              id="Shape"
                              fill="#000000"
                            />{" "}
                            <path
                              d="M7.5,8.9375 C9.17873716,8.9375 10.3449269,9.31349078 11.1381573,9.84231101 C12.2901724,10.6103211 12.625,11.6931647 12.625,12.1875 C12.625,12.625 12.1875,12.625 12.1875,12.625 L12.1875,12.625 L2.8125,12.625 C2.375,11.6931647 2.70982755,10.6103211 3.86184273,9.84231101 C4.65507308,9.31349078 5.82126284,8.9375 7.5,8.9375 Z M7.5,2.375 C8.13857924,2.375 8.71670424,2.63383538 9.13518443,3.05231557 C9.55366462,3.47079576 9.8125,4.04892076 9.8125,4.6875 C9.8125,5.32607924 9.55366462,5.90420424 9.13518443,6.32268443 C8.71670424,6.74116462 8.13857924,7 7.5,7 C6.86142076,7 6.28329576,6.74116462 5.86481557,6.32268443 C5.44633538,5.90420424 5.1875,5.32607924 5.1875,4.6875 C5.1875,4.04892076 5.44633538,3.47079576 5.86481557,3.05231557 C6.28329576,2.63383538 6.86142076,2.375 7.5,2.375 Z"
                              id="Shape"
                              stroke="#000000"
                            />{" "}
                            <path
                              d="M14.53125,0.9375 C14.7901335,0.9375 15,1.14736652 15,1.40625 L15,2.8125 L16.40625,2.8125 C16.6651335,2.8125 16.875,3.02236652 16.875,3.28125 C16.875,3.54013348 16.6651335,3.75 16.40625,3.75 L15,3.75 L15,5.15625 C15,5.41513348 14.7901335,5.625 14.53125,5.625 C14.2723665,5.625 14.0625,5.41513348 14.0625,5.15625 L14.0625,3.75 L12.65625,3.75 C12.3973665,3.75 12.1875,3.54013348 12.1875,3.28125 C12.1875,3.02236652 12.3973665,2.8125 12.65625,2.8125 L14.0625,2.8125 L14.0625,1.40625 C14.0625,1.14736652 14.2723665,0.9375 14.53125,0.9375 Z"
                              id="Path"
                              fill="#000000"
                            />
                          </g>
                        </g>
                      </svg>


                    </span>
                    <span className="txt__sm " style={{ marginLeft: 10 }}>
                      Add New User Type
                    </span>
                  </Link>
                }
              </div>
            </div>
          </div>
          {/* start form section */}
          <div className="col-12">
            <div className="bg-white p-3 rounded">

              <div className="row g-2">
                <div className="col-md-4">
                  <div className="position-relative list_search_fild">
                    <input type="text" name="query" placeholder="Search"
                      aria-label="" className="form-control h-100" onChange={(e) => handleInputChange(e)} value={search}/>
                    <button className="job_list_search_box">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                        fill="currentColor" viewBox="0 0 16 16" className="bi bi-search">
                        <path
                          d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z">
                        </path>
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="col-md-4">
                  <select className="col-sm-4  form-control form-control-custom" onChange={(e) => handlestatusInputChange(e)} value={status}>
                    <option value="" defaultValue="selected">Select status</option>
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                </div>
                <div className="col-9 col-md-3">
                  {
                    search === "" &&
                      status === "" ? null :
                      <button className="button_remove" onClick={clearfilter}>Clear Filter</button>
                  }
                </div>
                <div className="col-3 col-md-1 d-flex  align-content-center justify-content-end">
                  <select className="col-sm-4  form-control form-control-custom" onChange={(e) => handlePageSizeChange(e)} style={{width:'61px'}}>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                </div>
              </div>

            </div>
          </div>
          {/* end form section */}

          {/* start table section */}
          <div className="col-12">
            <div className="table_box p-3">
              <div className="table-responsive ">
                <table className="table table-striped setting_ table-striped-border">
                  <thead>
                    <tr>
                      <th className="txt__md fw-bold">Name</th>
                      <th className="txt__md fw-bold text-center text-md-start">Status</th>
                      {permissionList?.includes("65bb95ec53e762c89b9af175") &&
                        <th className="txt__md fw-bold text-center">Action</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {
                      userTypeList?.isLoading && !userTypeList?.isError ? <tr><td colSpan={3}>
                        <div className='text-center py-3'>
                          <span className="loader"></span>
                        </div>
                      </td></tr> : !userTypeList?.isLoading && !userTypeList?.isError && userTypeList?.data?.data?.length !== 0 ? userTypeList?.data?.data?.map((data, index) => (
                        <tr key={index}>
                          <td>
                            <div className="d-flex align-items-center">
                              <Avatar name={data?.name} size={"34px"} />
                              <p className=" mb-0 txt__para txt__sm">{data?.name}</p>
                            </div>
                          </td>

                          {data?.status == 1 ? (
                            <td className="align-start text-success text-center text-md-start" style={{whiteSpace:'nowrap'}}>
                              <span className="statusBadge bg-success"></span>
                              Active
                            </td>
                          ) : (
                            <td className="align-start text-danger text-center text-md-start" style={{whiteSpace:'nowrap'}}>
                              <span className="statusBadge bg-danger"></span>
                              Inactive
                            </td>
                          )}
                          {permissionList?.includes("65bb95ec53e762c89b9af175") &&
                            < td className="text-center">

                              <Link to={`/settings/update-user-type/${data?.id}`}>
                                <svg
                                  width={15}
                                  height={15}
                                  viewBox="0 0 15 15"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M14.502 2.64639C14.6965 2.45126 14.6965 2.13553 14.502 1.94039V1.93939L13.209 0.646393C13.0137 0.451202 12.6972 0.451202 12.502 0.646393L11.459 1.69039L13.459 3.69039L14.502 2.64639ZM10.752 2.39639L12.752 4.39639V4.39739L5.93897 11.2114C5.88384 11.2661 5.81673 11.3072 5.74297 11.3314L3.32897 12.1364C3.23921 12.1662 3.14034 12.1428 3.07347 12.0759C3.00661 12.009 2.98319 11.9101 3.01297 11.8204L3.81797 9.40639C3.8425 9.33253 3.88393 9.26542 3.93897 9.21039L10.752 2.39639Z"
                                    fill="#708098"
                                  />
                                  <path
                                    d="M0 13.5C0 14.3284 0.671573 15 1.5 15H12.5C13.3284 15 14 14.3284 14 13.5V7.5C14 7.22386 13.7761 7 13.5 7C13.2239 7 13 7.22386 13 7.5V13.5C13 13.7761 12.7761 14 12.5 14H1.5C1.22386 14 1 13.7761 1 13.5V2.5C1 2.22386 1.22386 2 1.5 2H8C8.27614 2 8.5 1.77614 8.5 1.5C8.5 1.22386 8.27614 1 8 1H1.5C0.671573 1 0 1.67157 0 2.5L0 13.5Z"
                                    fill="#708098"
                                  />
                                </svg>
                              </Link>
                            </td>
                          }
                        </tr>
                      )) : <tr><td colSpan={3}>
                      <div className='text-center py-3'>
                      No Data
                      </div>
                    </td></tr>
                    }

                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {totalPages == undefined ? null : (
            <>
              <div className="parentPage">
                <Paginate
                  currentPage={page}
                  totalPages={totalPages}
                  onPageChange={(e) => pageChange(e)}
                />
              </div>
            </>
          )}
          <div className="pagination-container"></div>
        </div>
      </div>
    </div >
  )
}

export default UserTypeManagement
