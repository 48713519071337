import { EXPERIENCE_LIST_FAILURE, EXPERIENCE_LIST_REQUEST, EXPERIENCE_LIST_SUCCESS } from "./actionType";

const initialState = {
    isLoading: false,
    data: [],
    isError: false,
    errorData: ""
}

export const getExperienceSet = (state = initialState,action)=>{
     switch(action.type){
        case EXPERIENCE_LIST_REQUEST:{
           return{
            ...state,
            isLoading : true,
            isError : false
           }
        }
        case EXPERIENCE_LIST_SUCCESS:{
            return{
                ...state,
                isLoading : false,
                data : action.payload,
                isError : false,
            }
        }
        case EXPERIENCE_LIST_FAILURE:{
            return{
                ...state,
                isLoading : false,
                data : [],
                isError : true,
                errorData:action.payload
            }
        }
        default : {
            return state
         }
     }
}