// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CustomPagination.css */

.custom-pagination-item {

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px !important; /* Border radius */
  border: none;
  background-color: #fff;
}

.parentPage .pagination {
  gap: 6px;
}
/* Style for active item */
.custom-pagination-item.active {
  background-color: #007bff; /* Example active background color */
  color: white;  /* Example active text color */
}

.custom-pagination-item.active  .page-link {
color: #fff;
}
/* Adjusting styles for ellipses and navigation buttons if needed */
.custom-pagination-item:not(.active) {
  background-color: #f8f9fa; /* Example background color for non-active items */
  color: black; /* Example text color for non-active items */
}

.page-item .page-link{
  width: 36px;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 11px rgb(0 0 0 / 15%);
  justify-content: center;
  height: 36px;
  border-radius: 10px !important;
  color: #000;
}
.page-item.active.disabled>.page-link{
  background-color: #007bff !important;
}

/* .custom-pagination-item.disabled > .page-link {
  background-color: #ffffff !important;
  color: black;
} */`, "",{"version":3,"sources":["webpack://./src/TestPortal/components/Pagination/Pagination.css"],"names":[],"mappings":"AAAA,yBAAyB;;AAEzB;;EAEE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,8BAA8B,EAAE,kBAAkB;EAClD,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,QAAQ;AACV;AACA,0BAA0B;AAC1B;EACE,yBAAyB,EAAE,oCAAoC;EAC/D,YAAY,GAAG,8BAA8B;AAC/C;;AAEA;AACA,WAAW;AACX;AACA,mEAAmE;AACnE;EACE,yBAAyB,EAAE,kDAAkD;EAC7E,YAAY,EAAE,4CAA4C;AAC5D;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,yCAAyC;EACzC,uBAAuB;EACvB,YAAY;EACZ,8BAA8B;EAC9B,WAAW;AACb;AACA;EACE,oCAAoC;AACtC;;AAEA;;;GAGG","sourcesContent":["/* CustomPagination.css */\n\n.custom-pagination-item {\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 10px !important; /* Border radius */\n  border: none;\n  background-color: #fff;\n}\n\n.parentPage .pagination {\n  gap: 6px;\n}\n/* Style for active item */\n.custom-pagination-item.active {\n  background-color: #007bff; /* Example active background color */\n  color: white;  /* Example active text color */\n}\n\n.custom-pagination-item.active  .page-link {\ncolor: #fff;\n}\n/* Adjusting styles for ellipses and navigation buttons if needed */\n.custom-pagination-item:not(.active) {\n  background-color: #f8f9fa; /* Example background color for non-active items */\n  color: black; /* Example text color for non-active items */\n}\n\n.page-item .page-link{\n  width: 36px;\n  display: flex;\n  align-items: center;\n  box-shadow: 0px 0px 11px rgb(0 0 0 / 15%);\n  justify-content: center;\n  height: 36px;\n  border-radius: 10px !important;\n  color: #000;\n}\n.page-item.active.disabled>.page-link{\n  background-color: #007bff !important;\n}\n\n/* .custom-pagination-item.disabled > .page-link {\n  background-color: #ffffff !important;\n  color: black;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
