import React, { useState } from 'react'
import SettingsNavbar from '../Components/SettingsNavbar'
import { BsFillArrowLeftCircleFill } from 'react-icons/bs'
import { Link, useNavigate } from 'react-router-dom'
import Avatar from '../Components/settings/Avatar'
import Spiner1 from '../Components/Spiner1'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { addSourcef } from '../Redux/settings/AddSource/action'

const AddSource = ({onSidedataChange, Sidedata}) => {
    const navigate = useNavigate();
    const [designationName, setDesignationName] = useState("");
    const [designationStatus, setDesignationStatus] = useState(1);
    const dispatch = useDispatch();
    const {isLoading} = useSelector(state => state.addSource);
    const notify = (message) => toast(message);
    const changeDesignationName = (e) => {
        e.preventDefault();
        let designation=e.target.value;
        designation=designation.replace(/\s+/g,' ');
        setDesignationName(designation);
    }
    const addDesignationS = (e) => {
        e.preventDefault();
        setDesignationName(designationName.trim());
        if (designationName.trim().length > 0) {
            dispatch(addSourcef(designationName, designationStatus, notify, navigate)).then((res)=>{
                setDesignationName("");
            });
        }else{
            toast.error("Please provide source name");
        }
    }
    return (
        <div>
            <SettingsNavbar navdata={{ header: "Add Lead Source", buttonText: "designation Button", buttonPath: "/settings/skillsuggetion" }}   newSidedata={Sidedata} newonSidedataChange={onSidedataChange} />
            <div className="user-action-area">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Link to={"/settings/source"}> <BsFillArrowLeftCircleFill /> Source List</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="create-company-form">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form">
                                <div className="row g-2">
                                    <div className="col-md-4">
                                        <div className="position-relative list_search_fild">
                                            <input type="text" name="query" placeholder="Source Name"
                                                aria-label="" value={designationName} className="form-control coustom_P h-100" onChange={(e) => changeDesignationName(e)} />
                                            <Avatar name={designationName} size={"34px"} position="absolute" top="3px" left="5px" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <select className="col-sm-4 mb-2 form-control form-control-custom" onChange={(e) => setDesignationStatus(e.target.value)} >
                                            <option value={1} selected>Active</option>
                                            <option value={0}>Inactive</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-success button-outline choosed mt-3" disabled={isLoading ? true : false} onClick={(e) => addDesignationS(e)}>
                                {isLoading ? <Spiner1 /> : "Submit"}
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddSource
