import axios from "axios";
import { UPDATE_SOURCE_FAILURE, UPDATE_SOURCE_REQUEST, UPDATE_SOURCE_SUCCESS } from "./actionType";
import { toast } from 'react-toastify';
import { errorCall } from "../../ErrorHandling/action";

export const update_source_req = () => ({
    type: UPDATE_SOURCE_REQUEST
});

export const update_source_succ = (data) => ({
    type: UPDATE_SOURCE_SUCCESS,
    payload: data
})
export const update_source_failure = (err) => ({
    type: UPDATE_SOURCE_FAILURE,
    payload: err
})


const getLocaldata = () => {
    const token = localStorage.getItem("token");
    return token
}
export const updateSourcef = (id, name, status, navigate) => (dispatch) => {
    dispatch(update_source_req());
    const mt = getLocaldata()
    const config = {
        headers: { Authorization: `Bearer ${mt}` }
    };
    const form = {
        form: {
            "id": id,
            "name": name,
            "status": status
        }
    }

    return axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/source/update`, form, config).then((res) => {
        dispatch(update_source_succ(res.data))
        toast.success('Source updated successfully');
        navigate('/settings/source');
        return res
    }).catch((err) => {
        if (err?.response?.data?.error == "Unauthenticated.") {
            localStorage.removeItem("token");
            navigate("/login");
        }
        dispatch(update_source_failure(err))
        if (err.message === "Network Error" || err.response.status === 500) {
            dispatch(errorCall(err))
        }
        toast.error(err.response.data.message);
      return err
            
    }
)
}


