import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { } from "./css/AddQuestions.css";
// import SideNav from "./SideNav";
import { cateGories } from "../../Redux/CategoryReducer/action";
import {
  copyQuestion,
  deleteQuestion,
  getSpecificQuestion,
  postQuestion,
  updateQuestion,
} from "../../Redux/QuestionReducer/action";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate, useParams } from "react-router-dom";
import { BiTrashAlt } from "react-icons/bi"
import { HiChevronLeft } from "react-icons/hi"
import Spiner1 from "../../Components/Spiner1";
import { BlurLoader } from "../../Components/BlurLoader/BlurLoader";
import Swal from "sweetalert2";
import { getTest } from "../../Redux/TestReducer/action";
import { toast } from "react-toastify";
const EditQuestion = (props) => {
  const webToken = localStorage.getItem("token");
  // questionData
  const answerList = useSelector((state) => state.question);
  const cateData = useSelector((state) => state.categories);
  const { isLoading } = cateData
  const quesData = useSelector((state) => state?.question?.questionData);
  const quesredux = useSelector((state) => state?.question);
  const { specificQuestLoading, deleteQuestLoading } = quesredux
  const updateLoading = useSelector((state) => state?.question?.updateQuestLoading);
  const copiedQuestion = useSelector((state) => state?.question?.copiedQuesData);
  const { copyQuestIsLoading } = useSelector((state) => state?.question);
  const editorConfig = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'link'],        // toggled buttons
      ['clean', 'blockquote', 'code-block', { 'list': 'ordered' }, { 'list': 'bullet' }, { 'align': [] }],
      [{ 'size': ['small', false, 'large', 'huge'] }],
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'font': [] }],
      ['image'],]
  }
  const [qesCategory, setQesCategory] = useState("");
  const [questionValue, setQuestionValue] = useState("<p></p>");
  const [questionTitle, setQuestionTitle] = useState("");
  const [answerStates, setAnswerStates] = useState("");
  const [answerValues, setAnswerValues] = useState("");
  const [isOneCorrect, setIsOneCorrect] = useState(true);
  const [isSuffle, setIsSuffle] = useState(true);
  const [catId, setCatId] = useState();
  const [error, setError] = useState(null)
  const dispatch = useDispatch();
  const { id, quesid } = useParams();
  const navigate = useNavigate()
  const [onSubmit, setOnSubmit] = useState(false);
  const [quesArray, setQuesArray] = useState([]);
  const [copy, setCopy] = useState(false);
  // const [extraLoading, setExtraLoading] = useState(false);
  // const [stop, setStop] = useState(0);
  const [artiFicialLoading,setArtificialLoading]=useState(true)
  // const [chnageTitle, setChangeTitle] = useState(false);
  const [questionType, setQuestionType] = useState('')
  // const [singleCorrectHit, setSingleCorrectHit] = useState(false);
  const [answerShow, setIsAnswerShow] = useState(false);

  const handleAnswer = () => {
    // Check if the last added answer is not empty
    if (quesArray.length === 0 || quesArray[quesArray.length - 1]?.answers.trim() !== "") {
      const example = {
        Correct_Answer: false,
        Stick_to_bottom: false,
        answers: answerValues.trim(),
      };

      setQuesArray((prevArray) => [...prevArray, example]);
      setAnswerValues(""); // Clear the answer input after adding
    }
  };
  const copyQuestions = () => {
    setCopy(true);
    dispatch(
      copyQuestion(
        webToken,
        quesid,
        id,
        navigate
      ))
  }


  const handleCopyQuestion = () => {
    if (onSubmit === false) {
      dispatch(updateQuestion(
        webToken,
        navigate,
        copiedQuestion?.clonedDocument?._id,
        qesCategory,
        questionValue,
        quesArray,
        isOneCorrect,
        isSuffle,
        catId,
        window.location.href,
        questionTitle
      ))
      setOnSubmit(true);
    }
    setTimeout(() => {
      setOnSubmit(false);
    }, 6000);
  }




  const handleCorrectAnswer = (event) => {
    const newArray = quesArray.map((item) => {
      return { ...item, Correct_Answer: false };
    })
    setQuesArray(newArray)
    if (event.target.checked == true && event.target.name == "singleAnswer") {
      setIsOneCorrect(true)
    }
    else if (event.target.checked == true && event.target.name == "multipleAnswer") {
      setIsOneCorrect(false)
    }
  };

  const handleEditCreate = async (e) => {


    if(questionValue.includes("<p><br></p>")){
      return toast.warn("Please Provide Question")
    }
  

    setIsAnswerShow(true);
    if (onSubmit === false) {
      dispatch(updateQuestion(
        webToken,
        navigate,
        quesid,
        qesCategory,
        questionValue,
        quesArray,
        isOneCorrect,
        isSuffle,
        catId,
        window.location.href,
        questionTitle
      ))
      setOnSubmit(true);
      setTimeout(() => {
        setOnSubmit(false);
      }, 2000);
    }
  }

  const handleSuffleAnswer = (event) => {
    if (event.target.name == "yesSuffelAnswer") {
      setIsSuffle(true)
    }
    else if (event.target.name == "noSuffelAnswer") {
      setIsSuffle(false)
    }
  };
  const handleAnswerEditorStateChanges = (index, newEditorState) => {
    const inputArray = [...quesArray]
    inputArray[index] = {
      answers: newEditorState,
    }
    setQuesArray(inputArray)
  };

  const removeAnswer = async (e, i) => {
    if (quesArray.length > 2) {
      const delVal = [...quesArray]
      delVal.splice(i, 1)
      setQuesArray(delVal)
    }

  }

  const checkedCorrect = (e) => {
    const { name } = e.target;
    let NewArray = [];
    if (isOneCorrect === false) {
      quesArray.map((element, index) => {
        if (name == index) {
          if (element.Correct_Answer === false) {
            element = { ...element, Correct_Answer: true };
          } else {
            element = { ...element, Correct_Answer: false }
          }
        }
        NewArray.push(element);
      });
    } else {
      quesArray.map((element, index) => {
        // Create a new object using spread operator
        element = { ...element };
        if (name == index) {
          element.Correct_Answer = true;
          // setCorrect(true);
        } else {
          element.Correct_Answer = false;
          // setCorrect(false);
        }
        NewArray.push(element);
      });
    }
    setQuesArray(NewArray);
  };



  const checkedStick = (e) => {
    const { name } = e.target;
    var NewArray = quesArray.map((element, index) => {
      if (name == index) {
        return { ...element, Stick_to_bottom: true };
      } else {
        return { ...element, Stick_to_bottom: false };
      }
    });
    setQuesArray(NewArray);
  };

  const url = window.location.href;
  const baseUrl = process.env.REACT_APP_URL
  const parts = url?.split(baseUrl);
  const setTestId = parts[1]?.replace("/test-edit", "");
  let splitUrl = setTestId?.split('/');
  splitUrl = splitUrl?.filter((e) => {
    return e !== "" && e
  })
  if (splitUrl?.length > 0) {
    var urlValue = splitUrl[0];
  }


  const handleNewQuestion = (e) => {
    dispatch(
      postQuestion(
        webToken,
        navigate,
        id,
        qesCategory,
        questionValue,
        quesArray,
        isOneCorrect,
        isSuffle,
        catId,
        window.location.href,
        true
      )
    ).then(() => {
      navigate(`/${urlValue}/question-add/${id}`)
    })
  }

  const backToTest = () => {
    navigate(`/test/test-edit/${id}`)
  };


  const handleDelete = (e) => {
    dispatch(
      deleteQuestion(webToken, quesid, id, quesData.categoryId, navigate)
    ).then(() => {
      navigate(`/test/create-test-screen/${id}`)
    })
  }

  useEffect(() => {
    setError(null)
    dispatch(getSpecificQuestion(webToken, quesid, navigate))
  }, [quesid]);
  useEffect(() => {
    dispatch(cateGories(webToken, id, navigate));
  }, [ id]);

  useEffect(() => {
    if (!answerShow) {
      setQuesArray(answerList?.questionData?.result?.answers);
    }
  }, [answerList]);

  useEffect(() => {
    setQesCategory(quesData?.result?.qesCategory)
   setIsSuffle(quesData?.result?.shuffleAnswers);
  }, [quesData?.result?.shuffleAnswers])


  useEffect(() => {
    if (quesData?.result?.selectAnswerType) {
      setIsOneCorrect(quesData?.result?.selectAnswerType);
    } else {
      setIsOneCorrect(quesData?.result?.selectAnswerType);
    }

  }, [quesData?.result?.selectAnswerType])


  useEffect(() => {
    if (quesData?.result?.answers) {
      setQuesArray(quesData?.result?.answers)
    }
  }, [quesData?.result?.answers])

  const menuClick = () => {
    props.onSidedataChange(!props.newSidedata);
  };


  const handleCloneQuestion = async (e) => {
    const result = await Swal.fire({
      title: 'Are you sure to clone this question?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3ab2e4',
      cancelButtonColor: 'grey',
      cancelButtonText: "No",
      confirmButtonText: 'Yes',

      customClass: {
        confirmButton: 'Swal_confirm',
        cancelButton: 'Swal_cancel',
        icon: 'Swal_icon',
        title: 'Swal_title'
      },
    });

    if (result.isConfirmed) {
      copyQuestions(e)
    }
  }

  const handleDeleteQuestion = async (e) => {
    const result = await Swal.fire({
      title: 'Are you sure to delete this question?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3ab2e4',
      cancelButtonColor: 'grey',
      cancelButtonText: "No",
      confirmButtonText: 'Yes',

      customClass: {
        confirmButton: 'Swal_confirm',
        cancelButton: 'Swal_cancel',
        icon: 'Swal_icon',
        title: 'Swal_title'
      },
    });

    if (result.isConfirmed) {
      handleDelete(e)
    }
  }


useEffect(()=>{
  if(!specificQuestLoading && !isLoading&&questionValue){
    setTimeout(() => {
      setArtificialLoading(false)
    }, 500);
  }
},[specificQuestLoading,isLoading,questionValue])
  const testId = useParams();

  const testData = useSelector((state) => state.test.singleTest);

  useEffect(() => {
    dispatch(getTest(webToken, testId.id, navigate));
  }, [testId.id])


  useEffect(() => {
    setQuestionTitle(quesData?.result?.logicalTestTitle);
  }, [quesData?.result?.logicalTestTitle])


  useEffect(() => {
    setQuestionValue(quesData?.result?.question);
  }, [ quesData?.result?.question])


  useEffect(()=>{
    if (testData?.testType === "custom test") {
      setQuestionType("MCQ")
    } else {
      setQuestionType("code")
    }
  },[testData])

  return (

    <div className={`content_app ${artiFicialLoading  &&"body_scroll_hide"}`}>
      {artiFicialLoading  && <BlurLoader />}
      <div className={`body_content ${isLoading || specificQuestLoading && 'body_scroll_hide'}`}>
        <div className="container-fluid px-0">
          <button className="fa_bars  d-lg-none" onClick={menuClick}>
            <i className="fa-solid fa-bars fs-4"></i>
          </button>
          <div className="row g-0">
            <div className="col-12 hjklop_right">
              <div className="container-fluid px-0 pt-4">
                <div className="row">
                  <div className="col-12">
                    <div className="Main_div">
                      <div className="addNewQuestions px-3 px-lg-4 px-xl-5 pb-5">
                        <span onClick={backToTest} className="back_to_test"><HiChevronLeft className="fs-3" />{" "}Back To Test</span>
                        <h3 className="addQuestion fw-bold my-3">Edit Questions</h3>
                        <div>
                          <label
                            htmlFor="category_select"
                            className="new_label mb-1"
                          >
                            Question Category
                          </label>
                          <div className="row g-3">
                            <div className="col-xl-9 col-lg-8 col-md-7 col-12 ">
                              <div className="">
                                <select
                                  onChange={(e) => {
                                    var after_star = e.target.value?.split('*')
                                    setQesCategory(after_star[0])
                                    setCatId(after_star[1])
                                  }}
                                  className="selector "
                                  id="category_select"
                                >
                                  <option
                                    className="text-secondary"
                                    key="a"
                                    name={quesData?.result?.qesCategory}
                                    value={quesData?.result?.qesCategory}
                                  >
                                    {quesData?.result?.qesCategory}
                                  </option>

                                  {cateData?.data?.result ? (
                                    cateData?.data?.result?.map(
                                      (element, index) => (
                                        quesData?.result?.qesCategory === element.categoryName
                                          ? null
                                          : (
                                            <option
                                              key={index}
                                              name={element?._id}
                                              value={element?.categoryName + "*" + element?._id}
                                            >
                                              {element?.categoryName}
                                            </option>
                                          )
                                      )
                                    )
                                  ) : (
                                    <option >No Data</option>
                                  )}
                                </select>
                              </div>

                              {
                                questionType === 'MCQ' ? (
                                  <>
                                    <div className="first_radio">
                                      <label className="new_label">
                                        How many correct answers does this question have?
                                      </label>
                                      <div className="radio_inner">
                                        <input
                                          // readOnly
                                          checked={isOneCorrect}
                                          name="singleAnswer"
                                          type="radio"
                                          id="test_correct"
                                          onChange={(e) => handleCorrectAnswer(e)}
                                        />{" "}
                                        <label htmlFor="test_correct">
                                          One correct answer
                                        </label>
                                        <input
                                          // readOnly
                                          className="radio_input"
                                          name="multipleAnswer"
                                          id="test_multiplt"
                                          checked={!isOneCorrect}
                                          type="radio"
                                          onChange={(e) => handleCorrectAnswer(e)}
                                        />{" "}
                                        <label htmlFor="test_multiplt">
                                          Multiple correct answers
                                        </label>
                                      </div>
                                    </div>
                                    <div className="first_radio">
                                      <label className="new_label">
                                        Do you want to shuffle answers when a candidate
                                        takes the test?
                                      </label>
                                      <div className="radio_inner">
                                        <div className="mb-2">
                                          <input
                                            name="yesSuffelAnswer"
                                            readOnly
                                            checked={Boolean(isSuffle)}
                                            type="radio"
                                            id="yes_suffle"
                                            onChange={(e) => handleSuffleAnswer(e)}
                                          />
                                          <label htmlFor="yes_suffle"> Yes, shuffle answers in the test</label>
                                          <span>
                                            ( <span className="recomand">Recommended </span>)
                                          </span>
                                        </div>
                                        <div>
                                          <input
                                            readOnly
                                            name="noSuffelAnswer"
                                            checked={Boolean(!isSuffle)}
                                            type="radio"
                                            id="no_suffle"
                                            onChange={(e) => handleSuffleAnswer(e)}
                                          />
                                          <label htmlFor="no_suffle">  No, keep the constant order</label>
                                          <span>
                                            <a className="Arrange_question"
                                            // href="/"
                                            >
                                              {" "}
                                              (Arrange the questions here)
                                            </a>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <div className="mt-3">
                                    <label
                                      htmlFor="category_select"
                                      className="new_label mb-1"
                                    >
                                      Select Language
                                    </label>

                                    <select
                                      className="selector "
                                      id="category_select"
                                    >
                                      <option value="NO Category" default>
                                        python
                                      </option>
                                      <option value="1" >
                                        java
                                      </option>
                                    </select>
                                  </div>
                                )
                              }


                            </div>
                            {copy == false ? (<div className="col-xl-3 col-lg-4 col-md-5 col-12 ">
                              <div className="top_right_div h-100 p-4">
                                <div className="live_body border-bottom border-top py-2">
                                  <button onClick={() => { navigate(`/test/question-add/${id}`) }} className="btn p-0 duplicate_btn d-block my-2 text-primary">
                                    <img
                                      className="vector-icon18 me-1"
                                      alt="plus"
                                      src="/logos/fa6solidplus.svg"
                                    />{" "}
                                    New Question
                                  </button>
                                  <button disabled={copyQuestIsLoading} className="btn p-0 duplicate_btn d-block my-2 text-primary" onClick={(e) => handleCloneQuestion(e)}>
                                    <img
                                      className="group-child36 me-1"
                                      alt=""
                                      src="/logos/group-15.svg"
                                    />{" "}
                                    Clone Question
                                  </button>
                                  <button className="btn p-0 duplicate_btn d-block my-2 text-primary" onClick={(e) => handleDeleteQuestion(e)}>
                                    <img className="me-1" alt="" src="/logos/vector8.svg" />{" "}
                                    Delete Question
                                  </button>
                                </div>
                                <div className="row parent_btn">
                                  <button disabled={updateLoading} onClick={(e) => handleEditCreate(e)} className="update">
                                    {updateLoading ? <Spiner1 /> : "Save"}
                                  </button>
                                </div>
                              </div>
                            </div>) :
                              <div className="col-xl-3 col-lg-4 col-md-5 col-12 ">
                                <div className="top_right_div h-23 p-4">
                                  <div className="live_body border-bottom border-top py-2">
                                    <button
                                      onClick={() => { navigate(`/test/question-add/${id}`) }}
                                      className="btn p-0 duplicate_btn d-block my-2 text-primary"
                                    >
                                      <img
                                        className="vector-icon18 me-1"
                                        alt="plus"
                                        src="/logos/fa6solidplus.svg"
                                      />{" "}
                                      New Question
                                    </button>
                                  </div>
                                  <div className="row parent_btn">
                                    {onSubmit == false ? (<button
                                      onClick={handleCopyQuestion}
                                      className="btn update"
                                    >
                                      Save
                                    </button>) : <button
                                      className="btn disable_update"
                                    >
                                      Save
                                    </button>}
                                  </div>
                                </div>
                              </div>
                            }
                          </div>

                          {!questionType?"please wait...":
//  <---------------------------------------------------------------------MCQ------------------------------------------------------------------------------------->                            
                            questionType === 'MCQ' ? (
                              <>
                                <div className="mt-4">
                                  <div className="question">
                                    <h3 id="question_text" className="fw-bold">Questions</h3>
                                    <div className="inner_question">
                                      {/* {questionValue} */}
                                      {!quesData?.result?.question ?
                                      "wait...":
                                        <ReactQuill
                                          theme="snow"
                                          modules={editorConfig}
                                          defaultValue={quesData?.result?.question}
                                          onChange={(newContent) => { setQuestionValue(newContent) }} /> }
                                    </div>
                                    {error === "**Question is required" ? (<span style={{ color: 'red' }}>**Question is required</span>) : null}
                                  </div>
                                </div>
                                <div className="">
                                  <div className="Answer mt-4">
                                    <h3 id="question_text" className="fw-bold">
                                      Answers
                                    </h3>
                                    {quesArray?.length === 0 ? "wait..." : (quesArray?.map((item, index) => (
                                      <div key={index} className="inner_answer">
                                        <div className="answer_tex_area">
                                          <div className="answer_top my-3">
                                            <div>
                                              <span className="me-3">Answer{" "}{index + 1}</span>
                                              <span className="me-2">
                                                <input
                                                  onChange={checkedCorrect}
                                                  name={index}
                                                  checked={item?.Correct_Answer}
                                                  type="checkbox"
                                                  className="ans_checkbox "
                                                  key={index}
                                                />{item?.Correct_Answer}
                                                Correct Answer
                                              </span>
                                              <span>
                                                <input
                                                  checked={item?.Stick_to_bottom}
                                                  onChange={checkedStick}
                                                  name={index}
                                                  type="checkbox"
                                                  className="ans_checkbox"
                                                />
                                                Stick to bottom
                                              </span>
                                            </div>
                                            {index < 2 ? null :
                                              <div
                                                onClick={(e) => {
                                                  removeAnswer(e, index);
                                                }}
                                                className="delete_link"
                                              >
                                                <BiTrashAlt className="m-1 mb-2 deleteIcon" />
                                                Delete
                                              </div>
                                            }
                                          </div>
                                          <div className="inner_answer">
                                            <ReactQuill
                                              theme="snow"
                                              ref={answerStates[index]}
                                              modules={editorConfig}
                                              defaultValue={item?.answers}
                                              onChange={(newEditorState) =>
                                                handleAnswerEditorStateChanges(
                                                  index,
                                                  newEditorState
                                                )} />
                                          </div>
                                        </div>
                                        {error === "**Answer is required" ? (<span style={{ color: 'red' }}>**Answer is required</span>) : null}
                                        {error === "Provide atleast one correct answer" ? (<span style={{ color: 'red' }}>**Provide atleast one correct answer</span>) : null}
                                      </div>

                                    )))}
                                  </div>
                                </div>
                                <button
                                  onClick={handleAnswer}
                                  className="btn btn-outline-secondary px-4"
                                  id="answer_btn"
                                >
                                  Add Answer{" "}
                                  <img
                                    style={{ marginLeft: "5px" }}
                                    className="vector-icon18"
                                    alt="plus"
                                    src="/logos/fa6solidplus.svg"
                                  />
                                </button>
                              </>
                            ) : (
// <--------------------------------------------------------------------------------------Logical----------------------------------------------------------->                              
                            <>
                              <div className="code_question my-4 border-bottom pb-3">

                                <h3 id="question_text" className="fw-bold">
                                  Questions
                                </h3>
                                <div className="code_inner_question">
                                  <ReactQuill
                                    theme="snow"
                                    placeholder="title"
                                    modules={editorConfig}
                                    value={questionTitle}
                                    // value={quesData?.result?.logicalTestTitle}
                                    onChange={(newContent) => {
                                      setQuestionTitle(newContent);
                                    }}
                                  />
                                </div>
                                {error === "**Question is required" ? (
                                  <span style={{ color: "red" }}>
                                    **Question is required
                                  </span>
                                ) : null}


                                <div className="inner_question mt-3">
                                  <ReactQuill
                                    theme="snow"
                                    modules={editorConfig}
                                    value={questionValue}
                                    // value={quesData?.result?.question}
                                    onChange={(newContent) => {
                                      setQuestionValue(newContent);
                                    }}
                                  />
                                </div>
                                {error === "**Question is required" ? (
                                  <span style={{ color: "red" }}>
                                    **Question is required
                                  </span>
                                ) : null}
                              </div>
                            </>)
                          }
                          <div className="end_line"></div>
                        </div>
                        <button onClick={() => handleNewQuestion()} className="btn btn-outline-secondary px-4" id="create_question">
                          Create New Question{" "}
                          <img
                            style={{ marginLeft: "5px" }}
                            className="vector-icon18"
                            alt="plus"
                            src="/logos/fa6solidplus.svg"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditQuestion;