import {
    ERROR_REQUEST,
ERROR_SUCCESS,
ERROR_FAIL
  } from "./actionType";

const initialState = {
    errorData:{},
    isloadingError:false,
    isErrorFail:false,
    errorFailData:"",
  };
  

  export const error_Reducer = (state = initialState, action) => {
    switch (action.type) {


      case ERROR_REQUEST: {
        return {
          ...state,
          statusCode:0,
          message:'',
          errorData:"",
          isloadingError:true,
          isErrorFail:false,
        };
      }
      case ERROR_SUCCESS: {
        return {
          ...state,
          errorData:action.payload,
          isloadingError:false,
          isErrorFail:false,
        };
      }
      case ERROR_FAIL: {
        return {
          statusCode:0,
          message:'',
          isloadingError:false,
          isErrorFail:true,
          errorData:"",
          errorFailData:action.payload,
        };
      }
      default: {
        return state;
      }
    }
  }