
import {
    ADD_APPLICANT_REQUEST,
    ADD_APPLICANT_SUCCESS,
    ADD_APPLICANT_FAILURE,
    GET_APPLICANT_REQUEST,
    GET_APPLICANT_SUCCESS,
    GET_APPLICANT_FAILURE,
    TOKEN_REQUEST,
    TOKEN_SUCCESS,
    TOKEN_FAILURE
} from "./actionType";

const initialState = {
    // name: "",
    // email: "",
    // phone: "",
    // extraDetails: "",
    data: [],
    singleData: {},
    // status: STATUSES.IDLE,
    message: "",
    isLoading: "",
    errorData: "",
    isError: false,


    addApplicantLoading: false,

    isErrorAdd: false,
}

export const Applicant_Reducer = (state = initialState, action) => {
    switch (action.type) {


        case ADD_APPLICANT_REQUEST: {
            return {
                ...state,
                addApplicantLoading: true,
                singleData: {},
                isErrorAdd: false
            };
        }
        case ADD_APPLICANT_SUCCESS: {
            return {
                ...state,
                addApplicantLoading: false,
                singleData: action.payload,
                isErrorAdd: false
            };
        }
        case ADD_APPLICANT_FAILURE: {
            return {
                ...state,
                addApplicantLoading: false,
                singleData: {},
                errorDataAdd: action.payload,
                isErrorAdd: true
            };
        }


        case GET_APPLICANT_REQUEST: {
            return {
                ...state,
                isLoading: true,
                data: [],
                isError: false
            };
        }
        case GET_APPLICANT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                data: action.payload,
                isError: false
            };
        }
        case GET_APPLICANT_FAILURE: {
            return {
                ...state,
                isLoading: false,
                data: [],
                isError: true
            };
        }


        case TOKEN_REQUEST: {
            return {
                ...state,
                isLoading: true,
                message: "",
                errorDataGet: "",
                isError: false
            };
        }
        case TOKEN_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                message: action.payload,
                errorDataGet: "",
                isError: false
            };
        }
        case TOKEN_FAILURE: {
            return {
                ...state,
                isLoading: false,
                message: "",
                errorDataGet: action.payload,
                isError: true
            };
        }
        default: {
            return state;
        }
    }
}