import React, { useState } from "react";
import "./CreateCandidate.css";
import EducationalData from "../../Components/candidate/EducationalData";
import GeneralData from "../../Components/candidate/GeneralData";
import HardwareData from "../../Components/candidate/HardwareData";
import SkillsData from "../../Components/candidate/SkillsData";
import ExperienceData from "../../Components/candidate/ExperienceData";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCandidate } from "../../Redux/Candidate/findCandidate/action";
import { designationList, extractresume, skills } from "../../Redux/CandidateReducer/action";
import { expList } from "../../Redux/settings/experience/action";
import { sourceList } from "../../Redux/settings/source_management/action";
import { updateProfileImage } from "../../Redux/updateProfileImage/action";
import { toast } from 'react-toastify';
import { BlurLoader } from "../../Components/BlurLoader/BlurLoader";
import { IoMdClose } from "react-icons/io";
import { AiOutlineFilePdf } from "react-icons/ai";
import { ImCross } from "react-icons/im";
import Modal from "react-bootstrap/Modal";
import { IoSaveOutline } from "react-icons/io5";
import { LuUpload } from "react-icons/lu";
import { FaCheck } from "react-icons/fa";
import { Document, Page, pdfjs } from "react-pdf";
import { typeList } from "../../Redux/JobReducer/action";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

const EditCandidate = ({ Sidedata, onSidedataChange, setActiveComponet }) => {
  const [tabData, setTabData] = useState("General");
  const navigate = useNavigate();
  const { id } = useParams();
  const [candidateData, setCandidateData] = useState({
    first_name: "",
    last_name: "",
    lead_source: "",
    designation: "",
    experience_levels: "",
    profile_image: "",
    resume:"",
  });
  const [contactData, setContactData] = useState({});
  const [imageName, setImageName] = useState("");
  const [selectedCVFile, setSelectedCVFile] = useState(null);
  const [selecetedPdf, setSelecetedPdf] = useState("");
  const [previewPDF, setPreviewPDF] = useState(false);
  const [maxPage, setMaxPage] = useState(1);
  const [show, setShow] = useState(false);
  const [cvData, setCvData] = useState("");
  const [skillsData, setSkillsData] = useState("");
  const singleCandidate = useSelector((state) => state.findCandidate);
  const designationListset = useSelector((state) => state.Candidate_Reducer);
  const sourceListset = useSelector((state) => state.getSourceSet);
  const experienceLableListset = useSelector((state) => state.getExperienceSet);
  const updateImage = useSelector((state) => state.updateImage);
  const errorData = useSelector((state) => state?.addValidation);
  const extractData = useSelector((state) => state.Candidate_Reducer);
  const skillsArray =useSelector((state) => state.Candidate_Reducer.skillsData);
  const { isLoadingResumeExtract, resumeExtractDataEdit, } = extractData;
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  // let skillsData=[]
  useEffect(() => {
    const userdata = candidateData;
    userdata.first_name = singleCandidate?.data?.data?.first_name;
    userdata.last_name = singleCandidate?.data?.data?.last_name;
    userdata.lead_source = singleCandidate?.data?.data?.lead_source;
    userdata.designation = singleCandidate?.data?.data?.designation_id;
    userdata.experience_levels =singleCandidate?.data?.data?.experience_level_id;
    userdata.profile_image = singleCandidate?.data?.data?.profile_image;
    userdata.resume = singleCandidate?.data?.data?.resume;
    dispatch(getCandidate(id, navigate));
    setActiveComponet("editCandidate");
    dispatch(designationList(navigate));
    dispatch(expList(navigate));
    dispatch(sourceList(navigate));
    dispatch(typeList(navigate, token));
  }, []);
  useEffect(() => {
    const userdata = candidateData;
    userdata.first_name = singleCandidate?.data?.data?.first_name;
    userdata.last_name = singleCandidate?.data?.data?.last_name;
    userdata.lead_source = singleCandidate?.data?.data?.lead_source;
    userdata.designation = singleCandidate?.data?.data?.designation_id;
    userdata.experience_levels = singleCandidate?.data?.data?.experience_level_id;
    userdata.profile_image = singleCandidate?.data?.data?.profile_image;
    userdata.resume = singleCandidate?.data?.data?.resume;
    const contact = singleCandidate?.data?.data;
    setSelecetedPdf(singleCandidate?.data?.data?.resume)
    setCandidateData(userdata);
    setContactData(contact);
  }, [singleCandidate]);
  // useEffect(() => { }, [candidateData]);
  const onHandleChangeFun = (e) => {
    const { name, value } = e.target;
    setContactData((prev) => ({ ...prev, [name]: value }));
  };
  const menuClick = () => {
    onSidedataChange(!Sidedata);
  };

  const handleCandidateDataChange = (e) => {
    let { name, value } = e.target;
    setCandidateData((prev) => ({ ...prev, [name]:name=="first_name" || name=="last_name"? value.replace(/[~!@#$%^&*()_\-+={}\[\]|\\/?:;"'<>.,0-9]/g,'')?.replace(/\s+/g,' ')?.trimStart():value }));
    
  };
  // handle edit profile ImageChange
  function truncateFilename(filename, wordCount) {
    if (typeof filename !== "string" || wordCount <= 0) {
      return filename;
    }
    let extension = filename.split(".").pop();
    let truncated =
      filename.length > wordCount
        ? filename.substring(0, wordCount) + "..."
        : filename.replace(extension, "");
    return truncated + "." + extension;
  }

  
  const handleShow = () => {
    // dispatch(validData())
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
const removePdf=()=>{
  setSelectedCVFile("")
    setSelecetedPdf(''); 
}
const importData = (event) => {
  // setIsChecked(event.target.checked)
 if (event.target.checked == true && selectedCVFile) {
   if (resumeExtractDataEdit == 0 && event.target.checked ) {
     toast.warn("Please Save The Resume ")
     return
   } else {
    if(resumeExtractDataEdit?.data?.resume){
    setCvData(resumeExtractDataEdit?.data?.resume)
    const userdata = candidateData;
    userdata.first_name = resumeExtractDataEdit?.data?.resume?.first_name&&resumeExtractDataEdit?.data?.resume?.first_name;
    userdata.last_name = resumeExtractDataEdit?.data?.resume?.last_name&&resumeExtractDataEdit?.data?.resume?.last_name;
    const userExperience=resumeExtractDataEdit?.data?.resume?.designation.trim(" ")
        const matchedDesgnation = designationListset?.designationData?.find((item) => {
            const regexPattern = new RegExp(item.name.trim(" "), 'i');
            return regexPattern.test(userExperience)
        });
        userdata.designation =matchedDesgnation?.designation_id;
        
        let skl=[]
        skillsArray.filter((item) => {
            return resumeExtractDataEdit?.data?.resume?.skills.find((element) => {
                const lowercaseSkillName = element.toLowerCase();
               return item.name && item.name.toLowerCase().includes(lowercaseSkillName) ? 
               skl.push({currently_working: true,exp_month: "",exp_year: "",skill_id: item.skill_id,name:item.name}):null
            });
          });
      setSkillsData(skl)
    // userdata.lead_source = resumeExtractDataEdit?.data?.resume?.lead_source && resumeExtractDataEdit?.data?.resume?.lead_source;
    // userdata.designation = resumeExtractDataEdit?.data?.resume?.designation_id && resumeExtractDataEdit?.data?.resume?.designation_id;
    // userdata.profile_image = resumeExtractDataEdit?.data?.resume?.profile_image;
    // const contact = resumeExtractDataEdit?.data?.resume;
    setCandidateData(userdata);
    //  dispatch(fillUpdata(resumeExtractDataEdit, navigate));
    
   }}
 } else {
 }
};

  const HandleProfileImage = async (e) => {
    let data = {
      id: "",
      Img: ""
    };
    let input = e;
    let base64String;
    if (input.target.files && input.target.files[0]) {
      let file = input.target.files[0];
      if (
        file && file.type != "image/jpeg" && file.type != "image/gif" && file.type != "image/png" && file.type != "image/svg"
      ) {
         toast.error("Please Provide Image Type File")
         return
      }
      let reader = new FileReader();
      reader.onload = function (e) {
        base64String = e.target.result;

        // Update candidateData with the new profile image
        const userdata = { ...candidateData };
        userdata.profile_image = base64String;
        setCandidateData(userdata);
        data.id = id;
        data.Img = base64String;
        dispatch(updateProfileImage(data, navigate)).then((res) => {
          let truncatedFilename = truncateFilename(file?.name, 8);
          setImageName(truncatedFilename);
          toast.success("Profile Image updated successfully");
        }).catch((err) => {
          toast.error("Something went wrong");
        })
      };

      reader.readAsDataURL(file);

    }
  };
  const renaming = (url) => {
    const filenameWithExtension = url?.split('/')?.pop() ||"";
    const parts = filenameWithExtension?.split('.')|| ["",""];
    const filename = decodeURI(parts[0]);
    const filetype = parts[1];
    return url ? filename + "." + filetype :""
  }

  const handleCVFileChange = async (event) => {
    const file = event.target.files[0];
    const userdata = candidateData;
    userdata.resume=file
    if (file) {
      if (file.type === "application/pdf") {
        if (file.size > 5242880) {
          toast.warn("Please select file smaller than 5MB");
        } else {
          setSelecetedPdf(file);
          setSelectedCVFile(file);
        }
      } else {
        toast.warn("Please upload a PDF file!");
      }
    }
  };
  const saveResume = (event) => {
    dispatch(extractresume(selectedCVFile, navigate,"edit"));
  };
  const removeFile = (e) => {
    setImageName();
    const userdata = candidateData;
    userdata.profile_image = ""
    let data = {
      id:id,
      Img: ""
    };
    dispatch(updateProfileImage(data, navigate)).then((res) => {
      toast.success("Profile Image removed successfully");
    }).catch((err) => {
      toast.error("Something went wrong");
    })
  };
  useEffect(()=>{
    !isLoadingResumeExtract&&setShow(isLoadingResumeExtract)
  },[isLoadingResumeExtract])
useEffect(()=>{
setImageName(renaming(singleCandidate?.data?.data?.profile_image))
},[singleCandidate?.data?.data?.profile_image])
useEffect(() => {
  dispatch(skills(navigate))
}, []);
  return (
    <>
      <section
        className={`addnewcandidate ${
          singleCandidate?.isLoading && "body_scroll_hide"
        }`}
      >
        {singleCandidate?.isLoading && <BlurLoader />}
        <div className="title">
          <h2 className="title-box position-relative">
            <button className="fa_bars  d-lg-none" onClick={menuClick}>
              <i className="fa-solid fa-bars"></i>
            </button>
            Edit Candidate
          </h2>
        </div>
        <div className="row create_candidate">
          <div className="col-lg-12 col-md-12">
            <div className="row g-2 align-items-end justify-content-between mb-3">
              <div className="col-auto ">
                <p className="add_p">Applicant Details</p>
              </div>
              <div className="col-12 col-md-auto align-items-center justify-content-end me-2">
                <div className=" d-flex align-items-center justify-content-center justify-content-sm-end mb-3">
                  <button className="btn btn-primary" onClick={handleShow}>
                    <span className="text-danger ms-1">*</span>
                    <AiOutlineFilePdf /> Upload CV
                  </button>
                </div>
                <div>
                  {selectedCVFile ? (
                    <div
                      className="d-flex justify-content-center justify-content-sm-end flex-wrap"
                      style={{ gap: "10px" }}
                    >
                      <div>
                        <a
                          href="#"
                          style={{ color: "#3ab2e4" }}
                          onClick={() => setPreviewPDF(true)}
                        >
                          {selectedCVFile && selectedCVFile.name}
                        </a>
                        <ImCross
                          onClick={(e) => {
                            removePdf(e);
                          }}
                          className="text-secondary p-1"
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="importData"
                          label="import data"
                          onClick={(e) => importData(e)}
                          className="ms-2 me-2"
                        />
                        <label
                          htmlFor="importData"
                          style={{ cursor: "pointer" }}
                        >
                          Fill Up The Fields With CV
                        </label>
                      </div>
                    </div>
                  ) : selecetedPdf != "" && selecetedPdf != null ? (
                    <div
                      className="d-flex justify-content-center justify-content-sm-end flex-wrap"
                      style={{ gap: "10px" }}
                    >
                      <div>
                        <a
                          href="#"
                          style={{ color: "#3ab2e4" }}
                          onClick={() => setPreviewPDF(true)}
                        >
                          {renaming(selecetedPdf)}
                        </a>
                      </div>
                    </div>
                  ) : null}
                  <p className="text-danger ms-1">
                    {errorData.field == "resume" && errorData.message}
                  </p>
                </div>
              </div>
            </div>
            <div className="newcandidate-form-box ">
              <div className="d-flex  flex-column flex-md-row align-items-center justify-content-around ">
                <div className="applicatant-img d-block d-md-flex pb-5 pb-md-0 mb-5 mb-md-0">
                  {candidateData?.profile_image ? (
                    <img
                      className="profile-img"
                      src={`${candidateData?.profile_image}`}
                      alt=""
                    />
                  ) : (
                    <img
                      className="profile-img"
                      src="/images/user-alt.png"
                      alt="profile"
                    />
                  )}
                  <label
                    htmlFor={updateImage?.isLoading ? "" : "image-upload"}
                    className=" text-white profile-img-upload"
                  >
                    <input
                      type="file"
                      id="image-upload"
                      name="profile_image"
                      accept="image/jpeg,image/gif,image/png"
                      aria-required="false"
                      aria-invalid="false"
                      onChange={(e) => HandleProfileImage(e)}
                      disabled={updateImage?.isLoading}
                    />
                    {updateImage?.isLoading ? (
                      <i className="fa-solid fa-circle-notch fa-spin"></i>
                    ) : (
                      <i className="fa-solid fa-pen"></i>
                    )}
                  </label>
                  <div
                    className="d-block d-md-none "
                    style={{ cursor: "pointer" }}
                  >
                    <p
                      className={`mt-2 ${
                        imageName ? "d-md-block" : "d-md-none"
                      } text-light text-center text-md-start`}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {imageName ? (
                        imageName
                      ) : (
                        <span style={{ fontSize: "12px" }}>
                          JPG/PNG/JPEG Are Allowed
                        </span>
                      )}
                      <b style={{ color: "red" }}>
                        <IoMdClose
                          className={!imageName && "d-none "}
                          onClick={(e) => removeFile(e)}
                        />
                      </b>
                    </p>
                  </div>
                </div>
                <div className="aplicatant-details">
                  <div className="row">
                    <div className="col-md-4">
                      <label className="text-white">First Name </label>
                      <span className="text-danger ms-1">*</span>
                      <input
                        type="text"
                        name="first_name"
                        pattern="[A-Za-z\s]+"
                        title="Letters only"
                        className="form-control"
                        aria-required="true"
                        aria-invalid="false"
                        autoComplete="off"
                        value={candidateData?.first_name ?candidateData?.first_name:""}
                        onChange={(e) => {
                          handleCandidateDataChange(e);
                        }}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="text-white">Last Name</label>
                      <span className="text-danger ms-1">*</span>
                      <input
                        type="text"
                        name="last_name"
                        className="form-control"
                        aria-required="true"
                        aria-invalid="false"
                        autoComplete="off"
                        value={candidateData?.last_name ? candidateData?.last_name:""}
                        onChange={(e) => handleCandidateDataChange(e)}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="text-white">Lead source</label>
                      <span className="text-danger ms-1">*</span>
                      <span className="option-container">
                        <i className="fa-solid fa-caret-down"></i>
                        <select
                          className="form-control"
                          value={candidateData?.lead_source}
                          name="lead_source"
                          onChange={(e) => handleCandidateDataChange(e)}
                        >
                          <option value="">Select Lead Source</option>
                          {sourceListset?.data?.data?.map((source) => (
                              <option
                                hidden={source.status!=1}
                                // selected={
                                //   source.name ===
                                //   singleCandidate?.data?.data?.lead_source_name
                                //     ?.name
                                // }
                                value={source.id}
                                key={source.id}
                              >
                                {source.name}
                              </option>
                          ))}
                        </select>
                      </span>
                    </div>
                    <div className="col-md-4">
                      <label className="text-white">Designation Experience</label>
                      <span className="text-danger ms-1">*</span>
                      <span className="option-container">
                        <i className="fa-solid fa-caret-down"></i>
                        <select
                          required="required"
                          className="form-control"
                          name="experience_levels"
                          value={candidateData.experience_levels}
                          onChange={(e) => handleCandidateDataChange(e)}
                        >
                          <option value="">Select Experience </option>
                          {experienceLableListset?.data?.data?.map(
                            (experience) => 
                                <option
                                hidden={experience.status!=1}
                                  // selected={
                                  //   experience?.name ===
                                  //   singleCandidate?.data?.data
                                  //     ?.experience_levels?.name
                                  // }
                                  value={experience.id}
                                  key={experience.experience_level_id}
                                >
                                  {experience.name}
                                </option>
                          )}
                        </select>
                      </span>
                    </div>
                    <div className="col-md-4">
                      <label className="text-white">Designation</label>
                      <span className="text-danger ms-1">*</span>
                      <span className="option-container">
                        <i className="fa-solid fa-caret-down"></i>
                        <select
                          name="designation"
                          className="form-control"
                          aria-required="true"
                          aria-invalid="false"
                          value={candidateData.designation}
                          onChange={(e) => handleCandidateDataChange(e)}
                        >
                          <option 
                           value="">Select Designation</option>
                          {designationListset?.designationData?.map(
                            (designation) =>
                                <option
                                hidden={designation.status!=1}
                                  // selected={
                                  //   designation?.name ===
                                  //   singleCandidate?.data?.data?.designation_id
                                  // }
                                  value={designation?.designation_id}
                                  key={designation?.designation_id}
                                >
                                  {designation?.name}
                                </option>
                          )}
                        </select>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-none d-md-block">
                <p
                  className={`mt-0 ${
                    imageName ? "d-md-block" : "d-md-none"
                  } text-light text-center text-md-start`}
                  style={{ whiteSpace: "nowrap" }}
                >
                  {imageName ? (
                    imageName
                  ) : (
                    <span style={{ fontSize: "12px" }}>
                      JPG/PNG/JPEG Are Allowed
                    </span>
                  )}
                  <b style={{ color: "red" }}>
                    <IoMdClose
                      className={!imageName && "d-none "}
                      onClick={(e) => removeFile(e)}
                    />
                  </b>
                </p>
              </div>
            </div>
            <div className="middle-section">
              <ul id="myTab" role="tablist" className="nav nav-tabs">
                <li className="nav-item">
                  <a
                    id="Profile-tab"
                    data-bs-toggle="tab"
                    href="#Profile"
                    role="tab"
                    aria-controls="Profile"
                    aria-selected="true"
                    className="nav-link active px-3"
                  >
                    Profile Details
                  </a>
                </li>
              </ul>
              <div id="myTabContent" className="tab-content">
                <div
                  id="Profile"
                  role="tabpanel"
                  aria-labelledby="Profile-tab"
                  className="tab-pane fade show active"
                >
                  <ul className="nav nxt-tab">
                    <li className="nav-item">
                      <a
                        className={
                          tabData == "General" ? "nav-link active" : "nav-link"
                        }
                        onClick={() => {
                          setTabData("General");
                        }}
                      >
                        General Details
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          tabData == "Education"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        onClick={() => {
                          setTabData("Education");
                        }}
                      >
                        Education Data
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          tabData == "Hardware" ? "nav-link active" : "nav-link"
                        }
                        onClick={() => {
                          setTabData("Hardware");
                        }}
                      >
                        Hardware requirements
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          tabData == "Experience"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        onClick={() => {
                          setTabData("Experience");
                        }}
                      >
                        Work Experience
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          tabData == "Skill" ? "nav-link active" : "nav-link"
                        }
                        onClick={() => {
                          setTabData("Skill");
                        }}
                      >
                        Skills
                      </a>
                    </li>
                  </ul>
                  {tabData == "General" ? (
                    <GeneralData
                      contact={contactData}
                      profileData={candidateData}
                      setContactData={setContactData}
                      onHandleChangeFun={onHandleChangeFun}
                      siteList={sourceListset}
                      cvData={cvData}
                    />
                  ) : tabData == "Education" ? (
                    <EducationalData
                      contact={contactData}
                      profileData={candidateData}
                      cvData={cvData}
                      tabData={tabData}
                    />
                  ) : tabData == "Hardware" ? (
                    <HardwareData
                      profileData={candidateData}
                      contact={contactData}
                      cvData={cvData}
                    />
                  ) : tabData == "Skill" ? (
                    <SkillsData
                      profileData={candidateData}
                      contact={contactData}
                      skillsData={skillsData}
                    />
                  ) : tabData == "Experience" ? (
                    <ExperienceData
                      profileData={candidateData}
                      contact={contactData}
                      cvData={cvData}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="md"
          centered
        >
          <Modal.Header closeButton={!isLoadingResumeExtract}>
            {/* <Modal.Title className="fw-bold fs-5">
            Upload New Resume
          </Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <div className="pdfModal pt-4 pb-5">
              <h4 style={{ fontSize: "22px" }}>Upload candidate`s CV/Resume</h4>
              {!isLoadingResumeExtract && (
                <>
                  <div className="pdfUploadArea">
                    <input
                      type="file"
                      id="pdfFile"
                      className="d-none"
                      accept="application/pdf"
                      onChange={handleCVFileChange}
                    />
                    <label htmlFor="pdfFile" className="btn btn-primary">
                      <LuUpload /> Upload
                    </label>
                    <p className="mt-2">
                      {selectedCVFile ? (
                        <span className="text-success">
                          {selectedCVFile.name}
                          <FaCheck />
                        </span>
                      ) : (
                        "Supports only pdf."
                      )}
                    </p>
                  </div>

                  {selectedCVFile && !isLoadingResumeExtract && (
                    <div className="mt-4">
                      <button
                        className="btn btn-primary"
                        onClick={(e) => saveResume(e)}
                        disabled={isLoadingResumeExtract ? true : false}
                      >
                        <IoSaveOutline className="m-1" />
                        Save
                      </button>
                    </div>
                  )}
                </>
              )}

              {isLoadingResumeExtract && (
                <>
                  <div className="progressARea mt-3">
                    <div className="progress">
                      <div className="progress-value" />
                    </div>
                  </div>
                  <p className="text-primary mt-2 fs-6 text-uppercase">
                    Importing Data...
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </p>
                </>
              )}
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          className="j_pdf_show_custom"
          show={previewPDF}
          onHide={() => setPreviewPDF(false)}
          backdrop="static"
          keyboard={false}
          size="xl"
          centered
        >
          <Modal.Header closeButton={previewPDF}>
            <Modal.Title className="fw-bold fs-5"></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="pdfModal d-flex align-items-center justify-content-center flex-column pt-4 pb-5">
              <table className=" w-100 d-flex justify-content-center">
                <tbody>
                  <tr>
                    <td>
                      <Document
                        file={selecetedPdf}
                        onLoadSuccess={({ numPages }) => {
                          setMaxPage(numPages);
                        }}
                      >
                        {[...Array(maxPage)].map((_, index) => (
                          <Page
                            key={index + 1}
                            pageNumber={index + 1}
                            renderTextLayer={false}
                            renderAnnotationLayer={false}
                          />
                          // each  page is able to render a page of pdf, thats why we are loading all pages withh different page number using loop
                        ))}
                      </Document>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
};

export default EditCandidate;
