import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { } from "./css/AddQuestions.css";
// import SideNav from "./SideNav";
import { cateGories } from "../../Redux/CategoryReducer/action";
import {
  getSpecificQuestion,
  postQuestion,
  updateQuestion,
} from "../../Redux/QuestionReducer/action";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate, useParams } from "react-router-dom";
import { BiTrashAlt } from "react-icons/bi";
import { HiChevronLeft } from "react-icons/hi";
import { toast } from "react-toastify";
import Spiner1 from "../../Components/Spiner1";
import { getTest } from "../../Redux/TestReducer/action";
import { Modal, Button } from 'react-bootstrap';

const AddQuestions = (props) => {
  // function getCookieValue(cookieName) {
  //   // Split the document.cookie string into individual cookies
  //   const cookies = document.cookie.split('; ');

  //   // Iterate through the cookies to find the one with the specified name
  //   for (const cookie of cookies) {
  //     const [name, value] = cookie.split('=');
  //     if (name === cookieName) {
  //       // Return the value of the cookie
  //       return decodeURIComponent(value);
  //     }
  //   }

  //   // Return null if the cookie with the specified name is not found
  //   return null;
  // }
  // const sessionTokenValue = getCookieValue('sessionToken');
  const sessionTokenValue = localStorage.getItem("token");

  const webToken = sessionTokenValue
  const cateData = useSelector((state) => state.categories);
  const quesData = useSelector((state) => state?.question?.questionData);
  const loading = useSelector((state) => state?.question.questionIsLoading);


  const editorConfig = {
    toolbar: [
      ["bold", "italic", "underline", "strike", "link"], // toggled buttons
      [
        "clean",
        "blockquote",
        "code-block",
        { list: "ordered" },
        { list: "bullet" },
        { align: [] },
      ],
      [{ size: ["small", false, "large", "huge"] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ font: [] }],
      ["image"],
    ],
  };
  const [qesCategory, setQesCategory] = useState("");
  const [questionValue, setQuestionValue] = useState();
  const [questionTitle, setQuestionTitle] = useState();
  const [answerStates, setAnswerStates] = useState("");
  const [onSubmit, setOnSubmit] = useState(false);
  const [isRadio1, setRadio1] = useState(true);
  const [isRadio2, setRadio2] = useState(true);
  const [isChecked, setIsChecked] = useState(true);
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(true);
  const [isChecked3, setIsChecked3] = useState(false);
  const [correct, setCorrect] = useState(false);
  const [saveProp, setSaveProp] = useState(false);
  const [catId, setCatId] = useState();
  const [stop, setStop] = useState(0);
  const [error, setError] = useState(null);
  const [questionType, setQuestionType] = useState('')
  const dispatch = useDispatch();
  const { id, quesid } = useParams();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const [myArray, setMyArray] = useState([
    {
      answers: ""
    },
    {
      answers: ""
    }
  ]);

  const handleAnswer = () => {
    if (myArray[myArray.length - 1].answers !== "") {
      const example = {
        Correct_Answer: false,
        Stick_to_bottom: false,
        answers: "",
      };

      setMyArray((prevArray) => [...prevArray, example]);
    }
  };

  const newQuestion = () => {
    setQesCategory("")
    setRadio1(true)
    setRadio2(true)
    setIsChecked(true)
    setIsChecked1(false);
    setIsChecked2(true);
    setIsChecked3(false);
    setCatId()
    setQuestionValue("")
    setCorrect(false)
    setQesCategory("")
    if (stop === 0) {
      setStop(stop + 1)
      const arr = myArray?.map((e) => {
        return (e.answers = "", e.Correct_Answer = false, e.Stick_to_bottom = false)
      })
      setMyArray(arr)
    setQuestionValue("")
    }
  }


  const handleEditCreate = async (e) => {
    const ansVal = myArray.filter((e, i) => {
      if (e.answers?.trim(" ").length === 0) {
        return e;
      }
    });
    if (saveProp === "Add Question") {
      const ansArray = myArray.filter((element, index) => {
        return element.Correct_Answer === true;
      });
      if (!questionValue || questionValue?.trim(" ").length === 0) {
        return setError(toast.warn("Question Is Required"));
      }
      if (testData?.testType === "logical test") {
        if (!questionTitle) {
          return setError(toast.warn("Title Is Required"));
        }
      }
      if (testData?.testType === "custom test") {
        if (ansVal.length !== 0) {
          return setError(toast.warn("Answer Is Required"));
        }
        if (ansArray.length === 0) {
          return setError(toast.warn("Provide Atleast One Correct Answer"));
        }
        if (isChecked === false) {
          if (ansArray?.length < 2) {
            return setError(toast.warn("Provide Atleast Two Correct Answer"));
          }
        }
      }
      if (onSubmit === false) {
        dispatch(
          postQuestion(
            webToken,
            navigate,
            id,
            qesCategory,
            questionValue,
            myArray,
            isRadio1,
            isRadio2,
            catId,
            window.location.href,
            false,
            questionTitle
          )
        );
        setOnSubmit(true);
        setTimeout(() => {
          setOnSubmit(false);
        }, 2000);
      }
    }
  };
  const handleSaveResetClick = async (e) => {
    const ansVal = myArray.filter((e, i) => {
      if (e.answers?.trim(" ").length === 0) {
        return e;
      }
    });
    if (saveProp === "Add Question") {
      const ansArray = myArray.filter((element, index) => {
        return element.Correct_Answer === true;
      });
      if (!questionValue || questionValue?.trim(" ").length === 0) {

        return setError(toast.warn("Question Is Required"));
      }
      if (testData?.testType === "logical test") {
        if (!questionTitle) {
          return setError(toast.warn("Title Is Required"));
        }
      }
      if (testData?.testType === "custom test") {
        if (ansVal.length !== 0) {
          return setError(toast.warn("Answer Is Required"));
        }
        if (ansArray.length === 0) {
          return setError(toast.warn("Provide Atleast One Correct Answer"));
        }
        if (isChecked === false) {
          if (ansArray?.length < 2) {
            return setError(toast.warn("Provide Atleast Two Correct Answer"));
          }
        }
      }
      if (onSubmit === false) {
         try {
        setOnSubmit(true);
         await dispatch(
          postQuestion(
            webToken,
            navigate,
            id,
            qesCategory,
            questionValue,
            myArray,
            isRadio1,
            isRadio2,
            catId,
            false,
            questionTitle
          )
        );
        toast.success("Question Saved Successfully");
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 500);
      } catch (error) {
        console.error("Error saving question:", error);
      } finally {
        setTimeout(() => {
          setOnSubmit(false);
        }, 2000);
      }
    }
  }
};


  const handleRadioChange = (event) => {
    if (event.target.checked) {
      setIsChecked(event.target.checked);
      if (isChecked === true) {
        setIsChecked(false);
        setIsChecked1(true);
        setRadio1(false);
      } else {
        setIsChecked(true);
        setIsChecked1(false);
        setRadio1(true);
      }
      const updatedArray = myArray.map((element) => {
        return { ...element, Correct_Answer: false };
      });
      setCorrect(false);
      setMyArray(updatedArray);
    } else {
      if (quesData.selectAnswerType) {
        setIsChecked(true);
        setIsChecked1(false);
      } else {
        setIsChecked(false);
        setIsChecked1(true);
      }
    }
  };



  const handleRadioChange1 = (event) => {
    const { checked } = event.target;

    if (checked) {
      if (isChecked2 === true) {
        setIsChecked2(false);
        setIsChecked3(true);
        setRadio2(false);
      } else {
        setIsChecked2(true);
        setIsChecked3(false);
        setRadio2(true);
      }
    }
  };


  const handleAnswerEditorStateChanges = (index, newEditorState) => {
    const inputArray = [...myArray];
    inputArray[index] = {
      answers: newEditorState,
    };
    setMyArray(inputArray);
  };

  const removeAnswer = async (e, i) => {
    if (myArray.length > 2) {
      const delVal = [...myArray];
      delVal.splice(i, 1);
      setMyArray(delVal);
    }
  };

  const checkedCorrect = (e) => {
    const { name } = e.target;
    let NewArray = [];

    if (isRadio1 === false) {
      myArray.map((element, index) => {
        if (name == index) {
          if (correct === false) {
            element = { ...element, Correct_Answer: true };
            setCorrect(true);
          } else {
            element = { ...element, Correct_Answer: false };
            setCorrect(false);
          }
        }
        NewArray.push(element);
      });
    } else {
      myArray.map((element, index) => {
        element = { ...element }; // Create a new object using spread operator
        if (name == index) {
          element.Correct_Answer = true;
          setCorrect(true);
        } else {
          element.Correct_Answer = false;
          setCorrect(false);
        }
        NewArray.push(element);
      });
    }
    setMyArray(NewArray);
  };

  var NewArray = null;
  const checkedStick = (e) => {
    const { name } = e.target;
    const selectedIndex = parseInt(name);
  
    const NewArray = myArray.map((element, index) => {
      if (index === selectedIndex) {
        return { ...element, Stick_to_bottom: !element.Stick_to_bottom };
      } else {
        return { ...element, Stick_to_bottom: false };
      }
    });
  
    setMyArray(NewArray);
  };
  
  


  const url = window.location.href;
  const baseUrl = process.env.REACT_APP_FRONTEDND_DOMAIN
  const parts = url?.split(baseUrl);
  const setTestId = parts[1]?.replace("/test-edit", "");
  let splitUrl = setTestId?.split('/');
  splitUrl = splitUrl?.filter((e) => {
    return e !== "" && e
  })
  if (splitUrl?.length > 0) {
    var urlValue = splitUrl[0];
  }

  const backToTest = () => {
    navigate(`/test/create-test-screen/${id}`)
  };
  // -------------------------------------------------------------edit--question-----------------------------------------------------------------------------------------------------

  useEffect(() => {
    dispatch(cateGories(webToken, id, navigate));
    setSaveProp(props.title);

  }, [props.title, quesid, id]);

  useEffect(() => {
    setMyArray(myArray);
    setSaveProp(props.title);
  }, [props.title, myArray]);
  const menuClick = () => {
    props.onSidedataChange(!props.newSidedata);
  };

  // const handleRadioChangeType = (e) => {
  //   setQuestionType(e)
  // }





  const testId = useParams();

  const testData = useSelector((state) => state.test.singleTest);

  useEffect(() => {
    dispatch(getTest(webToken, testId.id, navigate));
  }, [])


  useEffect(() => {
    if (testData?.testType === "custom test") {
      setQuestionType("MCQ")
    } else {
      setQuestionType("code")
    }
  }, [testData])

  const handleCreateNewQuestion = () => {
  setShowModal(true);
};


const handleSaveAndReset = async () => {
  let hasError = false;

  // Check for errors
  const ansVal = myArray.filter((e, i) => {
    if (e.answers?.trim(" ").length === 0) {
      return e;
    }
  });

  if (saveProp === "Add Question") {
    const ansArray = myArray.filter((element, index) => {
      return element.Correct_Answer === true;
    });

    if (!questionValue || questionValue?.trim(" ").length === 0) {
      setError(toast.warn("Question Is Required"));
      hasError = true;
    }

    if (testData?.testType === "logical test") {
      if (!questionTitle) {
        setError(toast.warn("Title Is Required"));
        hasError = true;
      }
    }

    if (testData?.testType === "custom test") {
      if (ansVal.length !== 0) {
        setError(toast.warn("Answer Is Required"));
        hasError = true;
      }

      if (ansArray.length === 0) {
        setError(toast.warn("Provide Atleast One Correct Answer"));
        hasError = true;
      }

      if (isChecked === false) {
        if (ansArray?.length < 2) {
          setError(toast.warn("Provide Atleast Two Correct Answer"));
          hasError = true;
        }
      }
    }
  }
  
    if (!hasError) {
       if (!questionValue || questionValue?.trim(" ").length === 0) {
      setError(toast.warn("Question Is Required"));
    }
    await handleSaveResetClick();
    handleReset()
  }
};


const handleReset = () => {
  setQuestionValue("")
   setQesCategory(" ")
    setRadio1(true)
    setRadio2(true)
    setIsChecked(true)
    setIsChecked1(false);
    setIsChecked2(true);
    setIsChecked3(false);
    setCatId()
    // setQuestionValue("")
    setCorrect(false)
    setQesCategory("")
    
    if (stop === 0) {
      // setStop(stop + 1)
      const arr = myArray?.map((e) => {
        return (e.answers = "", e.Correct_Answer = false, e.Stick_to_bottom = false)
      })
      setMyArray(arr)
    }
  setShowModal(false); 
};

  return (
    <div className="content_app ">
      <div className="body_content">
        <div className="container-fluid px-0">
          <button className="fa_bars  d-lg-none" onClick={menuClick}>
            <i className="fa-solid fa-bars fs-4"></i>
          </button>
          <div className="row g-0">
            <div className="col-12 hjklop_right">
              <div className="container-fluid px-0">
                <div className="row">
                  <div className="col-12">
                    <div className="Main_div">
                      <div className="addNewQuestions px-3 px-lg-4 px-xl-5 pb-5">
                        <span onClick={backToTest} className="back_to_test">
                          <HiChevronLeft className="fs-3" /> Back To Test
                        </span>
                        <h3 className="addQuestion fw-bold my-3">
                          {props.title}
                        </h3>
                        <div>
                          <label
                            htmlFor="category_select"
                            className="new_label mb-1"
                          >
                            Question Category
                          </label>
                          <div className="row g-3">
                            <div className="col-xl-9 col-lg-8 col-md-7 col-12 ">
                              <div className="">
                                <select
                                  onChange={(e) => {
                                    var after_star =
                                      e.target.value?.split("*");
                                    setQesCategory(after_star[0]);
                                    setCatId(after_star[1]);
                                  }}
                                  className="selector "
                                  id="category_select"
                                >
                                  <option value="NO Category" default>
                                    No Category
                                  </option>
                                  {cateData?.data?.result ? (
                                    cateData?.data?.result?.map(
                                      (element, index) => (
                                        <option
                                          key={index}
                                          name={element?._id}
                                          value={
                                            element?.categoryName +
                                            "*" +
                                            element?._id
                                          }
                                        >
                                          {element.categoryName}
                                        </option>
                                      )
                                    )
                                  ) : null}
                                </select>
                              </div>
                              {
                                questionType === 'MCQ' ? (
                                  <>
                                    <div className="first_radio mt-3">
                                      <label className="new_label">
                                        How many correct answers does this question
                                        have?
                                      </label>
                                      <div className="radio_inner">
                                        <input
                                          // readOnly
                                          checked={
                                            isRadio1 === true ? true : isChecked
                                          }
                                          name="radio1"
                                          type="radio"
                                          id="test_correct"
                                          onChange={handleRadioChange}
                                        />{" "}
                                        <label htmlFor="test_correct">
                                          One correct answer
                                        </label>
                                        <input
                                          className="radio_input"
                                          name="test_multiplt"
                                          checked={
                                            isRadio1 === true ? false : isChecked1
                                          }
                                          type="radio"
                                          onChange={handleRadioChange}
                                        />{" "}
                                        <label htmlFor="test_multiplt">
                                          Multiple correct answers
                                        </label>
                                      </div>
                                    </div>
                                    <div className="first_radio">
                                      <label className="new_label">
                                        Do you want to shuffle answers when a
                                        candidate takes the test?
                                      </label>
                                      <div className="radio_inner">
                                        <div className="mb-2">
                                          <input
                                            name="radio3"
                                            readOnly
                                            checked={
                                              isRadio2 === true ? true : isChecked2
                                            }
                                            type="radio"
                                            id="yes_suffle"
                                            onChange={handleRadioChange1}
                                          />
                                          <label htmlFor="yes_suffle">
                                            {" "}
                                            Yes, shuffle answers in the test
                                          </label>
                                          <span>
                                            ({" "}
                                            <span className="recomand">
                                              Recommended{" "}
                                            </span>
                                            )
                                          </span>
                                        </div>
                                        <div>
                                          <input
                                            readOnly
                                            name="radio4"
                                            checked={
                                              isRadio2 === true ? false : isChecked3
                                            }
                                            type="radio"
                                            id="no_suffle"
                                            onChange={handleRadioChange1}
                                          />
                                          <label htmlFor="no_suffle">
                                            {" "}
                                            No, keep the constant order
                                          </label>
                                          <span>
                                            <a
                                              className="Arrange_question"
                                            >
                                              {" "}
                                              (Arrange the questions here)
                                            </a>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )
                                  : (
                                    <div className="mt-3">
                                      <label
                                        htmlFor="category_select"
                                        className="new_label mb-1"
                                      >
                                        Select Language
                                      </label>

                                      <select
                                        className="selector "
                                        id="category_select"
                                      >
                                        <option value="NO Category" default>
                                          python
                                        </option>
                                        <option value="1" >
                                          java
                                        </option>
                                      </select>
                                    </div>
                                  )
                              }
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-5 col-12 ">
                              <div className="top_right_div h-23 p-4">
                                {/* <div className="live_body border-bottom border-top py-2">
                                  <button
                                    onClick={() =>
                                      newQuestion()}
                                    className="btn p-0 duplicate_btn d-block my-2 text-primary"
                                  >
                                    <img
                                      className="vector-icon18 me-1"
                                      alt="plus"
                                      src="/logos/fa6solidplus.svg"
                                    />{" "}
                                    New Question
                                  </button>
                                </div> */}
                                <div className="row parent_btn">
                                  <button
                                    onClick={handleEditCreate}
                                    className=" update"
                                    disabled={loading}
                                  >
                                    {loading ? <Spiner1 /> : "Save"}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          {
                            questionType === 'MCQ' ? (
                              <>
                                <div className="mt-4">
                                  <div className="question">
                                    <h3 id="question_text" className="fw-bold">
                                      Questions
                                    </h3>
                                    <div className="inner_question">
                                      <ReactQuill
                                        theme="snow"
                                        modules={editorConfig}
                                        value={questionValue}
                                        onChange={(newContent) => {
                                          setQuestionValue(newContent);
                                        }}
                                      />
                                    </div>
                                    {error === "**Question is required" ? (
                                      <span style={{ color: "red" }}>
                                        **Question is required
                                      </span>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="">
                                  <div className="Answer mt-4">
                                    <h3 id="question_text" className="fw-bold">
                                      Answers
                                    </h3>
                                    {myArray?.map((item, index) => (
                                      <div key={index} className="inner_answer">
                                        <div className="answer_tex_area">
                                          <div className="answer_top my-3">
                                            <div>
                                              <span className="me-3">
                                                Answer {index + 1}
                                              </span>
                                              <span className="me-2">
                                                <input
                                                  onChange={checkedCorrect}
                                                  name={index}
                                                  checked={item?.Correct_Answer === true ? true : false}
                                                  type="checkbox"
                                                  className="ans_checkbox "
                                                  key={index}
                                                />
                                                Correct Answer
                                              </span>
                                              <span>
                                                <input
                                                  checked={item?.Stick_to_bottom === true ? true : false}
                                                  onChange={checkedStick}
                                                  name={index}
                                                  type="checkbox"
                                                  className="ans_checkbox"
                                                  key={index}
                                                />
                                                Stick to bottom
                                              </span>
                                            </div>
                                            {index < 2 ? null :
                                              <div
                                                onClick={(e) => {
                                                  removeAnswer(e, index);
                                                }}
                                                className="delete_link"
                                              >
                                                <BiTrashAlt className="m-1 mb-2 deleteIcon" />
                                                Delete
                                              </div>
                                            }
                                          </div>

                                          <ReactQuill
                                            theme="snow"
                                            ref={answerStates[index]}
                                            modules={editorConfig}
                                            value={item?.answers || ""}
                                            onChange={(newEditorState) =>
                                              handleAnswerEditorStateChanges(
                                                index,
                                                newEditorState
                                              )
                                            }
                                          />
                                        </div>
                                        {error === "**Answer is required" ? (
                                          <span style={{ color: "red" }}>
                                            **Answer is required
                                          </span>
                                        ) : null}
                                        {error ===
                                          "Provide atleast one correct answer" ? (
                                          <span style={{ color: "red" }}>
                                            **Provide atleast one correct answer
                                          </span>
                                        ) : null}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                                <button
                                  onClick={handleAnswer}
                                  className="btn btn-outline-secondary px-4"
                                  id="answer_btn"
                                >
                                  Add Answer{" "}
                                  <img
                                    style={{ marginLeft: "5px" }}
                                    className="vector-icon18"
                                    alt="plus"
                                    src="/logos/fa6solidplus.svg"
                                  />
                                </button>
                                <div className="end_line"></div>
                              </>
                            ) : (
                              <div className="code_question my-4 border-bottom pb-3">

                                <h3 id="question_text" className="fw-bold">
                                  Questions
                                </h3>
                                <div className="code_inner_question">
                                  <ReactQuill
                                    theme="snow"
                                    placeholder="title"
                                    modules={editorConfig}
                                    value={questionTitle}
                                    onChange={(newContent) => {
                                      setQuestionTitle(newContent);
                                    }}
                                  />
                                </div>
                                {error === "**Question is required" ? (
                                  <span style={{ color: "red" }}>
                                    **Question is required
                                  </span>
                                ) : null} <div className="inner_question mt-3">
                                  <ReactQuill
                                    theme="snow"
                                    modules={editorConfig}
                                    value={questionValue}
                                    onChange={(newContent) => {
                                      setQuestionValue(newContent);
                                    }}
                                  />
                                </div>
                                {error === "**Question is required" ? (
                                  <span style={{ color: "red" }}>
                                    **Question is required
                                  </span>
                                ) : null}
                              </div>
                            )
                          }
                        </div>
                        {/* <button
                          onClick={() => {
                            
                          }}
                          className="btn btn-outline-secondary px-4"
                          id="create_question"
                        >
                          Create New Question{" "}
                          <img
                            style={{ marginLeft: "5px" }}
                            className="vector-icon18"
                            alt="plus"
                            src="/logos/fa6solidplus.svg"
                          />
                        </button> */}
                          <button
                                onClick={handleCreateNewQuestion}
                                className="btn btn-outline-secondary px-4"
                                id="create_question"
                              >
                                Create New Question{" "}
                                <img
                                  style={{ marginLeft: "5px" }}
                                  className="vector-icon18"
                                  alt="plus"
                                  src="/logos/fa6solidplus.svg"
                                />
                              </button>

                              <Modal show={showModal} onHide={() => setShowModal(false)}>
                                <Modal.Header closeButton>
                                  <Modal.Title>Create New Question</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  Are you sure, you want to Create New Question?
                                </Modal.Body>
                                <Modal.Footer>
                                  <Button variant="primary" onClick={handleSaveAndReset}>
                                    Save and Create New Question
                                  </Button>
                                  <Button variant="secondary" onClick={handleReset}>
                                    Create New Question
                                  </Button>
                                  
                                </Modal.Footer>
                              </Modal>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddQuestions;
