import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const PythonTest = ({ newSidedata, onSidedataChange }) => {
    const menuClick = () => {
        onSidedataChange(!newSidedata);
    };


    return (
        <div className="container-fluid">
            <button className="fa_bars  d-lg-none" onClick={menuClick}>
                <i className="fa-solid fa-bars fs-4"></i>
            </button>

            <div className="row">
                <div className="col-12 pb-4" style={{ borderBottom: '2px solid #CECECE' }}>
                    <div className="python_top_text">
                        <h4>Python Test</h4>
                        <p>Passed By <span>John Doe</span></p>
                    </div>
                </div>
                <div className="col-12 col-sm-10 pt-4">
                    <div className="question_box">
                        <h5 className='mb-4'>Question #1 <span> (Attention To Detail) </span></h5>
                        <div className='question_data_box'>
                            <ul className=' list-unstyled g-3 flex-wrap flex-md-nowrap d-flex align-items-center justify-content-between'>
                                <li>
                                    <p>Scrore - <span>2.5/10 </span></p>
                                </li>
                                <li>
                                    <p>Duration - <span> 1m40s </span></p>
                                </li>
                                <li>
                                    <p>Date - <span>01/07/2023 </span></p>
                                </li>
                                <li>
                                    <p>Time - <span>8:33:45 PM </span></p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="center_question_box mt-3 pb-3" style={{ borderBottom: '2px solid #CECECE' }}>
                        <h5 className=' heading_text_question border-bottom py-2'>
                            Question No.1
                        </h5>
                        <div className='mt-3'>
                            <h4>
                                Approach
                            </h4>
                            <p className='mb-3'>
                                Among continents, European countries accounted for the highest net worth of flower exports, with shipments amounting to $4 billion. The largest exporter in Europe was the Netherlands at $3.5 billion, followed by Italy.
                            </p>
                            <p>
                                Latin America (excluding Mexico) accounted for $2 billion of flower exports and represented the second-largest exporter region. The largest exporters in Latin America were Colombia at $1 billion and Ecuador at $800 million.
                            </p>
                        </div>

                        <div className='mt-4'>
                            <h4>
                                Complexity
                            </h4>
                            <p className='mb-3'>
                                Among continents, European countries accounted for the highest net worth of flower exports, with shipments amounting to $4 billion. The largest exporter in Europe was the Netherlands at $3.5 billion, followed by Italy.
                            </p>
                            <p>
                                Latin America (excluding Mexico) accounted for $2 billion of flower exports and represented the second-largest exporter region. The largest exporters in Latin America were Colombia at $1 billion and Ecuador at $800 million.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-12 pt-4">
                    <h5 className=' heading_text_question border-bottom py-2'>
                        Answer Given By The Candidate
                    </h5>
                </div>

                <div className="col-12 pt-4">
                    <h5 className=' heading_text_question border-bottom py-2'>
                        Result
                    </h5>
                    <div className='result_box'>
                        <ul className='list-unstyled d-flex align-items-center justify-content-between  m-3'>
                            <li className='d-flex align-items-center result_text'>
                                <p className='border-end pe-3'>Testcase</p>
                                <p className='ms-3 active'>Result</p>
                            </li>
                            <li className="d-flex align-items-center result_btn">
                                <button className='mx-4'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise " viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z" />
                                        <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                                    </svg></button>

                                <button> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-aspect-ratio" viewBox="0 0 16 16">
                                    <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h13A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5zM1.5 3a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5z" />
                                    <path d="M2 4.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1H3v2.5a.5.5 0 0 1-1 0zm12 7a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1 0-1H13V8.5a.5.5 0 0 1 1 0z" />
                                </svg>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>










            </div>


        </div>
    );
};

export default PythonTest;

