import React, { useMemo, useState } from "react";
import { IoArchiveSharp } from "react-icons/io5";
import { addToArchiveSingle, callRecordings } from "../../Redux/GetCallRecordings/action";
import { useDispatch, useSelector } from "react-redux";

const CallRecording = ({contact}) => {
  const [recordingData, setRecordingData] = useState(0);
  const recordings = useSelector((state) => state.getCallRecordings);
  const dispatch = useDispatch();
  useMemo(()=> dispatch(callRecordings(contact?.phone_primary)),[contact]);

 function getDateTime (time){
  const dateTimeObj = new Date(time);
  return dateTimeObj.toString();  
 }
 function addToarchiveData (data){
  setRecordingData(recordingData+1);
  dispatch(addToArchiveSingle(data)).then((res)=>{
    dispatch(callRecordings(contact?.phone_primary));
  }).catch((err)=>{
    console.log(err);
  });
 }

 const formatDateTime = (date) => {
  const dateTimeObj = new Date(date);
  const day = String(dateTimeObj.getDate()).padStart(2, '0');
  const month = String(dateTimeObj.getMonth() + 1).padStart(2, '0');
  const year = dateTimeObj.getFullYear();
  const formattedDate = `${day}.${month}.${year}`;
  let hours = dateTimeObj.getHours();
  const minutes = String(dateTimeObj.getMinutes()).padStart(2, '0');
  const seconds = String(dateTimeObj.getSeconds()).padStart(2, '0');
  const ampm = hours >= 12 ? ' PM' : ' AM';
  if (hours > 12) {
      hours -= 12;
  } else if (hours === 0) {
      hours = 12;
  }
  const formattedTime = `${String(hours).padStart(2, '0')}:${minutes}:${seconds}${ampm}`;
  return `${formattedDate} | ${formattedTime}`;
};
  
  return (
    <>
      <div className="profile_items_box_content p-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <p className="txt__xs fw-bold mb-0">
                {recordings?.data?.data?.length === 0 ? "Invitation has not been sent yet" : "Recordings"}
              </p>
              <div className="container-fluid"><div className="row"><div class="col"><p className="txt__xs mb-0 " style={{ marginLeft: '35%' }}>{recordings?.data?.message === "No data found" ? "No recordings available" : " "}  </p></div></div></div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3 mb-3">
        <div className="accordion recordings" id="accordionExample" >
          <div className="not_archive">
          {
            recordings?.isLoading && recordingData === 0 ? <div className="d-flex align-items-center justify-content-center"><span className="loader"></span></div> :
            recordings?.data?.data?.map((record,key)=> (
              !record.archive ?
              <div className="accordion-item mt-2" key={key}>
              <h2 className="accordion-header"  id={`header${record._id}`}>
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collapse${record._id}`}
                aria-expanded="true"
                aria-controls={`collapse${record._id}`}
              >
                <div className="innerAccordian_button">
                  <p className="text-bold">{formatDateTime(record?.call_start_time)}</p>
                  <button type="button" onClick={()=>addToarchiveData(record?._id)} className="btn btn-secondary btn-sm me-3">
                    Add to Archive
                  </button>
                </div>
              </button>
            </h2>
            <div
              id={`collapse${record._id}`}
              className={`accordion-collapse collapse`}
              aria-labelledby={`header${record._id}`}
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <div className="accordian_body_inner">
                  <p className="mt-3">Contacted On :</p>
                  <p className="fw-bold mt-1">{getDateTime(record?.call_start_time)}</p>
                  <p className="mt-3">HR Name :</p>
                  <p className="fw-bold mt-1">{record?.recruiter}</p>
                  <p className="mt-3">Duration :</p>
                  <p className="fw-bold mt-1">{record?.call_duration}</p>
                  <p className="mt-3">Recording :</p>
                  {record?.recording_url !== "" ? <a href={record?.recording_url} target="blank" className="text-primary">Recording Link</a> : <p>No recording found</p>}
                  <p className="mt-3">Call Reason :</p>
                  <p className="fw-bold mt-1">{record?.call_reason}</p>
                  <p className="mt-3">Call Outcome :</p>
                  <p className="fw-bold mt-1">{record?.call_outcome}</p>
                  <p className="mt-3">Tags :</p>
                  <p className="fw-bold mt-1">
                    frontend, react, kolkata, dayshift
                  </p>
                  <p className="mt-3">Call Notes :</p>
                  <p className="fw-bold mt-1">{record?.recruiter_notes}</p>
                </div>
              </div>
            </div>
          </div> : null
            ))
          }
          </div>
          <div className="archived">
          {recordings?.data?.data?.length !== 0 && recordings?.data !== null ? <h5 className="archived d-flex align-items-center mt-5"><span className="archive__icon"><IoArchiveSharp className=""/></span><span className="ms-2">Archived</span></h5> : null}
          
          {
            recordings?.data?.data?.map((record,key)=> (
              record.archive ?
              <div className="accordion-item mt-3" key={key}>
              <h2 className="accordion-header" id={`header${record._id}`}>
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collapse${record._id}`}
                aria-expanded="true"
                aria-controls={`collapse${record._id}`}
              >
                <div className="innerAccordian_button">
                  <p className="text-bold">{formatDateTime(record?.call_start_time)}</p>
                    <button type="button" onClick={()=>addToarchiveData(record?._id)} className="btn btn-success btn-sm me-3">
                      Move to Recordings
                    </button>
                </div>
              </button>
            </h2>
            <div
              id={`collapse${record._id}`}
              className="accordion-collapse collapse"
              aria-labelledby={`header${record._id}`}
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
              <div className="accordian_body_inner">
                  <p className="mt-3">Contacted On :</p>
                  <p className="fw-bold mt-1">{getDateTime(record?.call_start_time)}</p>
                  <p className="mt-3">HR Name :</p>
                  <p className="fw-bold mt-1">{record?.recruiter}</p>
                  <p className="mt-3">Duration :</p>
                  <p className="fw-bold mt-1">{record?.call_duration}</p>
                  <p className="mt-3">Recording :</p>
                  {record?.recording_url !== "" ? <a href={record?.recording_url}>Recording Link</a> : <p>No recording found</p>}
                  <p className="mt-3">Call Reason :</p>
                  <p className="fw-bold mt-1">{record?.call_reason}</p>
                  <p className="mt-3">Call Outcome :</p>
                  <p className="fw-bold mt-1">{record?.call_outcome}</p>
                  <p className="mt-3">Tags :</p>
                  <p className="fw-bold mt-1">
                    frontend, react, kolkata, dayshift
                  </p>
                  <p className="mt-3">Call Notes :</p>
                  <p className="fw-bold mt-1">{record?.recruiter_notes}</p>
                </div>
              </div>
            </div>
          </div> : null
            ))
          }
          </div>
        </div>
      </div>
    </>
  );
};
export default CallRecording;
