
import axios from "axios";
import {
    ADD_QUESTION_REQUEST,
    ADD_QUESTION_SUCCESS,
    ADD_QUESTION_FAILURE,
    COPY_QUESTION_REQUEST,
    COPY_QUESTION_SUCCESS,
    COPY_QUESTION_FAILURE,
    GET_SPECIFIC_QUES_REQUEST,
    GET_SPECIFIC_QUES_SUCCESS,
    GET_SPECIFIC_QUES_FAILURE,
    UPDATE_QUESTON_REQUEST,
    UPDATE_QUESTON_SUCCESS,
    UPDATE_QUESTON_FAILURE,
    GET_QUESTION_REQUEST,
    GET_QUESTION_SUCCESS,
    GET_QUESTION_FAILURE,
    GET_QUESTION_APPLICANT_REQUEST,
    GET_QUESTION_APPLICANT_SUCCESS,
    GET_QUESTION_APPLICANT_FAILURE,
    DELETE_QUESTION_REQUEST,
    DELETE_QUESTION_SUCCESS,
    DELETE_QUESTION_FAILURE,
    CORRECT_ANSWER_REQUEST,
    CORRECT_ANSWER_SUCCESS,
    CORRECT_ANSWER_FAILURE,
    CATEGORY_MARKS_REQUEST,
    CATEGORY_MARKS_SUCCESS,
    CATEGORY_MARKS_FAILURE
} from "./actionType";
import { toast } from "react-toastify";
import { errorCall } from "../ErrorHandling/action";


export const Question_Add_Req = (() => ({
    type: ADD_QUESTION_REQUEST,
}))
export const Question_Add_Succ = ((data) => ({
    type: ADD_QUESTION_SUCCESS,
    payload: data
}))
export const Question_Add_Fail = ((error) => ({
    type: ADD_QUESTION_FAILURE,
    payload: error
}))

export const postQuestion = (webToken, navigate, id, qesCategory, questionValue, answerValues, isRadio1, isRadio2, catId, urlPath, isCreateQuestion, questionTitle) => {
    return async function test(dispatch, getState) {
        dispatch(Question_Add_Req());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/addquestion/${id}`, {
                qesCategory: qesCategory,
                question: questionValue,
                answers
                    : answerValues,
                shuffleAnswers: isRadio2,
                selectAnswerType: isRadio1,
                catId: catId,
                logicalTestTitle: questionTitle
            }, {
                headers: {
                    Authorization: `Bearer ${webToken}`,
                }
            }
            );
            if (response.data) {
                dispatch(Question_Add_Succ(response.data));
                const url = urlPath;
                const baseUrl = process.env.REACT_APP_FRONTEDND_DOMAIN
                const parts = url?.split(baseUrl);
                const setTestId = parts[1]?.replace("/test-edit", "")
                let splitUrl = setTestId?.split('/');
                splitUrl = splitUrl?.filter((e) => {
                    return e !== "" && e
                })
                if (splitUrl?.length > 0) {
                    var urlValue = splitUrl[0];
                }

                toast.success("Question Added Successfully")
                if (isCreateQuestion != true) {
                    // setTimeout(() => {
                    navigate(`/test/question-edit/${response?.data?.result._id}/${response?.data?.result.testID}`, {
                        // navigate(`/test/create-test-screen/${response?.data?.result.testID}`, {
                        headers: {
                            Authorization: `Bearer ${webToken}`,
                        }
                    })

                    // }, 600);
                }
            }
        } catch (error) {
            if (error?.message == "Network Error" || error?.response?.status == 500) {
                dispatch(errorCall(error))
                return
            }
            dispatch(Question_Add_Fail(error));
            if (error?.response?.data?.message === "Unauthenticated") {
                localStorage.removeItem("token");
                navigate("/login");
            }
        }
    };
};




export const Copy_Question_Req = (() => ({
    type: COPY_QUESTION_REQUEST,
}))
export const Copy_Question_Succ = ((data) => ({
    type: COPY_QUESTION_SUCCESS,
    payload: data
}))
export const Copy_Question_Fail = ((error) => ({
    type: COPY_QUESTION_FAILURE,
    payload: error
}))

export const copyQuestion = (webToken, id, testid, navigate) => {
    return async function test(dispatch, getState) {
        dispatch(Copy_Question_Req());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/copyquestion/${testid}/${id}`, {}, {
                headers: {
                    Authorization: `Bearer ${webToken}`,
                }
            }
            );
            if (response.data) {
                dispatch(Copy_Question_Succ(response.data));
                toast.success("Question Cloned Successfully")
            }
        } catch (error) {
            if (error?.message == "Network Error" || error?.response?.status == 500) {
                dispatch(errorCall(error))
                return
            }
            dispatch(Copy_Question_Fail(error));
            if (error?.response?.data?.message === "Unauthenticated") {
                localStorage.removeItem("token");
                navigate("/login");
            }
        }
    };
};



export const Get_Speicific_Ques_Req = (() => ({
    type: GET_SPECIFIC_QUES_REQUEST,
}))
export const Get_Speicific_Ques_Succ = ((data) => ({
    type: GET_SPECIFIC_QUES_SUCCESS,
    payload: data
}))
export const Get_Speicific_Ques_Fail = ((error) => ({
    type: GET_SPECIFIC_QUES_FAILURE,
    payload: error
}))

//specific question by question id
export const getSpecificQuestion = (webToken, ques_id, navigate) => {
    return async function (dispatch, getState) {
        dispatch(Get_Speicific_Ques_Req());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/quest/${ques_id}`, {
                headers: {
                    Authorization: `Bearer ${webToken}`,
                }
            }
            );
            dispatch(Get_Speicific_Ques_Succ(response.data));
            if (response.data) {
                //  setIsLoading(false) 
            }
        } catch (error) {
            if (error?.message == "Network Error" || error?.response?.status == 500 || error?.response?.status == 404) {
                dispatch(errorCall(error))
                return
            }
            dispatch(Get_Speicific_Ques_Fail(error));
            if (error?.response?.data?.message === "Unauthenticated") {
                localStorage.removeItem("token");
                navigate("/login");
            }
        }
    };
};





export const Update_Question_Req = (() => ({
    type: UPDATE_QUESTON_REQUEST,
}))
export const Update_Question_Succ = ((data) => ({
    type: UPDATE_QUESTON_SUCCESS,
    payload: data
}))
export const Update_Question_Fail = ((error) => ({
    type: UPDATE_QUESTON_FAILURE,
    payload: error
}))


export const updateQuestion = (webToken, navigate, quesId, qesCategory, questionValue, myArray, isSingleCorrect, isSuffleAnswer, catId, urlPath, questionTitle) => {
    return async function (dispatch, getState) {
        dispatch(Update_Question_Req());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/editquestion/${quesId}`, {
                qesCategory: qesCategory,
                question: questionValue,
                answers: myArray,
                shuffleAnswers: isSuffleAnswer,
                selectAnswerType: isSingleCorrect,
                categoryId: catId,
                logicalTestTitle: questionTitle
            }, {
                headers: {
                    Authorization: `Bearer ${webToken}`,
                }
            }
            );
            // Dispatch an action to store the response data in the Redux store
            if (response?.data) {
                dispatch(Update_Question_Succ(response?.data));
                toast.success("Question Updated");
                const url = urlPath;
                const baseUrl = process.env.REACT_APP_FRONTEDND_DOMAIN
                const parts = url?.split(baseUrl);
                const setTestId = parts[1]?.replace("/test-edit", "")
                let splitUrl = setTestId?.split('/');
                splitUrl = splitUrl?.filter((e) => {
                    return e !== "" && e
                })
                if (splitUrl?.length > 0) {
                    var urlValue = splitUrl[0];
                }

                // setTimeout(() => {
                navigate(`/test/create-test-screen/${response?.data?.result?.testID}`)
                // }, 600)
            } else {
                toast.warn("failed to update")
            }

        } catch (error) {
            if (error?.message == "Network Error" || error?.response?.status == 500) {
                dispatch(errorCall(error))
                return
            }
            dispatch(Update_Question_Fail(error));
            toast.warn(error.response?.data?.msg);
            if (error?.response?.data?.message === "Unauthenticated") {
                localStorage.removeItem("token");
                navigate("/login");
            }
        }
    };
};




export const Get_Question_Req = (() => ({
    type: GET_QUESTION_REQUEST,
}))
export const Get_Question_Succ = ((data) => ({
    type: GET_QUESTION_SUCCESS,
    payload: data
}))
export const Get_Question_Fail = ((error) => ({
    type: GET_QUESTION_FAILURE,
    payload: error
}))

//get quest testid
export const getQuestion = (webToken, id, navigate) => {
    return async function getQuestionThunk(dispatch, getState) {
        dispatch(Get_Question_Req());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/testidqes/${id}`, {
                headers: {
                    Authorization: `Bearer ${webToken}`,
                }
            }
            ); if (response.data) {
                dispatch(Get_Question_Succ(response.data));
            }
        } catch (error) {
            if (error?.message == "Network Error" || error?.response?.status == 500 || error?.response?.status == 404) {
                dispatch(errorCall(error))
                return
            }
            dispatch(Get_Question_Fail(error));
            if (error?.response?.data?.message === "Unauthenticated") {
                localStorage.removeItem("token");
                navigate("/login");
            }
        }
    };
};

export const Get_Question_Applicant_Req = (() => ({
    type: GET_QUESTION_APPLICANT_REQUEST,
}))
export const Get_Question_Applicant_Succ = ((data) => ({
    type: GET_QUESTION_APPLICANT_SUCCESS,
    payload: data
}))
export const Get_Question_Applicant_Fail = ((error) => ({
    type: GET_QUESTION_APPLICANT_FAILURE,
    payload: error
}))


export const getQuestionApplicant = (id, navigate) => {
    return async function getQuestionThunk(dispatch, getState) {
        dispatch(Get_Question_Applicant_Req());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_BASE_URL}api/applicant/testidqesapplicant/${id}`,
            ); if (response.data) {
                dispatch(Get_Question_Applicant_Succ(response.data));
            }
        } catch (error) {
            if (error?.message == "Network Error" || error?.response?.status == 500) {
                dispatch(errorCall(error))
                return
            }
            dispatch(Get_Question_Applicant_Fail(error));
            if (error?.response?.data?.message === "Unauthenticated") {
                localStorage.removeItem("token");
                navigate("/login");
            }
        }
    };
};



export const Delete_Question_Req = (() => ({
    type: DELETE_QUESTION_REQUEST,
}))
export const Delete_Question_Succ = ((data) => ({
    type: DELETE_QUESTION_SUCCESS,
    payload: data
}))
export const Delete_Question_Fail = ((error) => ({
    type: DELETE_QUESTION_FAILURE,
    payload: error
}))

export const deleteQuestion = (webToken, id, testid, categoryId, navigate) => {
    return async function test(dispatch, getState) {
        dispatch(Delete_Question_Req());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/deletequestion/${testid}/${id}`, { catId: categoryId }, {
                headers: {
                    Authorization: `Bearer ${webToken}`,
                }
            },

            )
            if (response.data) {
                toast.success("Deleted Question Successfully")
                dispatch(Delete_Question_Succ(response.data.msg))
            }
        } catch (error) {
            if (error?.message == "Network Error" || error?.response?.status == 500) {
                dispatch(errorCall(error))
                return
            }
            dispatch(Delete_Question_Fail(error));
            if (error?.response?.data?.message === "Unauthenticated") {
                localStorage.removeItem("token");
                navigate("/login");
            }
        };
    };
};



export const Correct_Answer_Req = (() => ({
    type: CORRECT_ANSWER_REQUEST,
}))
export const Correct_Answer_Succ = ((data) => ({
    type: CORRECT_ANSWER_SUCCESS,
    payload: data
}))
export const Correct_Answer_Fail = ((error) => ({
    type: CORRECT_ANSWER_FAILURE,
    payload: error
}))

export const showCorrectAnswer = (webToken, id, navigate) => {
    return async function test(dispatch, getState) {
        dispatch(Correct_Answer_Req());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/testqes/${id}`, {
                headers: {
                    Authorization: `Bearer ${webToken}`,
                }
            }
            )
            if (response.data) {
                dispatch(Correct_Answer_Succ(response?.data?.result))
            }
        } catch (error) {
            if (error?.message == "Network Error" || error?.response?.status == 500) {
                dispatch(errorCall(error))
                return
            }
            dispatch(Correct_Answer_Fail(error));
            if (error?.response?.data?.message === "Unauthenticated") {
                localStorage.removeItem("token");
                navigate("/login");
            }
        };
    };
};


// export const Category_Marks_Req = (() => ({
//     type: CATEGORY_MARKS_REQUEST,
// }))
// export const Category_Marks_Succ = ((data) => ({
//     type: CATEGORY_MARKS_SUCCESS,
//     payload: data
// }))
// export const Category_Marks_Fail = ((error) => ({
//     type: CATEGORY_MARKS_FAILURE,
//     payload: error
// }))


//not in use
// export const categoryWiseMarks = (webToken, testId, resultId, navigate) => {
//     return async function test(dispatch, getState) {
//         dispatch(Category_Marks_Req());
//         try {
//             const response = await axios.get(
//                 `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/categorywisemarks/${testId}/${resultId}`, {
//                 headers: {
//                     Authorization: `Bearer ${webToken}`,
//                 }
//             }
//             )
//             if (response.data) {
//                 dispatch(Category_Marks_Succ(response?.data?.categoryWiseData))
//             }
//         } catch (error) {
//             if(error?.message=="Network Error" || error?.response?.status==500){
//                 dispatch(errorCall(error))
//                 return
//             }
//             dispatch(Category_Marks_Fail(error));
//             if (error?.response?.data?.message === "Unauthenticated") {
//                 localStorage.removeItem("token");
//                 navigate("/login");
//             }
//             return error
//         };
//     };
// };
//not in use