import {
    ADD_QUESTION_REQUEST,
    ADD_QUESTION_SUCCESS,
    ADD_QUESTION_FAILURE,
    COPY_QUESTION_REQUEST,
    COPY_QUESTION_SUCCESS,
    COPY_QUESTION_FAILURE,
    GET_SPECIFIC_QUES_REQUEST,
    GET_SPECIFIC_QUES_SUCCESS,
    GET_SPECIFIC_QUES_FAILURE,
    UPDATE_QUESTON_REQUEST,
    UPDATE_QUESTON_SUCCESS,
    UPDATE_QUESTON_FAILURE,
    GET_QUESTION_REQUEST,
    GET_QUESTION_SUCCESS,
    GET_QUESTION_FAILURE,
    GET_QUESTION_APPLICANT_REQUEST,
    GET_QUESTION_APPLICANT_SUCCESS,
    GET_QUESTION_APPLICANT_FAILURE,
    DELETE_QUESTION_REQUEST,
    DELETE_QUESTION_SUCCESS,
    DELETE_QUESTION_FAILURE,
    CORRECT_ANSWER_REQUEST,
    CORRECT_ANSWER_SUCCESS,
    CORRECT_ANSWER_FAILURE,
    // CATEGORY_MARKS_REQUEST,
    // CATEGORY_MARKS_SUCCESS,
    // CATEGORY_MARKS_FAILURE
} from "./actionType";

const initialState = {
    count: 0,
    name: "",
    data: [],
    qdata: {},
    testQuesData: {},
    testQuesAppData: {},
    localStorageDAta: [],
    validationError: "",
    testId: "",
    errors: {},
    deleteData: {},
    correctData: [],
    copiedQuesData: {},
    questionData: {},
    questionUpdateData: {},

    questionIsLoading: false,
    copyQuestIsLoading: false,
    specificQuestLoading: false,
    updateQuestLoading: false,
    getQuestionLoading: false,
    applicantQuestLoading: false,
    deleteQuestLoading: false,
    correctAnsLoading: false,
    // categMarksLoading: false,

    questionError: false,
    copyQuestError: false,
    specificQuestError: false,
    updateQuestError: false,
    getQuestionError: false,
    aaplicantQuestError: false,
    deleteQuestError: false,
    deleteAnsError: false,
    // categMarksError: false,

    questionErrorData: "",
    copyQuestErrorData: "",
    specificQuestErrorData: "",
    updateQuestErrorData: "",
    getQuestionErrorData: "",
    aaplicantQuestErrorData: "",
    deleteQuestErrorData: "",
    deleteAnsErrorData: "",
    // categMarksErrorData: "",

}

export const Question_Reducer = (state = initialState, action) => {
    switch (action.type) {


        case ADD_QUESTION_REQUEST: {
            return {
                ...state,
                questionIsLoading: true,
                qdata: {},
                questionError: false
            };
        }
        case ADD_QUESTION_SUCCESS: {
            return {
                ...state,
                questionIsLoading: false,
                qdata: action.payload,
                questionError: false
            };
        }
        case ADD_QUESTION_FAILURE: {
            return {
                ...state,
                questionIsLoading: false,
                qdata: {},
                questionErrorData: action.payload,
                questionError: true
            };
        }


        case COPY_QUESTION_REQUEST: {
            return {
                ...state,
                copyQuestIsLoading: true,
                copiedQuesData: {},
                copyQuestError: false
            };
        }
        case COPY_QUESTION_SUCCESS: {
            return {
                ...state,
                copyQuestIsLoading: false,
                copiedQuesData: action.payload,
                copyQuestError: false
            };
        }
        case COPY_QUESTION_FAILURE: {
            return {
                ...state,
                copyQuestIsLoading: false,
                copiedQuesData: {},
                copyQuestErrorData: action.payload,
                copyQuestError: true
            };
        }


        case GET_SPECIFIC_QUES_REQUEST: {
            return {
                ...state,
                specificQuestLoading: true,
                questionData: {},
                specificQuestError: false
            };
        }
        case GET_SPECIFIC_QUES_SUCCESS: {
            return {
                ...state,
                specificQuestLoading: false,
                questionData: action.payload,
                specificQuestError: false
            };
        }
        case GET_SPECIFIC_QUES_FAILURE: {
            return {
                ...state,
                specificQuestLoading: false,
                questionData: {},
                specificQuestErrorData: action.payload,
                specificQuestError: true
            };
        }

        case UPDATE_QUESTON_REQUEST: {
            return {
                ...state,
                updateQuestLoading: true,
                questionUpdateData: {},
                updateQuestError: false
            };
        }
        case UPDATE_QUESTON_SUCCESS: {
            return {
                ...state,
                updateQuestLoading: false,
                questionUpdateData: action.payload,
                updateQuestError: false
            };
        }
        case UPDATE_QUESTON_FAILURE: {
            return {
                ...state,
                updateQuestLoading: false,
                questionUpdateData: {},
                updateQuestErrorData: action.payload,
                updateQuestError: true
            };
        }


        case GET_QUESTION_REQUEST: {
            return {
                ...state,
                getQuestionLoading: true,
                testQuesData: {},
                getQuestionError: false
            };
        }
        case GET_QUESTION_SUCCESS: {
            return {
                ...state,
                getQuestionLoading: false,
                testQuesData: action.payload,
                getQuestionError: false
            };
        }
        case GET_QUESTION_FAILURE: {
            return {
                ...state,
                getQuestionLoading: false,
                testQuesData: {},
                getQuestionErrorData: action.payload,
                getQuestionError: true
            };
        }


        case GET_QUESTION_APPLICANT_REQUEST: {
            return {
                ...state,
                applicantQuestLoading: true,
                testQuesAppData: {},
                aaplicantQuestError: false
            };
        }
        case GET_QUESTION_APPLICANT_SUCCESS: {
            return {
                ...state,
                applicantQuestLoading: false,
                testQuesAppData: action.payload,
                aaplicantQuestError: false
            };
        }
        case GET_QUESTION_APPLICANT_FAILURE: {
            return {
                ...state,
                applicantQuestLoading: false,
                testQuesAppData: {},
                aaplicantQuestErrorData: action.payload,
                aaplicantQuestError: true
            };
        }


        case DELETE_QUESTION_REQUEST: {
            return {
                ...state,
                deleteQuestLoading: true,
                deleteData: {},
                deleteQuestError: false
            };
        }
        case DELETE_QUESTION_SUCCESS: {
            return {
                ...state,
                deleteQuestLoading: false,
                deleteData: action.payload,
                deleteQuestError: false
            };
        }
        case DELETE_QUESTION_FAILURE: {
            return {
                ...state,
                deleteQuestLoading: false,
                deleteData: {},
                deleteQuestErrorData: action.payload,
                deleteQuestError: true
            };
        }


        case CORRECT_ANSWER_REQUEST: {
            return {
                ...state,
                correctAnsLoading: true,
                correctData: {},
                deleteAnsError: false
            };
        }
        case CORRECT_ANSWER_SUCCESS: {
            return {
                ...state,
                correctAnsLoading: false,
                correctData: action.payload,
                deleteAnsError: false
            };
        }
        case CORRECT_ANSWER_FAILURE: {
            return {
                ...state,
                correctAnsLoading: false,
                correctData: {},
                deleteAnsErrorData: action.payload,
                deleteAnsError: true
            };
        }


        // case CATEGORY_MARKS_REQUEST: {
        //     return {
        //         ...state,
        //         categMarksLoading: true,
        //         marksData: null,
        //         categMarksError: false
        //     };
        // }
        // case CATEGORY_MARKS_SUCCESS: {
        //     return {
        //         ...state,
        //         categMarksLoading: false,
        //         marksData: action.payload,
        //         categMarksError: false
        //     };
        // }
        // case CATEGORY_MARKS_FAILURE: {
        //     return {
        //         ...state,
        //         categMarksLoading: false,
        //         marksData: null,
        //         categMarksErrorData: action.payload,
        //         categMarksError: true
        //     };
        // }

        default: {
            return state;
        }
    }
}