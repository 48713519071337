import axios from "axios";
import { errorCall } from "../ErrorHandling/action";
import { GLOBAL_SEARCH_FAILURE, GLOBAL_SEARCH_REQUEST, GLOBAL_SEARCH_SUCCESS } from "./actionType"

export const searchReq = () => ({
    type: GLOBAL_SEARCH_REQUEST
});
export const searchSuc = (data) => ({
    type: GLOBAL_SEARCH_SUCCESS,
    payload: data
});
export const serachFail = (error) => ({
    type: GLOBAL_SEARCH_FAILURE,
    payload: error
});


export const globalSearch = (webToken, navigate, searchValue) => async (dispatch) => {
    dispatch(searchReq());
    try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/globalsearch`, {
            searchValue
        }, {
            headers: {
                Authorization: `Bearer ${webToken}`,
            }
        })
        if (response.data) {
            dispatch(searchSuc(response.data));
        }
    } catch (error) {
        if (error?.message === "Network Error" || error?.response?.status === 500) {
            dispatch(errorCall(error))
            return;
        }
        dispatch(serachFail(error))
        if (error?.response?.data?.message === "Unauthenticated") {
            localStorage.removeItem("token");
            navigate("/login");
        }
    }
};