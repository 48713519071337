import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { errorCall } from '../../Redux/ErrorHandling/action';
import { useDispatch } from 'react-redux';

const NotImplemented = (props) => {
    const dispatch=useDispatch()
    const navigate = useNavigate();
    const path=useLocation().pathname
    const handleRefreshClick = () => {
      // this squence should not change
      // 1st
      navigate("/");
      // 2nd
      dispatch(errorCall());
      props?.setNotFoundSidebar(false)
    };
    // useEffect(() => {
    //     props?.setNotFoundSidebar(!props?.notFoundSidebar);
    // }, [])
    window.onpopstate = function(event) {
        window.location.reload();
        // dispatch(errorCall())
        // alert("Back button was clicked!",event); 
    };
    useEffect(() => {
        props?.setNotFoundSidebar(!props?.notFoundSidebar);
    }, [])
    return (
        <>
            <div className="network_eror_page position-relative">
                <img src="/noImpliment.png" alt="" className='Ellipse_img' />
                <img className=' error_log' src="/site_logo.png" alt="" />
                <div className='network_error_box d-flex align-items-center justify-content-center h-100 p-4' >

                    <div className='d-flex align-items-center justify-content-center' style={{
                    maxWidth: '697px',
                    minHeight: '437px',
                }}>
                        <img src="/Ellipse.png" alt="" className='network_logo' />

                        <div className=" error_content mt-5">
                            <h2 className="display-4" style={{ fontSize: '43px' }}>Not Implemented!</h2>
                            <p className=" text-start">Sorry, the feature you're looking for is not implemented yet.
                                Our team is working on it! In the meantime,
                                feel free to explore other parts of our website.</p>
                            <div className='network_btn d-flex align-items-center justify-content-start '>
                                <button onClick={handleRefreshClick}>
                                    Go Back To Home
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default NotImplemented;

