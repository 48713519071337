import axios from "axios";
import { RECORDING_archive_FAILURE, RECORDING_archive_REQUEST, RECORDING_archive_SUCCESS, RECORDING_GET_FAILURE, RECORDING_GET_REQUEST, RECORDING_GET_SUCCESS } from "./actionType";


export const get_recording_req = ()=>({
  type: RECORDING_GET_REQUEST
});

export const get_recording_succ = (data)=>({
  type: RECORDING_GET_SUCCESS,
  payload : data
});

export const get_recording_failure = (err)=>({
  type: RECORDING_GET_FAILURE,
  payload : err
});

export const get_archive_req = ()=>({
  type: RECORDING_archive_REQUEST
});

export const get_archive_succ = (data)=>({
  type: RECORDING_archive_SUCCESS,
  payload : data
});

export const get_archive_failure = (err)=>({
  type: RECORDING_archive_FAILURE,
  payload : err
});

export const addToArchiveSingle = (id) => async(dispatch) => {
  try {
    dispatch(get_archive_req());
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      }
    }
    return await axios.get(`${process.env.REACT_APP_BASE_URL}/api/auth/makeArchive/${id}`,config);
    dispatch(get_archive_succ());
  } catch (error) {
    console.log(error);
    dispatch(get_archive_failure());
  }
}





export const callRecordings = (phoneNumber) => async(dispatch) => {
  try {
    dispatch(get_recording_req());
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    params: {
      phoneNumber: phoneNumber
    }
  }

 let data = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/auth/getCallRecordings`,config);
 dispatch(get_recording_succ(data.data));
  } catch (error) {
    console.log(error);
    dispatch(get_recording_failure());
  }
}