// export const RESULT_DATA_REQUEST = "RESULT_DATA_REQUEST"
// export const RESULT_DATA_SUCCESS = "RESULT_DATA_SUCCESS"
// export const RESULT_DATA_FAILURE = "RESULT_DATA_FAILURE"

export const RESULT_SINGLE_REQUEST = "RESULT_SINGLE_REQUEST"
export const RESULT_SINGLE_SUCCESS = "RESULT_SINGLE_SUCCESS"
export const RESULT_SINGLE_FAILURE = "RESULT_SINGLE_FAILURE"

export const RESULT_SINGLE_APPLICANT_REQUEST = "RESULT_SINGLE_APPLICANT_REQUEST"
export const RESULT_SINGLE_APPLICANT_SUCCESS = "RESULT_SINGLE_APPLICANT_SUCCESS"
export const RESULT_SINGLE_APPLICANT_FAILURE = "RESULT_SINGLE_APPLICANT_FAILURE"

// export const RESULT_REQUEST = "RESULT_REQUEST"
// export const RESULT_SUCCESS = "RESULT_SUCCESS"
// export const RESULT_FAILURE = "RESULT_FAILURE"

// export const PAGINATION_REQUEST = "PAGINATION_REQUEST"
// export const PAGINATION_SUCCESS = "PAGINATION_SUCCESS"
// export const PAGINATION_FAILURE = "PAGINATION_FAILURE"

export const POST_RESULT_REQUEST = "POST_RESULT_REQUEST"
export const POST_RESULT_SUCCESS = "POST_RESULT_SUCCESS"
export const POST_RESULT_FAILURE = "POST_RESULT_FAILURE"

export const UPDATE_RESULT_REQUEST = "UPDATE_RESULT_REQUEST"
export const UPDATE_RESULT_SUCCESS = "UPDATE_RESULT_SUCCESS"
export const UPDATE_RESULT_FAILURE = "UPDATE_RESULT_FAILURE"

export const UPDATE_MARKS_REQUEST = "UPDATE_MARKS_REQUEST"
export const UPDATE_MARKS_SUCCESS = "UPDATE_MARKS_SUCCESS"
export const UPDATE_MARKS_FAILURE = "UPDATE_MARKS_FAILURE"

export const ARCHIVE_REQUEST = "ARCHIVE_REQUEST"
export const ARCHIVE_SUCCESS = "ARCHIVE_SUCCESS"
export const ARCHIVE_FAILURE = "ARCHIVE_FAILURE"

export const UNARCHIVE_ALL_REQUEST = "UNARCHIVE_ALL_REQUEST"
export const UNARCHIVE_ALL_SUCCESS = "UNARCHIVE_ALL_SUCCESS"
export const UNARCHIVE_ALL_FAILURE = "UNARCHIVE_ALL_FAILURE"

// export const DOWNLOAD_RESULT_REQUEST = "DOWNLOAD_RESULT_REQUEST"
// export const DOWNLOAD_RESULT_SUCCESS = "DOWNLOAD_RESULT_SUCCESS"
// export const DOWNLOAD_RESULT_FAILURE = "DOWNLOAD_RESULT_FAILURE"

// export const AVERAGE_RESULT_REQUEST = "AVERAGE_RESULT_REQUEST"
// export const AVERAGE_RESULT_SUCCESS = "AVERAGE_RESULT_SUCCESS"
// export const AVERAGE_RESULT_FAILURE = "AVERAGE_RESULT_FAILURE"

export const RESULT_SEARCH_REQUEST = "RESULT_SEARCH_REQUEST"
export const RESULT_SEARCH_SUCCESS = "RESULT_SEARCH_SUCCESS"
export const RESULT_SEARCH_FAILURE = "RESULT_SEARCH_FAILURE"

export const ALL_RESULT_REQUEST = "ALL_RESULT_REQUEST"
export const ALL_RESULT_SUCCESS = "ALL_RESULT_SUCCESS"
export const ALL_RESULT_FAILURE = "ALL_RESULT_FAILURE"

export const ANS_SHEET_REQUEST = "ANS_SHEET_REQUEST,"
export const ANS_SHEET_SUCCESS = "  ANS_SHEET_SUCCESS,"
export const ANS_SHEET_FAILURE = "  ANS_SHEET_FAILURE,"

