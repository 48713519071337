import { UPDATE_SOURCE_FAILURE, UPDATE_SOURCE_REQUEST, UPDATE_SOURCE_SUCCESS } from "./actionType"

const initialState = {
    isLoading: false,
    data: [],
    isError: false,
    errorData: ""
}

export const updateSource = (state = initialState,action)=>{
     switch(action.type){
        case UPDATE_SOURCE_REQUEST:{
           return{
            isLoading : true,
            data : [],
            isError : false
           }
        }
        case UPDATE_SOURCE_SUCCESS:{
            return{
                isLoading : false,
                data : action.payload,
                isError : false,
            }
        }
        case UPDATE_SOURCE_FAILURE:{
            return{
                isLoading : false,
                data : [],
                isError : true,
                errorData:action.payload
            }
        }
        default : {
            return state
         }
     }
}