import React, { useState } from "react";
import { LiaCloudUploadAltSolid } from "react-icons/lia";
import Button from "react-bootstrap/Button";
import { FileUploader } from "react-drag-drop-files";
import { toast } from "react-toastify";

const fileTypes = ["PDF", "DOC", "DOCX"];

const UploadCV = ({ getFileData }) => {
  const [files, setFiles] = useState("");
  const [base64, setBase64] = useState("");
  const handleChange = (file) => {
    if (file) {
      setFiles(file.name);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setBase64(reader.result);
      };
      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };
    }
  };

  const onError = (err) => {
    if (err) {
      toast.error(err);
    }
  };

  const handleClick = () => {
    if (base64) {
      toast.success("File uploaded");
      getFileData(base64);

      console.log(base64);
    } else {
      toast.error("please upload a file");
    }
  };

  return (
    <>
      <div className="upload-wrap">
        <FileUploader
          handleChange={handleChange}
          name="file"
          types={fileTypes}
          children={layout}
          onTypeError={onError}
        />
        <p className="upload-file-name">{files}</p>
        <Button variant="primary" className="upload-btn" onClick={handleClick}>
          Upload
        </Button>
      </div>
    </>
  );
};

export default UploadCV;

const layout = (
  <>
    <div className="upload-wrap">
      <div className="upload-title">
        <h5>Upload CV</h5>
      </div>
      <div className="upload-body">
        <LiaCloudUploadAltSolid className="upload-icon" />
        <p>
          Drag & drop files or <span>Browse</span>
        </p>
        <p style={{ fontWeight: "normal" }}>Supported fromats: PDF,DOC,WORD</p>
      </div>
    </div>
  </>
);
