import axios from "axios";
import { GET_DESIGNATION_LIST_FAILURE, GET_DESIGNATION_LIST_REQUEST, GET_DESIGNATION_LIST_SUCCESS } from "./actionType";
import { errorCall } from "../../ErrorHandling/action";
// import { designation_failure, designation_req, designation_succ } from "../../CandidateReducer/action";

export const designation_req = () => ({
  type: GET_DESIGNATION_LIST_REQUEST
});

export const designation_succ = (data) => ({
  type: GET_DESIGNATION_LIST_SUCCESS,
  payload: data
})
export const designation_failure = (err) => ({
  type: GET_DESIGNATION_LIST_FAILURE,
  payload: err
})
const getLocaldata = () => {
  const token = localStorage.getItem("token");
  return token
}

// export const designationList = (navigate) => (dispatch) => {
//   dispatch(designation_req());
//   const mt = getLocaldata()
//   const config = {
//     headers: { Authorization: `Bearer ${mt}` }
//   };
//   axios.get(`${process.env.REACT_APP_BASE_URL}/api/auth/designationList`, config).then((res) => {
//     dispatch(designation_succ(res.data))
//     return res
//   }).catch((err) => {
//     if (err.message === "Network Error" || err.response.status === 500) {
//       dispatch(errorCall(err))
//       return;
//     }

//     if (err?.response?.data?.error == "Unauthenticated.") {
//       localStorage.removeItem("token");
//       navigate("/login");
//     }
//     dispatch(designation_failure(err))
//   })
// }

export const designationhort = (navigate, search, status, page, perpage) => (dispatch) => {
  dispatch(designation_req());
  const mt = getLocaldata()
  const config = {
    headers: { Authorization: `Bearer ${mt}` }
  };
  const formdata = {
    "search": search,
    "status": status,
    "pageNumber": page,
    perpage
  }
  axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/sortDesignations`, formdata, config).then((res) => {
    dispatch(designation_succ(res.data))
  }).catch((err) => {
    if (err.message === "Network Error" || err.response.status === 500) {
      dispatch(errorCall(err))
      return;
    }
    if (err?.response?.data?.error == "Unauthenticated.") {
      localStorage.removeItem("token");
      navigate("/login");
    }

    dispatch(designation_failure(err))
    if (err.message === "Network Error" || err.response.status === 500) {
      dispatch(errorCall(err))
    }
  })
}