import React, { useEffect, useState } from 'react';
import SettingsNavbar from '../Components/SettingsNavbar';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { BsFillArrowLeftCircleFill } from 'react-icons/bs';
import Avatar from '../Components/settings/Avatar';
import { useDispatch, useSelector } from 'react-redux';
import { findSkill,modify_framework } from '../Redux/settings/findSkill/action';
import { editSkill } from '../Redux/settings/updateskill/action';
import { addSkill } from '../Redux/settings/add_skill/action';
import {toast} from 'react-toastify'
import Spiner1 from '../Components/Spiner1';


const UpdateSkill = (props) => {
    let { id } = useParams();
    const location = useLocation();
    const [skillname, setSkillname] = useState("");
    const [status1, setStatus] = useState("0");
    const [skillId, setskillId] = useState(null);
    const [newFrameworkName,setNewFrameworkName]=useState("");
    const [newFrameworkStatus,setNewFrameworkStatus]=useState("1");
    const [addFrameworks,setAddFrameworks]=useState(false);
    const [editableFramework,setEditableFramework]=useState(null);
    const skillList = useSelector(state => state?.findSkillS);
    const updateSkill = useSelector(state => state.updateSkill);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [frameworkBackup,setFrameworkBackup]=useState({name:'',status:'1',index:''});

    const changeSkilName = (e) => {
        setSkillname(e.target.value);
    }
    const addSkillA = (e) => {
        e.preventDefault();
          function containsSpecialCharacters(str) {
            const regex = /^[^\w\s]+$/;
            return regex.test(str);
        }
        function containsBrackets(str) {
          // Regular expression to match any bracket characters
          const regex = /[\[\](){}<>]/;
          return regex.test(str);
        }
        if (skillname.trim().length === 0) {
            toast.warning('Please enter skill name');
            return
        }
        if(containsSpecialCharacters(skillname) || containsBrackets(skillname)){
          toast.warning('Please provide valid skill name');
          return
        }
        dispatch(editSkill(id, 0, skillname, skillId, status1, navigate))
    }
    const changeFrameworkName=(e,index,oldName)=>{
        if(frameworkBackup.name==''){
          setFrameworkBackup({...frameworkBackup,index:index,name:oldName});
        }
        dispatch(modify_framework({index,key:'name',value:e.target.value}));

    }
    const changeFrameworkStatus=(e,index,oldStatus)=>{
      if(frameworkBackup.status=='1'){
        setFrameworkBackup({...frameworkBackup,status:oldStatus});
      }
        dispatch(modify_framework({index,key:'status',value:e.target.value}));
    }
    const addEditable = (index) => {
      setEditableFramework(index);
    }

    const addNewFramework=()=>{
      setEditableFramework(false);
      function containsSpecialCharacters(str) {
        const regex = /^[^\w\s]+$/;
        return regex.test(str);
    }
    function containsBrackets(str) {
      // Regular expression to match any bracket characters
      const regex = /[\[\](){}<>]/;
      return regex.test(str);
    }
    
      let newFramework=newFrameworkName.replace(/\s+/g," ");
      if (newFramework.trim().length === 0) {
        toast.warning('Please enter framework name');
        return
      }
      if(containsSpecialCharacters(newFramework) || containsBrackets(newFramework)){
        toast.warning('Please provide valid framework');
        return
      }
      dispatch(addSkill(1, newFramework.trim(), skillList?.data?.data?.id, newFrameworkStatus, navigate)).then((res)=>{
        if(res!=undefined){
          // toast.success("Framework added successfully")
          setNewFrameworkName("");
          setNewFrameworkStatus("1");
          setAddFrameworks(!addFrameworks);
          dispatch(findSkill(id, navigate));
        }else{
         
        }
        
      })
    };
    const removeNewFrameWork=()=>{
        setNewFrameworkName("");
        setNewFrameworkStatus("1");
        setAddFrameworks(false);
    };
    const editFramework=(e,index)=>{
        e.preventDefault();
        function containsSpecialCharacters(str) {
          const regex = /^[^\w\s]+$/;
          return regex.test(str);
      }
      function containsBrackets(str) {
        // Regular expression to match any bracket characters
        const regex = /[\[\](){}<>]/;
        return regex.test(str);
      }

        let targetFrameWork=skillList?.data?.data?.linked_framework[index];
        if (targetFrameWork.name.trim().length === 0) {
            toast.warning('Please enter framework name');
            return
        }
        if(containsSpecialCharacters(targetFrameWork.name) || containsBrackets(targetFrameWork.name)){
          toast.warning('Please provide valid framework');
          return
        }
       dispatch(editSkill(targetFrameWork._id, 1, targetFrameWork.name.trim(), targetFrameWork.framework_skill_id, targetFrameWork.status, navigate,index)).then(()=>{
        setEditableFramework(null);
       }).catch((error)=>{
        frameworkBackup.name!='' && dispatch(modify_framework({index:frameworkBackup.index,key:'name',value:frameworkBackup.name}));
        dispatch(modify_framework({index:frameworkBackup.index,key:'status',value:frameworkBackup.status}));
        setFrameworkBackup({name:'',status:'1',index:''});
       })
    }
    useEffect(() => {
      skillList?.data?.data?.name && setSkillname(skillList?.data?.data?.name);
    }, [skillList?.data?.data?.name])
    useEffect(() => {
        setStatus(skillList?.data?.data?.status);
    }, [skillList?.data?.data?.status])


    useEffect(() => {
        dispatch(findSkill(id, navigate));
        // setSkillname(skillList?.data?.data?.name);
    }, []);
    return (
      <div>
        <SettingsNavbar
          navdata={{
            header: "Update Skill",
            buttonText: "Skill Button",
            buttonPath: "/settings/skillsuggetion",
          }}
          newSidedata={props.Sidedata}
          newonSidedataChange={props.onSidedataChange}
        />
        <div className="user-action-area">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <Link to={"/settings/skills"}>
                  {" "}
                  <BsFillArrowLeftCircleFill /> Skill List
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="create-company-form placeholder-glow">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="type">
                  <span className="font-weight-bold ">Skills</span>
                </div>
                <div className="form">
                  <div className="row g-2">
                    <div className="col-md-4">
                      <div className="position-relative list_search_fild  ">
                        <input
                          type="text"
                          name="query"
                          placeholder="Skill Name"
                          aria-label=""
                          value={skillname}
                          className={`form-control coustom_P h-100 ${ !skillList?.data?.data?.name?"placeholder ":""}`}
                          onChange={(e) => changeSkilName(e)}
                        />
                        <Avatar
                          placeholder={ !skillList?.data?.data?.name?"placeholder":""}
                          name={skillname}
                          size={"34px"}
                          position="absolute"
                          top="3px"
                          left="5px"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <select
                        value={status1}
                        className={`col-sm-4 mb-2 form-control form-control-custom  ${ !skillList?.data?.data?.name?"placeholder":""}`}
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <option
                          value="1"
                        >
                          Active
                        </option>
                        <option
                          value="0"
                        >
                          Inactive
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-3">
                      <button
                        className={`btn btn-success button-outline choosed ${ !skillList?.data?.data?.name?" placeholder":""}`}
                        disabled={
                          skillList?.isLoading ||
                          (updateSkill?.isLoading &&
                            updateSkill.targetFrameworkIndex == null)
                        }
                        onClick={(e) => addSkillA(e)}
                      >
                        {skillList?.isLoading ||
                        (updateSkill?.isLoading &&
                          updateSkill.targetFrameworkIndex == null) ? (
                          <Spiner1 />
                        ) : (
                          "Update"
                        )}
                      </button>
                    </div>
          </div>
        </div>
        {!skillList?.isError &&
        !skillList?.isLoading &&
        skillList?.data?.data?.linked_framework.length != 0 ? (
          <div className="create-company-form">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="type mb-0 mt-2 col-12 d-flex align-items-center justify-content-between flex-wrap">
                      <span className="font-weight-bold ">Frameworks</span>

                      <a
                        href="#"
                        style={{ color: "#3ab2e4", "fontSize": "small" }}
                        onClick={()=>setAddFrameworks(true)}
                      >
                        <i className="fa-solid fa-plus"></i>Add Frameworks
                      </a>
                    </div>
                  </div>
                  {addFrameworks?
                  <div className="form mt-3">
                        <div className="row g-2">
                          <div className="col-md-4">
                            <div className="position-relative list_search_fild">
                              <input
                                type="text"
                                name="query"
                                placeholder="New Framework Name"
                                aria-label=""
                                defaultValue={newFrameworkName?newFrameworkName:""}
                                className="form-control coustom_P h-100"
                                onChange={(e) => setNewFrameworkName(e.target.value)}
                              />
                              <Avatar
                                name={newFrameworkName?newFrameworkName:""}
                                size={"34px"}
                                position="absolute"
                                top="3px"
                                left="5px"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <select
                              className={`col-sm-4 mb-2 form-control form-control-custom  ${ !skillList?.data?.data?.name?"placeholder":""}`}
                              defaultValue={newFrameworkStatus?newFrameworkStatus:""}
                              onChange={(e) => setNewFrameworkStatus(e.target.value)}
                            >
                              <option value="1">Active</option>
                              <option value="0">Inactive</option>
                            </select>
                          </div>
                          <div className="col-md-4">
                            <button
                              className={`btn btn-success button-outline  ${ !skillList?.data?.data?.name?"placeholder":""}`}
                              onClick={() =>addNewFramework()}
                            >
                                  <i className="fa-regular fa-circle-check"></i>
                            </button>
                            
                            <button
                              className={`btn button-outline  ${ !skillList?.data?.data?.name?"placeholder":""}`}
                              style={{'backgroundColor':"#e4613a",'color':"#ffffff",'margin':"0px 0px 0px 4px"}}
                              onClick={() =>removeNewFrameWork()}
                            >
                                  <i className="fa-solid fa-x"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      :null}
                  {skillList?.data?.data?.linked_framework.map(
                    (data, index) => (
                      <div key={index} className="form mt-3">
                        <div className="row g-2">
                          <div className="col-md-4">
                            <div className="position-relative list_search_fild">
                              <input
                                type="text"
                                name="query"
                                placeholder="Framework Name"
                                aria-label=""
                                value={data?.name}
                                className="form-control coustom_P h-100"
                                onChange={(e) => changeFrameworkName(e, index,data?.name)}
                                disabled={
                                  editableFramework === index ? false : true
                                }
                              />
                              <Avatar
                                name={data.name?data?.name:""}
                                size={"34px"}
                                position="absolute"
                                top="3px"
                                left="5px"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <select
                              className="col-sm-4 mb-2 form-control form-control-custom"
                              value={data?.status}
                              onChange={(e) => changeFrameworkStatus(e, index,data?.status)}
                              disabled={
                                editableFramework === index ? false : true
                              }
                            >
                              <option value="1">Active</option>
                              <option value="0">Inactive</option>
                            </select>
                          </div>
                          <div className="col-md-4">
                            <button
                              className={`btn ${editableFramework === index?"btn-success":"btn-secondary"} button-outline  ${ !skillList?.data?.data?.name?"placeholder":""}`}
                              disabled={
                                skillList?.isLoading ||
                                (updateSkill?.isLoading &&
                                  updateSkill.targetFrameworkIndex == index)
                              }
                              onClick={(e) => {
                                editableFramework === index
                                  ? editFramework(e, index)
                                  : addEditable(index);
                              }}
                            >
                              {editableFramework === index ? (
                                skillList?.isLoading ||
                                (updateSkill?.isLoading &&
                                  updateSkill.targetFrameworkIndex == index) ? (
                                  <Spiner1 />
                                ) : (
                                  <i className="fa-regular fa-circle-check"></i>
                                )
                              ) : (
                                <i className="fa-solid fa-pen-to-square"></i>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
}

export default UpdateSkill